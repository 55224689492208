import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Divider } from '@shape-construction/arch-ui';
import { useCurrentUser } from 'app/queries/users/users';
import { BackNavigationButton } from '../Navbar/components/BackNavigationButton';
import { ForwardNavigationButton } from '../Navbar/components/ForwardNavigationButton';
import { ReloadButton } from '../Navbar/components/ReloadButton';
import ShapeLogo from '../ShapeLogo/ShapeLogo';

const SideDrawerHeader: React.FC = () => {
  const projectRoute = useMatch('/projects/:projectId/*');
  const notificationRoute = useMatch('/notifications/projects/:projectId');
  const match = projectRoute || notificationRoute;
  const { defaultProject } = useCurrentUser();

  const currentProjectId = match?.params?.projectId ?? defaultProject;
  return (
    <div className="section-header my-2 md:hidden">
      <div className="flex h-16 w-full items-center px-6 py-5 justify-between">
        <div className="h-full shrink-0">
          <Link to="/">
            <ShapeLogo withLettering />
          </Link>
        </div>
        <div className="flex items-center">
          {currentProjectId && (
            <>
              <BackNavigationButton color="primary" />
              <ForwardNavigationButton color="primary" />
            </>
          )}
          <ReloadButton color="primary" />
        </div>
      </div>
      <Divider orientation="horizontal" />
    </div>
  );
};
export { SideDrawerHeader };
