import { useAtom } from 'jotai';
import { useStableCallback } from '@shape-construction/hooks';
import { TourPage } from 'app/config/productTour.config';
import { makeAreAllTipsDismissedAtom } from '../state';

export const useEnablePageTour = (tourPage: TourPage) => {
  const [areAllTipsDismissed, setAreAllDismissed] = useAtom(makeAreAllTipsDismissedAtom(tourPage));
  const enablePageTour = useStableCallback(() => setAreAllDismissed(false));

  return { areAllTipsDismissed, enablePageTour };
};
