import * as React from 'react';
import type { SVGProps } from 'react';
const ShapeIssueTrackerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="outline/shape-issue-tracker">
      <path
        id="Icon"
        d="M9.14997 4.62422C9.35664 4.26526 9.23319 3.80671 8.87422 3.60003C8.51526 3.39336 8.05671 3.51681 7.85003 3.87578L9.14997 4.62422ZM15.15 4.62422C15.3566 4.26526 15.2332 3.80671 14.8742 3.60003C14.5153 3.39336 14.0567 3.51681 13.85 3.87578L15.15 4.62422ZM21.15 4.62422C21.3566 4.26526 21.2332 3.80671 20.8742 3.60003C20.5153 3.39336 20.0567 3.51681 19.85 3.87578L21.15 4.62422ZM3.5 19.25C3.08579 19.25 2.75 19.5858 2.75 20C2.75 20.4142 3.08579 20.75 3.5 20.75V19.25ZM8.5 20.75C8.91421 20.75 9.25 20.4142 9.25 20C9.25 19.5858 8.91421 19.25 8.5 19.25V20.75ZM15 19.25C14.5858 19.25 14.25 19.5858 14.25 20C14.25 20.4142 14.5858 20.75 15 20.75V19.25ZM20 20.75C20.4142 20.75 20.75 20.4142 20.75 20C20.75 19.5858 20.4142 19.25 20 19.25V20.75ZM7.85003 3.87578L3.10003 12.1258L4.39997 12.8742L9.14997 4.62422L7.85003 3.87578ZM13.85 3.87578L9.10003 12.1258L10.4 12.8742L15.15 4.62422L13.85 3.87578ZM19.85 3.87578L15.1 12.1258L16.4 12.8742L21.15 4.62422L19.85 3.87578ZM3.75 4.75H20.25V3.25H3.75V4.75ZM20.5 5V11.75H22V5H20.5ZM20.25 12H3.75V13.5H20.25V12ZM3.5 11.75V5H2V11.75H3.5ZM3.75 12C3.61193 12 3.5 11.8881 3.5 11.75H2C2 12.7165 2.7835 13.5 3.75 13.5V12ZM20.5 11.75C20.5 11.8881 20.3881 12 20.25 12V13.5C21.2165 13.5 22 12.7165 22 11.75H20.5ZM20.25 4.75C20.3881 4.75 20.5 4.86193 20.5 5H22C22 4.0335 21.2165 3.25 20.25 3.25V4.75ZM3.75 3.25C2.7835 3.25 2 4.0335 2 5H3.5C3.5 4.86193 3.61193 4.75 3.75 4.75V3.25ZM5.25 14.5V20H6.75V14.5H5.25ZM3.5 20.75H6V19.25H3.5V20.75ZM6 20.75H8.5V19.25H6V20.75ZM16.75 14.5V20H18.25V14.5H16.75ZM15 20.75H17.5V19.25H15V20.75ZM17.5 20.75H20V19.25H17.5V20.75Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ShapeIssueTrackerIcon;
