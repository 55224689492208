import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from 'app/queries/users/users';
import { defaultProjectPath } from 'app/utils/defaultProjectPath';

export const loginRoutes = ['/auth', '/signup'];
export const redirectParamBlackList = ['/auth', '/'];

export const usePostLoginRedirect = () => {
  const { search } = useLocation();
  const user = useCurrentUser();
  const { defaultProject, defaultProduct } = user;

  const redirectQueryParam = () => {
    const { redirect } = queryString.parse(search);
    return Array.isArray(redirect) ? redirect[0] : redirect;
  };

  const getPostLoginPath = () => {
    const redirectParam = redirectQueryParam();
    if (redirectParam) {
      return redirectParam;
    }

    return defaultProjectPath(defaultProject, defaultProduct);
  };

  return {
    postLoginPath: getPostLoginPath(),
  };
};
