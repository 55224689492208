import * as React from 'react';
import type { SVGProps } from 'react';
const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/check-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6C17.302 21.6 21.6 17.3019 21.6 12C21.6 6.69806 17.302 2.39999 12 2.39999C6.69809 2.39999 2.40002 6.69806 2.40002 12C2.40002 17.3019 6.69809 21.6 12 21.6ZM16.6279 9.82935C16.9202 9.42736 16.8314 8.86449 16.4294 8.57213C16.0274 8.27978 15.4645 8.36865 15.1722 8.77064L10.9917 14.5188L8.73642 12.2636C8.38495 11.9121 7.8151 11.9121 7.46363 12.2636C7.11216 12.6151 7.11216 13.1849 7.46363 13.5364L10.4636 16.5364C10.6497 16.7225 10.908 16.8178 11.1704 16.7972C11.4328 16.7766 11.6731 16.6422 11.8279 16.4293L16.6279 9.82935Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CheckCircleIcon;
