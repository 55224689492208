import { matchRoutes, useLocation } from 'react-router-dom';
import { PageLabel, pageLabel } from 'app/constants/PageLabel';

type Path = keyof typeof labelsRoute;

const defaultLabels: PageLabel[] = ['general'];
const labelsRoute = {
  '/': [pageLabel.home],
  '/my-profile': [pageLabel.my_account],
  '/my-projects': [pageLabel.my_projects],
  '/my-projects/new': [pageLabel.new_project],
  '/projects/:projectId/issues/lists/all': [pageLabel.issue_list],
  '/projects/:projectId/issues/lists/my-actions': [pageLabel.issue_list],
  '/projects/:projectId/issues/lists/im-responsible': [pageLabel.issue_list],
  '/projects/:projectId/issues/lists/watching': [pageLabel.issue_list],
  '/projects/:projectId/issues/lists/unpublished': [pageLabel.issue_list],
  '/projects/:projectId/issues/lists/archived': [pageLabel.issue_list],
  '/projects/:projectId/issues/:issueId': [pageLabel.issue_details],
  '/projects/:projectId/issues/:issueId/export': [pageLabel.issue_export],
  '/projects/:projectId/timeline': [pageLabel.timeline],
  '/projects/:projectId/settings/locations': [pageLabel.locations],
  '/projects/:projectId/settings/teams': [pageLabel.teams],
  '/projects/:projectId/settings/teams/:teamId': [pageLabel.show_team],
  '/projects/:projectId/settings/teams/:teamId/setup': [pageLabel.setup_team],
  '/projects/:projectId/settings/teams/invite': [pageLabel.invite_team],
  '/projects/:projectId/settings/access-requests': [pageLabel.access_requests],
  '/projects/:projectId/settings/custom-fields': [pageLabel.custom_fields],
  '/projects/:projectId/settings/custom-fields/new': [pageLabel.new_custom_field],
  '/projects/:projectId/settings/custom-fields/:customFieldId/edit': [pageLabel.edit_custom_field],
  '/notifications': [pageLabel.notifications],
  // TODO: check how to handle with querystring
  // '/notifications/projects/:projectId?issueId': [pageLabel['issue_details_n']],
  '/notifications/projects/:projectId': [pageLabel.issue_details_n],
  '/projects/:projectId/search': [pageLabel.search],
  '/projects/:projectId/filter': [pageLabel.filter],
  '/projects/:projectId/filter/new': [pageLabel.new_filter],
  '/projects/:projectId/filter/apply': [pageLabel.apply_filter],
  '/projects/:projectId/shift-reports': [pageLabel.shift_reports],
  '/projects/:projectId/shift-reports/drafts': [pageLabel.shift_reports_in_progress],
  '/projects/:projectId/shift-reports/archived': [pageLabel.shift_reports_archived],
  '/projects/:projectId/shift-reports/:shiftReportId': [pageLabel.shift_report_preview],
  '/projects/:projectId/shift-reports/:shiftReportId/collaborators': [
    pageLabel.shift_report_collaborators,
  ],
  '/projects/:projectId/shift-reports/:shiftReportId/manager': [
    pageLabel.shift_reports_manager_view,
  ],
  '/projects/:projectId/shift-reports/:shiftReportId/edit': [pageLabel.shift_report_edit],
} satisfies Record<string, PageLabel[]>;

const routes = Object.keys(labelsRoute) as Path[];
const routesPaths = routes.map((path) => ({ path }));

export const useWidgetLabels = (): PageLabel[] => {
  const location = useLocation();
  const matchedRouted = matchRoutes(routesPaths, location);
  const currentRoute = matchedRouted?.at(0);

  const path = currentRoute?.route.path;

  if (!path || !labelsRoute[path]) return defaultLabels;
  return labelsRoute[path];
};
