import * as React from 'react';
import type { SVGProps } from 'react';
const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/calendar">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90002 2.39999C7.39708 2.39999 7.80002 2.80294 7.80002 3.29999V4.79999H16.2V3.29999C16.2 2.80294 16.603 2.39999 17.1 2.39999C17.5971 2.39999 18 2.80294 18 3.29999V4.79999H18.3C20.1226 4.79999 21.6 6.27745 21.6 8.09999V18.3C21.6 20.1225 20.1226 21.6 18.3 21.6H5.70003C3.87749 21.6 2.40002 20.1225 2.40002 18.3V8.09999C2.40002 6.27746 3.87748 4.79999 5.70002 4.79999H6.00002V3.29999C6.00002 2.80294 6.40297 2.39999 6.90002 2.39999ZM5.70002 8.99999C4.8716 8.99999 4.20002 9.67157 4.20002 10.5V18.3C4.20002 19.1284 4.8716 19.8 5.70003 19.8H18.3C19.1285 19.8 19.8 19.1284 19.8 18.3V10.5C19.8 9.67157 19.1285 8.99999 18.3 8.99999H5.70002Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CalendarIcon;
