import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import isEqual from 'lodash.isequal';
import { TourPage, productTourConfig } from 'app/config/productTour.config';
import { TipState, productTourTipRegistryAtom } from './productTourTipRegistryAtom';

const defaultTipState: Readonly<TipState> = { dismissed: false };

export type ProductTourRegistry = {
  [key in TourPage]?: Record<string, TipState | undefined>;
};

export const makePageTourAtom = atomFamily((tourPage: TourPage) =>
  atom(
    (get): undefined | Record<string, TipState | undefined> =>
      get(productTourTipRegistryAtom)[tourPage],
    (_get, set, state: Record<string, TipState | undefined>) => {
      set(productTourTipRegistryAtom, (tourTips) => ({
        ...tourTips,
        [tourPage]: state,
      }));
    }
  )
);

export const makeProductTourTipAtom = atomFamily(
  ([tourPage, tipId]: [TourPage, string]) =>
    atom(
      (get): TipState => ({ ...defaultTipState, ...get(makePageTourAtom(tourPage))?.[tipId] }),
      (get, set, state: TipState) => {
        const pageTourAtom = makePageTourAtom(tourPage);
        set(pageTourAtom, { ...get(pageTourAtom), [tipId]: state });
      }
    ),
  isEqual
);

const mapObjectValues = <T, R>(
  obj: Record<string, T>,
  fn: (value: T, key: string) => R
): Record<string, R> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, fn(value, key)]));

export const makeAreAllTipsDismissedAtom = atomFamily((tourPage: TourPage) =>
  atom(
    (get) => {
      const pageTourTips = get(makePageTourAtom(tourPage));
      if (!pageTourTips || Object.keys(productTourConfig.tips[tourPage] ?? {}).length === 0)
        return false;

      return Object.keys(productTourConfig.tips[tourPage] ?? {}).every(
        (tipId) => pageTourTips?.[tipId]?.dismissed === true
      );
    },
    (_get, set, allDismissed: boolean) => {
      if (allDismissed) {
        const tips = mapObjectValues(productTourConfig.tips[tourPage] ?? {}, (_) => ({
          dismissed: true,
        }));
        set(makePageTourAtom(tourPage), tips);
      } else {
        set(makePageTourAtom(tourPage), {});
      }
    }
  )
);
