import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  settingsContainer: {
    marginLeft: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    fontWeight: 'bolder',
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },
  settingsListItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  userDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  userNameAndMembership: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
    paddingBottom: '12px',
  },
  appVersionListItem: {
    'color': theme.palette.secondary.main,
    '& span': {
      fontSize: '12px',
    },
  },
}));
