import { useQuery } from '@tanstack/react-query';
import { matchRoutes, useLocation, useMatch } from 'react-router-dom';
import { MenuItem } from 'app/components/UI/ResponsiveSideDrawer/side-menu.types';
import { useMenuItems } from 'app/components/UI/ResponsiveSideDrawer/SideDrawerContentItems';
import { getProjectQueryOptions } from 'app/queries/projects/projects';

interface CurrentProductPage {
  key: MenuItem['key'];
  title: MenuItem['title'];
}

export const useCurrentProductPage = (): CurrentProductPage | null => {
  // Using useMatch instead of useParams because this hook is used outside the context of Router
  const projectRoute = useMatch('/projects/:projectId/*');
  const currentProjectId = projectRoute?.params.projectId;

  const { data: project } = useQuery(
    getProjectQueryOptions(currentProjectId!, { query: { enabled: !!currentProjectId } })
  );
  const menuItems = useMenuItems(project);
  const location = useLocation();
  const currentPath = location.pathname;

  const selectedMenuItem = menuItems.find(
    (menuItem) => menuItem.isActiveOn && matchRoutes([{ path: menuItem.isActiveOn }], currentPath)
  );

  return selectedMenuItem ? { key: selectedMenuItem.key, title: selectedMenuItem.title } : null;
};
