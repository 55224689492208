import * as React from 'react';
import type { SVGProps } from 'react';
const CsvIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/csv">
      <g id="Vector" filter="url(#filter0_dd_3816_37941)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="#059669"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="url(#paint0_radial_3816_37941)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <g id="Vector_2">
        <path
          d="M18.941 15.8889C20.6201 15.8889 21.9114 16.3321 22.8149 17.2185C23.7269 18.0964 24.1829 19.3536 24.1829 20.9901V29.556H22.2907L21.9327 27.9835C20.6201 29.2023 19.1456 29.8117 17.5091 29.8117C16.1625 29.8117 15.0928 29.4495 14.3001 28.725C13.5074 28.0005 13.1111 27.0289 13.1111 25.81C13.1111 24.3952 13.6907 23.2914 14.8499 22.4987C16.009 21.7061 17.637 21.3097 19.7337 21.3097H21.7154V20.939C21.6472 19.0212 20.6627 18.0624 18.7621 18.0624C18.1825 18.0624 17.5304 18.1518 16.806 18.3308C16.0815 18.5013 15.3527 18.7357 14.6197 19.034L13.9421 17.0396C14.7263 16.6731 15.553 16.3918 16.4224 16.1958C17.3003 15.9912 18.1399 15.8889 18.941 15.8889ZM19.8871 23.1891C18.5234 23.1891 17.4623 23.415 16.7037 23.8667C15.9451 24.3099 15.5658 24.9321 15.5658 25.7333C15.5658 26.347 15.7746 26.82 16.1923 27.1524C16.6099 27.4763 17.1938 27.6383 17.9438 27.6383C18.6598 27.6383 19.3587 27.4891 20.0405 27.1908C20.7224 26.884 21.2807 26.4748 21.7154 25.9634V23.1891H19.8871Z"
          fill="white"
        />
        <path d="M26.9072 26.9351H29.3619L27.0351 32.7778H25.4625L26.9072 26.9351Z" fill="white" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_37941"
        x={2.66666}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_37941" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3816_37941"
          result="effect2_dropShadow_3816_37941"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3816_37941"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_3816_37941"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default CsvIcon;
