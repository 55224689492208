import React from 'react';
import { twMerge } from 'tailwind-merge';

export type ModalBaseContentProps = React.HtmlHTMLAttributes<HTMLDivElement>;

export const ModalBaseContent: React.FC<ModalBaseContentProps> = ({ className, ...props }) => {
  const classes = twMerge('flex h-full grow flex-col overflow-auto px-6', className);

  return <div data-testid="modal-content" className={classes} {...props} />;
};
