import { useQueryClient } from '@tanstack/react-query';

import {
  getApiNotifications,
  getGetApiNotificationsQueryKey,
  usePostApiNotificationsMarkAllRead,
  usePostApiNotificationsNotificationIdMarkRead,
  getGetApiNotificationsOverviewQueryKey,
  getGetApiNotificationsOverviewQueryOptions,
  getGetApiNotificationsInfiniteQueryOptions,
} from '@shape-construction/api/api';

import { environment } from 'app/config/environment';

// Queries
export const getNotificationsOverviewQueryOptions: typeof getGetApiNotificationsOverviewQueryOptions =
  (...args) => {
    const [options] = args;

    return getGetApiNotificationsOverviewQueryOptions({
      query: {
        ...options?.query,
        refetchInterval: Number(environment.NOTIFICATIONS_CHECK_INTERVAL_MS),
        refetchOnWindowFocus: false,
      },
    });
  };

export const getNotificationsInfiniteQueryOptions: typeof getGetApiNotificationsInfiniteQueryOptions =
  (...args) => {
    const [params, options] = args;

    return getGetApiNotificationsInfiniteQueryOptions(params, {
      query: {
        queryFn: ({ pageParam, signal }) =>
          getApiNotifications(
            {
              ...params,
              // @ts-expect-error
              ...pageParam,
            },
            options?.request,
            signal
          ),
        getNextPageParam: ({ meta }) =>
          meta.currentPage !== meta.totalPages ? { page: meta.currentPage + 1 } : undefined,
        refetchInterval: Number(environment.NOTIFICATIONS_CHECK_INTERVAL_MS),
        refetchOnWindowFocus: false,
        initialPageParam: { page: undefined as undefined | number },
      },
    });
  };

// Mutations
export const useNotificationMarkReadMutation = () => {
  const queryClient = useQueryClient();

  return usePostApiNotificationsNotificationIdMarkRead({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: getGetApiNotificationsQueryKey() });
        queryClient.invalidateQueries({ queryKey: getGetApiNotificationsOverviewQueryKey() });
      },
    },
  });
};

export const useNotificationMarkReadAllMutation = () => {
  const queryClient = useQueryClient();

  return usePostApiNotificationsMarkAllRead({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: getGetApiNotificationsQueryKey() });
        queryClient.invalidateQueries({ queryKey: getGetApiNotificationsOverviewQueryKey() });
      },
    },
  });
};
