import React from 'react';
import { XMarkIcon } from '../../Icons/solid';
import { IconButton } from '../../Button/IconButton/IconButton';

import { AlertColor, AlertEmphasis } from '../alert-config';

export type AlertCloseButtonProps = {
  onClose: () => void;
  color: AlertColor;
  emphasis: AlertEmphasis;
};

export const AlertCloseButton: React.FC<AlertCloseButtonProps> = ({ onClose, color, emphasis }) => {
  const iconVariant = emphasis === 'subtle' ? 'text' : 'contained';
  return (
    <div className="flex items-center -mr-2 ml-1">
      <IconButton
        aria-label="close alert"
        type="button"
        size="md"
        shape="square"
        color={color}
        variant={iconVariant}
        icon={XMarkIcon}
        onClick={onClose}
      />
    </div>
  );
};
