import { atom, useAtom } from 'jotai';

export const openPopoverAtom = atom<boolean>(false);

export const useProductTipsPopover = () => {
  const [isProductTipsPopoverOpen, setProductTipsPopoverOpen] = useAtom(openPopoverAtom);

  const openProductTipsPopover = () => setProductTipsPopoverOpen(true);
  const closeProductTipsPopover = () => setProductTipsPopoverOpen(false);

  return { isProductTipsPopoverOpen, openProductTipsPopover, closeProductTipsPopover };
};
