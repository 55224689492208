import * as React from 'react';
import type { SVGProps } from 'react';
const EmlIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/eml">
      <g id="Vector" filter="url(#filter0_dd_3816_37913)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="url(#paint0_radial_3816_37913)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2221 7.44444C26.2221 8.9172 27.416 10.1111 28.8887 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="#F3F4F6"
        />
      </g>
      <path
        id="Vector_2"
        d="M23.083 23.9856L29.4093 27.9811V19.8289L23.083 23.9856ZM22.0196 24.6945L20.3548 25.79C20.2581 25.8545 20.1507 25.8867 20.0326 25.8867C19.9252 25.8867 19.807 25.8545 19.7104 25.79L18.0563 24.6945L10.6989 29.3452C10.7955 29.9037 11.2574 30.3333 11.8374 30.3333H28.2385C28.8185 30.3333 29.2804 29.9037 29.3663 29.3452L22.0196 24.6945ZM28.2385 17.4445H11.8374C11.2467 17.4445 10.7848 17.8848 10.6989 18.4433L20.0326 24.5978L29.3663 18.4433C29.2804 17.8848 28.8185 17.4445 28.2385 17.4445ZM10.6667 19.8289V27.9811L16.9822 23.9856L10.6667 19.8289Z"
        fill="#374151"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_37913"
        x={2.66666}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_37913" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3816_37913"
          result="effect2_dropShadow_3816_37913"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3816_37913"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_3816_37913"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.03} />
      </radialGradient>
    </defs>
  </svg>
);
export default EmlIcon;
