import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="outline/chevron-down">
      <path
        id="Icon"
        d="M19 8.90002L12 15.9L5 8.90002"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default ChevronDownIcon;
