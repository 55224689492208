import { DocumentPayload } from '../types';

const MAX_FILE_SIZE_IN_BYTES = 100 * 1024 * 1024;

type PayloadValidationError = {
  filename: string;
  error: string;
};

type ResultType = {
  allValid: boolean;
  validFiles: DocumentPayload[];
  validationErrors: PayloadValidationError[];
};

type PayloadValidationProps = {
  maxSize?: number;
};

class PayloadValidation {
  maxSize: number;

  constructor({ maxSize = MAX_FILE_SIZE_IN_BYTES }: PayloadValidationProps = {}) {
    this.maxSize = maxSize;
  }

  validateInput(items: DocumentPayload[]): ResultType {
    const exceedsSize = (payload: DocumentPayload) => payload?.file.size > this.maxSize;

    let result: ResultType = {
      allValid: true,
      validFiles: Array.from(items),
      validationErrors: [],
    };

    result = this.filterResults(result, exceedsSize, 'file_too_big');

    return result;
  }

  private filterResults(
    current: ResultType,
    filter: (payload: DocumentPayload) => boolean,
    errorMessage: string
  ) {
    const validFiles: DocumentPayload[] = [];
    const validationErrors: PayloadValidationError[] = [];

    current.validFiles.forEach((payload) => {
      const fileTooBig = filter(payload);
      if (fileTooBig) {
        validationErrors.push({ filename: payload?.file.name, error: errorMessage });
      } else {
        validFiles.push(payload);
      }
    });

    return {
      allValid: validFiles.length === current.validFiles.length,
      validFiles,
      validationErrors: [...current.validationErrors, ...validationErrors],
    };
  }
}

export default PayloadValidation;
export { MAX_FILE_SIZE_IN_BYTES, PayloadValidationError };
