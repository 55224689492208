import { useEffect, useMemo } from 'react';
import { keepPreviousData } from '@tanstack/react-query';
import { getGetApiFeatureFlagsQueryKey, useGetApiFeatureFlags } from '@shape-construction/api/api';
import type { FeatureFlagSchema, UserSchema } from '@shape-construction/api/model';
import { FEATURE_FLAGS_CONFIG, FlagName } from 'app/config/featureFlags.config';
import { useDeviceSessionId } from 'app/hooks/useDeviceSessionId';
import { useCurrentTeamId } from 'app/queries/projects/teams';
import { useCurrentUser } from 'app/queries/users/users';
import { FeatureFlags, FeatureFlagsByTarget, FlagConfig, FlagResult } from './types';
import { pickTargetFeatureFlag } from './utils/flagValue';

const mapValues = <T, R>(
  obj: Record<string, T>,
  fn: (value: T, key: string) => R
): Record<string, R> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, fn(value, key)]));

const toFeatureFlags = (flags: FeatureFlagSchema[]): FeatureFlags<string> =>
  Object.fromEntries(flags.map((flag) => [flag.key, flag]));

export const useFeatureFlag = <
  Name extends FlagName,
  Config extends FlagConfig<any> = (typeof FEATURE_FLAGS_CONFIG)[Name],
>(
  name: Name,
  defaultValue?: Config['default']
): FlagResult<Config> => {
  const user = useCurrentUser() as UserSchema | undefined;
  const deviceSessionId = useDeviceSessionId();
  const { data: featureFlagsData, refetch } = useGetApiFeatureFlags(
    { device_session_id: deviceSessionId },
    {
      query: {
        queryKey: [
          ...getGetApiFeatureFlagsQueryKey({ device_session_id: deviceSessionId }),
          user?.id,
        ],
        refetchOnWindowFocus: process.env.NODE_ENV === 'development', // Refetch on focus only during development
        refetchOnMount: false,
        refetchOnReconnect: true,
        placeholderData: keepPreviousData,
        staleTime: process.env.NODE_ENV === 'development' ? 5 * 1000 : 5 * 60 * 1000, // 5 seconds on development, 5 minutes otherwise
        refetchInterval: 10 * 60 * 1000, // 10 minutes
      },
    }
  );

  const flagsByTarget: FeatureFlagsByTarget | undefined = useMemo(() => {
    if (!featureFlagsData) return undefined;
    return {
      user: featureFlagsData.user && toFeatureFlags(featureFlagsData.user),
      session: featureFlagsData.session && toFeatureFlags(featureFlagsData.session),
      teams: featureFlagsData.teams && mapValues(featureFlagsData.teams, toFeatureFlags),
    };
  }, [featureFlagsData]);

  const currentTeamId = useCurrentTeamId();

  useEffect(() => {
    if (!flagsByTarget || !currentTeamId) return;
    const config = FEATURE_FLAGS_CONFIG[name];
    // Refetch flags if it's not available for the current team
    if (config?.target === 'team' && !flagsByTarget?.teams?.[`${currentTeamId}`]) {
      refetch();
    }
  }, [currentTeamId, flagsByTarget, name, refetch]);

  return pickTargetFeatureFlag(name, flagsByTarget, { defaultValue, currentTeamId });
};
