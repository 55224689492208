import React from 'react';
import classNames from 'clsx';
import { ButtonBase, ButtonBaseProps } from './ButtonBase';
import { getIconClasses } from './IconClasses';
import { innerSizeClassesMap } from './ButtonClasses';

export type ButtonProps = Omit<ButtonBaseProps, 'className' | 'shape'> & {
  leadingIcon?: React.ElementType;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ leadingIcon: LeadingIcon, ...props }, ref) => {
    const { color, variant, size } = props;
    const { iconClasses } = getIconClasses({ color, variant, size });

    return (
      <ButtonBase {...props} ref={ref}>
        <span
          className={classNames('inline-flex flex-row items-center', innerSizeClassesMap[size])}
        >
          {!!LeadingIcon && <LeadingIcon data-testid="leading-icon" className={iconClasses} />}
          {props.children}
        </span>
      </ButtonBase>
    );
  }
);

Button.displayName = 'Button';
