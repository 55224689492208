import React from 'react';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';
import { getIconClasses } from '../IconClasses';

export type IconButtonProps = Omit<ButtonBaseProps, 'className' | 'fullWidth' | 'children'> & {
  icon: React.ElementType;
};

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon: Icon, ...props }, ref) => {
    const { color, variant, size, shape = 'rounded' } = props;
    const { iconClasses } = getIconClasses({ color, variant, size });

    return (
      <ButtonBase shape={shape} {...props} ref={ref}>
        <Icon data-testid="icon" className={iconClasses} />
      </ButtonBase>
    );
  }
);

IconButton.displayName = 'IconButton';
