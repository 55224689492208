import * as React from 'react';
import type { SVGProps } from 'react';
const ExclamationCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/exclamation-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12C21.6 17.3019 17.3019 21.6 12 21.6C6.69806 21.6 2.39999 17.3019 2.39999 12C2.39999 6.69806 6.69806 2.39999 12 2.39999C17.3019 2.39999 21.6 6.69806 21.6 12ZM12 5.99999C12.4971 5.99999 12.9 6.40294 12.9 6.89999V12.3C12.9 12.7971 12.4971 13.2 12 13.2C11.5029 13.2 11.1 12.7971 11.1 12.3V6.89999C11.1 6.40294 11.5029 5.99999 12 5.99999ZM12 18C12.6627 18 13.2 17.4627 13.2 16.8C13.2 16.1373 12.6627 15.6 12 15.6C11.3373 15.6 10.8 16.1373 10.8 16.8C10.8 17.4627 11.3373 18 12 18Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ExclamationCircleIcon;
