import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getGetApiUsersMeQueryKey } from '@shape-construction/api/api';
import type { UserSchema } from '@shape-construction/api/model';
import { showErrorToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import type { ResponseError } from 'app/axios';
import { getToken, setToken } from './authorization';
import queryClient from './queries/query.client.builder';
import { endSession, hasAuthToken } from './session';

const getConsentData = () => window?.CookieConsent?.consent || null;
const cookieConsentHeaderContent = ({ statistics }: Consent) => JSON.stringify({ statistics });

export const setupAuthorizationHeader = (request: InternalAxiosRequestConfig) => {
  const config = { ...request };
  const authorization = getToken();
  const consentData = getConsentData();

  config.headers = config.headers ?? {};

  if (authorization) {
    config.headers.Authorization = authorization;
  }

  if (consentData) {
    config.headers['Cookie-Consent'] = cookieConsentHeaderContent(consentData);
  }

  return config;
};

export const preserveAuthorizationHeader = (response: AxiosResponse) => {
  const authorization = response.headers.authorization;
  if (authorization) {
    setToken(authorization);
  }
};

export const onMaintenanceMode = () => {
  window.location.assign('/maintenance.html');
};

export const onMutateError = (error: ResponseError) => {
  const errorMessage =
    error.response?.data?.errorDescription ?? error?.message ?? 'Something went wrong';
  showErrorToast({ message: errorMessage });
};

export const onUnAuthorized = () => {
  if (hasAuthToken()) {
    showErrorToast({ message: "You've been inactive for a while and your session expired" });
    endSession();
  }
};

export const onNotAcceptedEndUserAgreement = () => {
  const user = queryClient.getQueryData<UserSchema>(getGetApiUsersMeQueryKey());

  if (user) {
    queryClient.setQueryData(getGetApiUsersMeQueryKey(), { ...user, pendingEuaAcceptance: true });
  }
};
