import React from 'react';
import { useMediaQuery } from '@shape-construction/hooks';
import { breakpoints } from '../utils/breakpoints';
import { ModalBase, ModalBaseProps } from '../ModalBase';
import { DrawerFooter } from './components/DrawerFooter';
import { DrawerHeader } from './components/DrawerHeader';
import { DrawerContent } from './components/DrawerContent';
import { DrawerPersistent } from './components/DrawerPersistent';

type Components = {
  Header: typeof DrawerHeader;
  Title: typeof ModalBase.Title;
  Subtitle: typeof ModalBase.SubTitle;
  Content: typeof DrawerContent;
  Footer: typeof DrawerFooter;
  Persistent: typeof DrawerPersistent;
};

export type DrawerProps = Pick<
  ModalBaseProps,
  'open' | 'onClose' | 'maxWidth' | 'zIndex' | 'transition'
> & {
  children: React.ReactNode;
};

export const Drawer: React.FC<DrawerProps> & Components = ({
  children,
  maxWidth = 'md',
  ...drawerProps
}) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  return (
    <ModalBase
      transition="right-to-left"
      {...drawerProps}
      fullWidth
      maxWidth={isLargeScreen ? maxWidth : 'none'}
      fullScreen={!isLargeScreen}
      className="fixed inset-y-0 right-0"
    >
      {children}
    </ModalBase>
  );
};

Drawer.Title = ModalBase.Title;
Drawer.Subtitle = ModalBase.SubTitle;
Drawer.Header = DrawerHeader;
Drawer.Content = DrawerContent;
Drawer.Footer = DrawerFooter;
Drawer.Persistent = DrawerPersistent;
