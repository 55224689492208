import * as React from 'react';
import type { SVGProps } from 'react';
const XlmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/xlm">
      <g id="Vector" filter="url(#filter0_dd_3816_37956)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="#059669"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="url(#paint0_radial_3816_37956)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6667 15.2222H17.3333V20.5555H19.1111V22.3333H13.7778H13.5556H12V25.8888L10.2222 25.8888V31.2222H15.5556V25.8888L13.7778 25.8888V24.1111H19.1111V25.8888H17.3333V31.2222H22.6667V25.8888H20.8889V24.1111H26.2222V22.3333H20.8889V20.5555H22.6667V15.2222ZM26.2222 22.3333H28V25.8888L29.7778 25.8888V31.2222H24.4445V25.8888L26.2222 25.8888V22.3333Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_37956"
        x={2.66666}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_37956" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3816_37956"
          result="effect2_dropShadow_3816_37956"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3816_37956"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_3816_37956"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default XlmIcon;
