export class DirectUploadError extends Error {
  constructor(message: any, ...params: any[]) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    super(params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DirectUploadError);
    }

    this.name = 'DirectUploadError';
    this.message = message;
  }
}
