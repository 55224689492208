import * as actionTypes from '../actions/actionTypes';

const changeLayout = (state: any, action: any) => ({
  ...state,
  ...action,
});

export const layoutReducer = (state = {}, action: any) => {
  switch (action.type) {
    case actionTypes.CHANGE_LAYOUT:
      return changeLayout(state, action);
    default:
      return state;
  }
};
