/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Shape API
 * OpenAPI spec version: v1
 */

export type ShiftReportVisibilitySchema =
  (typeof ShiftReportVisibilitySchema)[keyof typeof ShiftReportVisibilitySchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShiftReportVisibilitySchema = {
  private: 'private',
  public: 'public',
  specific_teams: 'specific_teams',
} as const;
