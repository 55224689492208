import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-right">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.59998 12C3.59998 11.5029 4.00292 11.1 4.49998 11.1L17.2655 11.1L12.2762 6.34874C11.9179 6.00422 11.9067 5.43449 12.2512 5.07619C12.5957 4.7179 13.1655 4.70672 13.5238 5.05124L20.1238 11.3512C20.3002 11.5209 20.4 11.7552 20.4 12C20.4 12.2448 20.3002 12.4791 20.1238 12.6487L13.5238 18.9487C13.1655 19.2933 12.5957 19.2821 12.2512 18.9238C11.9067 18.5655 11.9179 17.9958 12.2762 17.6512L17.2655 12.9L4.49998 12.9C4.00292 12.9 3.59998 12.497 3.59998 12Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ArrowRightIcon;
