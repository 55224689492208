import React from 'react';
import { useStyles } from './Loading.style';

export const LOADING_VARIANT = {
  SCREEN: 'screen',
};

export interface LoadingSpinnerProps {
  variant?: (typeof LOADING_VARIANT)[keyof typeof LOADING_VARIANT];
}

export const LoadingSpinner: React.VFC<LoadingSpinnerProps> = ({ variant }) => {
  const classes = useStyles();
  const isMax = variant === LOADING_VARIANT.SCREEN;

  return (
    <div className={isMax ? classes.spinBoxMax : classes.spinBoxMin}>
      <div data-testid="res-loading" className={classes.loadingSpinner} />
    </div>
  );
};
