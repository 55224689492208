import * as actionTypes from '../actions/actionTypes';

export const notificationsPermissionAccept = () => ({
  type: actionTypes.NOTIFICATIONS_PERMISSIONS_ACCEPT,
});

export const notificationsPermissionReject = () => ({
  type: actionTypes.NOTIFICATIONS_PERMISSIONS_REJECT,
});

export const userConsentAccept = () => ({
  type: actionTypes.USER_CONSENT_ACCEPT,
});

export const userConsentClose = () => ({
  type: actionTypes.USER_CONSENT_CLOSE,
});

export const userConsentDeny = () => ({
  type: actionTypes.USER_CONSENT_DENY,
});
