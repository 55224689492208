import localForage from 'localforage';
import { persistReducer, PersistConfig } from 'redux-persist';
import type { RootState } from '../../store';
import { persistVersion, migrations } from './migrations';

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: localForage,
  blacklist: ['errors'],
  version: persistVersion,
  migrate: migrations,
};

export const storePersistReducer = (rootReducer: any) => persistReducer(persistConfig, rootReducer);

export * from 'redux-persist';
