const AUTHORIZATION_KEY = 'authorization';

export const setToken = (token: string) => {
  localStorage.setItem(AUTHORIZATION_KEY, token);
};

export const getToken = () => localStorage.getItem(AUTHORIZATION_KEY);

export const clearToken = () => {
  localStorage.removeItem(AUTHORIZATION_KEY);
};
