import { ProjectSchema } from '@shape-construction/api/model';
import { formatDateTimezoneAndKeepLocalTimeToUtc } from '@shape-construction/utils/DateTime';
import {
  type PredefinedOptionKey,
  datePickerPredefinedOptionsMap,
} from 'app/components/Filters/DateSelect/DateSelectOptions';
import type { GalleryFilters } from '../useGalleryParams';

export const useDateFiltersFromGallery = (galleryFilters: GalleryFilters, project?: ProjectSchema) => {
  const relativeDate = galleryFilters.relativeDate as PredefinedOptionKey;
  if (!relativeDate) {
    return {
      date: undefined,
      endDate: undefined,
    };
  }
  const range =
    relativeDate === 'custom'
      ? datePickerPredefinedOptionsMap.custom.value(galleryFilters.date, galleryFilters.endDate)
      : datePickerPredefinedOptionsMap[relativeDate].value();

  return {
    date: formatDateTimezoneAndKeepLocalTimeToUtc(range.from!, project?.timezone!),
    endDate: formatDateTimezoneAndKeepLocalTimeToUtc(range.to!, project?.timezone!),
  };
};
