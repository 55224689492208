import type { Article, Locale, PageParams, PageResponse } from 'zendesk/api/help-center/types';
import { BaseApi } from 'zendesk/base-api';

export type GetArticlesParams = PageParams & { label_names?: string[] };
export type GetArticlesResponse = PageResponse & { articles: Article[] };

export class Articles extends BaseApi {
  getArticlesByLocale(locale: Locale, params?: GetArticlesParams) {
    return this.request<GetArticlesResponse>({
      url: `/${locale}/articles`,
      method: 'get',
      params,
    });
  }
}
