import React from 'react';
import UtilsHtml from '../../Utils/UtilsHtml';
import type { MenuItem } from './side-menu.types';
import { SideDrawerHeading } from './SideDrawerHeading';
import { SideDrawerListCategory } from './SideDrawerListCategory';

export interface SideDrawerSectionProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  title?: string;
  isExpanded: boolean;
  menuItems: MenuItem[];
  clickAction: React.MouseEventHandler<HTMLElement>;
}

const SideDrawerSection: React.FC<SideDrawerSectionProps> = ({
  title,
  menuItems,
  isExpanded,
  clickAction,
  ...props
}) => (
  <div className={`section-${UtilsHtml.getIdentifier(title)}`} role="menu" {...props}>
    {title && <SideDrawerHeading title={title} isExpanded={isExpanded} />}
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
    <nav onClick={clickAction} className="py-0 px-2">
      <SideDrawerListCategory items={menuItems} isExpanded={isExpanded} />
    </nav>
  </div>
);

export { SideDrawerSection };
