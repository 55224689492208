import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowLeftOnRectangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-left-on-rectangle">
      <g id="Icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.59998 5.10002C3.59998 3.60886 4.80881 2.40002 6.29998 2.40002H12.9C14.3911 2.40002 15.6 3.60886 15.6 5.10002V7.50002C15.6 7.99708 15.197 8.40002 14.7 8.40002C14.2029 8.40002 13.8 7.99708 13.8 7.50002V5.10002C13.8 4.60297 13.397 4.20002 12.9 4.20002H6.29998C5.80292 4.20002 5.39998 4.60297 5.39998 5.10002V18.9C5.39998 19.3971 5.80292 19.8 6.29998 19.8H12.9C13.397 19.8 13.8 19.3971 13.8 18.9V16.5C13.8 16.003 14.2029 15.6 14.7 15.6C15.197 15.6 15.6 16.003 15.6 16.5V18.9C15.6 20.3912 14.3911 21.6 12.9 21.6H6.29998C4.80881 21.6 3.59998 20.3912 3.59998 18.9V5.10002Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8 12C22.8 11.503 22.397 11.1 21.9 11.1H10.4453L11.702 9.96899C12.0715 9.63647 12.1015 9.06741 11.7689 8.69795C11.4364 8.3285 10.8674 8.29855 10.4979 8.63106L7.49791 11.3311C7.30826 11.5017 7.19998 11.7449 7.19998 12C7.19998 12.2552 7.30826 12.4983 7.49791 12.669L10.4979 15.369C10.8674 15.7015 11.4364 15.6715 11.7689 15.3021C12.1015 14.9326 12.0715 14.3636 11.702 14.0311L10.4453 12.9H21.9C22.397 12.9 22.8 12.4971 22.8 12Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default ArrowLeftOnRectangleIcon;
