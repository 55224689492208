import React from 'react';
import classNames from 'clsx';
import { ModalBase } from '../../ModalBase';
import { ModalBaseContentProps } from '../../ModalBase/components/ModalBaseContent';

export interface ConfirmationModalContentProps extends ModalBaseContentProps {
  centered?: boolean;
}

export const ConfirmationModalContent: React.FC<ConfirmationModalContentProps> = ({
  className,
  centered,
  ...props
}) => {
  const classes = classNames(
    {
      'text-center items-center': centered,
    },
    className
  );

  return (
    <ModalBase.Content {...props} className={classes}>
      {props.children}
    </ModalBase.Content>
  );
};
