import React, { type ComponentProps } from 'react';
import classNames from 'clsx';

export type SelectValueProps = Omit<ComponentProps<'span'>, 'children'> & {
  value: string | null;
};
export const SelectValue: React.FC<SelectValueProps> = ({ placeholder, value, ...props }) => {
  const content = value ?? placeholder;
  const isEmpty = !value;

  return (
    <span
      className={classNames('truncate', { 'opacity-50': isEmpty })}
      aria-label={content}
      {...props}
    >
      {content}
    </span>
  );
};
SelectValue.displayName = 'Select.Value';
