import { Menu } from '@headlessui/react';
import React, { Fragment } from 'react';

export type DropdownButtonProps = {
  children: React.ReactNode;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({ children }) => (
  <Menu.Button as={Fragment}>{children}</Menu.Button>
);
