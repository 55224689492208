import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { twMerge } from 'tailwind-merge';
import type { ProjectSchema } from '@shape-construction/api/model';
import { Tooltip } from '@shape-construction/arch-ui';
import { Avatar } from '@shape-construction/arch-ui/src/Avatar';
import { FolderIcon, ChevronUpDownIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { getOffsetFromTimezone } from '@shape-construction/utils/DateTime';
import { ProjectsMenu } from '../ProjectsMenu';

export interface ProjectsSelectorProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  currentProject?: ProjectSchema;
  isExpanded: boolean;
}

export const ProjectsSelector: React.FC<ProjectsSelectorProps> = ({
  currentProject,
  isExpanded,
  className,
}) => {
  const messages = useMessageGetter('sideDrawer');

  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const getTimezoneWithOffset = () => `${currentProject?.timezone}
  ${currentProject?.timezone ? getOffsetFromTimezone(currentProject?.timezone) : ''}`;

  const getTooltipContent = () => {
    if (!currentProject) return '';

    return (
      <div className="max-w-drawer-expanded">
        {!isExpanded && <div>{currentProject?.title}</div>}
        {getTimezoneWithOffset()}
      </div>
    );
  };

  return (
    <ProjectsMenu>
      <div
        className={twMerge(
          'mt-0 max-w-drawer-expanded overflow-hidden text-ellipsis rounded text-gray-900 hover:bg-gray-100 md:mt-5',
          className
        )}
        role="menu"
        aria-label="projects menu"
      >
        <Tooltip.Root>
          <Tooltip.Trigger asChild aria-label="tooltip-trigger">
            <div className="flex w-full items-center gap-x-1.5 p-2">
              <Avatar
                variant="rounded"
                imgURL={currentProject?.logoUrl ?? undefined}
                text={currentProject?.shortName ?? ''}
                size={isLargeScreen ? 'lg' : 'sm'}
                icon={
                  currentProject ? undefined : (
                    <FolderIcon className="text-white" data-testid="default-project-icon" />
                  )
                }
              />

              <div className="flex flex-col items-start justify-center overflow-hidden text-left">
                <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium leading-5">
                  {currentProject ? currentProject.title : messages('selectProject')}
                </span>
                {currentProject?.timezone && (
                  <span className="hidden w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal leading-4 text-gray-500 md:block">
                    {' '}
                    {getTimezoneWithOffset()}
                  </span>
                )}
              </div>
              <ChevronUpDownIcon className="ml-auto h-5 w-5 shrink-0 text-gray-400" />
            </div>
          </Tooltip.Trigger>
          <Tooltip.Content side="right">{getTooltipContent()}</Tooltip.Content>
        </Tooltip.Root>
      </div>
    </ProjectsMenu>
  );
};
