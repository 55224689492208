import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { Helmet } from 'react-helmet';
import { environment } from './config/environment';

export const Meta: React.FC = () => {
  const metaMessages = useMessageGetter('meta');

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#6366F1" />
      <meta name="description" content={metaMessages('description')} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <title>{metaMessages('title')}</title>
      {environment.COOKIEBOT_DOMAIN_GROUP_ID && (
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={environment.COOKIEBOT_DOMAIN_GROUP_ID}
          type="text/javascript"
          async
        />
      )}
      {environment.STATUS_PAGE_EMBED_URL && (
        <script defer src={environment.STATUS_PAGE_EMBED_URL} />
      )}
    </Helmet>
  );
};
