import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ActionsProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};

export const CollapsableCardActions: React.FC<ActionsProps> = ({
  className,
  children,
  ...props
}) => (
  <div className={twMerge('flex justify-end items-center gap-2', className)} {...props}>
    {children}
  </div>
);
