import React, { ElementType } from 'react';
import { Dialog } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export type ModalBaseTitleProps = React.ComponentPropsWithRef<typeof Dialog.Title> & {
  children?: React.ReactNode;
  className?: string;
  as?: ElementType;
};

export const ModalBaseTitle: React.FC<ModalBaseTitleProps> = ({
  className,
  as: Wrapper = Dialog.Title,
  ...props
}) => (
  <Wrapper
    className={twMerge('text-lg font-medium text-gray-900 leading-6', className)}
    {...props}
  />
);

export type ModalBaseSubTitleProps = React.ComponentPropsWithRef<typeof Dialog.Title> & {
  children?: React.ReactNode;
  className?: string;
  as?: ElementType;
};

export const ModalBaseSubTitle: React.FC<ModalBaseSubTitleProps> = ({
  className,
  as: Wrapper = Dialog.Description,
  ...props
}) => {
  const classes = twMerge('text-sm font-normal leading-5 text-gray-500', className);

  return <Wrapper as="h3" className={classes} {...props} />;
};
