import * as React from 'react';
import type { SVGProps } from 'react';
const XCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/x-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6C17.302 21.6 21.6 17.302 21.6 12C21.6 6.69809 17.302 2.40002 12 2.40002C6.69809 2.40002 2.40002 6.69809 2.40002 12C2.40002 17.302 6.69809 21.6 12 21.6ZM9.93642 8.66363C9.58495 8.31216 9.0151 8.31216 8.66363 8.66363C8.31216 9.0151 8.31216 9.58495 8.66363 9.93642L10.7272 12L8.66363 14.0636C8.31216 14.4151 8.31216 14.985 8.66363 15.3364C9.0151 15.6879 9.58495 15.6879 9.93642 15.3364L12 13.2728L14.0636 15.3364C14.4151 15.6879 14.985 15.6879 15.3364 15.3364C15.6879 14.985 15.6879 14.4151 15.3364 14.0636L13.2728 12L15.3364 9.93642C15.6879 9.58495 15.6879 9.0151 15.3364 8.66363C14.985 8.31216 14.4151 8.31216 14.0636 8.66363L12 10.7272L9.93642 8.66363Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default XCircleIcon;
