/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Shape API
 * OpenAPI spec version: v1
 */

/**
 * @deprecated
 */
export type IssueImageKindSchema = (typeof IssueImageKindSchema)[keyof typeof IssueImageKindSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueImageKindSchema = {
  image: 'image',
  evidence: 'evidence',
} as const;
