import classNames from 'clsx';
import React from 'react';
import { getDividerClasses } from './DividerClasses';

export type DividerProps = {
  orientation: 'vertical' | 'horizontal';
  children?: React.ReactNode;
  line?: 'solid' | 'dashed';
  variant?: 'start' | 'middle' | 'end';
};

export const Divider: React.FC<DividerProps> = ({ orientation, line, variant, children }) => {
  return (
    <div
      className={classNames('relative', {
        'w-full': orientation === 'horizontal',
        'h-full': orientation === 'vertical',
      })}
      role="separator"
      aria-label="divider"
    >
      {!children && (
        <div
          className={classNames('absolute', {
            'h-full w-[1px] border-l border-l-gray-200': orientation === 'vertical',
            'h-[1px] w-full border-t border-t-gray-200 ': orientation === 'horizontal',
            'border-dashed': line === 'dashed',
          })}
        />
      )}

      {children && (
        <div className={classNames(getDividerClasses({ orientation, variant, line }))}>
          <span className="px-2 inline-block">{children}</span>
        </div>
      )}
    </div>
  );
};
