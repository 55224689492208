export const inputContainerClasses = {
  default: [
    'w-full flex items-center rounded outline-none overflow-hidden min-w-0',
    'ui-disabled:opacity-50 ui-disabled:cursor-not-allowed',
  ],
  variant: {
    plain: {
      light:
        'bg-white text-gray-800 hover:text-indigo-600 focus:outline-none focus:text-indigo-600',
      dark: 'text-white hover:text-indigo-400 focus:outline-none focus:text-indigo-400',
    },
    bordered: {
      light: [
        'bg-white text-gray-800',
        'border ring-gray-300 border-gray-300',
        'focus:ring-2 focus:ring-indigo-500',
        'hover:ring-2',
      ],
      dark: [
        'bg-gray-600 text-white',
        'border ring-gray-400 border-gray-400',
        'focus:ring-2 focus:ring-indigo-500',
        'hover:ring-2',
      ],
    },
  },
};

export const inputContentClasses = {
  default: 'w-full flex gap-2 flex-1 truncate items-center',
  size: {
    sm: 'py-2 px-2.5 text-xs leading-4 font-normal',
    md: 'py-2.5 px-3 text-sm leading-5',
  },
};

export const adornmentClasses = {
  default: 'stretch',
  size: {
    sm: 'text-xs leading-4',
    md: 'text-sm leading-5',
  },
};

export const indicatorClasses = {
  default: 'inline-flex center',
  size: {
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
  },
};
