import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export const ToastPortal: React.FC<PropsWithChildren> = ({ children }) => {
  const toastElementName = 'toast-portal';
  let portalRoot = document.getElementById(toastElementName);

  if (!portalRoot) {
    portalRoot = document.createElement('div');
    portalRoot.id = toastElementName;
    document.body.appendChild(portalRoot);
  }

  const toastContainer = React.useMemo(() => document.createElement('div'), []);

  React.useEffect(() => {
    portalRoot!.appendChild(toastContainer);

    return () => {
      toastContainer.remove();
    };
  }, [portalRoot, toastContainer]);

  return createPortal(children, portalRoot);
};
