import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@shape-construction/arch-ui';
import { IconButtonProps } from '@shape-construction/arch-ui/src/Button/IconButton/IconButton';
import { ArrowLeftIcon } from '@shape-construction/arch-ui/src/Icons/solid';

export interface BackNavigationButtonProps {
  color?: IconButtonProps['color'];
}

export const BackNavigationButton: React.FC<BackNavigationButtonProps> = ({ color = 'white' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLastLocation = location.key === 'default';

  const goBack = () => {
    if (isLastLocation) {
      navigate('/');
      return;
    }

    navigate(-1);
  };

  return (
    <IconButton
      color={color}
      variant="text"
      aria-label="back button"
      onClick={goBack}
      icon={ArrowLeftIcon}
      size="xs"
    />
  );
};
