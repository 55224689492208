import * as React from 'react';
import type { SVGProps } from 'react';
const SignalSlashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/signal-slash">
      <g id="Icon">
        <path
          d="M2.66356 2.6636C3.01503 2.31213 3.58488 2.31213 3.93635 2.6636L12.0751 10.8023C12.678 10.8395 13.1604 11.322 13.1976 11.9249L21.3363 20.0636C21.6878 20.4151 21.6878 20.9849 21.3363 21.3364C20.9849 21.6879 20.415 21.6879 20.0636 21.3364L11.9248 13.1977C11.3219 13.1605 10.8395 12.678 10.8023 12.0751L2.66356 3.93639C2.31208 3.58492 2.31208 3.01507 2.66356 2.6636Z"
          fill="currentColor"
        />
        <path
          d="M4.3632 19.6368C0.804775 16.0783 0.248578 10.6546 2.69461 6.51303L4.01815 7.83657C2.24715 11.2294 2.78643 15.5144 5.63599 18.364C5.98746 18.7154 5.98746 19.2853 5.63599 19.6368C5.28452 19.9882 4.71467 19.9882 4.3632 19.6368Z"
          fill="currentColor"
        />
        <path
          d="M7.75731 16.2427C6.10088 14.5862 5.61542 12.2022 6.30094 10.1194L7.81581 11.6342C7.71212 12.8275 8.11688 14.0566 9.0301 14.9699C9.38157 15.3213 9.38157 15.8912 9.0301 16.2427C8.67863 16.5941 8.10878 16.5941 7.75731 16.2427Z"
          fill="currentColor"
        />
        <path
          d="M19.6367 4.36326C23.1951 7.92167 23.7513 13.3454 21.3053 17.487L19.9818 16.1634C21.7527 12.7706 21.2135 8.4856 18.3639 5.63605C18.0124 5.28458 18.0124 4.71473 18.3639 4.36326C18.7154 4.01178 19.2852 4.01178 19.6367 4.36326Z"
          fill="currentColor"
        />
        <path
          d="M16.2426 7.75738C17.899 9.4138 18.3845 11.7978 17.699 13.8806L16.1841 12.3658C16.2878 11.1725 15.883 9.94338 14.9698 9.03017C14.6183 8.6787 14.6183 8.10885 14.9698 7.75738C15.3213 7.4059 15.8911 7.4059 16.2426 7.75738Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default SignalSlashIcon;
