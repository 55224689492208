import type { Category, Locale, PageParams, PageResponse } from 'zendesk/api/help-center/types';
import { BaseApi } from 'zendesk/base-api';

export type GetCategoriesParams = PageParams;
export type GetCategoriesResponse = PageResponse & { categories: Category[] };

export class Categories extends BaseApi {
  getCategories(locale: Locale, params?: GetCategoriesParams) {
    return this.request<GetCategoriesResponse>({
      url: `/${locale}/categories`,
      method: 'get',
      params,
    });
  }
}
