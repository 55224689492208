import { useLayoutEffect, useRef, useState } from 'react';
import { useDebounceCallback } from '@shape-construction/hooks';

export const useDistanceToBottom = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);
  const [distanceToBottom, setDistanceToBottom] = useState(0);

  const calculateDistance = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const distance = window.innerHeight - rect.bottom;
      setDistanceToBottom(distance);
    }
  };

  const debouncedCalculateDistance = useDebounceCallback(calculateDistance, 250);

  useLayoutEffect(() => {
    calculateDistance();
    window.addEventListener('resize', debouncedCalculateDistance);
    window.addEventListener('scroll', debouncedCalculateDistance);

    return () => {
      window.removeEventListener('resize', debouncedCalculateDistance);
      window.removeEventListener('scroll', debouncedCalculateDistance);
    };
  }, [debouncedCalculateDistance]);

  return {
    ref,
    distanceToBottom,
  };
};
