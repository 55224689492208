/**
 * Utility functions related to HTML and Document Object Model
 */
const UtilsHtml = () => {
  /**
   * Converts a text content to kebab-case string,
   * e.g. "My Projects" to "my-projects"
   *
   * @param text: content such as title
   * @return <string> a new string in kebab-case that can be used as HTML id
   */
  const getIdentifier = (text: any) => {
    if (typeof text === 'string' || typeof text === 'number') {
      return (
        text
          .toString()
          .trim()
          .toLowerCase()
          // replace spaces with hyphens
          .replace(/\s+/g, '-')
          // remove duplicate hyphens
          .replace(/--+/g, '-')
          // remove non alphanumeric characters excl. hyphen
          .replace(/[^a-zA-Z0-9-]+/g, '')
      );
    }
    return '';
  };
  return { getIdentifier };
};

export default UtilsHtml();
