import { QueryClient } from '@tanstack/react-query';
import { useEffectOnce } from '@shape-construction/hooks';
import { useUploadIssueImageOptions } from 'app/queries/issues/images/images';
import { useCreateIssueOptions } from 'app/queries/issues/issues';

export const useSyncPausedMutations = (queryClient: QueryClient, onDefaultsSet: () => void) => {
  const { mutationKey: createIssueMutationKey, ...createIssueMutationOptions } =
    useCreateIssueOptions();
  const { mutationKey: uploadIssueImageKey, ...uploadIssueImageOptions } =
    useUploadIssueImageOptions();

  useEffectOnce(() => {
    queryClient.setMutationDefaults(createIssueMutationKey!, createIssueMutationOptions);
    queryClient.setMutationDefaults(uploadIssueImageKey!, uploadIssueImageOptions);
    onDefaultsSet();
  });
};
