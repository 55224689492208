import {
  IssueViewFilterItemSchema,
  IssueViewFilterItemSchemaName,
  IssueViewListSchema,
} from '@shape-construction/api/model';
import { parseDateWithFormat } from '@shape-construction/utils/DateTime';
import { PredefinedOptionKey } from 'app/components/Filters/DateSelect/DateSelectOptions';
import { emptyFilters } from '../constants';
import { IssuesFilters } from '../types/types';

const dateKeysReplacements = {
  published_date_relative: 'relative_date',
  published_date_start: 'date',
  published_date_end: 'end_date',
};

export const mapSavedViews = (viewTabs: IssueViewListSchema | undefined) => {
  return Object.fromEntries(
    (viewTabs ?? []).map((issueView) => {
      const viewPartial = {
        group_by: issueView.groupBy,
        group_properties: issueView.groupProperties,
        sort_by: issueView.sortBy,
        sort_order: issueView.sortOrder,
      };
      const viewObject = issueView.filterProperties.reduce(
        transformFilterObject,
        viewPartial
      ) as IssuesFilters;

      if (
        viewObject.date &&
        viewObject.date.date &&
        viewObject.date.end_date &&
        !viewObject.date.relative_date
      ) {
        viewObject.date.relative_date = 'custom';
      }

      return [issueView.id, viewObject];
    })
  );
};

const transformFilterObject = (
  acc: Partial<IssuesFilters>,
  { name, value }: IssueViewFilterItemSchema
) => {
  const updatedName = handleDateReplacement(name);

  let tempAcc: Partial<IssuesFilters> = { ...emptyFilters, ...acc };

  tempAcc =
    handleDateReplacement(name) === name
      ? handleNonDateFilter(tempAcc, updatedName, value)
      : handleDateFilter(tempAcc, updatedName, value);

  return tempAcc;
};

const handleDateReplacement = (name: IssueViewFilterItemSchemaName) => {
  return (
    Object.keys(dateKeysReplacements).includes(name)
      ? dateKeysReplacements[name as keyof typeof dateKeysReplacements]
      : name
  ) as IssueViewFilterItemSchemaName;
};

const handleDateFilter = (
  acc: Partial<IssuesFilters>,
  name: IssueViewFilterItemSchemaName | 'relative_date',
  value: unknown | unknown[]
) => {
  if (name === 'relative_date') {
    const updatedDate = {
      date: undefined,
      end_date: undefined,
      relative_date: value as PredefinedOptionKey,
    };
    return { ...acc, date: updatedDate };
  }

  const updatedDate = { ...acc.date, [name]: parseDateWithFormat(value as Date, 'YYYY-MM-DD') };

  return { ...acc, date: updatedDate };
};

const handleNonDateFilter = (
  acc: Partial<IssuesFilters>,
  name: IssueViewFilterItemSchemaName,
  value: unknown | unknown[]
) => {
  return { ...acc, [name]: Array.isArray(value) ? value : [value] };
};
