import { useCallback, useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { useDispatch, useSelector } from 'react-redux';
import { usePushNotifications } from 'app/pages/notifications/hooks/usePushNotifications';
import {
  notificationsPermissionAccept,
  notificationsPermissionReject,
  userConsentAccept,
  userConsentClose,
  userConsentDeny,
} from 'app/store/notifications/notifications-action-creators';
import { getUserConsentStatus } from 'app/store/notifications/notifications-selectors';

export const USER_CONSENT_STATE = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  CLOSED: 'closed',
  DENIED: 'denied',
};

const enableNotificationsBanner = atom(true);

export const useNotifications = () => {
  const dispatch = useDispatch();
  const [showPermissionBanner, setShowPermissionBanner] = useState<Boolean>(false);
  const [showEnableNotificationsBanner, setShowEnableNotificationsBanner] =
    useAtom(enableNotificationsBanner);
  const userConsentStatus = useSelector(getUserConsentStatus);
  const { userConsent, pushNotificationSupported } = usePushNotifications();

  const onNotificationsPermissionAccept = useCallback(
    () => dispatch(notificationsPermissionAccept()),
    [dispatch]
  );

  const onNotificationsPermissionReject = useCallback(
    () => dispatch(notificationsPermissionReject()),
    [dispatch]
  );

  const onConsentDeny = useCallback(() => {
    dispatch(userConsentDeny());
  }, [dispatch]);

  const onConsentClose = useCallback(() => {
    dispatch(userConsentClose());
  }, [dispatch]);

  const onConsentAccept = useCallback(() => {
    dispatch(userConsentAccept());
  }, [dispatch]);

  useEffect(() => {
    setShowPermissionBanner(
      pushNotificationSupported &&
        !['denied', 'granted'].includes(userConsent as NotificationPermission) &&
        [USER_CONSENT_STATE.DENIED, USER_CONSENT_STATE.CLOSED].includes(userConsentStatus)
    );
  }, [pushNotificationSupported, userConsentStatus, userConsent, setShowPermissionBanner]);

  useEffect(() => {
    if (userConsentStatus === USER_CONSENT_STATE.PENDING && userConsent === 'granted') {
      onConsentAccept();
      onNotificationsPermissionAccept();
    } else if (userConsentStatus === USER_CONSENT_STATE.PENDING && userConsent === 'denied') {
      onConsentDeny();
      onNotificationsPermissionReject();
    }
  }, [
    userConsent,
    userConsentStatus,
    onNotificationsPermissionAccept,
    onNotificationsPermissionReject,
    onConsentDeny,
    onConsentAccept,
  ]);

  return {
    onConsentAccept,
    onConsentClose,
    onConsentDeny,
    onNotificationsPermissionAccept,
    onNotificationsPermissionReject,
    setShowEnableNotificationsBanner,
    setShowPermissionBanner,
    showEnableNotificationsBanner,
    showPermissionBanner,
    userConsent,
    userConsentStatus,
  };
};
