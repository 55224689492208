/** Labels used to identify pages. Used for help center and product tips */
export const pageLabel = {
  general: 'general',
  home: 'home',
  my_account: 'my_account',
  my_projects: 'my_projects',
  new_project: 'new_project',
  issue_list: 'issue_list',
  issue_details: 'issue_details',
  issue_export: 'issue_export',
  timeline: 'timeline',
  locations: 'locations',
  teams: 'teams',
  show_team: 'show_team',
  setup_team: 'setup_team',
  invite_team: 'invite_team',
  access_requests: 'access_requests',
  custom_fields: 'custom_fields',
  new_custom_field: 'new_custom field',
  edit_custom_field: 'edit_custom field',
  notifications: 'notifications',
  issue_details_n: 'issue_details_n',
  search: 'search',
  filter: 'filter',
  new_filter: 'new_filter',
  apply_filter: 'apply_filter',
  shift_reports: 'shift_reports',
  shift_reports_in_progress: 'shift_reports_in_progress',
  shift_reports_archived: 'shift_reports_archived',
  shift_report_preview: 'shift_report_preview',
  shift_report_collaborators: 'shift_report_collaborators',
  shift_reports_manager_view: 'shift_reports_manager_view',
  shift_report_edit: 'shift_report_edit',
} as const;

export type PageLabel = (typeof pageLabel)[keyof typeof pageLabel];
