import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import { showErrorToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import type { DocumentBody, DocumentPayload } from 'app/components/Gallery/types';
import PayloadValidation, {
  MAX_FILE_SIZE_IN_BYTES,
} from 'app/components/Gallery/utils/payloadValidation';
import UtilsStats from 'app/components/Utils/UtilsStats';
import { useUploadProjectDocument } from 'app/queries/projectDocuments/projectDocuments';

export const useProjectDocumentsUploadWorkflow = (projectId: ProjectSchema['id']) => {
  const errorMessages = useMessageGetter('issue.detail.media.tabs.errors');

  const { mutateAsync: uploadProjectDocumentAsync } = useUploadProjectDocument(projectId);

  const uploadDocuments = (items: DocumentPayload[]) => {
    const { validFiles, validationErrors } = new PayloadValidation().validateInput(items);

    validationErrors.forEach(({ filename, error }) => {
      switch (error) {
        case 'file_too_big':
          showErrorToast({
            message: errorMessages('file_too_big', {
              filename,
              max: MAX_FILE_SIZE_IN_BYTES / (1024 * 1024),
            }),
          });
      }
    });

    const promises = validFiles.map((payload) =>
      uploadProjectDocumentAsync({
        ...payload,
        temporaryId: UtilsStats.uuidv4(),
      })
    );

    return Promise.all(promises);
  };

  const uploadDocument = async (file: File, body: DocumentBody) => {
    if (!file) return [];

    const items: DocumentPayload[] = [{ file, body }];

    return uploadDocuments(items).catch(() => []);
  };

  return {
    uploadDocument,
  };
};
