import * as React from 'react';
import type { SVGProps } from 'react';
const PlusCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/plus-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6C17.3019 21.6 21.6 17.302 21.6 12C21.6 6.69809 17.3019 2.40002 12 2.40002C6.69806 2.40002 2.39999 6.69809 2.39999 12C2.39999 17.302 6.69806 21.6 12 21.6ZM12.9 8.10003C12.9 7.60297 12.4971 7.20003 12 7.20003C11.5029 7.20003 11.1 7.60297 11.1 8.10003V11.1H8.09999C7.60294 11.1 7.19999 11.503 7.19999 12C7.19999 12.4971 7.60294 12.9 8.09999 12.9H11.1V15.9C11.1 16.3971 11.5029 16.8 12 16.8C12.4971 16.8 12.9 16.3971 12.9 15.9V12.9H15.9C16.3971 12.9 16.8 12.4971 16.8 12C16.8 11.503 16.3971 11.1 15.9 11.1H12.9V8.10003Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PlusCircleIcon;
