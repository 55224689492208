import React, { FC } from 'react';
import { ProjectSchema, TeamMemberRoleSchema } from '@shape-construction/api/model';
import { ProjectSetupProgress } from 'app/components/ProjectSetupProgress/ProjectSetupProgress';
import { useProject } from 'app/queries/projects/projects';
import { useProjectSetupProgress } from './useProjectSetupProgress';

export const ProjectSetupProgressNavbar: FC<{ projectId: ProjectSchema['id'] }> = ({
  projectId,
}) => {
  const { data: project } = useProject(projectId);
  const { options, completedPercentage } = useProjectSetupProgress(projectId);
  const adminRoles: TeamMemberRoleSchema[] = ['admin', 'owner'];
  const havePermission = project && adminRoles.includes(project.currentTeamMemberRole);

  if (!havePermission) return null;
  if (completedPercentage === 100) return null;

  return <ProjectSetupProgress options={Object.values(options)} percentage={completedPercentage} />;
};
