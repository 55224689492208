import React, { FC } from 'react';
import * as icons from '../Icons/file-icon';
import { fileExtensionsReferences } from './fileExtensionsIcons';

export interface FileExtensionIconProps extends React.ComponentProps<'svg'> {
  extension: string;
}

export const FileExtensionIcon: React.FC<FileExtensionIconProps> = ({ extension, ...props }) => {
  // @ts-expect-error No index signature with a parameter of type 'string' was found on type
  const fileExtensionName = fileExtensionsReferences[extension.toLowerCase()];
  const Icon: FC<React.ComponentProps<'svg'>> =
    // @ts-expect-error Element implicitly has an 'any' type because expression of type 'any' can't be used to index type
    // eslint-disable-next-line import/namespace
    icons[`${fileExtensionName}Icon`] ?? icons.GenericIcon;

  return <Icon data-testid={`${fileExtensionName || 'Default'} file extension icon`} {...props} />;
};
