import { SEARCH_STATE } from '../constants/SearchState';
import { ILayoutConfig } from './layout.types';

const initialVariant: ILayoutConfig = {
  title: '',
  hide: false,
  showTitle: true,
  backButtonRoute: undefined,
  backButtonCallback: undefined,
  searchState: SEARCH_STATE.DISABLED,
  showSideDrawerMenu: true,
  showSideDrawer: true,
  showBottomNavigation: true,
  showBackButton: false,
  showProjectList: true,
  showFilter: true,
  secondaryAction: undefined,
  secondaryActionText: '',
};

const projectListVariant: ILayoutConfig = {
  ...initialVariant,
  searchState: SEARCH_STATE.ENABLED,
};

const hideNavVariant: ILayoutConfig = {
  ...initialVariant,
  hide: true,
  showBottomNavigation: false,
};

const titleVariant: ILayoutConfig = {
  ...initialVariant,
  showProjectList: false,
  showFilter: false,
};

const titleWithBackButtonVariant: ILayoutConfig = {
  ...initialVariant,
  showProjectList: false,
  showFilter: false,
  showBackButton: true,
  showSideDrawerMenu: false,
  showBottomNavigation: false,
};

const searchVariant: ILayoutConfig = {
  ...initialVariant,
  searchState: SEARCH_STATE.SEARCHING,
  showTitle: false,
  showProjectList: false,
  showFilter: false,
  showBackButton: false,
  showSideDrawerMenu: false,
  showBottomNavigation: false,
};

const hideSideDrawer: ILayoutConfig = {
  ...initialVariant,
  showSideDrawerMenu: false,
  showSideDrawer: false,
  showBackButton: false,
  showProjectList: false,
};

const showSideDrawer: ILayoutConfig = {
  ...initialVariant,
  showSideDrawerMenu: true,
  showSideDrawer: true,
  showProjectList: true,
};

const fullscreenVariant: ILayoutConfig = {
  ...initialVariant,
  hide: true,
  showBottomNavigation: false,
  showSideDrawer: false,
  showSideDrawerMenu: false,
  showProjectList: false,
};

export const LayoutConfigs = {
  initialVariant,
  hideNavVariant,
  projectListVariant,
  titleVariant,
  titleWithBackButtonVariant,
  searchVariant,
  hideSideDrawer,
  showSideDrawer,
  fullscreenVariant,
};
