import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="outline/chevron-right">
      <path
        id="Icon"
        d="M8.90002 19L15.9 12L8.90002 5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default ChevronRightIcon;
