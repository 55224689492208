import React, { ElementType } from 'react';
import classNames from 'clsx';
import { useMediaQuery } from '@shape-construction/hooks';
import { twMerge } from 'tailwind-merge';
import { breakpoints } from '../utils/breakpoints';
import { SelectPanel, SelectPanelProps } from './SelectPanel';

export type SelectResponsivePanelProps<TTag extends ElementType> = SelectPanelProps<TTag>;
export function SelectResponsivePanel<TTag extends ElementType>({
  className,
  ...props
}: SelectResponsivePanelProps<TTag>) {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  // For small screens, fixed will be applied and the style will be clean so we can position
  // in the bottom
  const positionRules = {
    strategy: isLargeScreen ? 'absolute' : 'fixed',
    style: isLargeScreen ? undefined : {},
  } as const;

  return (
    <>
      <div
        data-testid="select-backdrop"
        className={classNames(
          'fixed h-screen w-screen left-0 top-0 bg-gray-800 bg-opacity-75 md:bg-opacity-0 backdrop-filter overflow-none z-popover',
          'ui-open:visible ui-not-open:invisible'
        )}
      />
      <SelectPanel
        {...props}
        {...positionRules}
        className={twMerge(
          classNames(
            !isLargeScreen && 'fixed left-0 bottom-0 rounded-none rounded-t-lg max-h-[80%]',
            'md:absolute md:rounded-md md:bottom-auto md:max-h-72'
          ),
          className as string
        )}
      />
    </>
  );
}
SelectResponsivePanel.displayName = 'Select.ResponsivePanel';
