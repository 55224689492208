import * as React from 'react';
import type { SVGProps } from 'react';
const FolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/folder">
      <g id="Icon">
        <path
          d="M4.49999 3.59998C3.3402 3.59998 2.39999 4.54018 2.39999 5.69998V9.61307C3.00617 9.22497 3.72681 8.99997 4.49999 8.99997H19.5C20.2732 8.99997 20.9938 9.22497 21.6 9.61306V8.09997C21.6 6.94018 20.6598 5.99998 19.5 5.99998H13.6971C13.6175 5.99998 13.5412 5.96837 13.4849 5.91211L11.7879 4.21505C11.394 3.82122 10.8599 3.59998 10.3029 3.59998H4.49999Z"
          fill="currentColor"
        />
        <path
          d="M4.49999 10.8C3.3402 10.8 2.39999 11.7402 2.39999 12.9V18.3C2.39999 19.4598 3.3402 20.4 4.49999 20.4H19.5C20.6598 20.4 21.6 19.4598 21.6 18.3V12.9C21.6 11.7402 20.6598 10.8 19.5 10.8H4.49999Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default FolderIcon;
