import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowDownTrayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-down-tray">
      <g id="Icon">
        <path
          d="M12.9 3.30002C12.9 2.80297 12.4971 2.40002 12 2.40002C11.503 2.40002 11.1 2.80297 11.1 3.30002V13.6363L7.55434 9.88206C7.21305 9.5207 6.64343 9.50442 6.28206 9.84571C5.9207 10.187 5.90442 10.7566 6.24571 11.118L11.3457 16.518C11.5157 16.698 11.7524 16.8 12 16.8C12.2476 16.8 12.4843 16.698 12.6543 16.518L17.7543 11.118C18.0956 10.7566 18.0794 10.187 17.718 9.84571C17.3566 9.50442 16.787 9.5207 16.4457 9.88206L12.9 13.6363V3.30002Z"
          fill="currentColor"
        />
        <path
          d="M4.20002 15.3C4.20002 14.803 3.79708 14.4 3.30002 14.4C2.80297 14.4 2.40002 14.803 2.40002 15.3V18.3C2.40002 20.1226 3.87748 21.6 5.70002 21.6H18.3C20.1226 21.6 21.6 20.1226 21.6 18.3V15.3C21.6 14.803 21.1971 14.4 20.7 14.4C20.203 14.4 19.8 14.803 19.8 15.3V18.3C19.8 19.1284 19.1284 19.8 18.3 19.8H5.70002C4.8716 19.8 4.20002 19.1284 4.20002 18.3V15.3Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default ArrowDownTrayIcon;
