import { createMigrate } from 'redux-persist';
import type { RootState } from '../../store';

export const migrations = createMigrate(
  {
    // @ts-expect-error
    0: (state: RootState) => {}, // The previous code needed to be deleted, but we decided to leave the migration index so old clients don't skip migration steps.
    // @ts-expect-error
    1: (state: RootState) => {}, // The previous code needed to be deleted, but we decided to leave the migration index so old clients don't skip migration steps.
    // @ts-expect-error
    2: (state: Record<string, any>) => {
      const storeKeys = ['issueExports', 'issueListExports', 'issueWatchings'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    3: (state: Record<string, any>) => {
      const storeKeys = ['issueComments', 'issueEvents', 'issueImages', 'issueEvidenceImages'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    4: (state: Record<string, any>) => {
      const storeKeys = ['locations'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    5: (state: Record<string, any>) => {
      const storeKeys = ['timelineEvents'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    6: (state: Record<string, any>) => {
      const storeKeys = ['accessRequests'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    7: (state: Record<string, any>) => {
      const storeKeys = ['filters'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    8: (state: Record<string, any>) => {
      const storeKeys = ['teams'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    9: (state: Record<string, any>) => {
      const storeKeys = ['project', 'projects'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    10: (state: Record<string, any>) => {
      const storeKeys = ['eua'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    11: (state: Record<string, any>) => {
      const storeKeys = ['auth'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
    // @ts-expect-error
    12: (state: Record<string, any>) => {
      const storeKeys = ['issueFilters', 'issueSortingOptions', 'grouping'];

      storeKeys.forEach((key) => {
        if (state?.[key]) delete state[key];
      });
    },
  },
  { debug: process.env.NODE_ENV === 'development' }
);

export const persistVersion = 12;
