import React from 'react';
import { MessageProvider } from '@messageformat/react';
import { GalleryPickerModal } from 'app/components/Gallery/GalleryPickerModal';
import messages from 'app/components/Gallery/messages.en.yaml';

export const ProjectGalleryPicker: typeof GalleryPickerModal = (props) => (
  <MessageProvider messages={messages}>
    <GalleryPickerModal {...props} />
  </MessageProvider>
);
