import React, { useState } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useForm } from 'react-hook-form';
import { Badge, Button, InputText } from '@shape-construction/arch-ui';
import { SHAPE, SIZE, THEME } from '@shape-construction/arch-ui/src/Badge/Badge.types';
import { DetailItem } from '@shape-construction/arch-ui/src/DetailItem';
import { MapPinIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { ModalBase as Modal } from '@shape-construction/arch-ui/src/ModalBase';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { truncatedLocationPath } from 'app/components/Utils/locations';
import { useProjectLocations } from 'app/queries/projects/locations';
import { LocationSelector } from '../../../LocationSelector/LocationSelector';
import { uploadModalSize } from '../../constants';
import type { DocumentBody } from '../../types';

type DocumentMetadataProps = {
  projectId: string;
  handleSave: (item: DocumentBody) => void;
};

export const DocumentMetadata = ({ projectId, handleSave }: DocumentMetadataProps) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const captionMessages = useMessageGetter('projectGallery.metadata.caption');
  const locationMessages = useMessageGetter('projectGallery.metadata.location');
  const { data: locations = [] } = useProjectLocations(projectId);

  const [modalOpen, setModalOpen] = useState(false);

  const { handleSubmit, register, setValue, getValues } = useForm<DocumentBody>({
    defaultValues: {
      caption: '',
      locationId: null,
    },
    shouldUnregister: true,
  });

  const selectedLocations = truncatedLocationPath(locations, getValues().locationId) || '';

  const onSubmit = async (item: DocumentBody) => {
    await handleSave({ ...getValues(), ...item });
  };

  const onClearLocation = async () => {
    onCloseModal();
    setValue('locationId', null);
    await handleSave({ ...getValues(), locationId: null });
  };

  const onOpenModal = () => {
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const renderLocationBadge = () => {
    return (
      <div className="w-fit max-w-sm rounded-full" data-cy="upload-preview-location-badge">
        <Badge
          label={selectedLocations}
          size={SIZE.LARGE}
          theme={THEME.GRAY}
          shape={SHAPE.BASIC}
          withRemoveButton
          icon={MapPinIcon}
          onRemove={handleSubmit(onClearLocation)}
        />
      </div>
    );
  };

  const renderLocationPicker = () => (
    <>
      <div className="flex flex-col justify-center h-7 pb-1 text-sm font-medium">
        <DetailItem
          icon={<MapPinIcon className="h-5 w-5 text-gray-600" />}
          title={locationMessages('add')}
          onClick={onOpenModal}
        />
      </div>
      <Modal
        open={modalOpen}
        fullScreen={!isLargeScreen}
        outsidePad={false}
        className={uploadModalSize}
        roundBorders={isLargeScreen}
        onClose={onCloseModal}
      >
        <Modal.Header onClose={onCloseModal}>
          <Modal.Title>{locationMessages('edit')}</Modal.Title>
        </Modal.Header>
        <Modal.Content>
          <div className="-mx-6">
            <LocationSelector
              locations={locations}
              defaultLocationId={getValues().locationId}
              name="locationId"
              onSelect={(_, locationId) => setValue('locationId', locationId)}
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button color="secondary" variant="outlined" size="md" onClick={onCloseModal}>
            {locationMessages('cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="md"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            {locationMessages('save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  const renderLocation = () => {
    return (
      <div className="flex justify-between">
        <div className="text-sm font-medium sm:w-full md:w-full max-w-lg h-7">
          {getValues().locationId ? renderLocationBadge() : renderLocationPicker()}
        </div>
      </div>
    );
  };

  const renderCaption = () => {
    return (
      <div className="flex justify-start text-sm w-full">
        <InputText
          fullWidth
          maxLength={255}
          placeholder={captionMessages('add')}
          {...register('caption', {
            onBlur: handleSubmit(onSubmit),
          })}
        />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-y-4">
        {renderLocation()}
        {renderCaption()}
      </div>
    </form>
  );
};
