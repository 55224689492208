import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema, UserSchema } from '@shape-construction/api/model';
import { IconButton } from '@shape-construction/arch-ui';
import {
  ArrowRightOnRectangleIcon,
  ArrowLeftOnRectangleIcon,
} from '@shape-construction/arch-ui/src/Icons/solid';
import { ProjectsSelector } from 'app/components/ProjectsSelector/ProjectsSelector';
import { ADMIN_OR_ABOVE } from '../../../constants/Roles';
import { useOverviewItems, useAdminItems } from './SideDrawerContentItems';
import { SideDrawerFooter } from './SideDrawerFooter';
import { SideDrawerHeader } from './SideDrawerHeader';
import { SideDrawerSection } from './SideDrawerSection';

export interface SideDrawerContentProps {
  currentUser: UserSchema | null;
  currentProject?: ProjectSchema;
  drawerExpanded: boolean;
  notificationOverview?: { totalUnread: number };
  closeDrawer: React.MouseEventHandler<HTMLElement>;
  isHomeEnabled?: boolean;
  toggleDrawerExpanded: (drawerExpanded: boolean) => void;
}

const SideDrawerContent: React.FC<SideDrawerContentProps> = React.memo(
  ({ currentUser, closeDrawer, currentProject, drawerExpanded, toggleDrawerExpanded }) => {
    const overviewItems = useOverviewItems(currentProject);
    const adminItems = useAdminItems(currentProject);
    const messages = useMessageGetter('sideDrawer');

    const renderHeader = () => <SideDrawerHeader />;

    const renderCollapseButton = () => (
      <div className="hidden p-4 pt-0 lg:flex lg:justify-end">
        <IconButton
          icon={drawerExpanded ? ArrowLeftOnRectangleIcon : ArrowRightOnRectangleIcon}
          size="md"
          aria-label="drawer control"
          aria-expanded={drawerExpanded}
          color="secondary"
          variant="text"
          onClick={() => toggleDrawerExpanded(!drawerExpanded)}
        />
      </div>
    );

    const renderProject = () => {
      if (!currentUser) return null;

      return (
        <div className="hidden px-2 md:block">
          <ProjectsSelector currentProject={currentProject} isExpanded={drawerExpanded} />
        </div>
      );
    };

    const renderMenuItems = () => {
      if (!currentUser) return null;

      return (
        <SideDrawerSection
          aria-label="main menu"
          menuItems={overviewItems}
          isExpanded={drawerExpanded}
          clickAction={closeDrawer}
        />
      );
    };

    const renderAdminItems = () => {
      if (!currentProject) return null;
      if (!ADMIN_OR_ABOVE.includes(currentProject.currentTeamMemberRole)) return null;

      return (
        <SideDrawerSection
          title={messages('admin')}
          aria-label="admin menu"
          menuItems={adminItems}
          isExpanded={drawerExpanded}
          clickAction={closeDrawer}
        />
      );
    };

    const renderFooter = () => {
      if (!currentUser) return null;

      return <SideDrawerFooter aria-label="footer menu" />;
    };

    return (
      <div data-testid="sideList" role="presentation" className="flex h-full flex-col gap-y-5">
        {renderHeader()}
        {renderProject()}
        <div className="flex flex-col gap-y-5 overflow-hidden overflow-y-auto">
          {renderMenuItems()}
          {renderAdminItems()}
        </div>
        {renderFooter()}
        {renderCollapseButton()}
      </div>
    );
  }
);

export { SideDrawerContent };
