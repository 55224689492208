import React from 'react';
import { useMediaQuery } from '@shape-construction/hooks';
import { breakpoints } from '../utils/breakpoints';
import { Button, ButtonProps } from '../Button/Button';

type SecondaryActionProps = Omit<ButtonProps, 'color' | 'variant' | 'size'>;
export const SecondaryAction: React.FC<SecondaryActionProps> = ({ ...buttonProps }) => {
  const buttonSize = useMediaQuery(breakpoints.down('md')) ? 'md' : 'sm';

  return (
    <Button {...buttonProps} size={buttonSize} variant="outlined" color="secondary" fullWidth />
  );
};
