import * as React from 'react';
import type { SVGProps } from 'react';
const XerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/xer">
      <g id="Vector" filter="url(#filter0_dd_3816_38095)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="#DC2626"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="url(#paint0_radial_3816_38095)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8765 19.8396H16.2222C14.1085 19.8396 12.395 21.553 12.395 23.6667C12.395 25.7804 14.1085 27.4939 16.2222 27.4939H23.8765C25.9902 27.4939 27.7037 25.7804 27.7037 23.6667C27.7037 21.553 25.9902 19.8396 23.8765 19.8396ZM16.2222 16.7778C12.4176 16.7778 9.33331 19.8621 9.33331 23.6667C9.33331 27.4714 12.4176 30.5556 16.2222 30.5556H23.8765C27.6812 30.5556 30.7654 27.4714 30.7654 23.6667C30.7654 19.8621 27.6812 16.7778 23.8765 16.7778H16.2222Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38095"
        x={2.66669}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38095" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3816_38095"
          result="effect2_dropShadow_3816_38095"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3816_38095"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38095"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default XerIcon;
