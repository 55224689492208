import * as React from 'react';
import type { SVGProps } from 'react';
const BellAlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/bell-alert">
      <g id="Icon">
        <path
          d="M5.05735 3.87214C5.37391 3.48892 5.31988 2.92164 4.93666 2.60508C4.55345 2.28852 3.98616 2.34255 3.6696 2.72577C2.59047 4.03212 1.81193 5.59787 1.44186 7.31639C1.33723 7.80231 1.64631 8.28105 2.13223 8.38569C2.61815 8.49032 3.09689 8.18124 3.20153 7.69532C3.50935 6.26588 4.15715 4.96188 5.05735 3.87214Z"
          fill="currentColor"
        />
        <path
          d="M20.33 2.72577C20.0135 2.34255 19.4462 2.28852 19.063 2.60508C18.6797 2.92164 18.6257 3.48892 18.9423 3.87214C19.8425 4.96188 20.4903 6.26588 20.7981 7.69532C20.9027 8.18124 21.3815 8.49032 21.8674 8.38569C22.3533 8.28105 22.6624 7.80231 22.5578 7.31639C22.1877 5.59787 21.4092 4.03212 20.33 2.72577Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.39995C8.02353 2.39995 4.79998 5.6235 4.79998 9.59995C4.79998 11.8639 4.25551 13.9981 3.29115 15.8811C3.16312 16.1311 3.15915 16.4266 3.2804 16.6799C3.40166 16.9333 3.63424 17.1155 3.90925 17.1726C5.19047 17.4386 6.4942 17.6427 7.81714 17.7816C8.00996 19.9223 9.80908 21.6 12 21.6C14.191 21.6 15.9901 19.9223 16.1829 17.7816C17.5058 17.6427 18.8095 17.4386 20.0907 17.1726C20.3657 17.1155 20.5983 16.9333 20.7196 16.6799C20.8408 16.4266 20.8368 16.1311 20.7088 15.8811C19.7445 13.9981 19.2 11.8639 19.2 9.59995C19.2 5.6235 15.9764 2.39995 12 2.39995ZM12 19.7999C10.8573 19.7999 9.90119 19.0014 9.65918 17.9319C10.4338 17.9771 11.2142 18 12 18C12.7858 18 13.5663 17.9771 14.3409 17.9319C14.0989 19.0014 13.1427 19.7999 12 19.7999Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default BellAlertIcon;
