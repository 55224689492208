import { atom, useAtom } from 'jotai';
import { useStableCallback } from '@shape-construction/hooks';

const isHelpCenterVisibleAtom = atom(false);

export const useShowHelpCenter = () => {
  const [isHelpCenterVisible, setIsHelpCenterVisible] = useAtom(isHelpCenterVisibleAtom);

  const showHelpCenter = useStableCallback(() => setIsHelpCenterVisible(true));

  const hideHelpCenter = useStableCallback(() => setIsHelpCenterVisible(false));

  return {
    isHelpCenterVisible,
    showHelpCenter,
    hideHelpCenter,
  };
};
