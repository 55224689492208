import { getGetApiFeatureFlagsQueryKey, useGetApiFeatureFlags } from '@shape-construction/api/api';
import type { UserSchema } from '@shape-construction/api/model';
import { useDeviceSessionId } from 'app/hooks/useDeviceSessionId';
import { useCurrentUser } from 'app/queries/users/users';

type useSetupFeatureFlagsProps = {
  isLoading?: boolean;
};

export const useSetupFeatureFlags = ({ isLoading = false }: useSetupFeatureFlagsProps) => {
  const user = useCurrentUser() as UserSchema | undefined;
  const deviceSessionId = useDeviceSessionId();

  // Warm up feature flag cache
  useGetApiFeatureFlags(
    { device_session_id: deviceSessionId },
    {
      query: {
        queryKey: [
          ...getGetApiFeatureFlagsQueryKey({ device_session_id: deviceSessionId }),
          user?.id,
        ],
        enabled: !isLoading,
      },
    }
  );
};
