import { atom, useAtom } from 'jotai';

export const openProductTourPopoverAtom = atom<boolean>(false);
export const showProductTourPopoverAtom = atom<boolean>(true);

export const useProductTourPopover = () => {
  const [isProductTourPopoverOpen, setProductTourPopoverOpen] = useAtom(openProductTourPopoverAtom);
  const [showProductTourPopover, setShowProductTourPopover] = useAtom(showProductTourPopoverAtom);

  const openProductTourPopover = () => {
    setProductTourPopoverOpen(true);
    setShowProductTourPopover(false);
  };

  const closeProductTourPopover = () => {
    setProductTourPopoverOpen(false);
  };

  return {
    showProductTourPopover,
    isProductTourPopoverOpen,
    openProductTourPopover,
    closeProductTourPopover,
  };
};
