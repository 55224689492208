import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-up">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 20.4C11.503 20.4 11.1001 19.9971 11.1001 19.5L11.1001 6.7345L6.3488 11.7238C6.00429 12.0821 5.43455 12.0933 5.07625 11.7488C4.71796 11.4042 4.70679 10.8345 5.0513 10.4762L11.3513 3.87621C11.521 3.69974 11.7552 3.60001 12.0001 3.60001C12.2449 3.60001 12.4791 3.69974 12.6488 3.87621L18.9488 10.4762C19.2933 10.8345 19.2821 11.4042 18.9239 11.7488C18.5656 12.0933 17.9958 12.0821 17.6513 11.7238L12.9001 6.7345L12.9001 19.5C12.9001 19.9971 12.4971 20.4 12.0001 20.4Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ArrowUpIcon;
