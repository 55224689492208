/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Shape API
 * OpenAPI spec version: v1
 */

export type DocumentKindSchema = (typeof DocumentKindSchema)[keyof typeof DocumentKindSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentKindSchema = {
  file: 'file',
  image: 'image',
} as const;
