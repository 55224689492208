import React, { useEffect } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useQueryClient } from '@tanstack/react-query';
import { getGetApiProductToursProductTourKeyQueryKey } from '@shape-construction/api/api';
import { PatchApiProductToursProductTourKeyBody } from '@shape-construction/api/model';
import { ModalBase } from '@shape-construction/arch-ui/src/ModalBase';
import { useSendAnalyticsEvent } from 'app/queries/analytics/analytics';
import { useProductTourUpdate } from 'app/queries/productTours/productTours';
import { useProductTourPopover } from '../hooks/useProductTourPopover';
import { AnalyticEvent, StorylaneEventName } from 'app/analytics/eventsTypes';

const SCRIPT_ID = 'storylane-demo-script';

const STORYLANE_MIXPANEL_MAP: Record<string, StorylaneEventName> = {
  demo_open: 'tour_opened',
  demo_finished: 'tour_finished',
  flow_start: 'tour_flow_started',
  flow_end: 'tour_flow_ended',
};

export type StorylaneAnalyticEvent = AnalyticEvent<StorylaneEventName>;

const analyticsEventData = (event: any, productTourKey: string): StorylaneAnalyticEvent | null => {
  const storylaneEventName = event?.data?.payload?.event as string | undefined;
  if (!storylaneEventName) return null;

  const eventName = STORYLANE_MIXPANEL_MAP[storylaneEventName];
  if (!eventName) return null;

  const demoName = event?.data?.payload?.demo?.name;
  const flowName = event?.data?.payload?.flow?.name;

  return {
    event_name: eventName,
    properties: {
      tour_key: productTourKey,
      ...(demoName && { storylane_demo_name: demoName }),
      ...(flowName && { storylane_flow_name: flowName }),
    },
  };
};

const productTourUpdateData = (event: any): PatchApiProductToursProductTourKeyBody | null => {
  const storylaneEventName = event?.data?.payload?.event as string | undefined;
  if (!storylaneEventName) return null;
  if (!['demo_open', 'demo_finished'].includes(storylaneEventName)) return null;

  const now = new Date().toISOString();

  return {
    ...(storylaneEventName === 'demo_open' && { started_at: now }),
    ...(storylaneEventName === 'demo_finished' && { finished_at: now }),
  };
};

type ProductTourStorylaneModalProps = {
  open: boolean;
  onClose: () => void;
  productTourKey: string;
  productTourName: string | JSX.Element;
  storylaneUrl: string;
};

export const ProductTourStorylaneModal: React.FC<ProductTourStorylaneModalProps> = ({
  open,
  onClose,
  productTourKey,
  productTourName,
  storylaneUrl,
}) => {
  const queryClient = useQueryClient();
  const messages = useMessageGetter('productTour.storylaneModal');
  const { showProductTourPopover, openProductTourPopover } = useProductTourPopover();
  const { mutate: updateProductTour } = useProductTourUpdate();
  const { mutate: sendAnalyticsEvent } = useSendAnalyticsEvent();

  const invalidateProductTour = () => {
    queryClient.invalidateQueries({
      queryKey: getGetApiProductToursProductTourKeyQueryKey(productTourKey),
    });
  };

  const handleCloseModal = () => {
    onClose();
    invalidateProductTour();

    if (showProductTourPopover) openProductTourPopover();
  };

  useEffect(() => {
    const storylaneEventsCallback = (event: any) => {
      const updateData = productTourUpdateData(event);
      if (updateData) updateProductTour({ productTourKey, data: updateData });

      const eventData = analyticsEventData(event, productTourKey);
      if (eventData) sendAnalyticsEvent(eventData);
    };

    window.addEventListener('message', storylaneEventsCallback);
    return () => {
      window.removeEventListener('message', storylaneEventsCallback);
    };
  }, [productTourKey, updateProductTour, sendAnalyticsEvent]);

  useEffect(() => {
    const existingScript = document.getElementById(SCRIPT_ID);

    if (!existingScript) {
      const script = Object.assign(document.createElement('script'), {
        id: SCRIPT_ID,
        src: 'https://js.storylane.io/js/v1/storylane.js',
        type: 'text/javascript',
      });
      script.dataset.testid = SCRIPT_ID;
      document.body.appendChild(script);
    }

    return () => {
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <ModalBase open={open} onClose={handleCloseModal} roundBorders outsidePad className="h-full w-full">
      <ModalBase.Header onClose={handleCloseModal}>
        <ModalBase.Title>{messages('title', { productTourName })}</ModalBase.Title>
      </ModalBase.Header>

      <ModalBase.Content className="flex p-0">
        <div className="sl-embed relative h-full w-full">
          <iframe
            className="sl-demo absolute inset-0 h-full w-full"
            src={storylaneUrl}
            allow="fullscreen"
            title="Storylane demo"
            aria-label={messages('ariaLabel', { productTourName })}
          />
        </div>
      </ModalBase.Content>
    </ModalBase>
  );
};
