import React, { ElementType, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { ChevronDownIcon, ChevronRightIcon } from '../Icons/solid';

export type HeaderProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  hideIcon?: boolean;
  openIcon?: ElementType<{ className?: string }>;
  closeIcon?: ElementType<{ className?: string }>;
  children: ReactNode;
};

export const CollapsableCardHeader: React.FC<HeaderProps> = ({
  /** Is collapsable card open */
  isOpen = false,

  /** Icon to show when the card is open */
  openIcon = ChevronRightIcon,

  /** Icon to show when the card is closed */
  closeIcon = ChevronDownIcon,

  /** If true, will not display an icon next to the header */
  hideIcon = false,

  className,
  children,
  ...props
}) => {
  const Icon = isOpen ? closeIcon : openIcon;

  return (
    <div className={twMerge('flex items-center gap-3 font-semibold', className)} {...props}>
      {!hideIcon && (
        <div className="rounded-full border border-gray-300 p-1">
          <Icon className="text-gray-600 h-4 w-4" aria-label="Collapsable Card Icon" />
        </div>
      )}

      {children}
    </div>
  );
};
