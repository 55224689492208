import * as React from 'react';
import type { SVGProps } from 'react';
const QuestionMarkCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/question-mark-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12C21.6 17.302 17.302 21.6 12 21.6C6.69809 21.6 2.40002 17.302 2.40002 12C2.40002 6.69809 6.69809 2.40002 12 2.40002C17.302 2.40002 21.6 6.69809 21.6 12ZM10.7272 8.3272C10.3758 8.67867 9.80591 8.67867 9.45444 8.3272C9.10297 7.97573 9.10297 7.40588 9.45444 7.05441C10.8603 5.64852 13.1397 5.64852 14.5456 7.05441C15.9515 8.4603 15.9515 10.7397 14.5456 12.1456C14.0746 12.6166 13.5034 12.9308 12.9 13.0861V13.5C12.9 13.997 12.4971 14.4 12 14.4C11.503 14.4 11.1 13.997 11.1 13.5V12.9C11.1 12.0356 11.784 11.4939 12.3976 11.3559C12.7179 11.2838 13.022 11.1237 13.2728 10.8728C13.9758 10.1698 13.9758 9.03015 13.2728 8.3272C12.5699 7.62426 11.4302 7.62426 10.7272 8.3272ZM12 18C12.6628 18 13.2 17.4628 13.2 16.8C13.2 16.1373 12.6628 15.6 12 15.6C11.3373 15.6 10.8 16.1373 10.8 16.8C10.8 17.4628 11.3373 18 12 18Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default QuestionMarkCircleIcon;
