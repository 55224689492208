import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { Alert, Button } from '@shape-construction/arch-ui';
import { BellAlertIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useNotifications } from './hooks/useNotifications';
import { usePushNotifications } from './hooks/usePushNotifications';

export const EnableNotificationsBanner = () => {
  const isLargeScreen = useMediaQuery(breakpoints.up('lg'));
  const messages = useMessageGetter('notifications.permission.global');
  const { pushNotificationSupported, onAskPushNotificationPermission } = usePushNotifications();

  const {
    userConsent,
    onNotificationsPermissionReject,
    onConsentDeny,
    setShowEnableNotificationsBanner,
    setShowPermissionBanner,
  } = useNotifications();

  const handleNotificationsClose = () => {
    onConsentDeny();
    onNotificationsPermissionReject();
    setShowEnableNotificationsBanner(false);
    setShowPermissionBanner(true);
  };

  const handleNotificationsPermission = () => {
    onAskPushNotificationPermission();
    setShowEnableNotificationsBanner(false);
  };

  if (isLargeScreen || !pushNotificationSupported || userConsent !== 'default') return null;

  return (
    <>
      <Alert
        color="secondary"
        emphasis="bold"
        customIcon={<BellAlertIcon className="h-5 w-5 text-white" />}
        rounded={false}
        displayInRow
      >
        <Alert.Message>{messages('label')}</Alert.Message>
        <Alert.Actions>
          <Button color="white" variant="outlined" size="xs" onClick={handleNotificationsClose}>
            {messages('laterButton')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="xs"
            onClick={handleNotificationsPermission}
          >
            {messages('enableButton')}
          </Button>
        </Alert.Actions>
      </Alert>
    </>
  );
};
