import { IssueEventTypeSchema } from '@shape-construction/api/model';
import {
  getEndOfDay,
  getStartOfDay,
  now,
  parseDate,
  DateType,
} from '@shape-construction/utils/DateTime';

export type DateOptions = typeof defaultDateOptions;

export const defaultDateOptions = {
  all: {
    label: 'All',
    selected: true,
    value: () => ({ date: undefined, endDate: undefined }),
  },
  today: {
    label: 'Today',
    selected: false,
    value: () => {
      const today = now();

      return {
        date: getStartOfDay(today).format(),
        endDate: getEndOfDay(today).format(),
      };
    },
  },
  yesterday: {
    label: 'Yesterday',
    selected: false,
    value: () => {
      const today = now();
      const yesterday = parseDate(today).subtract(1, 'days');

      return {
        date: getStartOfDay(yesterday).format(),
        endDate: getEndOfDay(yesterday).format(),
      };
    },
  },
  custom: {
    label: 'Custom',
    selected: false,
    value: (startDate: DateType | null, endDate: DateType | null) => ({
      date: startDate ? getStartOfDay(startDate).format() : null,
      endDate: endDate ? getEndOfDay(endDate).format() : null,
    }),
  },
};

export type TimelineEventType = {
  label: string;
  selected: boolean;
};
export type EventTypesFilterOptions =
  | 'approve'
  | 'change_status'
  | 'comment_on'
  | 'create'
  | 'private_comment_on'
  | 'reject_resolution'
  | 'reopen'
  | 'update_impact'
  | 'update'
  | 'upload_image';

export type TypeOptions = Pick<
  Record<IssueEventTypeSchema, TimelineEventType>,
  EventTypesFilterOptions
>;

// This is the order which is going to appear in the filter list.
export const defaultTypeOptions: TypeOptions = {
  comment_on: {
    label: 'filter.options.commentOn',
    selected: false,
  },
  private_comment_on: {
    label: 'filter.options.privateCommentOn',
    selected: false,
  },
  upload_image: {
    label: 'filter.options.uploadImage',
    selected: true,
  },
  create: {
    label: 'filter.options.create',
    selected: true,
  },
  change_status: {
    label: 'filter.options.changeStatus',
    selected: false,
  },
  approve: {
    label: 'filter.options.approve',
    selected: true,
  },
  reject_resolution: {
    label: 'filter.options.rejectResolution',
    selected: false,
  },
  reopen: {
    label: 'filter.options.reopen',
    selected: false,
  },
  update_impact: {
    label: 'filter.options.updateImpact',
    selected: false,
  },
  update: {
    label: 'filter.options.update',
    selected: false,
  },
};
