import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowUturnLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-uturn-left">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35097 2.6786C9.69418 3.03815 9.68093 3.60784 9.32138 3.95105L4.34626 8.70003H16.3499C19.9122 8.70003 22.7999 11.5878 22.7999 15.15C22.7999 18.7123 19.9122 21.6 16.3499 21.6H12.8999C12.4029 21.6 11.9999 21.1971 11.9999 20.7C11.9999 20.203 12.4029 19.8 12.8999 19.8H16.3499C18.9181 19.8 20.9999 17.7181 20.9999 15.15C20.9999 12.5819 18.9181 10.5 16.3499 10.5H4.34626L9.32138 15.249C9.68093 15.5922 9.69418 16.1619 9.35097 16.5215C9.00776 16.881 8.43807 16.8942 8.07852 16.551L1.47852 10.251C1.30061 10.0812 1.19995 9.84598 1.19995 9.60003C1.19995 9.35407 1.30061 9.11883 1.47852 8.94901L8.07852 2.64901C8.43807 2.3058 9.00776 2.31905 9.35097 2.6786Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ArrowUturnLeftIcon;
