import { useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import {
  getGetApiProjectsProjectIdShiftReportsArchivedQueryKey,
  getGetApiProjectsProjectIdShiftReportsDraftQueryKey,
  getGetApiProjectsProjectIdShiftReportsQueryKey,
  getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey,
  getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey,
  useGetApiProjectsProjectIdShiftReports,
  useGetApiProjectsProjectIdShiftReportsArchived,
  useGetApiProjectsProjectIdShiftReportsDraft,
  useGetApiProjectsProjectIdShiftReportsShiftReportId,
  useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople,
  useGetApiProjectsProjectIdShiftReportsCompletions,
  usePostApiProjectsProjectIdShiftReports,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdPublish,
  usePatchApiProjectsProjectIdShiftReportsShiftReportId,
  useDeleteApiProjectsProjectIdShiftReportsShiftReportId,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdRestore,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdResetSection,
  usePostApiProjectsProjectIdShiftReportsExport,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdImport,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdArchive,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdExport,
  getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions,
} from '@shape-construction/api/api';
import type {
  GetApiProjectsProjectIdShiftReportsParams,
  ProjectSchema,
  ShiftReportListSchema,
} from '@shape-construction/api/model';

// queries
export const useShiftReports = useGetApiProjectsProjectIdShiftReports;

export const useShiftReportsDraft = useGetApiProjectsProjectIdShiftReportsDraft;

export const useShiftReportsArchive = useGetApiProjectsProjectIdShiftReportsArchived;

export const useShiftReportsPeople = useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople;

export const useShiftReport = useGetApiProjectsProjectIdShiftReportsShiftReportId;
export const getShiftReportQueryOptions =
  getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions;

export const usePreviousShiftReport = (
  projectId: ProjectSchema['id'],
  authorId: GetApiProjectsProjectIdShiftReportsParams['author_id'],
  options?: Partial<UseQueryOptions<ShiftReportListSchema>>
) => {
  const { data } = useGetApiProjectsProjectIdShiftReports(
    projectId,
    { page_size: 1, author_id: authorId },
    { query: options }
  );
  const shiftReportId = data?.shiftReports?.[0]?.id;
  return useGetApiProjectsProjectIdShiftReportsShiftReportId(projectId, shiftReportId!, {
    query: {
      enabled: Boolean(data?.meta.total),
    },
  });
};

export const useShiftReportsCompletions = useGetApiProjectsProjectIdShiftReportsCompletions;

// mutations
export const useCreateShiftReport = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReports({
    mutation: {
      onSettled: (_, __, { projectId }) =>
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsDraftQueryKey(projectId),
        }),
    },
  });
};

export const usePublishShiftReport = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdPublish({
    mutation: {
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsDraftQueryKey(projectId),
        });
      },
    },
  });
};

export const useUpdateShiftReport = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdShiftReportsShiftReportId({
    mutation: {
      mutationKey: ['update-shift-report-mutation'],
      onSuccess(updatedShiftReport, { projectId, shiftReportId }) {
        queryClient.setQueryData(
          getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          updatedShiftReport
        );
      },
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey(
            projectId,
            shiftReportId
          ),
        });
      },
      onError: (error, { projectId, shiftReportId }) => {
        if (error?.response?.status === 404) {
          queryClient.invalidateQueries({
            queryKey: getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(
              projectId,
              shiftReportId
            ),
          });
        }
      },
    },
  });
};

export const useShiftReportExport = usePostApiProjectsProjectIdShiftReportsShiftReportIdExport;

export const useShiftReportArchive = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdArchive({
    mutation: {
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(
            projectId,
            shiftReportId
          ),
        });
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId),
        });
      },
    },
  });
};

export const useShiftReportRestore = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdRestore({
    mutation: {
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(
            projectId,
            shiftReportId
          ),
        });
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId),
        });
      },
    },
  });
};

export const useShiftReportsDraftDelete = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdShiftReportsShiftReportId({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdShiftReportsDraftQueryKey(projectId),
        });
      },
    },
  });
};

export const useShiftReportsResetSection = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdResetSection({
    mutation: {
      mutationKey: ['reset-section-shift-report-mutation'],

      onSuccess(updatedShiftReport, { projectId, shiftReportId }) {
        queryClient.setQueryData(
          getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          updatedShiftReport
        );
      },
      onError(error, { projectId, shiftReportId }) {
        if (error?.response?.status === 400) {
          queryClient.invalidateQueries({
            queryKey: getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(
              projectId,
              shiftReportId
            ),
          });
        }
      },
    },
  });
};

export const useExportShiftReports = usePostApiProjectsProjectIdShiftReportsExport;

export const useImportShiftReport = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdImport({
    mutation: {
      mutationKey: ['import-shift-report-mutation'],

      onSuccess(updatedShiftReport, { projectId, shiftReportId }) {
        queryClient.setQueryData(
          getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          updatedShiftReport
        );
      },
      onError(error, { projectId, shiftReportId }) {
        if (error?.response?.status === 404) {
          queryClient.invalidateQueries({
            queryKey: getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(
              projectId,
              shiftReportId
            ),
          });
        }
      },
    },
  });
};
