import React from 'react';

export interface AuthPagesHeaderProps {
  logo?: React.ReactNode;
  title: string;
  subTitle?: string;
}

export const AuthPagesHeader: React.FC<AuthPagesHeaderProps> = ({ logo, title, subTitle }) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {logo && <div className="flex h-12 justify-center">{logo}</div>}
        <h2 className="mt-6 text-center text-2xl font-semibold leading-9 text-gray-900">{title}</h2>
        {subTitle && <p className="mt-2 text-center text-sm text-gray-600">{subTitle}</p>}
      </div>
    </div>
  );
};
