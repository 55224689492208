import { useQueryClient } from '@tanstack/react-query';
import {
  usePostApiProjectsProjectIdLocationsLocationIdSort,
  useGetApiProjectsProjectIdLocations,
  getGetApiProjectsProjectIdLocationsQueryKey,
  usePostApiProjectsProjectIdLocations,
  useDeleteApiProjectsProjectIdLocationsLocationId,
  usePatchApiProjectsProjectIdLocationsLocationId,
  getGetApiProjectsProjectIdLocationsQueryOptions,
} from '@shape-construction/api/api';
import type { LocationListSchema } from '@shape-construction/api/model';

// queries
export const useProjectLocations = useGetApiProjectsProjectIdLocations;
export const getProjectLocationsQueryOptions = getGetApiProjectsProjectIdLocationsQueryOptions;

// mutations
export const useCreateProjectLocation = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdLocations({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdLocationsQueryKey(projectId),
        });
      },
    },
  });
};

export const useUpdateProjectLocation = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdLocationsLocationId({
    mutation: {
      onSuccess: (updatedLocation, { projectId }) => {
        queryClient.setQueryData<LocationListSchema>(
          getGetApiProjectsProjectIdLocationsQueryKey(projectId),
          (data = []) => {
            const result = data?.map((currentLocation) => {
              if (currentLocation.id === updatedLocation.id) return updatedLocation;
              return currentLocation;
            });

            return result;
          }
        );
      },
    },
  });
};

export const useDeleteProjectLocation = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdLocationsLocationId({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdLocationsQueryKey(projectId),
        });
      },
    },
  });
};

export const useSortProjectLocations = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdLocationsLocationIdSort({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdLocationsQueryKey(projectId),
        });
      },
    },
  });
};
