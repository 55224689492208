import { ThemeColors } from '../types/Colors';

export const AvatarHighlightedColorClasses: Record<ThemeColors, string> = {
  default: 'ring-white',
  primary: 'ring-indigo-500',
  secondary: 'ring-gray-500',
  success: 'ring-green-500',
  warning: 'ring-yellow-500',
  danger: 'ring-red-500',
  neutral: 'ring-gray-100',
};
