import React from 'react';
import classNames from 'clsx';
import { ExclamationCircleIcon } from '../Icons/solid';
import { InputBaseProps } from '../types/InputBase';

export interface InputSelectOptionProps {
  label?: string;
  value?: string;
  disabled?: boolean;
}

export type InputSelectProps = InputBaseProps<HTMLSelectElement> & {
  options: InputSelectOptionProps[];
};

export const InputSelect = React.forwardRef<HTMLSelectElement, InputSelectProps>(
  ({ cornerAdornment, description, disabled, error, fullWidth, touched = true, id, label, options, ...props }, ref) => {
    const showError = error && touched;

    const labelClassnames = classNames('block text-sm font-medium text-gray-700', {
      'w-full': fullWidth,
    });

    const inputClassNames = classNames(
      'mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md truncate',
      {
        'pr-10 border-red-300 text-red-800 placeholder-red-300 focus:ring-red-400 focus:border-red-400': showError,
        'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !showError,
        'opacity-50': disabled,
      },
    );

    return (
      <label htmlFor={id} className={labelClassnames}>
        <div className="flex justify-between">
          {label}
          {cornerAdornment && <div>{cornerAdornment}</div>}
        </div>
        <div className="relative mt-1">
          <select {...props} ref={ref} id={id} className={inputClassNames} disabled={disabled}>
            {options.map((option) => (
              <option value={option.value} key={option.label} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </select>
          {showError && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
          )}
        </div>
        {showError && <p className="mt-2 text-sm font-normal text-red-700">{error}</p>}
        {description && <p className="mt-2 text-sm font-normal text-gray-500">{description}</p>}
      </label>
    );
  },
);

InputSelect.displayName = 'InputSelect';
