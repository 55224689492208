import * as React from 'react';
import type { SVGProps } from 'react';
const EyeSlashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/eye-slash">
      <g id="Icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.9364 2.6636C3.58492 2.31213 3.01508 2.31213 2.6636 2.6636C2.31213 3.01507 2.31213 3.58492 2.6636 3.93639L20.0636 21.3364C20.4151 21.6879 20.9849 21.6879 21.3364 21.3364C21.6879 20.9849 21.6879 20.4151 21.3364 20.0636L19.2422 17.9694C21.0077 16.6312 22.3914 14.8158 23.2012 12.7151C23.3777 12.2572 23.3779 11.7495 23.2016 11.2915C21.4704 6.7926 17.1072 3.59999 11.9983 3.59999C9.93849 3.59999 7.99988 4.11898 6.30612 5.03332L3.9364 2.6636ZM9.30232 8.02952L10.6124 9.33961C11.0274 9.12267 11.4995 8.99999 12.0002 8.99999C13.6571 8.99999 15.0002 10.3431 15.0002 12C15.0002 12.5007 14.8775 12.9728 14.6606 13.3878L15.9709 14.6981C16.4944 13.9292 16.8004 13.0003 16.8004 12C16.8004 9.34903 14.6514 7.19999 12.0004 7.19999C11.0001 7.19999 10.0712 7.50601 9.30232 8.02952Z"
          fill="currentColor"
        />
        <path
          d="M12.8981 16.7162L15.9253 19.7434C14.6955 20.1689 13.375 20.4 12.0004 20.4C6.89157 20.4 2.52837 17.2074 0.797106 12.7084C0.62087 12.2505 0.621022 11.7428 0.797532 11.2849C1.38724 9.75517 2.2813 8.37668 3.40549 7.22362L7.28423 11.1024C7.22923 11.3932 7.20045 11.6932 7.20045 12C7.20045 14.651 9.34948 16.8 12.0004 16.8C12.3072 16.8 12.6073 16.7712 12.8981 16.7162Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default EyeSlashIcon;
