import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLayout } from '../store/actions';
import { ContextState, ILayoutConfig } from './layout.types';
import { LayoutConfigs } from './layoutConfigs';

export const LayoutContext = React.createContext({} as ContextState);

const { Provider } = LayoutContext;

export interface LayoutContextProviderProps {
  children: React.ReactNode;
  layoutConfig?: ILayoutConfig;
  drawerOpen?: boolean;
}

export const LayoutContextProvider: React.FC<LayoutContextProviderProps> = ({
  children,
  drawerOpen: drawerOpenProp = false,
  layoutConfig: layoutConfigProp = LayoutConfigs.initialVariant,
}) => {
  const [layoutConfig, setLayoutConfig] = useState<ILayoutConfig>(layoutConfigProp);
  const [drawerOpen, setDrawerOpen] = useState(drawerOpenProp);

  const layoutStoreState = useSelector((state: any) => state.layout);
  const dispatch = useDispatch();

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const toggleDrawerExpanded = useCallback(
    (drawerExpanded: boolean) => {
      dispatch(
        changeLayout({
          drawerExpanded,
        })
      );
    },
    [dispatch]
  );

  const values = useMemo(
    () => ({
      setLayoutConfig,
      layoutConfig,
      drawerOpen,
      openDrawer,
      closeDrawer,
      toggleDrawerExpanded,
      drawerExpanded: layoutStoreState.drawerExpanded,
    }),
    [
      closeDrawer,
      drawerOpen,
      layoutConfig,
      layoutStoreState.drawerExpanded,
      openDrawer,
      toggleDrawerExpanded,
    ]
  );

  return <Provider value={values}>{children}</Provider>;
};
