import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowRightOnRectangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-right-on-rectangle">
      <g id="Icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.59998 5.09999C3.59998 3.60882 4.80881 2.39999 6.29998 2.39999H12.9C14.3911 2.39999 15.6 3.60882 15.6 5.09999V7.49999C15.6 7.99705 15.197 8.39999 14.7 8.39999C14.2029 8.39999 13.8 7.99705 13.8 7.49999V5.09999C13.8 4.60294 13.397 4.19999 12.9 4.19999H6.29998C5.80292 4.19999 5.39998 4.60294 5.39998 5.09999V18.9C5.39998 19.397 5.80292 19.8 6.29998 19.8H12.9C13.397 19.8 13.8 19.397 13.8 18.9V16.5C13.8 16.0029 14.2029 15.6 14.7 15.6C15.197 15.6 15.6 16.0029 15.6 16.5V18.9C15.6 20.3912 14.3911 21.6 12.9 21.6H6.29998C4.80881 21.6 3.59998 20.3912 3.59998 18.9V5.09999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.19997 12C7.19997 11.5029 7.60292 11.1 8.09997 11.1H19.5546L18.2979 9.96896C17.9284 9.63644 17.8985 9.06738 18.231 8.69792C18.5635 8.32846 19.1326 8.29851 19.502 8.63103L22.502 11.331C22.6917 11.5017 22.8 11.7449 22.8 12C22.8 12.2551 22.6917 12.4983 22.502 12.669L19.502 15.369C19.1326 15.7015 18.5635 15.6715 18.231 15.3021C17.8985 14.9326 17.9284 14.3635 18.2979 14.031L19.5546 12.9H8.09997C7.60292 12.9 7.19997 12.497 7.19997 12Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default ArrowRightOnRectangleIcon;
