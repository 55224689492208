import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';

export type Configuration = AxiosRequestConfig;

export class BaseApi {
  private config: Configuration;

  constructor(config: Configuration) {
    this.config = config;
  }

  protected request<T>(options: Configuration): Promise<T> {
    return axios({
      ...this.config,
      ...options,
      paramsSerializer: (params) =>
        queryString.stringify(params, {
          arrayFormat: 'comma',
        }),
    }).then(({ data }) => data);
  }
}
