export const mediaQueryOptions = {
  'xs': 0,
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
};

export type Breakpoint = keyof typeof mediaQueryOptions;

export const breakpoints = {
  down: (size: Breakpoint) => `(max-width:${mediaQueryOptions[size] - 1}px)`,
  up: (size: Breakpoint) => `(min-width:${mediaQueryOptions[size]}px)`,
  between: (min: Breakpoint, max: Breakpoint) =>
    `(min-width:${mediaQueryOptions[min]}px) and (max-width:${mediaQueryOptions[max] - 1}px)`,
};
