import * as React from 'react';
import type { SVGProps } from 'react';
const CircleDotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/circle-dot">
      <path
        id="Exclude"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6C17.302 21.6 21.6 17.3019 21.6 12C21.6 6.69806 17.302 2.39999 12 2.39999C6.69809 2.39999 2.40002 6.69806 2.40002 12C2.40002 17.3019 6.69809 21.6 12 21.6ZM4.40002 12C4.40002 7.80263 7.80266 4.39999 12 4.39999C16.1974 4.39999 19.6 7.80263 19.6 12C19.6 16.1974 16.1974 19.6 12 19.6C7.80266 19.6 4.40002 16.1974 4.40002 12Z"
        fill="currentColor"
      />
      <path
        id="Icon"
        d="M12 8.39999C10.0118 8.39999 8.40002 10.0118 8.40002 12C8.40002 13.9882 10.0118 15.6 12 15.6C13.9883 15.6 15.6 13.9882 15.6 12C15.6 10.0118 13.9883 8.39999 12 8.39999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CircleDotIcon;
