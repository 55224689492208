import React, { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type PageBodyProps = ComponentProps<'div'>;

const PageFooter = React.forwardRef<HTMLElement, PageBodyProps>(({ className, ...props }, ref) => (
  <footer ref={ref} className={twMerge('mx-auto w-full p-4 pb-5 md:p-8', className)} {...props} />
));

PageFooter.displayName = 'Page.Footer';

export default PageFooter;
