import React, { useMemo } from 'react';
import { getChildByTypeDeep } from 'react-nanny';
import { Link } from '../Link/Link';
import { PrimaryAction } from './PrimaryAction';
import { SecondaryAction } from './SecondaryAction';

type Components = {
  PrimaryAction: typeof PrimaryAction;
  SecondaryAction: typeof SecondaryAction;
  Link: typeof Link;
};

export type EmptyStateProps = {
  icon?: React.ReactNode;
  title?: string;
  body?: string | React.ReactNode;
  children?: React.ReactNode;
};

export const EmptyState: React.FC<EmptyStateProps> & Components = ({
  icon,
  title,
  body,
  children,
}) => {
  const { primaryAction, secondaryAction, link } = useMemo(
    () => ({
      primaryAction: getChildByTypeDeep(children, EmptyState.PrimaryAction),
      secondaryAction: getChildByTypeDeep(children, EmptyState.SecondaryAction),
      link: getChildByTypeDeep(children, EmptyState.Link),
    }),
    [children]
  );

  return (
    <div className="flex flex-col items-center px-4 pt-6 pb-8 md:px-6 md:pt-6 md:pb-12 gap-y-6">
      <div className="flex flex-col items-center">
        {icon && <div className="mb-4 text-gray-400">{icon}</div>}

        <h3 className="text-lg leading-6 font-medium text-neutral-bold text-center">{title}</h3>
        {body && (
          <div className="text-sm leading-5 font-normal text-neutral-subtle mt-2 text-center">
            {body}
          </div>
        )}
      </div>
      {(primaryAction || secondaryAction) && (
        <div className="flex gap-x-2">
          {primaryAction}
          {secondaryAction}
        </div>
      )}
      {link && <div>{link}</div>}
    </div>
  );
};

EmptyState.PrimaryAction = PrimaryAction;
EmptyState.SecondaryAction = SecondaryAction;
EmptyState.Link = Link;
