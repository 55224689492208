import classnames from 'clsx';
import { InternalProps, SHAPE, SIZE } from './Badge.types';

export const shapePropsShaping = ({ shape, size }: InternalProps): Partial<InternalProps> => {
  const shapeClassNames = classnames({
    'rounded-[10px]': shape === SHAPE.BASIC && (size === SIZE.EXTRA_SMALL || size === SIZE.SMALL),
    'rounded-xl': shape === SHAPE.BASIC && size === SIZE.LARGE,
    'rounded': shape === SHAPE.ROUNDED,
  });

  return { shapeClassNames };
};
