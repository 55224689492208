import React, { memo, PropsWithChildren, useEffect, useMemo } from 'react';
import { useMessage, useMessageGetter } from '@messageformat/react';
import { FormProvider, useForm, useFormContext as useReactHookFormContext } from 'react-hook-form';
import { Badge, Button, Drawer } from '@shape-construction/arch-ui';
import { SHAPE, SIZE, THEME } from '@shape-construction/arch-ui/src/Badge/Badge.types';
import { FilterIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useModal } from 'app/hooks/useModal';

import type { GalleryFilters, GallerySort } from './useGalleryParams';

export type GalleryFilterFormValues = {
  authors?: GalleryFilters['authors'];
  locations?: GalleryFilters['locations'];
  format?: GalleryFilters['format'];
  order?: GallerySort['order'];
  date: {
    date?: GalleryFilters['date'];
    endDate?: GalleryFilters['endDate'];
    relativeDate?: GalleryFilters['relativeDate'];
  };
  search?: GalleryFilters['search'];
};

export const initialState: GalleryFilterFormValues = {
  authors: [],
  locations: [],
  format: undefined,
  order: undefined,
  search: undefined,
  date: {
    date: undefined,
    endDate: undefined,
    relativeDate: undefined,
  },
};
const useFormContext = () => useReactHookFormContext<GalleryFilterFormValues>();
const useGalleryFormFiltersCount = () => {
  const form = useFormContext();
  const params = form.watch();
  return useMemo(() => {
    const authors = params?.authors ? params?.authors.length : 0;
    const locations = params?.locations ? params?.locations.length : 0;
    const date = params?.date?.relativeDate ? 1 : 0;
    const format = params?.format ? 1 : 0;
    const total = authors + locations + date + format;

    return { total, authors, locations, date };
  }, [params]);
};

export const ClearFilters = ({ children }: PropsWithChildren) => {
  const form = useFormContext();
  const { total: filtersCount } = useGalleryFormFiltersCount();

  if (filtersCount === 0) return null;
  return (
    <Button
      color="primary"
      variant="text"
      size="xs"
      onClick={() =>
        form.reset({
          ...initialState,
          search: form.getValues('search'),
          order: form.getValues('order'),
        })
      }
    >
      {children}
    </Button>
  );
};

export type GalleryFilterFormProps = PropsWithChildren<{
  defaultValues: GalleryFilterFormValues;
  values: GalleryFilterFormValues;
  onSubmit: (values: GalleryFilterFormValues) => void;
}>;

export const GalleryFilterForm = memo(
  ({ children, defaultValues, values, onSubmit }: GalleryFilterFormProps) => {
    const form = useForm<GalleryFilterFormValues>({ defaultValues, values });
    const isLargeScreen = useMediaQuery(breakpoints.up('md'));
    const watchKeys: Partial<keyof GalleryFilterFormValues>[] = useMemo(
      () =>
        isLargeScreen
          ? ['authors', 'date', 'format', 'locations', 'order', 'search']
          : ['order', 'search'],
      [isLargeScreen]
    );

    const { watch, handleSubmit } = form;
    useEffect(() => {
      const subscription = watch((_, { name }) => {
        if (!name || watchKeys.includes(name as keyof GalleryFilterFormValues)) {
          handleSubmit(onSubmit)();
        }
      });

      return () => subscription.unsubscribe();
    }, [handleSubmit, onSubmit, watch, watchKeys]);

    return <FormProvider {...form}>{children}</FormProvider>;
  }
);

type ResponsiveFilterOptionsProps = PropsWithChildren<{
  onSubmit: (values: GalleryFilterFormValues) => void;
}>;
export const ResponsiveGalleryFilterOptions: React.FC<ResponsiveFilterOptionsProps> = ({
  children,
  onSubmit,
}) => {
  const messageGetter = useMessageGetter('');
  const label = useMessage('projectGallery.filters.label');
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const { open, closeModal, openModal } = useModal(false);
  const form = useFormContext();
  const { total } = useGalleryFormFiltersCount();

  const handleCloseModal = () => {
    form.reset();
    closeModal();
  };

  if (isLargeScreen) return <div className="flex flex-row items-center gap-2">{children}</div>;

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        size="xs"
        onClick={openModal}
        leadingIcon={FilterIcon}
      >
        {label}
        {total > 0 && (
          <Badge
            size={SIZE.EXTRA_SMALL}
            theme={THEME.BLUE}
            shape={SHAPE.BASIC}
            label={total.toString()}
          />
        )}
      </Button>
      <Drawer open={open} onClose={handleCloseModal}>
        <Drawer.Header onClose={handleCloseModal}>
          <Drawer.Title>{label}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Content>
          <div className="flex flex-col gap-6 py-6 px-4">{children}</div>
        </Drawer.Content>
        <Drawer.Footer>
          <div className="flex gap-x-6 text-sm font-medium leading-5">
            <Button color="secondary" variant="outlined" size="sm" onClick={handleCloseModal}>
              {messageGetter('actions.cancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="sm"
              type="submit"
              onClick={(e) => {
                form.handleSubmit(onSubmit)(e);
                closeModal();
              }}
              disabled={!form.formState.isDirty}
            >
              {messageGetter('actions.save')}
            </Button>
          </div>
        </Drawer.Footer>
      </Drawer>
    </>
  );
};
