import React from 'react';
import { useMessageGetter } from '@messageformat/react';
// eslint-disable-next-line import/extensions
import { useRegisterSW } from 'virtual:pwa-register/react';
import { Alert, Link } from '@shape-construction/arch-ui';

type ServiceWorkerLoaderProps = {
  showUpdate: boolean;
};
export const ServiceWorkerLoader: React.FC<ServiceWorkerLoaderProps> = ({ showUpdate }) => {
  const messages = useMessageGetter('ServiceWorkerLoader');
  const {
    needRefresh: [hasUpdate],
    updateServiceWorker,
  } = useRegisterSW({ immediate: false });

  if (!hasUpdate || !showUpdate) return null;

  const onUpdateClick = () => updateServiceWorker(true);

  return (
    <div className="text-center">
      <Alert color="primary" justifyContent="center">
        <Alert.Message>{messages('description')}</Alert.Message>
        <Alert.Actions>
          <Link color="primary" as="button" onClick={onUpdateClick}>
            {messages('cta')}
          </Link>
        </Alert.Actions>
      </Alert>
    </div>
  );
};
