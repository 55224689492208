import { environment } from 'app/config/environment';

/**
 * internal helper function to  decode the public key and convert it to raw bytes
 */
export function urlBase64ToUint8Array(base64String: any) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * checks if Push notification and service workers are supported by your browser
 */
export function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
export async function askUserPermission() {
  if (!isPushNotificationSupported()) {
    return null;
  }

  return Notification.requestPermission();
}

/**
 * using the registered service worker creates a push notification subscription and returns it
 */
export async function createNotificationSubscription() {
  if (!isPushNotificationSupported()) return null;

  const { PUSH_NOTIFICATIONS_PUBLIC_KEY } = environment;

  if (!PUSH_NOTIFICATIONS_PUBLIC_KEY) {
    throw new Error('Missing push notification public key');
  }

  const serviceWorker = await navigator.serviceWorker.ready;
  const subscription = await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(PUSH_NOTIFICATIONS_PUBLIC_KEY),
  });
  return subscription;
}

/**
 * returns the subscription if present or nothing
 */
export async function getUserSubscription() {
  if (!isPushNotificationSupported()) return null;

  const serviceWorker = await navigator.serviceWorker.ready;
  return serviceWorker.pushManager.getSubscription();
}

/**
 * returns the user consent permission, one of granted, default, denied
 */
export function getUserConsent() {
  if (!isPushNotificationSupported()) {
    return null;
  }
  return Notification.permission;
}
