import * as React from 'react';
import type { SVGProps } from 'react';
const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/check">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0454 4.98415C20.4408 5.28538 20.5171 5.8501 20.2159 6.24547L10.6159 18.8455C10.4585 19.0521 10.2195 19.1806 9.96037 19.198C9.70125 19.2154 9.44722 19.1201 9.26358 18.9364L3.86358 13.5364C3.51211 13.185 3.51211 12.6151 3.86358 12.2636C4.21505 11.9122 4.7849 11.9122 5.13637 12.2636L9.80819 16.9355L18.7841 5.1546C19.0853 4.75922 19.65 4.68291 20.0454 4.98415Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CheckIcon;
