import React from 'react';
import type { MenuItem } from './side-menu.types';
import { SideDrawerListItem } from './SideDrawerListItem';

export interface SideDrawerListCategoryProps {
  items: MenuItem[];
  isExpanded: boolean;
}

const SideDrawerListCategory: React.FC<SideDrawerListCategoryProps> = ({
  items = [],
  isExpanded,
}) => (
  <>
    {items.map((item) => (
      <SideDrawerListItem key={item.title as string} category={item} isExpanded={isExpanded} />
    ))}
  </>
);

export { SideDrawerListCategory };
