import React from 'react';
import { useMessage } from '@messageformat/react';
import { Button } from '@shape-construction/arch-ui';

export type UploadSaveProps = {
  onHandleSave: () => void;
};

export const UploadSave = ({ onHandleSave }: UploadSaveProps) => {
  const label = useMessage('projectGallery.uploadPreview.save');
  return (
    <div className="flex justify-end gap-x-2">
      <Button
        aria-label={label}
        color="primary"
        size="md"
        onClick={onHandleSave}
        variant="contained"
      >
        {label}
      </Button>
    </div>
  );
};
