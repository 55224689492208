import * as React from 'react';
import type { SVGProps } from 'react';
const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/eye">
      <g id="Icon">
        <path
          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3432 13.6569 9.00001 12 9.00001C10.3431 9.00001 9 10.3432 9 12C9 13.6569 10.3431 15 12 15Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.797106 12.7085C0.62087 12.2505 0.621022 11.7428 0.797532 11.2849C2.53054 6.78946 6.89195 3.60001 11.9983 3.60001C17.1072 3.60001 21.4704 6.79261 23.2016 11.2916C23.3779 11.7495 23.3777 12.2572 23.2012 12.7151C21.4682 17.2105 17.1068 20.4 12.0004 20.4C6.89157 20.4 2.52837 17.2074 0.797106 12.7085ZM16.8004 12C16.8004 14.651 14.6514 16.8 12.0004 16.8C9.34948 16.8 7.20045 14.651 7.20045 12C7.20045 9.34904 9.34948 7.20001 12.0004 7.20001C14.6514 7.20001 16.8004 9.34904 16.8004 12Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default EyeIcon;
