import * as React from 'react';
import type { SVGProps } from 'react';
const MapPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/map-pin">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6281 22.7196L11.6315 22.7211C11.868 22.824 12 22.8 12 22.8C12 22.8 12.132 22.824 12.3695 22.7206L12.3719 22.7196L12.3788 22.7164L12.4006 22.7064C12.4186 22.698 12.4436 22.6863 12.4751 22.6711C12.5381 22.6407 12.6271 22.5967 12.7384 22.5386C12.9609 22.4226 13.2735 22.2502 13.646 22.0194C14.3895 21.5586 15.3808 20.8591 16.3746 19.9013C18.3626 17.9851 20.4 14.991 20.4 10.8C20.4 6.1608 16.6392 2.39999 12 2.39999C7.36081 2.39999 3.60001 6.1608 3.60001 10.8C3.60001 14.991 5.6374 17.9851 7.62543 19.9013C8.61917 20.8591 9.61053 21.5586 10.354 22.0194C10.7265 22.2502 11.0391 22.4226 11.2616 22.5386C11.3729 22.5967 11.462 22.6407 11.5249 22.6711C11.5564 22.6863 11.5814 22.698 11.5994 22.7064L11.6212 22.7164L11.6281 22.7196ZM12 13.5C13.4912 13.5 14.7 12.2912 14.7 10.8C14.7 9.30883 13.4912 8.09999 12 8.09999C10.5088 8.09999 9.30001 9.30883 9.30001 10.8C9.30001 12.2912 10.5088 13.5 12 13.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default MapPinIcon;
