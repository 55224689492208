import * as React from 'react';
import type { SVGProps } from 'react';
const MegaphoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/megaphone">
      <g id="Icon">
        <path
          d="M16.7042 4.61392C14.3831 5.81011 11.8364 6.62921 9.14466 6.99063C8.11652 7.12868 7.06684 7.20001 6.00001 7.20001C3.34905 7.20001 1.20001 9.34904 1.20001 12C1.20001 14.4461 3.02973 16.4649 5.39518 16.7623C5.7454 18.1275 6.25176 19.4722 6.92047 20.7718C7.39633 21.6966 8.53083 21.9704 9.38117 21.4794L10.4204 20.8794C11.2922 20.3761 11.549 19.2941 11.1267 18.4539C10.9274 18.0573 10.7482 17.6554 10.5887 17.2496C12.7492 17.679 14.8024 18.406 16.7042 19.3861C17.5427 17.0817 18 14.5943 18 12C18 9.40574 17.5427 6.91831 16.7042 4.61392Z"
          fill="currentColor"
        />
        <path
          d="M18.2913 3.71587C19.2663 6.29106 19.8 9.08321 19.8 12C19.8 14.9168 19.2663 17.709 18.2913 20.2841C18.2912 20.2841 18.2914 20.2842 18.2913 20.2841L18.2563 20.3768C18.0783 20.8409 18.3102 21.3614 18.7743 21.5394C19.2384 21.7174 19.7589 21.4855 19.9369 21.0214C20.0402 20.752 20.139 20.4804 20.2333 20.2067C20.8982 18.2752 21.3349 16.2379 21.5114 14.1274C22.2774 13.7266 22.8 12.9249 22.8 12C22.8 11.0751 22.2774 10.2734 21.5114 9.87258C21.3349 7.7621 20.8982 5.72483 20.2333 3.79336C20.139 3.51962 20.0402 3.248 19.9369 2.97862C19.7589 2.51453 19.2384 2.28259 18.7743 2.46058C18.3102 2.63857 18.0783 3.15908 18.2563 3.62318L18.2913 3.71587C18.2914 3.71583 18.2913 3.71591 18.2913 3.71587Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default MegaphoneIcon;
