import * as actionTypes from '../actions/actionTypes';

/**
 * Adds the recently created issue to the builder state
 * @param { Issue } issue
 * @returns { type: string, payload: { issue: Issue } }
 */
export const builderIssue = (issueId: string | null) => ({
  type: actionTypes.BUILDER_ISSUE,
  payload: { issueId },
});
