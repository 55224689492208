import * as React from 'react';
import type { SVGProps } from 'react';
const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/clock">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6C17.302 21.6 21.6 17.302 21.6 12C21.6 6.69809 17.302 2.40002 12 2.40002C6.69809 2.40002 2.40002 6.69809 2.40002 12C2.40002 17.302 6.69809 21.6 12 21.6ZM13.125 6.00003C13.125 5.3787 12.6213 4.87502 12 4.87502C11.3787 4.87502 10.875 5.3787 10.875 6.00003V12C10.875 12.6213 11.3787 13.125 12 13.125H16.8C17.4213 13.125 17.925 12.6213 17.925 12C17.925 11.3787 17.4213 10.875 16.8 10.875H13.125V6.00003Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ClockIcon;
