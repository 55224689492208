import type { ArticleLabel } from 'zendesk/api/help-center/types';
import { BaseApi } from 'zendesk/base-api';

export type GetArticleLabelsResponse = { labels: ArticleLabel[] };

export class ArticleLabels extends BaseApi {
  getLabels() {
    return this.request<GetArticleLabelsResponse>({
      url: `/articles/labels`,
      method: 'get',
    });
  }
}
