import React from 'react';
import classNames from 'clsx';
import {
  ModalBaseContent,
  ModalBaseContentProps,
} from '../../ModalBase/components/ModalBaseContent';

export type DrawerContentProps = ModalBaseContentProps;

export const DrawerContent: React.FC<ModalBaseContentProps> = (props) => {
  return <ModalBaseContent {...props} className={classNames('px-0', props.className)} />;
};
