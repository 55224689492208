import * as React from 'react';
import type { SVGProps } from 'react';
const ChatPrivateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chat-private">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12C21.6 16.6392 17.302 20.4 12 20.4C10.2101 20.4 8.53451 19.9713 7.10015 19.2249L2.40002 20.4L4.00599 16.6527C2.99139 15.3207 2.40002 13.7208 2.40002 12C2.40002 7.36078 6.69809 3.59998 12 3.59998C17.302 3.59998 21.6 7.36078 21.6 12ZM12 6.375C10.3431 6.375 9 7.71815 9 9.375V11.0012C8.36258 11.0297 7.85455 11.5555 7.85455 12.2V15.8C7.85455 16.4627 8.39181 17 9.05455 17H15.0546C15.7173 17 16.2546 16.4627 16.2546 15.8V12.2C16.2546 11.5373 15.7173 11 15.0546 11H15V9.375C15 7.71815 13.6569 6.375 12 6.375ZM13.5 11V9.375C13.5 8.54657 12.8284 7.875 12 7.875C11.1716 7.875 10.5 8.54657 10.5 9.375V11H13.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChatPrivateIcon;
