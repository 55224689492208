import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { Button } from '@shape-construction/arch-ui';
import { ErrorIcon } from '@shape-construction/arch-ui/src/Icons/custom';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { environment } from '../../config/environment';

interface ErrorProps {
  resetError?: () => void;
}

export const Error: React.VFC<ErrorProps> = ({ resetError }) => {
  const messages = useMessageGetter('error');
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const supportEmail = environment.SUPPORT_EMAIL;
  const appVersion = process.env.REACT_APP_VERSION;

  return (
    <div className="flex h-full flex-col items-center justify-center space-y-6 p-4">
      <ErrorIcon />
      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-3xl font-bold">{messages('title')}</h1>
        <p className="text-base leading-6">
          {messages('content')}{' '}
          <a
            href={`mailto:${supportEmail}`}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-indigo-500 hover:text-indigo-400"
          >
            {messages('contactCTA')}
          </a>
          .
        </p>
      </div>
      <Button
        color="primary"
        variant="contained"
        size="md"
        onClick={resetError}
        fullWidth={!isLargeScreen}
      >
        {messages('backHomeCTA')}
      </Button>
      {appVersion ? (
        <p className=" text-center text-xs font-normal leading-5 text-gray-400">
          {messages('version', { version: appVersion })}
        </p>
      ) : null}
    </div>
  );
};
