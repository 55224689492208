import React from 'react';
import classNames from 'clsx';
import { ModalBase } from '../../ModalBase';

export type ConfirmationModalTitleProps = React.ComponentProps<typeof ModalBase.Title>;

export const ConfirmationModalTitle: React.FC<ConfirmationModalTitleProps> = ({
  className,
  ...props
}) => {
  const classes = classNames('text-lg leading-6 font-medium', className);

  return <ModalBase.Title className={classes} {...props} />;
};
