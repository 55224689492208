import { useMessageGetter } from '@messageformat/react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  usePostApiUsersConfirmation,
  usePostApiUsersPasswordInstructions,
  usePostApiLogin,
  useDeleteApiLogout,
  usePatchApiUsersPassword,
  usePostApiUsersConfirmationInstructions,
  usePostApiUsers,
  usePatchApiUsersMe,
  usePostApiAuthentication,
  useGetApiUsersMe,
  getGetApiUsersMeQueryKey,
} from '@shape-construction/api/api';
import type { UserSchema } from '@shape-construction/api/model';
import { showErrorToast, showSuccessToast } from '@shape-construction/arch-ui/src/Toast/toasts';

// queries
export const useUsersMe = () =>
  useGetApiUsersMe({
    query: {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  });

export const useCurrentUser = () => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(getGetApiUsersMeQueryKey()) as UserSchema;
};

// mutations
export const useConfirmEmail = () => {
  const queryClient = useQueryClient();
  const messages = useMessageGetter('auth.confirmEmail.toast');

  return usePostApiUsersConfirmation({
    mutation: {
      onSuccess: (user) => {
        showSuccessToast({ message: messages('success') });

        queryClient.setQueryData(getGetApiUsersMeQueryKey(), user);
      },
      onError: () => {
        showErrorToast({ message: messages('failure') });
      },
    },
  });
};

export const useForgotPassword = usePostApiUsersPasswordInstructions;

export const useLogin = () => {
  const queryClient = useQueryClient();

  return usePostApiLogin({
    mutation: {
      onSuccess: (user) => {
        queryClient.setQueryData(getGetApiUsersMeQueryKey(), user);
      },
    },
  });
};

export const useLogout: typeof useDeleteApiLogout = (options) => {
  const navigate = useNavigate();

  return useDeleteApiLogout({
    ...options,
    mutation: {
      ...options?.mutation,
      onSettled: () => navigate(`/`, { state: { redirect: null } }),
    },
  });
};

export const useResetPassword = usePatchApiUsersPassword;

export const useResendConfirmationEmail = () => {
  const messages = useMessageGetter('auth.verificationSent');

  return usePostApiUsersConfirmationInstructions({
    mutation: {
      onSuccess: () => {
        showSuccessToast({ message: messages('success') });
      },
      onError: () => {
        showErrorToast({ message: messages('failure') });
      },
    },
  });
};

export const useSignUpUser = () => {
  const queryClient = useQueryClient();

  return usePostApiUsers({
    mutation: {
      onSuccess: (user) => {
        if (user.emailConfirmed) queryClient.setQueryData(getGetApiUsersMeQueryKey(), user);
      },
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return usePatchApiUsersMe({
    mutation: {
      onSuccess: (user) => {
        queryClient.setQueryData(getGetApiUsersMeQueryKey(), user);
      },
    },
  });
};

export const useVerifyEmail = usePostApiAuthentication;
