import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

const DEVICE_SESSION_ID_KEY = 'shape/device-session-id';

const getDeviceSessionId = () => {
  const id = localStorage.getItem(DEVICE_SESSION_ID_KEY);
  if (id) return id;

  const newId = uuidv4();
  localStorage.setItem(DEVICE_SESSION_ID_KEY, newId);
  return newId;
};

/** Returns a unique device entity ID for the current user session. */
export const useDeviceSessionId = () => {
  return useMemo(() => getDeviceSessionId(), []);
};
