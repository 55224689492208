import * as React from 'react';
import type { SVGProps } from 'react';
const UserCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/user-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12C21.6 17.302 17.302 21.6 12 21.6C6.69809 21.6 2.40002 17.302 2.40002 12C2.40002 6.69809 6.69809 2.40002 12 2.40002C17.302 2.40002 21.6 6.69809 21.6 12ZM15 9.00002C15 10.6569 13.6569 12 12 12C10.3432 12 9.00002 10.6569 9.00002 9.00002C9.00002 7.34317 10.3432 6.00002 12 6.00002C13.6569 6.00002 15 7.34317 15 9.00002ZM12 14.4C9.64962 14.4 7.56227 15.5263 6.24817 17.2685C7.67413 18.8245 9.72315 19.8 12 19.8C14.2769 19.8 16.3259 18.8245 17.7519 17.2685C16.4378 15.5263 14.3504 14.4 12 14.4Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default UserCircleIcon;
