import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';

export type APIError = {
  response: {
    data: {
      errorCode: string;
      errorDescription: string;
    };
  };
};

export type ErrorState = (Error | APIError)[];

const initialState: ErrorState = [];

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    add(state, action: PayloadAction<Error>) {
      state.push(action.payload);
    },
    removeFirst(state) {
      state.shift();
    },
  },
});

export const { add, removeFirst } = errorsSlice.actions;
export default errorsSlice.reducer;

/**
 * Selectors
 */
const getAllErrors = (state: RootState) => state.errors;
export const selectFirstError = createSelector([getAllErrors], (errors) => errors?.[0]);
