import React, { forwardRef } from 'react';

import * as List from '../primitives/List';
import { cn } from '../utils/classes';

export type RootProps = React.ComponentPropsWithoutRef<typeof List.Root> & {
  divider?: boolean;
};

export const Root = forwardRef<React.ElementRef<typeof List.Root>, RootProps>(
  ({ className, divider, ...props }, ref) => {
    return (
      <List.Root
        ref={ref}
        className={cn(
          'w-full flex flex-col',
          {
            'divide-y divide-neutral-subtlest': divider,
          },
          className
        )}
        {...props}
      />
    );
  }
);
Root.displayName = 'List.Root';

export const Divider = forwardRef<
  React.ElementRef<typeof List.Root>,
  React.ComponentPropsWithoutRef<typeof List.Root>
>(({ className, ...props }, ref) => {
  return (
    <List.Divider
      ref={ref}
      className={cn('border-[0.5px] border-neutral-subtlest', className)}
      {...props}
    />
  );
});
Divider.displayName = 'List.Divider';

export const Item = forwardRef<
  React.ElementRef<typeof List.Item>,
  React.ComponentPropsWithoutRef<typeof List.Item>
>(({ className, ...props }, ref) => {
  return (
    <List.Item
      ref={ref}
      className={cn(
        'w-full px-4 py-3 flex flex-row gap-3 items-center bg-neutral-alpha-subltest',
        'disabled:opacity-60',
        'hover:bg-neutral-alpha-subltest-hovered',
        'active:bg-neutral-alpha-subltest-pressed',
        className
      )}
      {...props}
    />
  );
});
Item.displayName = 'List.Item';

export const Content = forwardRef<
  React.ElementRef<typeof List.Content>,
  React.ComponentPropsWithoutRef<typeof List.Content>
>(({ className, ...props }, ref) => {
  return (
    <List.Content
      ref={ref}
      className={cn('flex-1 flex flex-col items-start gap-1', className)}
      {...props}
    />
  );
});
Content.displayName = 'List.Content';

export const Title = forwardRef<
  React.ElementRef<typeof List.Title>,
  React.ComponentPropsWithoutRef<typeof List.Title>
>(({ className, ...props }, ref) => {
  return (
    <List.Title
      ref={ref}
      className={cn('text-sm leading-5 font-medium text-neutral-bold', className)}
      {...props}
    />
  );
});
Title.displayName = 'List.Title';

export const SupportingText = forwardRef<
  React.ElementRef<typeof List.SupportingText>,
  React.ComponentPropsWithoutRef<typeof List.SupportingText>
>(({ className, ...props }, ref) => {
  return (
    <List.SupportingText
      ref={ref}
      className={cn('text-xs leading-4 font-normal text-neutral-subtle', className)}
      {...props}
    />
  );
});
SupportingText.displayName = 'List.SupportingText';
