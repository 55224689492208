import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronUpDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-up-down">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3636 5.11358C11.7151 4.76211 12.2849 4.76211 12.6364 5.11358L16.1364 8.61358C16.4878 8.96505 16.4878 9.5349 16.1364 9.88637C15.7849 10.2378 15.2151 10.2378 14.8636 9.88637L12 7.02277L9.13637 9.88637C8.7849 10.2378 8.21505 10.2378 7.86358 9.88637C7.51211 9.5349 7.51211 8.96505 7.86358 8.61358L11.3636 5.11358ZM7.86358 14.1136C8.21505 13.7621 8.7849 13.7621 9.13637 14.1136L12 16.9772L14.8636 14.1136C15.2151 13.7621 15.7849 13.7621 16.1364 14.1136C16.4878 14.4651 16.4878 15.0349 16.1364 15.3864L12.6364 18.8864C12.2849 19.2378 11.7151 19.2378 11.3636 18.8864L7.86358 15.3864C7.51211 15.0349 7.51211 14.4651 7.86358 14.1136Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChevronUpDownIcon;
