import React from 'react';
import { useMessage } from '@messageformat/react';
import { NoResultsPlaceholder } from '../EmptyGalleryPlaceholder/NoResultsPlaceholder';
import { ClearFilters } from '../GalleryToolbar/GalleryFilterForm';

export const EmptyResultsClear = () => {
  return (
    <NoResultsPlaceholder>
      <ClearFilters>{useMessage('projectGallery.noResultsPlaceholder.clearCTA')}</ClearFilters>
    </NoResultsPlaceholder>
  );
};
