import type { NodeModel } from '@minoru/react-dnd-treeview';

export { getDescendants, getTreeItem } from '@minoru/react-dnd-treeview';

export function createItem<T>(
  props: Omit<NodeModel<T>, 'droppable' | 'text'> & { text?: string }
): NodeModel<T> {
  return {
    droppable: true,
    text: '',
    ...props,
  };
}

export function getChildren<T>(tree: NodeModel<T>[], nodeId: NodeModel<T>['id']) {
  return tree.filter(({ parent }) => parent === nodeId);
}

export function hasChildren<T>(tree: NodeModel<T>[], nodeId: NodeModel<T>['id']) {
  return tree.some(({ parent }) => parent === nodeId);
}
