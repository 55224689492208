import React from 'react';
import { useMediaQuery } from '@shape-construction/hooks';
import { breakpoints } from '../utils/breakpoints';
import { ModalBase, ModalBaseProps } from '../ModalBase/ModalBase';
import { Transition } from '../ModalBase/config/transitions';
import { FullScreenModalContent } from './components/FullScreenModalContent';
import { FullScreenModalFooter } from './components/FullScreenModalFooter';
import { FullScreenModalTitle } from './components/FullScreenModalTitle';
import { FullScreenModalHeader } from './components/FullScreenModalHeader';

type Components = {
  Header: typeof ModalBase.Header;
  Title: typeof ModalBase.Title;
  Content: typeof ModalBase.Content;
  Footer: typeof ModalBase.Footer;
};

export type FullScreenModalProps = Pick<ModalBaseProps, 'children' | 'open' | 'onClose' | 'initialFocus'> & {
  isPadded?: boolean;
  hasRoundBorders?: boolean;
};

export const FullScreenModal: React.FC<FullScreenModalProps> & Components = ({
  onClose,
  open,
  children,
  isPadded: isPaddedProp,
  hasRoundBorders: hasRoundBordersProp,
  ...props
}) => {
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const fullScreenMode = true;
  const isPadded = isPaddedProp ?? !isSmallScreen;
  const hasRoundBorders = hasRoundBordersProp ?? !isSmallScreen;
  const transition: Transition = isSmallScreen ? 'bottom-to-top' : 'fade';

  return (
    <ModalBase
      {...props}
      onClose={onClose}
      open={open}
      fullScreen={fullScreenMode}
      outsidePad={isPadded}
      roundBorders={hasRoundBorders}
      transition={transition}
    >
      {children}
    </ModalBase>
  );
};

FullScreenModal.Header = FullScreenModalHeader;
FullScreenModal.Title = FullScreenModalTitle;
FullScreenModal.Content = FullScreenModalContent;
FullScreenModal.Footer = FullScreenModalFooter;
