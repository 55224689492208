import React from 'react';
import { toast } from 'react-hot-toast';
import { Toast, ToastProps } from './Toast';

interface ShowToastProps extends Omit<ToastProps, 'toast' | 'onClose'> {
  id?: string;
  duration?: number;
}

const showToast = ({
  message,
  Actions,
  alignContent,
  id,
  duration,
  hasIcon,
  color,
}: ShowToastProps) =>
  toast.custom(
    (t) => (
      <Toast
        color={color}
        toast={t}
        message={message}
        Actions={Actions}
        onClose={() => toast.dismiss(t.id)}
        alignContent={alignContent}
        hasIcon={hasIcon}
      />
    ),
    { id, duration }
  );

// Specific toast functions
export const showSuccessToast = (props: Omit<ShowToastProps, 'color'>) =>
  showToast({ ...props, color: 'success' });

export const showErrorToast = (props: Omit<ShowToastProps, 'color'>) =>
  showToast({ ...props, color: 'danger' });

export const showInfoToast = (props: Omit<ShowToastProps, 'color'>) =>
  showToast({ ...props, color: 'primary' });

export const showWarningToast = (props: Omit<ShowToastProps, 'color'>) =>
  showToast({ ...props, color: 'warning' });

export const showSecondaryToast = (props: Omit<ShowToastProps, 'color'>) =>
  showToast({ ...props, color: 'secondary' });

export const dismissToast = (id: string) => toast.dismiss(id);
