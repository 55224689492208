import React from 'react';
import InputEmail from '../InputEmail';
import InputPassword from '../InputPassword';
import Button from '../Button';
import { AuthPagesHeader } from '../AuthPagesHeader/AuthPagesHeader';
import { InputExtraPropsType } from '../types/InputBase';
import { LogInBottomSection } from './components/LogInBottomSection';

type Components = {
  BottomSection: typeof LogInBottomSection;
};

export type ExtraPropsType = {
  email: InputExtraPropsType;
  password: InputExtraPropsType;
};

export interface LogInProps {
  /**
   * Link component for the forgot password page
   */
  forgotPasswordLink: React.ReactNode;
  /**
   * Link component for the auth page
   */
  notYouLink: React.ReactNode;
  /**
   * Logo to be displayed on the top section
   */
  logo?: React.ReactNode;
  /**
   * function to be called on the submit of form
   */
  submitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  /**
   * Renders the feedback/response after the submission of form
   */
  submitFeedback?: React.ReactNode;
  /**
   * Disables submit button while submitting the form
   */
  disableSubmitButton: boolean;
  /**
   * Stores the error messages for password input
   */
  passwordError?: string;
  /**
   * Extra props to register an input and apply validation rules to React Hook Form
   */
  extraProps: ExtraPropsType;
  /**
   * Element to be rendered at the end of the component
   */
  children?: React.ReactNode;
}

export const LogIn: React.FC<LogInProps> & Components = ({
  forgotPasswordLink,
  notYouLink,
  logo,
  submitForm,
  submitFeedback,
  children,
  extraProps,
  disableSubmitButton,
  passwordError,
}) => {
  return (
    <div className="flex flex-col justify-center">
      <AuthPagesHeader title="Log in" logo={logo} />

      {submitFeedback && <div className="mt-8">{submitFeedback}</div>}

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8">
          <form className="space-y-6" onSubmit={submitForm} noValidate>
            <InputEmail
              {...extraProps.email}
              name="email"
              label="Email"
              cornerAdornment={notYouLink}
              disabled
            />
            <InputPassword
              {...extraProps.password}
              name="password"
              label="Password"
              error={passwordError}
              showVisibilityToggle
            />

            <div className="flex items-center justify-center text-sm">{forgotPasswordLink}</div>

            <Button
              color="primary"
              variant="contained"
              size="md"
              type="submit"
              fullWidth
              disabled={disableSubmitButton}
            >
              Log in
            </Button>
          </form>
        </div>
        {children}
      </div>
    </div>
  );
};

LogIn.BottomSection = LogInBottomSection;
