import * as React from 'react';
import type { SVGProps } from 'react';
const ChatBubbleOvalLeftEllipsisIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chat-bubble-oval-left-ellipsis">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.60001C6.82809 3.60001 2.40002 7.24009 2.40002 12C2.40002 14.4293 3.57422 16.5903 5.39882 18.1018C5.38048 18.8753 5.15373 19.5948 4.77293 20.2091C4.607 20.4768 4.59306 20.8118 4.73619 21.0923C4.87932 21.3729 5.15868 21.5582 5.4728 21.581C5.64704 21.5936 5.82286 21.6 6.00002 21.6C7.56446 21.6 9.01415 21.1001 10.1954 20.252C10.7806 20.3492 11.3839 20.4 12 20.4C17.172 20.4 21.6 16.7599 21.6 12C21.6 7.24009 17.172 3.60001 12 3.60001ZM12 13.2C12.6628 13.2 13.2 12.6627 13.2 12C13.2 11.3373 12.6628 10.8 12 10.8C11.3373 10.8 10.8 11.3373 10.8 12C10.8 12.6627 11.3373 13.2 12 13.2ZM9.60002 12C9.60002 12.6627 9.06277 13.2 8.40002 13.2C7.73728 13.2 7.20002 12.6627 7.20002 12C7.20002 11.3373 7.73728 10.8 8.40002 10.8C9.06277 10.8 9.60002 11.3373 9.60002 12ZM15.6 13.2C16.2628 13.2 16.8 12.6627 16.8 12C16.8 11.3373 16.2628 10.8 15.6 10.8C14.9373 10.8 14.4 11.3373 14.4 12C14.4 12.6627 14.9373 13.2 15.6 13.2Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChatBubbleOvalLeftEllipsisIcon;
