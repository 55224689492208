import * as React from 'react';
import type { SVGProps } from 'react';
const NoSymbolIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/no-symbol">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15753 5.88474L18.1153 16.8425C19.1706 15.5118 19.8 13.83 19.8 12C19.8 7.69217 16.3078 4.19999 12 4.19999C10.17 4.19999 8.48823 4.82934 7.15753 5.88474ZM16.8425 18.1153L5.88474 7.15753C4.82934 8.48823 4.19999 10.17 4.19999 12C4.19999 16.3078 7.69217 19.8 12 19.8C13.83 19.8 15.5118 19.1706 16.8425 18.1153ZM5.21177 5.21177C6.94804 3.4755 9.34928 2.39999 12 2.39999C17.3019 2.39999 21.6 6.69806 21.6 12C21.6 14.6507 20.5245 17.0519 18.7882 18.7882C17.0519 20.5245 14.6507 21.6 12 21.6C6.69806 21.6 2.39999 17.3019 2.39999 12C2.39999 9.34928 3.4755 6.94804 5.21177 5.21177Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default NoSymbolIcon;
