import React from 'react';
import classNames from 'clsx';
import { FeedEventBaseHeader } from './components/FeedEventBaseHeader/FeedEventBaseHeader';
import { FeedEventBaseContent } from './components/FeedEventBaseContent/FeedEventBaseContent';

type Components = {
  Header: typeof FeedEventBaseHeader;
  Content: typeof FeedEventBaseContent;
};

export type FeedEventBaseProps = {
  className?: string;
  children: React.ReactNode;
  avatar?: React.ReactNode;
};

export const FeedEventBase: React.FC<FeedEventBaseProps> & Components = ({
  className,
  children,
  avatar,
}) => {
  return (
    <div className={classNames('flex gap-x-3 bg-white px-4 py-3', className)}>
      {avatar}
      <div className="flex flex-col gap-y-1 w-full min-w-0">{children}</div>
    </div>
  );
};

FeedEventBase.Header = FeedEventBaseHeader;
FeedEventBase.Content = FeedEventBaseContent;
