import classnames from 'clsx';
import { InternalProps, SIZE } from './Badge.types';

export const sizePropsShaping = ({ size }: InternalProps): Partial<InternalProps> => {
  const sizeClassNames = classnames({
    'text-sm font-medium leading-5': size === SIZE.LARGE,
    'text-xs font-medium leading-4': size === SIZE.EXTRA_SMALL || size === SIZE.SMALL,

    'px-[5px]': size === SIZE.EXTRA_SMALL,
    'px-1.5 py-0.5': size === SIZE.SMALL,
    'px-2 py-0.5': size === SIZE.LARGE,
  });

  return { sizeClassNames };
};
