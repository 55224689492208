import React from 'react';
import { cn } from '../utils/classes';

export const NOTIFICATION_ICON_COLORS = {
  indigo: 'bg-accent-indigo-bold',
  green: 'bg-accent-green',
  red: 'bg-accent-red-bold',
  yellow: 'bg-accent-yellow',
  pink: 'bg-accent-pink',
} as const;

export type NotificationIconColorType = keyof typeof NOTIFICATION_ICON_COLORS;

type NotificationIconProps = {
  color: NotificationIconColorType;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const NotificationIcon = ({ color = 'indigo', icon: Icon }: NotificationIconProps) => {
  return (
    <figure
      className={cn(
        'h-5 w-5 rounded-full flex flex-row items-center justify-center',
        NOTIFICATION_ICON_COLORS[color]
      )}
    >
      <Icon className="h-3 w-3 text-accent-pink-inverse" />
    </figure>
  );
};
