import React, { forwardRef, useMemo } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { DocumentKindSchema } from '@shape-construction/api/model';
import { InputAdornment, Select, type SelectRootProps } from '@shape-construction/arch-ui';

export type FormatSelectProps = SelectRootProps<DocumentKindSchema, false>;

export const FormatSelect = forwardRef<React.ElementRef<typeof Select.Root>, FormatSelectProps>(
  ({ value, ...props }, ref) => {
    const messageGetter = useMessageGetter('projectGallery.filters.format');
    const options = useMemo(
      () => [
        { name: messageGetter('all'), value: '' },
        { name: messageGetter('images'), value: DocumentKindSchema.image },
        { name: messageGetter('files'), value: DocumentKindSchema.file },
      ],
      [messageGetter]
    );
    const selected = useMemo(
      () => options.find((option) => option.value === value) || options[0],
      [value, options]
    );

    return (
      <Select.Root ref={ref} value={selected.value} {...props}>
        <Select.Trigger
          size="sm"
          startAdornment={<InputAdornment>{messageGetter('label')}</InputAdornment>}
        >
          <Select.Value value={selected.name} />
        </Select.Trigger>
        <Select.Panel>
          <Select.Options>
            {options.map((option) => (
              <Select.Option key={option.name} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select.Options>
        </Select.Panel>
      </Select.Root>
    );
  }
);
FormatSelect.displayName = 'FormatSelect';
