import * as React from 'react';
import type { SVGProps } from 'react';
const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/camera">
      <g id="Icon">
        <path
          d="M12 16.8C13.9882 16.8 15.6 15.1882 15.6 13.2C15.6 11.2118 13.9882 9.60001 12 9.60001C10.0118 9.60001 8.40001 11.2118 8.40001 13.2C8.40001 15.1882 10.0118 16.8 12 16.8Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.20001 9.60001C1.20001 8.27452 2.27453 7.20001 3.60001 7.20001H4.71557C5.51802 7.20001 6.26737 6.79896 6.71249 6.13129L7.68753 4.66873C8.13265 4.00105 8.88201 3.60001 9.68445 3.60001H14.3156C15.118 3.60001 15.8674 4.00105 16.3125 4.66873L17.2875 6.13129C17.7327 6.79896 18.482 7.20001 19.2845 7.20001H20.4C21.7255 7.20001 22.8 8.27452 22.8 9.60001V18C22.8 19.3255 21.7255 20.4 20.4 20.4H3.60001C2.27453 20.4 1.20001 19.3255 1.20001 18V9.60001ZM17.4 13.2C17.4 16.1823 14.9824 18.6 12 18.6C9.01767 18.6 6.60001 16.1823 6.60001 13.2C6.60001 10.2177 9.01767 7.80001 12 7.80001C14.9824 7.80001 17.4 10.2177 17.4 13.2Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default CameraIcon;
