import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-down">
      <path
        id="Icon"
        d="M11.3636 16.2864C11.7151 16.6379 12.2849 16.6379 12.6364 16.2864L19.1364 9.7864C19.4878 9.43492 19.4878 8.86508 19.1364 8.5136C18.7849 8.16213 18.2151 8.16213 17.8636 8.5136L12 14.3772L6.13637 8.5136C5.7849 8.16213 5.21505 8.16213 4.86358 8.5136C4.51211 8.86508 4.51211 9.43492 4.86358 9.7864L11.3636 16.2864Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChevronDownIcon;
