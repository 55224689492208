import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { type DocumentKindSchema, ProjectSchema } from '@shape-construction/api/model';
import { FileUpload, Menu } from '@shape-construction/arch-ui';
import { ShapeProjectGalleryIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { MenuHeading } from '@shape-construction/arch-ui/src/Menu/Menu';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useProject } from '../../queries/projects/projects';
import { isFileImageType } from '../Gallery/utils/UtilsGallery';
import { MediaPicker, MediaPickerOptions } from '../MediaPicker/MediaPicker';

export type GallerySectionProps = {
  projectId: ProjectSchema['id'];
  onUpload: (file: File, type: DocumentKindSchema) => void;
};

export const GallerySection = ({ projectId, onUpload }: GallerySectionProps) => {
  const messages = useMessageGetter('quickNewButton');
  const { data: project } = useProject(projectId);
  const canUploadDocument = project?.availableActions?.uploadDocument;
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const options: MediaPickerOptions = {
    gallery: {
      accept: 'image/*',
      enabled: true,
      onSelectFiles: ([file]) => onUpload(file, 'image'),
    },
    camera: {
      enabled: true,
      onSelectFiles: ([file]) => onUpload(file, 'image'),
    },
    documents: {
      accept: '*',
      enabled: true,
      onSelectFiles: ([file]) => onUpload(file, 'file'),
    },
  };

  const renderUploadItem = () => (
    <FileUpload.Root
      onChange={([file]) => onUpload(file, isFileImageType(file) ? 'image' : 'file')}
    >
      <FileUpload.Trigger asChild>
        <div className="flex flex-row gap-3">
          <div className="justify-start items-start gap-2.5 flex">
            <ShapeProjectGalleryIcon className="opacity-50 w-5 h-5" />
          </div>
          <div className="flex flex-col gap-1 justify-start items-start">
            <div className="text-sm font-medium leading-tight">
              {messages('gallery.options.upload.title')}
            </div>
          </div>
        </div>
      </FileUpload.Trigger>
    </FileUpload.Root>
  );

  const renderMobileUploadItem = () => (
    <MediaPicker options={options}>
      <div className="flex flex-row gap-3">
        <div className="justify-start items-start gap-2.5 flex">
          <ShapeProjectGalleryIcon className="opacity-50 w-5 h-5" />
        </div>
        <div className="flex flex-col gap-1 justify-start items-start">
          <div className="text-sm font-medium leading-tight">
            {messages('gallery.options.upload.title')}
          </div>
        </div>
      </div>
    </MediaPicker>
  );

  if (!project || !canUploadDocument) return null;

  return (
    <>
      <MenuHeading>{messages('gallery.heading')}</MenuHeading>
      <Menu.Item aria-label={messages('gallery.options.upload.title')}>
        {isLargeScreen ? renderUploadItem() : renderMobileUploadItem()}
      </Menu.Item>
    </>
  );
};
