import { useMemo } from 'react';
import { useMessage } from '@messageformat/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getGetApiConstructionRolesQueryOptions } from '@shape-construction/api/api';
import {
  ConstructionRoleNames,
  ConstructionRoleOptions,
  ConstructionRoleOptionsWithPlaceholder,
  ConstructionRoles,
  PlaceholderOption,
} from '../types';
import { transformConstructionRoles } from '../utils';

type UseConstructionRolesOptions = {
  includePlaceholderOption?: boolean;
};

export const useConstructionRoles = ({
  includePlaceholderOption = true,
}: UseConstructionRolesOptions = {}) => {
  const placeholder = useMessage('placeholders.constructionRoles');
  const { data, isLoading } = useSuspenseQuery(getGetApiConstructionRolesQueryOptions());

  // this method returns all construction roles for general display, e.g. text labels
  const constructionRoles: ConstructionRoles = useMemo(
    () => transformConstructionRoles(data),
    [data]
  );

  // this method returns all construction role names (keys) for indexation
  const constructionRoleNames = useMemo(
    () => Object.keys(constructionRoles) as ConstructionRoleNames,
    [constructionRoles]
  );

  // this method returns active construction roles as options for input e.g. Select
  const constructionRoleOptions: ConstructionRoleOptionsWithPlaceholder = useMemo(() => {
    const roles = Array.isArray(data) ? data.filter(({ active }) => !!active) : [];
    const options = Object.values(transformConstructionRoles(roles)) as ConstructionRoleOptions;

    return includePlaceholderOption
      ? [{ label: placeholder, value: '' } as PlaceholderOption, ...options]
      : options;
  }, [data, includePlaceholderOption, placeholder]);

  return {
    constructionRoleNames,
    constructionRoleOptions,
    constructionRoles,
    isLoading,
  };
};
