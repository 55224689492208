import * as React from 'react';
import type { SVGProps } from 'react';
const PdfIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/pdf">
      <g id="Vector" filter="url(#filter0_dd_3816_37868)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="#DC2626"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="url(#paint0_radial_3816_37868)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6296 13.8889C17.478 13.8889 16.5309 14.836 16.5309 15.9877C16.5309 17.4194 17.3263 19.1967 18.164 20.8673C17.5092 22.9169 16.7662 25.1134 15.816 26.9669C15.4718 27.1019 15.1342 27.2308 14.8059 27.3562C13.2788 27.9393 11.952 28.446 11.0938 29.1378C11.0775 29.1544 11.0622 29.1719 11.0479 29.1902C10.6668 29.5799 10.4445 30.1105 10.4445 30.679C10.4445 31.8307 11.3916 32.7778 12.5432 32.7778C13.1048 32.7778 13.6469 32.5682 14.0386 32.1679C14.0522 32.1575 14.0653 32.1465 14.0779 32.1351C14.8456 31.2185 15.7507 29.5557 16.5571 28.0359C18.4157 27.3048 20.3637 26.5608 22.2435 26.1142C23.6157 27.2201 25.6009 27.9506 27.2346 27.9506C28.3862 27.9506 29.3334 27.0035 29.3334 25.8519C29.3334 24.7002 28.3862 23.7531 27.2346 23.7531C25.9244 23.7531 24.0206 24.2208 22.5648 24.7107C21.3849 23.603 20.3008 22.2195 19.5216 20.7493C19.6059 20.4898 19.6922 20.2302 19.7782 19.972L19.7782 19.972C20.2602 18.5238 20.7284 17.1169 20.7284 15.9877C20.7284 14.836 19.7813 13.8889 18.6296 13.8889ZM18.629 15.1487C19.1001 15.1487 19.4685 15.5171 19.4685 15.9882C19.4685 16.6181 19.1311 17.7779 18.7405 19.0248C18.2184 17.8118 17.7895 16.648 17.7895 15.9882C17.7895 15.5171 18.1579 15.1487 18.629 15.1487ZM21.1681 25.0975C20.3612 24.2682 19.6219 23.3338 18.9906 22.3298C18.5516 23.6659 18.0877 25.0229 17.5084 26.3305C18.7079 25.8739 19.9235 25.4378 21.1681 25.0975ZM27.235 25.0124C27.7061 25.0124 28.0745 25.3808 28.0745 25.8519C28.0745 26.323 27.7061 26.6914 27.235 26.6914C26.2892 26.6914 24.945 26.2645 23.8704 25.6683C25.1039 25.3195 26.4498 25.0124 27.235 25.0124ZM13.1211 31.3012C13.5314 30.8064 14.1181 29.8231 14.7083 28.7696C13.4909 29.2568 12.4198 29.7134 11.9274 30.0944C11.7987 30.2362 11.7044 30.4564 11.7044 30.6781C11.7044 31.1492 12.0728 31.5176 12.5439 31.5176C12.7945 31.5176 12.9809 31.4352 13.1211 31.3012Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_37868"
        x={2.66666}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_37868" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3816_37868"
          result="effect2_dropShadow_3816_37868"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3816_37868"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_3816_37868"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default PdfIcon;
