import { useMemo } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useSearchParams } from 'react-router-dom';
import type { DocumentKindSchema, QuerySortOrderParameter } from '@shape-construction/api/model';
import { addQueryParams } from 'app/lib/utils/query-string';
import { type PredefinedOptionKey } from 'app/components/Filters/DateSelect/DateSelectOptions';

export type GalleryFilters = {
  authors?: string[];
  locations?: string[];
  format?: DocumentKindSchema;
  relativeDate?: PredefinedOptionKey;
  date?: string;
  endDate?: string;
  search?: string;
};

export type GallerySort = {
  order?: QuerySortOrderParameter;
};

export const defaultGalleryFilters: GalleryFilters = {
  format: undefined,
  authors: [],
  locations: [],
  date: undefined,
  endDate: undefined,
  relativeDate: undefined,
  search: undefined,
};

export const defaultGallerySort: GallerySort = {
  order: undefined,
};

export const useGallerySearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters: GalleryFilters = useMemo(
    () => ({
      locations: searchParams.getAll('locations'),
      authors: searchParams.getAll('authors'),
      format: (searchParams.get('format') as GalleryFilters['format']) || undefined,
      date: searchParams.get('date') || undefined,
      endDate: searchParams.get('endDate') || undefined,
      relativeDate: (searchParams.get('relativeDate') as GalleryFilters['relativeDate']) ?? undefined,
      search: searchParams.get('search') || undefined,
    }),
    [searchParams],
  );

  const sort: GallerySort = useMemo(
    () => ({
      order: (searchParams.get('order') as GallerySort['order']) || defaultGallerySort.order,
    }),
    [searchParams],
  );

  const updateParams = (newParams: GalleryFilters & GallerySort) => {
    setSearchParams((state) => {
      return new URLSearchParams(addQueryParams(state.toString(), newParams as unknown as Record<string, any>));
    });
  };

  return { filters, sort, updateParams } as const;
};

export const useGalleryFiltersSearchParamsCount = () => {
  const { filters } = useGallerySearchParams();
  return useMemo(() => {
    const authors = filters.authors ? filters.authors.length : 0;
    const locations = filters.locations ? filters.locations.length : 0;
    const date = filters.relativeDate ? 1 : 0;
    const format = filters.format ? 1 : 0;
    const total = authors + locations + date + format;

    return { total, authors, locations, date };
  }, [filters]);
};

const galleryParamsAtom = atom({
  ...defaultGalleryFilters,
  ...defaultGallerySort,
});
export const useGalleryStateParams = () => {
  const [stateParams, setStateParams] = useAtom(galleryParamsAtom);
  const filters: GalleryFilters = useMemo(
    () => ({
      locations: stateParams.locations,
      authors: stateParams.authors,
      format: stateParams.format,
      date: stateParams.date,
      endDate: stateParams.endDate,
      relativeDate: stateParams.relativeDate,
      search: stateParams.search,
    }),
    [stateParams],
  );

  const sort: GallerySort = useMemo(
    () => ({
      order: stateParams.order,
    }),
    [stateParams],
  );

  return {
    filters,
    sort,
    updateParams: setStateParams,
  } as const;
};

export const useGalleryFiltersStateCount = () => {
  const params = useAtomValue(galleryParamsAtom);
  return useMemo(() => {
    const authors = params.authors ? params.authors.length : 0;
    const locations = params.locations ? params.locations.length : 0;
    const date = params.relativeDate ? 1 : 0;
    const format = params.format ? 1 : 0;
    const total = authors + locations + date + format;

    return { total, authors, locations, date };
  }, [params]);
};
