import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { UploadingDocument } from '../utils/uploadingDocument';

const uploadingDocumentsAtom = atomFamily((id: string) => atom([] as UploadingDocument[]));

const useUploadingDocuments = (contextId: string) => {
  const [uploadingDocuments, setUploadingDocuments] = useAtom(uploadingDocumentsAtom(contextId));
  const addUploadingDocument = (uploadingDocument: UploadingDocument) => {
    setUploadingDocuments((state) => [uploadingDocument, ...state]);
  };

  const removeUploadingDocument = (uploadingDocumentId: UploadingDocument['id']) => {
    setUploadingDocuments((state) => {
      const updatedUploadingDocuments = state.filter(({ id }) => id !== uploadingDocumentId);

      if (updatedUploadingDocuments.length === 0) uploadingDocumentsAtom.remove(contextId);

      return updatedUploadingDocuments;
    });
  };

  const updateDocumentUploadProgress = (
    uploadingDocumentId: UploadingDocument['id'],
    progress: UploadingDocument['uploadProgress']
  ) => {
    setUploadingDocuments((state) => {
      return produce(state, (draft) => {
        const uploadingDocumentIndex = draft.findIndex(({ id }) => id === uploadingDocumentId);

        if (uploadingDocumentIndex !== -1) draft[uploadingDocumentIndex].uploadProgress = progress;

        return draft;
      });
    });
  };

  return {
    uploadingDocuments,
    addUploadingDocument,
    removeUploadingDocument,
    updateDocumentUploadProgress,
  };
};

export default useUploadingDocuments;
