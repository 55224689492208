export type TrackerClasses = 'marketing' | 'necessary' | 'preferences' | 'statistics';
export type TrackerNames = 'gtm' | 'hotjar' | 'mixpanel' | 'sentry' | 'useberry';

export type TrackerTypes = {
  [key in TrackerNames]: TrackerClasses;
};

export const trackerTypes: TrackerTypes = {
  gtm: 'marketing',
  hotjar: 'statistics',
  sentry: 'statistics',
  mixpanel: 'statistics',
  useberry: 'statistics',
};
