import * as React from 'react';
import type { SVGProps } from 'react';
const ShapeChannelsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/shape-channels">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9325 20.1128H11.9358C16.4875 20.1128 20.1921 16.426 20.194 11.8944C20.1948 9.69846 19.3367 7.6336 17.7775 6.08017C16.2183 4.5268 14.1449 3.67081 11.9391 3.66996C7.38396 3.66996 3.67934 7.35647 3.67752 11.8878C3.6769 13.4406 4.11349 14.953 4.94013 16.2615L4.96703 16.3041C5.07503 16.4751 5.106 16.6833 5.05242 16.8781L4.63352 18.4009C4.4863 18.936 4.9763 19.4291 5.51544 19.2884L7.13496 18.8656C7.32231 18.8166 7.52154 18.8445 7.6881 18.9429L7.72935 18.9673C8.99725 19.7161 10.4507 20.1122 11.9325 20.1128ZM11.9358 2C14.5936 2.00105 17.0883 3.03082 18.9642 4.8998C20.8401 6.76874 21.8725 9.25302 21.8715 11.895C21.8693 17.3469 17.4117 21.7828 11.9358 21.7828H11.9318C10.3653 21.7821 8.82455 21.4137 7.43801 20.7125C7.27347 20.6293 7.08434 20.6052 6.90582 20.6518L4.34303 21.3208C3.26475 21.6023 2.28478 20.6161 2.57922 19.5458L3.2464 17.1205C3.29748 16.9349 3.27068 16.7372 3.17916 16.5676C2.40527 15.1336 1.99933 13.5288 2 11.8871C2.0022 6.43536 6.45935 2 11.9358 2ZM8.93213 10.3178C9.84359 8.7264 10.2993 7.93069 10.8569 7.60215C11.6164 7.15466 12.5563 7.15466 13.3158 7.60215C13.8734 7.93069 14.3291 8.7264 15.2406 10.3178C16.152 11.9092 16.6078 12.7049 16.6112 13.356C16.6159 14.2428 16.146 15.0633 15.3818 15.5026C14.8207 15.8251 13.9093 15.8251 12.0864 15.8251C10.2634 15.8251 9.35198 15.8251 8.79093 15.5026C8.02673 15.0633 7.55682 14.2428 7.5615 13.356C7.56495 12.7049 8.02068 11.9092 8.93213 10.3178ZM10.7166 11.1924C11.3335 10.1152 11.642 9.57664 12.1047 9.57664C12.5674 9.57664 12.8759 10.1152 13.4928 11.1924L13.6086 11.3945C14.2255 12.4717 14.534 13.0103 14.3027 13.4143C14.0713 13.8182 13.4544 13.8182 12.2205 13.8182H11.9889C10.755 13.8182 10.1381 13.8182 9.90674 13.4143C9.67538 13.0103 9.98386 12.4717 10.6008 11.3945L10.7166 11.1924Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ShapeChannelsIcon;
