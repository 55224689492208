import * as React from 'react';
import type { SVGProps } from 'react';
const XMarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="outline/x-mark">
      <path
        id="Icon"
        d="M6.5 17.5L12 12M12 12L17.5 6.5M12 12L6.5 6.5M12 12L17.5 17.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default XMarkIcon;
