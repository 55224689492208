import { createJSONStorage } from 'jotai/utils';
import localforage from 'localforage';
import { environment } from 'app/config/environment';

const jsonDB = localforage.createInstance({
  name: environment.JSON_STORAGE_KEY,
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
});

export const makeJSONStorage = <T>() => {
  const store = createJSONStorage<T>(() => ({
    async getItem(key) {
      try {
        return await jsonDB.getItem<string>(key);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return null;
      }
    },
    async setItem(key, value) {
      await jsonDB.setItem(key, value);
    },
    async removeItem(key) {
      await jsonDB.removeItem(key);
    },
  }));

  store.delayInit = true;

  return store;
};

export const jsonStorage = makeJSONStorage();
