import React, { useState, useRef } from 'react';
import { useMessage } from '@messageformat/react';
import { PopoverMenu } from '@shape-construction/arch-ui';
import { Avatar } from '@shape-construction/arch-ui/src/Avatar';
import { ChevronDownIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { showSuccessToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { useClickAway } from '@shape-construction/hooks';
import { UserProfile } from 'app/components/UI/Navbar/components/UserProfile';
import { getAppRevision } from 'app/components/Utils/app';
import { useCreateFeedback } from 'app/queries/feedback/feedback';
import { useCurrentUser } from 'app/queries/users/users';
import { FeedbackForm, FeedbackFormValues } from './FeedbackForm';

export const ProfileMenu: React.FC = () => {
  const feedbackSuccessMessage = useMessage('feedbackForm.successMessage');
  const { mutate: createFeedback } = useCreateFeedback();
  const menuRef = useRef<HTMLDivElement>(null);
  const { avatarUrl, name } = useCurrentUser();
  const appRevision = getAppRevision();
  const appVersion = process.env.REACT_APP_VERSION;

  const [open, setOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const closePopover = () => setOpen(false);
  const toggleOpenPopover = () => setOpen(!open);

  useClickAway(menuRef, closePopover, ['click']);

  const closeFeedback = () => {
    setFeedbackDialogOpen(false);
    closePopover();
  };

  const submitFeedback = async ({ likeText, improvementText }: FeedbackFormValues) => {
    createFeedback(
      {
        data: {
          like: likeText,
          app_revision: appRevision,
          app_version: appVersion,
          improvement: improvementText,
        },
      },
      {
        onSuccess: () => {
          showSuccessToast({ message: feedbackSuccessMessage });
          closeFeedback();
        },
      }
    );
  };

  return (
    <>
      <PopoverMenu
        placement="bottom-end"
        content={
          <div ref={menuRef} id="menuRef" className="p-3">
            <UserProfile
              onSelectMenuItem={closePopover}
              setFeedbackDialogOpen={setFeedbackDialogOpen}
              appVersion={appVersion}
            />
          </div>
        }
        onClickButton={toggleOpenPopover}
        isStatic
        open={open}
      >
        <div
          data-cy="res-my-profile-link"
          role="presentation"
          className="inline-flex items-center gap-x-3 rounded-full py-2 pl-2"
        >
          <Avatar text={name} imgURL={avatarUrl || ''} size="sm" />
          <span className="hidden items-center gap-x-1 text-sm font-medium leading-5 md:flex">
            {name} <ChevronDownIcon className="h-4 w-4 text-gray-400" />
          </span>
        </div>
      </PopoverMenu>
      {feedbackDialogOpen && <FeedbackForm onClose={closeFeedback} onSubmit={submitFeedback} />}
    </>
  );
};
