import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowPathIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-path">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3749 13.7083C17.4315 17.2292 13.8124 19.3186 10.2916 18.3752C9.12874 18.0636 8.12429 17.4616 7.33262 16.6685L6.95878 16.2947L9.87865 16.2941C10.3757 16.2941 10.7786 15.891 10.7785 15.394C10.7784 14.8969 10.3754 14.4941 9.87833 14.4941L4.78621 14.4951C4.54751 14.4951 4.31861 14.59 4.14985 14.7588C3.9811 14.9276 3.88632 15.1565 3.88637 15.3952L3.88744 20.4854C3.88755 20.9825 4.29058 21.3854 4.78763 21.3853C5.28469 21.3851 5.68755 20.9821 5.68744 20.4851L5.68683 17.5683L6.05865 17.9401C7.0679 18.9512 8.34911 19.7182 9.82569 20.1139C14.3068 21.3146 18.9128 18.6553 20.1135 14.1742C20.2422 13.694 19.9573 13.2005 19.4772 13.0719C18.997 12.9432 18.5035 13.2282 18.3749 13.7083ZM19.8496 9.24143C20.0184 9.07261 20.1132 8.84366 20.1131 8.60494L20.1123 3.5146C20.1122 3.01754 19.7092 2.61467 19.2122 2.61475C18.7151 2.61483 18.3122 3.01784 18.3123 3.51489L18.3128 6.43194L17.941 6.06016C16.9318 5.04926 15.6503 4.28192 14.1739 3.88631C9.69274 2.6856 5.0867 5.34489 3.88599 9.826C3.75735 10.3061 4.04227 10.7996 4.52239 10.9283C5.00251 11.0569 5.49601 10.772 5.62466 10.2919C6.56808 6.771 10.1871 4.68156 13.708 5.62497C14.8708 5.93656 15.8753 6.53857 16.667 7.33175L17.0403 7.70508L14.122 7.70508C13.6249 7.70508 13.222 8.10803 13.222 8.60508C13.222 9.10214 13.6249 9.50508 14.122 9.50508H19.2131C19.4519 9.50508 19.6808 9.41024 19.8496 9.24143Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ArrowPathIcon;
