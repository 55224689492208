import { useSyncExternalStore } from 'react';

function subscribe(callback: () => void) {
  window.addEventListener('CookiebotOnConsentReady', callback);
  return () => {
    window.removeEventListener('CookiebotOnConsentReady', callback);
  };
}

export const useCookieConsent = () => {
  const consentData = useSyncExternalStore(subscribe, () => window?.CookieConsent?.consent || null);
  return { consentData };
};
