import React, { PropsWithoutRef } from 'react';
import { SearchField, type SearchFieldProps } from '../SearchField/SearchField';

// https://github.com/tailwindlabs/headlessui/discussions/820#discussioncomment-5878478
const allowedHeadlessUIKeys = [
  'ArrowUp',
  'ArrowDown',
  'Enter',
  ' ', // Space Key
  'Home',
  'End',
  'Escape',
];

export type SelectSearchProps = PropsWithoutRef<SearchFieldProps>;
export const SelectSearch = React.forwardRef<HTMLInputElement, SelectSearchProps>((props, ref) => {
  return (
    <SearchField
      ref={ref}
      onKeyDown={(e) => {
        if (!allowedHeadlessUIKeys.includes(e.key)) e.stopPropagation();
      }}
      autoComplete="off"
      {...props}
    />
  );
});
SelectSearch.displayName = 'Select.Search';
