import React from 'react';
import { useMessage } from '@messageformat/react';
import { Alert } from '@shape-construction/arch-ui';
import { SignalSlashIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { useNetworkState } from '@shape-construction/hooks';

export type NetworkStatusNotificationProps = {};

export const NetworkStatusNotification: React.FC<NetworkStatusNotificationProps> = () => {
  const { online } = useNetworkState();
  const message = useMessage('offline.status');

  if (online) return null;

  return (
    <Alert
      color="danger"
      emphasis="bold"
      rounded={false}
      customIcon={<SignalSlashIcon className="h-4 w-4" />}
    >
      {message}
    </Alert>
  );
};
