import { Query, QueryClient, QueryClientConfig } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

const CACHE_TIME = 1000 * 60 * 60 * 24; // 24 hours
const STALE_TIME = 5000; // 5 seconds
const PREVENT_RETRY_STATUS_CODES = [400, 401, 403, 404];
const MAX_RETRY_COUNT = 3;

const isNetworkError = (error: AxiosError) => error.request.status === 0;
const hasCachedData = (query: Query) => query.state.data !== undefined;
const isRetryableErrorStatus = (status: number) => !PREVENT_RETRY_STATUS_CODES.includes(status);

export const defaultQueryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      gcTime: CACHE_TIME,
      staleTime: STALE_TIME,
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error) && failureCount < MAX_RETRY_COUNT) {
          if (error.response) return isRetryableErrorStatus(error.response.status);

          return isNetworkError(error);
        }

        return false;
      },
      throwOnError: (error, query) => {
        if (axios.isAxiosError(error)) {
          if (isNetworkError(error) && hasCachedData(query)) return false;
        }
        /**
         * We need to rely on options.suspense because react-query does not care about it if useErrorBoundary is defined.
         * However, we only want to affect queries performed with suspense: true; otherwise, we want to fallback on
         * library behaviour.
         */
        // @ts-expect-error Property 'suspense' does not exist on type 'QueryOptions<unknown, unknown, unknown, QueryKey>'.ts(2339)
        return Boolean(query.options.suspense);
      },
    },
  },
};

export const buildQueryClient = (config: QueryClientConfig = defaultQueryClientConfig) =>
  new QueryClient(config);

const queryClient = buildQueryClient();

export default queryClient;
