import { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';

const isMatching = (breakpoint: string) => window.matchMedia(breakpoint).matches;

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(isMatching(query));

  useEffect(() => {
    const listener = () => {
      const newMatches = isMatching(query);
      // Used isEqual to avoid setState when the media query results didnt changed
      if (!isEqual(matches, newMatches)) setMatches(newMatches);
    };

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};
