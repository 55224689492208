const projectIdReplacer = (url: any, newProjectId: any) => {
  const projectRegExp = /\/projects\/([0-9a-zA-Z-]*)([/?]|$)/;
  const [, matchProjectId] = url.match(projectRegExp) ?? [];

  if (!matchProjectId) return url;

  return url.replace(`/projects/${matchProjectId}`, `/projects/${newProjectId}`);
};

export default projectIdReplacer;
