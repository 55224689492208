import { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import localForage from 'localforage';
import superjson, { type SuperJSONResult } from 'superjson';

// By default superjson bypasses the Functions. We are cleaning them to prevent function serialisation errors
superjson.registerCustom<Function, undefined>(
  {
    isApplicable: (v): v is Function => v instanceof Function,
    serialize: () => undefined,
    deserialize: () => () => undefined,
  },
  'persistFunction'
);

export function createPersister(tableName: string = 'react-query', key: string = 'persist') {
  const db = localForage.createInstance({ name: tableName, driver: localForage.INDEXEDDB });

  return {
    persistClient: async (client: PersistedClient) => {
      await db.setItem(key, superjson.serialize(client));
    },
    restoreClient: async () => {
      const client = await db.getItem<SuperJSONResult>(key);
      return client ? superjson.deserialize<PersistedClient>(client) : undefined;
    },
    removeClient: async () => {
      await db.removeItem(key);
    },
  } as Persister;
}
