import React from 'react';
import { InfiniteLoadWaypoints } from 'app/components/InfiniteLoadWaypoints/InfiniteLoadWaypoints';
import { GallerySkeleton } from '../GalleryGrid/GalleryGrid';
import { GalleryDocument } from '../../hooks/useDocumentsGallery';
import { parseDateWithFormat } from '@shape-construction/utils/DateTime';

type GalleryGridItemWrapperProps = {
  document: GalleryDocument;
  index: number;
  documents: GalleryDocument[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  skeletonLength: number;
  showUploadDate?: boolean;
  children: React.ReactNode;
};

export const GalleryGridItemWrapper: React.FC<GalleryGridItemWrapperProps> = ({
  document,
  index,
  documents,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  skeletonLength,
  showUploadDate = true,
  children,
}) => {
  if (!document || !documents || documents.length === 0) return null;

  const isFirstEvent = index === 0;
  const isLastEvent = index === documents.length - 1;

  if (isFetchingNextPage && isLastEvent) {
    return <GallerySkeleton key={document.id} className="contents" length={skeletonLength} />;
  }

  const previousCreatedAtDate = index > 0 ? documents[index - 1]?.createdAt : null;
  const parsedPreviousCreatedAtDate = previousCreatedAtDate
    ? parseDateWithFormat(previousCreatedAtDate, 'DD-MMM-YYYY')
    : null;
  const parsedCreatedAtDate = parseDateWithFormat(document.createdAt, 'DD-MMM-YYYY');
  const shouldDisplayUploadDate = showUploadDate && parsedCreatedAtDate !== parsedPreviousCreatedAtDate;

  return (
    <InfiniteLoadWaypoints
      key={document.id}
      hasNextPage={hasNextPage}
      fetchNext={fetchNextPage}
      isFirst={isFirstEvent}
      isLast={isLastEvent}
    >
      <div className="flex flex-col justify-end gap-3 mb-2.5">
        {shouldDisplayUploadDate && (
          <span className="text-xs leading-4 font-semibold text-neutral-bold">{parsedCreatedAtDate}</span>
        )}
        {children}
      </div>
    </InfiniteLoadWaypoints>
  );
};
