import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  defaultTypeOptions,
  TypeOptions,
} from '../../../pages/projects/[projectId]/timeline/constants/TimelineFilterOptions';
import type { RootState } from '../../index';

type State = {
  current: TypeOptions;
};

export const initialState: State = {
  current: defaultTypeOptions,
};

const timelineTypesFilterSlice = createSlice({
  name: 'timeline-types-filter',
  initialState,
  reducers: {
    updateTypesFilter(state, action: PayloadAction<TypeOptions>) {
      state.current = action.payload;
      return state;
    },
  },
});

export const { updateTypesFilter } = timelineTypesFilterSlice.actions;
export default timelineTypesFilterSlice.reducer;

/**
 * Selectors
 */
export const getCurrentTypesFilter = ({ timelineTypesFilter }: RootState) =>
  timelineTypesFilter.current;
