import { sentenceCase } from 'change-case';
import { ISSUE_STATES } from '../../constants/IssueStates';

export const stateObjectFromState = (currentState: any) => {
  switch (currentState) {
    case ISSUE_STATES.DRAFT:
      return {
        displayedState: 'Draft',
        actionDescription: null,
      };
    case ISSUE_STATES.ASSIGNMENT_REJECTED:
      return {
        displayedState: 'Unassigned',
        actionDescription: 'Assign a responsible person',
      };
    case ISSUE_STATES.ASSIGNED:
      return {
        displayedState: 'Not started',
        actionDescription: 'Rectify issue',
      };
    case ISSUE_STATES.ASSIGNMENT_REQUESTED:
      return {
        displayedState: 'Pending Responsible',
        actionDescription: 'Pending acceptance',
      };
    case ISSUE_STATES.IN_PROGRESS:
      return {
        displayedState: 'Started',
        actionDescription: 'Rectify issue',
      };
    case ISSUE_STATES.COMPLETED:
      return {
        displayedState: 'Pending Approval',
        actionDescription: 'Pending Approval',
      };
    case ISSUE_STATES.RESOLVED:
      return {
        displayedState: 'Resolved',
        actionDescription: 'No current action',
      };
    default:
      return {
        displayedState: 'ERROR',
        actionDescription: 'ERROR',
      };
  }
};

const issueStateImpact = (impact: any) => sentenceCase(impact);

export function issueStateInformation(issue: any) {
  const issueStateObject = stateObjectFromState(issue.currentState);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'impact' does not exist on type '{ ... Remove this comment to see the full error message
  issueStateObject.impact = issueStateImpact(issue.impact);

  return issueStateObject;
}
