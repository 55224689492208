import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { ShapeAnalytics } from 'app/analytics/shape-analytics';
import { environment } from '../config/environment';
import { useCookieConsent } from './hooks/useCookieConsent';
import { useHotjar } from './hooks/useHotjar';
import { useUseberry } from './hooks/useUseberry';
import { trackerTypes } from './trackingConfig';

export const useAnalytics = () => {
  const analyticsId = environment.GTM_ID;
  const { consentData } = useCookieConsent();
  const [cookies] = useCookies(['tracking-preferences']);
  const trackingCookie = cookies['tracking-preferences'];

  useHotjar();
  useUseberry();

  useEffect(() => {
    // Disable Google Tag Manager when consent is not given.
    // GTM adds third-party cookies such as LinkedIn, Hubspot etc.
    // This possibly could be configured in the GTM panel.
    const shouldInitialize = analyticsId && consentData?.[trackerTypes.gtm];

    if (shouldInitialize) {
      ShapeAnalytics.initialize(analyticsId);
    }
  }, [analyticsId, consentData]);

  useEffect(() => {
    if (ShapeAnalytics.isInitialized) {
      ShapeAnalytics.consent({
        analyticsStorage: consentData?.[trackerTypes.gtm],
      });
    }
  }, [consentData, trackingCookie]);
};
