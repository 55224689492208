import * as React from 'react';
import type { SVGProps } from 'react';
const ExclamationTriangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/exclamation-triangle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1814 2.994C10.9897 1.594 13.0104 1.594 13.8187 2.994L21.3531 16.044C22.1614 17.444 21.151 19.194 19.5345 19.194H4.46562C2.84904 19.194 1.83868 17.444 2.64697 16.044L10.1814 2.994ZM12 6.00001C12.497 6.00001 12.9 6.40295 12.9 6.90001V11.1C12.9 11.5971 12.497 12 12 12C11.5029 12 11.1 11.5971 11.1 11.1V6.90001C11.1 6.40295 11.5029 6.00001 12 6.00001ZM12 16.8C12.6627 16.8 13.2 16.2627 13.2 15.6C13.2 14.9373 12.6627 14.4 12 14.4C11.3372 14.4 10.8 14.9373 10.8 15.6C10.8 16.2627 11.3372 16.8 12 16.8Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ExclamationTriangleIcon;
