import * as React from 'react';
import type { SVGProps } from 'react';
const DocumentTextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/document-text">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40001 2.4C4.40589 2.4 3.60001 3.20589 3.60001 4.2V19.8C3.60001 20.7941 4.40589 21.6 5.40001 21.6H18.6C19.5941 21.6 20.4 20.7941 20.4 19.8V9.14559C20.4 8.6682 20.2104 8.21036 19.8728 7.8728L14.9272 2.92721C14.5896 2.58964 14.1318 2.4 13.6544 2.4H5.40001ZM8.10001 12.6C7.60295 12.6 7.20001 13.0029 7.20001 13.5C7.20001 13.9971 7.60295 14.4 8.10001 14.4H15.9C16.3971 14.4 16.8 13.9971 16.8 13.5C16.8 13.0029 16.3971 12.6 15.9 12.6H8.10001ZM8.10001 16.2C7.60295 16.2 7.20001 16.6029 7.20001 17.1C7.20001 17.5971 7.60295 18 8.10001 18H15.9C16.3971 18 16.8 17.5971 16.8 17.1C16.8 16.6029 16.3971 16.2 15.9 16.2H8.10001Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DocumentTextIcon;
