import * as React from 'react';
import type { SVGProps } from 'react';
const InformationCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/information-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12C21.6 17.302 17.3019 21.6 12 21.6C6.69806 21.6 2.39999 17.302 2.39999 12C2.39999 6.69809 6.69806 2.40002 12 2.40002C17.3019 2.40002 21.6 6.69809 21.6 12ZM13.2 7.20002C13.2 7.86277 12.6627 8.40002 12 8.40002C11.3373 8.40002 10.8 7.86277 10.8 7.20002C10.8 6.53728 11.3373 6.00002 12 6.00002C12.6627 6.00002 13.2 6.53728 13.2 7.20002ZM10.8 10.8C10.3029 10.8 9.89999 11.203 9.89999 11.7C9.89999 12.1971 10.3029 12.6 10.8 12.6H11.1041C11.296 12.6 11.4385 12.7778 11.3969 12.9651L10.8459 15.4445C10.5545 16.7559 11.5525 18 12.8959 18H13.2C13.6971 18 14.1 17.5971 14.1 17.1C14.1 16.603 13.6971 16.2 13.2 16.2H12.8959C12.704 16.2 12.5614 16.0223 12.6031 15.8349L13.154 13.3556C13.4455 12.0441 12.4475 10.8 11.1041 10.8H10.8Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default InformationCircleIcon;
