import * as React from 'react';
import type { SVGProps } from 'react';
const PlayCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/play-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.39999 12C2.39999 6.69809 6.69806 2.40002 12 2.40002C17.3019 2.40002 21.6 6.69809 21.6 12C21.6 17.302 17.3019 21.6 12 21.6C6.69806 21.6 2.39999 17.302 2.39999 12ZM10.0687 8.51008C10.3577 8.35232 10.7097 8.36493 10.9867 8.54297L15.1867 11.243C15.4443 11.4086 15.6 11.6938 15.6 12C15.6 12.3063 15.4443 12.5915 15.1867 12.7571L10.9867 15.4571C10.7097 15.6351 10.3577 15.6477 10.0687 15.49C9.77976 15.3322 9.59999 15.0293 9.59999 14.7V9.30003C9.59999 8.9708 9.77976 8.66785 10.0687 8.51008Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PlayCircleIcon;
