import React, { FC, Suspense, useState } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { HelpCenter } from '@shape-construction/arch-ui';
import { HelpCenterProps } from '@shape-construction/arch-ui/src/HelpCenter/HelpCenter';
import { QuestionMarkCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useStableCallback } from '@shape-construction/hooks';
import { useShowHelpCenter } from 'app/components/HelpCenter/useShowHelpCenter';
import { ProductTipsGeneralPopover } from 'app/components/ProductTour/ProductTipsGeneralPopover';
import { environment } from 'app/config/environment';
import { PageLabel } from 'app/constants/PageLabel';
import {
  useHelpCenterArticles,
  useHelpCenterSections,
  useHelpCenterSection,
} from 'zendesk/queries/zendesk';
import { useEnablePageTour } from '../ProductTour/hooks/useEnablePageTour';
import { useProductTipsPopover } from '../ProductTour/hooks/useProductTipsPopover';
import { useProductTourPopover } from '../ProductTour/hooks/useProductTourPopover';
import { ProductTour } from '../ProductTour/ProductTour';
import { ProductTourPopover } from '../ProductTour/ProductTourPopover/ProductTourPopover';
import { NavbarButton } from '../UI/Navbar/components/NavbarButton';
import { useWidgetLabels } from './useWidgetLabels';

const { ZENDESK_ALLOW_DRAFT_ARTICLES } = environment;

const language = 'en-us' as const;
const queryOptions: Partial<UseQueryOptions<any>> = {
  refetchInterval: 0,
  staleTime: Infinity,
  gcTime: Infinity,
  refetchOnWindowFocus: false,
};

type HelpCenterContainerProps = Pick<HelpCenterProps, 'open' | 'onClose'> & {
  labels: PageLabel[];
};

const HelpCenterContainer: FC<HelpCenterContainerProps> = ({ labels, ...props }) => {
  const { areAllTipsDismissed, enablePageTour } = useEnablePageTour(labels[0]);

  const onSeeTips = useStableCallback(() => {
    enablePageTour();
    props.onClose();
  });

  const [selectedArticle, setSelectedArticle] = useState<HelpCenterProps['selectedArticle']>();
  const { data: articles, isLoading: isLoadingArticles } = useHelpCenterArticles(
    language,
    { label_names: labels },
    {
      ...queryOptions,
      enabled: props.open,
      select: (data) => {
        if (ZENDESK_ALLOW_DRAFT_ARTICLES) return data;

        return {
          ...data,
          articles: data.articles.filter((article) => article.draft === false),
        };
      },
    }
  );

  const { data: sections, isLoading: isLoadingSections } = useHelpCenterSections(
    language,
    undefined,
    {
      ...queryOptions,
      enabled: props.open,
      select: (data) => ({
        ...data,
        sections: data.sections.slice(0, 3),
      }),
    }
  );

  const articleSectionId = articles?.articles?.[0]?.section_id;
  const { data: selectedSection, isLoading: isLoadingSelectedSection } = useHelpCenterSection(
    language,
    articleSectionId!,
    {
      ...queryOptions,
      enabled: !!articleSectionId,
    }
  );

  const filteredSections = sections?.sections.filter(
    ({ id }) => articles?.articles?.[0]?.section_id !== id
  );

  const isLoading = isLoadingArticles || isLoadingSections || isLoadingSelectedSection;

  return (
    <HelpCenter
      articles={articles?.articles ?? []}
      sections={filteredSections ?? []}
      helpCenterEmail={environment.SUPPORT_EMAIL}
      helpCenterUrl={environment.ZENDESK_HELPCENTER_URL}
      selectedArticle={selectedArticle}
      selectedSection={selectedSection?.section}
      onSelectArticle={setSelectedArticle}
      isLoading={isLoading}
      showProductTipsAlert={areAllTipsDismissed}
      seeTips={onSeeTips}
      productTour={<ProductTour isAlertAlwaysVisible />}
      {...props}
    />
  );
};

export const HelpCenterWidget = () => {
  const { isHelpCenterVisible, showHelpCenter, hideHelpCenter } = useShowHelpCenter();
  const labels = useWidgetLabels();
  const { isProductTipsPopoverOpen } = useProductTipsPopover();
  const { isProductTourPopoverOpen } = useProductTourPopover();

  return (
    <>
      <div className="flex flex-row">
        <NavbarButton aria-label="help center" onClick={showHelpCenter} className="mr-2 md:mr-4">
          <QuestionMarkCircleIcon className="h-full" />
        </NavbarButton>
        {isProductTipsPopoverOpen && <ProductTipsGeneralPopover />}
        {isProductTourPopoverOpen && <ProductTourPopover />}
      </div>
      <Suspense fallback={null}>
        <HelpCenterContainer open={isHelpCenterVisible} onClose={hideHelpCenter} labels={labels} />
      </Suspense>
    </>
  );
};
