import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowUpCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-up-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40002 12C2.40002 6.69809 6.69809 2.40002 12 2.40002C17.302 2.40002 21.6 6.69809 21.6 12C21.6 17.302 17.302 21.6 12 21.6C6.69809 21.6 2.40002 17.302 2.40002 12ZM11.1 16.5001C11.1 16.9971 11.503 17.4001 12 17.4001C12.4971 17.4001 12.9 16.9971 12.9 16.5001V9.67282L15.6137 12.3865C15.9651 12.7379 16.535 12.7379 16.8865 12.3865C17.2379 12.035 17.2379 11.4651 16.8865 11.1137L12.6364 6.86363C12.4676 6.69484 12.2387 6.60002 12 6.60002C11.7613 6.60002 11.5324 6.69485 11.3636 6.86363L7.11367 11.1137C6.7622 11.4652 6.76221 12.035 7.11368 12.3865C7.46516 12.7379 8.03501 12.7379 8.38647 12.3865L11.1 9.67285L11.1 16.5001Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ArrowUpCircleIcon;
