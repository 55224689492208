import { DocumentKindSchema, DocumentSchema } from '@shape-construction/api/model';
import { getFileExtension } from './UtilsGallery';

export type UploadingDocument = DocumentSchema & {
  isUploading?: boolean;
  uploadProgress?: number;
};

type FileToUploadingDocumentAttributes = {
  temporaryDocumentId: string;
};

export const fileToUploadingDocument = (
  file: File,
  { temporaryDocumentId }: FileToUploadingDocumentAttributes
): UploadingDocument => ({
  availableActions: { edit: false, delete: false },
  byteSize: file.size,
  caption: null,
  contentType: '',
  createdAt: new Date(file.lastModified).toISOString(),
  downloadUrl: '',
  exifMetadata: null,
  extension: getFileExtension(file),
  filename: file.name,
  id: temporaryDocumentId,
  imageUrl: null,
  inlineUrl: '',
  isUploading: true,
  kind: DocumentKindSchema.file,
  locationId: null,
  teamMemberId: 0,
});
