import { PageLabel } from 'app/constants/PageLabel';

export type TipConfig = {
  /** Heading of the product tip (supports messageformat) */
  heading: string;

  /** Body of the product tip (supports messageformat) */
  body?: string;
};

export type ProductTourTips = { [key in PageLabel]?: Record<string, TipConfig> };

/**
 * Add configuration for product tips here.
 *
 * @example
 * ```ts
 * const tips = {
 *   shift_report_edit: {
 *     add_collaborators: {
 *       heading: 'productTour.shiftReportEdit.addCollaborators.heading' },
 *       body: 'productTour.shiftReportEdit.addCollaborators.body' },
 *     },
 *   },
 * } satisfies ProductTourTips;
 * ```
 */
const tips = {
  shift_report_edit: {},
} satisfies ProductTourTips;

export const productTourConfig = { tips: tips as ProductTourTips };

export type TourPage = PageLabel;

export type TourTips<Page extends TourPage> = Page extends keyof typeof tips
  ? keyof (typeof tips)[Page]
  : never;
