import { useCallback } from 'react';
import { useLatest } from 'react-use';

/**
 * Returns a stable callback that will not change references between renders.
 * It's like useCallback, but without the need for a dependency array.
 *
 * Example -
 * ```javascript
 *   const onClick = useStableCallback((e) => {
 *     console.log('clicked', e);
 *   })
 *   return <button onClick={onClick}>Btn</button>
 * ```
 */
export const useStableCallback = <Args extends any[], Ret>(
  callback: (...a: Args) => Ret
): ((...a: Args) => Ret) => {
  const cb = useLatest(callback);
  return useCallback((...args: Args) => cb.current(...args), [cb]);
};
