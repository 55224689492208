import React from 'react';
import classNames from 'clsx';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';

export interface SideDrawerHeadingProps {
  title: string;
  isExpanded: boolean;
}

const useClasses = () => ({
  title: classNames(
    'px-6 pb-3 text-xs leading-4 font-semibold tracking-wider uppercase text-gray-400'
  ),
});

const SideDrawerHeading: React.FC<SideDrawerHeadingProps> = ({
  title = '',
  isExpanded = false,
}) => {
  const desktopView = useMediaQuery(breakpoints.up('md'));
  const classes = useClasses();

  if (isExpanded || !desktopView) {
    return <h6 className={classes.title}>{title}</h6>;
  }

  return (
    <>
      <div className="w-10 m-auto border-b" />
      <div className="h-4 w-10 m-auto" />
    </>
  );
};

export { SideDrawerHeading };
