import React, { useContext } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Button } from '@shape-construction/arch-ui';
import { ArrowUpCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { now, parseDate } from '@shape-construction/utils/DateTime';
import { LayoutContext } from 'app/contexts/Layout.context';
import { useProject } from 'app/queries/projects/projects';
import { useTeamsSubscriptionPlan } from 'app/queries/teamsSubscriptionPlan/teamsSubscriptionPlan';

type SideDrawerUpgradePlanButtonProps = {
  activeTeamSubscritpionPlanSlug: string;
  trialDaysLeft: string;
  currentTeamUrl: string;
};

const SideDrawerUpgradePlanButton: React.FC<SideDrawerUpgradePlanButtonProps> = ({
  activeTeamSubscritpionPlanSlug,
  trialDaysLeft,
  currentTeamUrl,
}) => {
  const navigate = useNavigate();
  const { drawerExpanded, drawerOpen, closeDrawer } = useContext(LayoutContext);
  const showFullVersion = drawerExpanded || drawerOpen;
  const upgradePlanButtonMessages = useMessageGetter(
    `sideDrawer.upgradePlanButton.${activeTeamSubscritpionPlanSlug}`
  );

  const redirectToSubscritionPage = () => {
    closeDrawer();
    navigate(currentTeamUrl);
  };

  return (
    <>
      {showFullVersion ? (
        <button
          className="px-4"
          type="button"
          aria-label={upgradePlanButtonMessages(`title`)}
          onClick={redirectToSubscritionPage}
        >
          <div className="bg-indigo-500 border-indigo-600 border rounded-md p-3 flex gap-x-3">
            <div>
              <ArrowUpCircleIcon className="text-white" width={24} />
            </div>
            <div className="flex flex-col gap-y-1">
              <div className="text-start text-sm leading-5 text-white font-medium">
                {upgradePlanButtonMessages(`title`)}
              </div>
              <div className="text-start text-xs leading-4 font-medium text-indigo-50">
                {upgradePlanButtonMessages('description', { trialDays: trialDaysLeft })}
              </div>
            </div>
          </div>
        </button>
      ) : (
        <div className="flex justify-center">
          <Button
            aria-label={upgradePlanButtonMessages(`title`)}
            size="md"
            color="primary"
            variant="contained"
            leadingIcon={ArrowUpCircleIcon}
            onClick={redirectToSubscritionPage}
          />
        </div>
      )}
    </>
  );
};

const SideDrawerUpgradePlanButtonContainer: React.FC = () => {
  const pathParams = useMatch('/projects/:projectId');
  const projectId = pathParams?.params.projectId;
  const { data: project } = useProject(projectId!);
  const currentTeamUrl = `/projects/${projectId}/settings/teams/${project?.currentTeamId}/subscription`;
  const { data: teamSubscriptionData } = useTeamsSubscriptionPlan(
    projectId!,
    project?.currentTeamId!
  );
  const trialDaysLeft = parseDate(teamSubscriptionData?.trialEndAt || new Date())
    .diff(now(), 'days')
    .toString();

  const canEditTeamSubscription = teamSubscriptionData?.availableActions.edit || false;
  const activeTeamSubscritpionPlanSlug = teamSubscriptionData?.activePlanSlug;
  const inFreeOrTrialSubscriptionPlan =
    activeTeamSubscritpionPlanSlug === 'free' || activeTeamSubscritpionPlanSlug === 'trial';
  const canShowSideDrawerUpgradePlanButton =
    canEditTeamSubscription && inFreeOrTrialSubscriptionPlan;

  if (!canShowSideDrawerUpgradePlanButton) return null;

  return (
    <SideDrawerUpgradePlanButton
      activeTeamSubscritpionPlanSlug={activeTeamSubscritpionPlanSlug}
      trialDaysLeft={trialDaysLeft}
      currentTeamUrl={currentTeamUrl}
    />
  );
};

export { SideDrawerUpgradePlanButtonContainer as SideDrawerUpgradePlanButton };
