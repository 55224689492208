import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  'spinBoxMax': {
    position: 'fixed',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'spinBoxMin': {
    width: '100%',
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'loadingSpinner': {
    width: '36px',
    height: '36px',
    margin: '10px auto',
    border: `6px solid ${theme.palette.primary.light}`,
    animation: `$loadingSpinner 800ms infinite`,
    borderRadius: '50%',
    borderTopColor: theme.palette.primary.main,
  },
  '@keyframes loadingSpinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
