import { createTheme } from '@material-ui/core';
import { colors } from './colors';

const theme = createTheme({
  zIndex: {
    mobileStepper: 3,
    speedDial: 5,
    appBar: 10,
    drawer: 20,
    modal: 30,
    snackbar: 40,
    tooltip: 50,
  },
  overrides: {
    MuiTouchRipple: {
      child: {
        backgroundColor: 'RGBA(108, 122, 149, 0.36)',
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      main: colors.PRIMARY_MAIN,
      light: colors.PRIMARY_LIGHT,
    },
    secondary: {
      main: colors.SECONDARY_MAIN,
      light: colors.SECONDARY_LIGHT,
    },
    warning: {
      main: colors.WARNING_MAIN,
      light: colors.WARNING_LIGHT,
    },
    success: {
      main: colors.SUCCESS_MAIN,
    },
    info: {
      main: colors.INFO_MAIN,
      light: colors.INFO_LIGHT,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ main: string; light: string; lighter: stri... Remove this comment to see the full error message
      lighter: colors.INFO_LIGHTER,
    },
    text: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; secondary: string; }' is no... Remove this comment to see the full error message
      label: colors.TEXT,
      secondary: colors.TEXT_SECONDARY,
    },
    background: {
      // an equivalent of earlier: fade(theme.palette.primary.light, 0.5)
      // removed because two the semi-transparent background produced
      // unexpected results when overlaying different colours
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ main: string; modal: string; light: string... Remove this comment to see the full error message
      main: colors.BACKGROUND_MAIN,
      light: colors.BACKGROUND_LIGHT,
    },
  },
  typography: {
    fontFamily: "'Inter var', BlinkMacSystemFont, -apple-system, sans-serif",
  },
  boxShadows: ['none', '0 2px 4px 2px rgba(0,0,0,0.05)'],
});

const highlightText = theme.palette.secondary.light;
const highlightBackground = `${theme.palette.primary.main}!important`;

theme.overrides = {
  ...theme.overrides,
  MuiCssBaseline: {
    '@global': {
      // area outside the container
      '#root': {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'TypeBackgr... Remove this comment to see the full error message
        backgroundColor: theme.palette.background.main,
      },
      // highlighting selection on site
      '::-webkit-selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      '::-moz-selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      '::selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      // highlighting selection in input and textarea
      'input::-webkit-selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      'textarea::-webkit-selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      'input::-moz-selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      'textarea::-moz-selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      'input::selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
      'textarea::selection': {
        color: highlightText,
        backgroundColor: highlightBackground,
      },
    },
  },
  MuiDialog: {
    paperFullScreen: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${theme.spacing(35)}px)`,
        marginLeft: 0,
      },
    },
  },
};

export { theme };
