import React from 'react';
import { SideDrawerUpgradePlanButton } from './SideDrawerUpgradePlanButton';

const SideDrawerFooter: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => (
  <div className="flex flex-1 flex-col justify-end gap-y-4 pb-4" role="menu" {...props}>
    <SideDrawerUpgradePlanButton />
  </div>
);

export { SideDrawerFooter };
