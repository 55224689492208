import { ConstructionRoleListSchema } from '@shape-construction/api/model';
import { ConstructionRoleName, ConstructionRoles } from './types';

export const transformConstructionRoles = (roles: ConstructionRoleListSchema) =>
  roles.reduce<ConstructionRoles>((acc, role) => {
    const roleCode = role.value as ConstructionRoleName;
    return {
      ...acc,
      [roleCode]: {
        value: roleCode,
        label: role.label,
      },
    };
  }, {} as ConstructionRoles);
