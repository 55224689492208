import * as React from 'react';
import classNames from 'clsx';
import { SkeletonBase, SkeletonBaseProps } from './SkeletonBase';
import { Size } from './skeleton-config';

export interface SkeletonCardProps extends SkeletonBaseProps {
  size: Size;
}

const sizeClasses: Record<Size, string> = {
  xxs: 'h-14',
  xs: 'h-16',
  sm: 'h-20',
  md: 'h-24',
  lg: 'h-28',
  xl: 'h-32',
  xxl: 'h-36',
};

export const SkeletonCard: React.FC<SkeletonCardProps> = (props) => (
  <SkeletonBase
    {...props}
    className={classNames('bg-gray-200 rounded-md w-full h-', sizeClasses[props.size])}
  />
);
