import { useCallback, useState } from 'react';

export const useModal = (initialOpen: boolean) => {
  const [open, setIsOpen] = useState(initialOpen);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return { open, openModal, closeModal };
};
