import * as React from 'react';
import type { SVGProps } from 'react';
const AtSymbolIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/at-symbol">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.48484 17.5155C3.43875 14.4694 3.43875 9.53068 6.48484 6.48459C9.53092 3.4385 14.4696 3.4385 17.5157 6.48459C19.0389 8.00781 19.8003 10.0024 19.8003 12C19.8003 12.8285 19.1284 13.5 18.3 13.5C17.4716 13.5 16.8 12.8285 16.8 12C16.8 9.34906 14.651 7.20002 12 7.20002C9.34902 7.20002 7.19999 9.34906 7.19999 12C7.19999 14.651 9.34902 16.8 12 16.8C13.7464 16.8 15.275 15.8673 16.1148 14.4729C16.6968 14.9876 17.4619 15.3 18.3 15.3C20.1137 15.3 21.5857 13.8368 21.5999 12.0265C21.6001 12.0177 21.6003 12.0089 21.6003 12C21.6003 9.54456 20.6628 7.08614 18.7885 5.2118C15.0395 1.46277 8.96108 1.46277 5.21204 5.2118C1.46301 8.96083 1.46301 15.0392 5.21204 18.7882C8.96108 22.5373 15.0395 22.5373 18.7885 18.7882C19.14 18.4368 19.14 17.8669 18.7885 17.5155C18.437 17.164 17.8672 17.164 17.5157 17.5155C14.4696 20.5615 9.53092 20.5615 6.48484 17.5155ZM12 9.00002C10.3431 9.00002 8.99999 10.3432 8.99999 12C8.99999 13.6569 10.3431 15 12 15C13.6568 15 15 13.6569 15 12C15 10.3432 13.6568 9.00002 12 9.00002Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default AtSymbolIcon;
