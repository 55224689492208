import React from 'react';
import { useMediaQuery } from '@shape-construction/hooks';
import { breakpoints } from '../utils/breakpoints';
import { ButtonVariant } from '../Button/button-config';
import { Button, ButtonProps } from '../Button/Button';

type PrimaryActionProps = Omit<ButtonProps, 'color' | 'variant' | 'size'> & {
  variant?: ButtonVariant;
};
export const PrimaryAction: React.FC<PrimaryActionProps> = ({
  variant = 'contained',
  ...buttonProps
}) => {
  const buttonSize = useMediaQuery(breakpoints.down('md')) ? 'md' : 'sm';

  return <Button {...buttonProps} size={buttonSize} variant={variant} color="primary" fullWidth />;
};
