import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    'color': theme.palette.common.white,
    'width': '100%',
    'border': `2px solid ${theme.palette.primary.main}`,
    'fontSize': '1.2em',
    'borderRadius': '8px',
    '& input': {
      'margin': '5px 5px 5px 0',
      'paddingTop': '2px',
      'paddingBottom': '2px',
      'paddingLeft': theme.spacing(0.5),
      'borderRadius': '2px',
      'outlineWidth': '1px',
      'outlineStyle': 'solid',
      'outlineColor': '#323A49',
      'outlineOffset': 0,
      'backgroundColor': '#323A49',
      '&:focus': {
        borderRadius: '2px',
        outlineWidth: '1px',
        outlineStyle: 'solid',
        outlineColor: '#374151',
        outlineOffset: 0,
        backgroundColor: '#374151',
      },
    },
    '& input::placeholder': {
      paddingLeft: theme.spacing(0.3),
    },
  },
}));
