export const ISSUE_STATES = {
  DRAFT: 'draft',
  ASSIGNED: 'assigned',
  ASSIGNMENT_REJECTED: 'assignment_rejected',
  ASSIGNMENT_REQUESTED: 'assignment_requested',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  RESOLVED: 'resolved',
};

export const ISSUE_APPROVERS_STATE = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const ISSUE_VISIBILITY_STATE = {
  PROJECT_WIDE: 'project_wide',
  INTERNAL: 'internal',
  SPECIFIC_TEAMS: 'specific_teams',
};

export const ISSUE_STATE_COLORS = {
  [ISSUE_STATES.DRAFT]: 'WHITE',
  [ISSUE_STATES.ASSIGNMENT_REJECTED]: 'PINK',
  [ISSUE_STATES.ASSIGNED]: 'GRAY',
  [ISSUE_STATES.ASSIGNMENT_REQUESTED]: 'YELLOW',
  [ISSUE_STATES.IN_PROGRESS]: 'BLUE',
  [ISSUE_STATES.COMPLETED]: 'TEAL',
  [ISSUE_STATES.RESOLVED]: 'GREEN',
};
