import LogoWithLettering from 'app/assets/shape-logo-with-lettering.svg';
import Logo from 'app/assets/shape-logo.svg';
import React, { ComponentProps } from 'react';

export interface ShapeLogoProps {
  withLettering?: boolean;
  whiteVersion?: boolean;
}

const ShapeLogo: React.FC<ShapeLogoProps & ComponentProps<'svg'>> = ({
  withLettering = false,
  whiteVersion = false,
}) => {
  const props = {
    name: 'Shape logo',
    'data-testid': 'shape-logo',
    className: 'h-full',
    fill: whiteVersion ? 'white' : '#1f2937',
  };

  return withLettering ? <LogoWithLettering {...props} /> : <Logo {...props} />;
};

export default ShapeLogo;
