import React from 'react';
import { twMerge } from 'tailwind-merge';

export const PopoverContentBody: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <p
      className={twMerge('font-normal text-sm max-h-96 overflow-y-auto', className)}
      id="popover-body"
    >
      {children}
    </p>
  );
};
