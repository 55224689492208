import React, { Fragment } from 'react';
import classNames from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Dialog, DialogProps, Transition } from '@headlessui/react';

import { ModalBaseHeader } from './components/ModalBaseHeader';
import { ModalBaseTitle, ModalBaseSubTitle } from './components/ModalBaseTitle';
import { ModalBaseContent } from './components/ModalBaseContent';
import { ModalBaseFooter } from './components/ModalBaseFooter';
import { transitions, Transition as TransitionType } from './config/transitions';

import { ModalBaseOverlay } from './components/ModalBaseOverlay';
import { ModalBaseCloseButton } from './components/ModalBaseCloseButton';

export type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'none';

const maxWithOptions = {
  'xs': 'max-w-xs',
  'sm': 'max-w-sm',
  'md': 'max-w-md',
  'lg': 'max-w-lg',
  'xl': 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  'none': 'max-w-none',
} as { [key in MaxWidth]: string };

const getMaxWidth = (maxWidth: MaxWidth) => maxWithOptions[maxWidth];

type OwnProps = React.PropsWithChildren<{
  /**
   * Root component classname
   */
  className?: string;
  /**
   * If true, will not trigger onClose.
   */
  disableEscapeKey?: boolean;
  /**
   * Called when the Dialog is dismissed (via the overlay or Escape key). Typically used to close the dialog by setting open to false.
   */
  onClose: (value?: boolean) => void;
  /**
   * Set modal fullscreen
   */
  fullScreen?: boolean;
  /**
   * Set modal full width
   */
  fullWidth?: boolean;
  /**
   * Determine the max-width of the dialog.
   */
  maxWidth?: MaxWidth;
  /**
   * Adds an outside pad around the modal. Useful when used, for example, with fullScreen set to true.
   */
  outsidePad?: boolean;
  /**
   * Adds rounded borders. Usually used when not in fullscreen and without outsidePad
   */
  roundBorders?: boolean;
  /**
   * Type of transitions to be applied to the modal. When auto, it will apply slide-bottom when small screen and fade for larger screens
   */
  transition?: TransitionType;
  /**
   * Override z-index of the modal
   */
  zIndex?: number;
}>;

export type ModalBaseProps = Omit<DialogProps<'div'>, 'onClose' | 'unmount'> & OwnProps;

export const ModalBase = ({
  children,
  className,
  disableEscapeKey,
  fullScreen,
  fullWidth,
  maxWidth,
  outsidePad,
  roundBorders,
  zIndex,
  transition = 'none',
  onClose,
  ...modalProps
}: ModalBaseProps) => {
  const hasOverlay = !fullScreen || outsidePad;

  const handleClose: ModalBaseProps['onClose'] = (open) => {
    // When open is false, it means key down has been pressed
    if (open === false && disableEscapeKey) return;

    onClose(open);
  };

  return (
    <Transition as={Fragment} show={modalProps.open}>
      <Dialog
        onClose={handleClose}
        as="div"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        {...modalProps}
      >
        <div style={{ zIndex }} className="fixed inset-0 z-modal flex items-center justify-center">
          {hasOverlay && <ModalBaseOverlay />}
          <Transition.Child
            as="div"
            className={twMerge(
              classNames(
                'flex flex-col max-h-full',
                {
                  'w-full': fullWidth,
                  'h-full w-full': fullScreen,
                },
                maxWidth && getMaxWidth(maxWidth),
                className as string
              )
            )}
            {...transitions[transition]}
          >
            <div
              className={classNames('flex grow transform flex-col overflow-hidden bg-white', {
                'm-8 shadow-xl': outsidePad,
                'rounded-lg': roundBorders,
              })}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

ModalBase.CloseButton = ModalBaseCloseButton;
ModalBase.Header = ModalBaseHeader;
ModalBase.Title = ModalBaseTitle;
ModalBase.SubTitle = ModalBaseSubTitle;
ModalBase.Content = ModalBaseContent;
ModalBase.Footer = ModalBaseFooter;
ModalBase.Overlay = ModalBaseOverlay;
