import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { environment } from 'app/config/environment';
import { ZenDesk } from 'zendesk';
import {
  GetArticleAttachmentsParams,
  GetArticleAttachmentsResponse,
} from 'zendesk/api/help-center/article-attachments';
import { GetArticleLabelsResponse } from 'zendesk/api/help-center/article-labels';
import { GetArticlesParams, GetArticlesResponse } from 'zendesk/api/help-center/articles';
import { GetCategoriesParams, GetCategoriesResponse } from 'zendesk/api/help-center/categories';
import {
  GetSectionResponse,
  GetSectionsParams,
  GetSectionsResponse,
} from 'zendesk/api/help-center/sections';
import { Article, Locale } from 'zendesk/api/help-center/types';
import { queryKeys } from './queryKeys';

const { ZENDESK_API_URL, ZENDESK_OAUTH_TOKEN } = environment;

export const zendesk = new ZenDesk({
  baseURL: ZENDESK_API_URL,
  headers: {
    Authorization: `Bearer ${ZENDESK_OAUTH_TOKEN}`,
  },
});

const helpCenterApi = zendesk.helpcenter;

export const useHelpCenterCategories = (
  locale: Locale,
  params?: GetCategoriesParams,
  queryOptions?: Partial<UseQueryOptions<GetCategoriesResponse>>
) =>
  useQuery<GetCategoriesResponse>({
    queryKey: queryKeys.helpCenterCategories(locale, params),
    queryFn: () => helpCenterApi.categories.getCategories(locale, params),
    ...queryOptions,
  });

export const useHelpCenterSections = (
  locale: Locale,
  params?: GetSectionsParams,
  queryOptions?: Partial<UseQueryOptions<GetSectionsResponse>>
) =>
  useQuery<GetSectionsResponse>({
    queryKey: queryKeys.helpCenterSections(locale, params),
    queryFn: () => helpCenterApi.sections.getSections(locale, params),
    ...queryOptions,
  });

export const useHelpCenterSection = (
  locale: Locale,
  sectionId: number,
  queryOptions?: Partial<UseQueryOptions<GetSectionResponse>>
) =>
  useQuery<GetSectionResponse>({
    queryKey: queryKeys.helpCenterSection(locale, sectionId),
    queryFn: () => helpCenterApi.sections.getSection(locale, sectionId),
    ...queryOptions,
  });

export const useHelpCenterArticles = (
  locale: Locale,
  params?: GetArticlesParams,
  queryOptions?: Partial<UseQueryOptions<GetArticlesResponse>>
) =>
  useQuery<GetArticlesResponse>({
    queryKey: queryKeys.helpCenterArticles(locale, params),
    queryFn: () => helpCenterApi.articles.getArticlesByLocale(locale, params),
    ...queryOptions,
  });

export const useHelpCenterArticleLabels = (
  queryOptions?: Partial<UseQueryOptions<GetArticleLabelsResponse>>
) =>
  useQuery<GetArticleLabelsResponse>({
    queryKey: queryKeys.helpCenterArticleLabels(),
    queryFn: () => helpCenterApi.articleLabels.getLabels(),
    ...queryOptions,
  });

export const useHelpCenterArticleAttachments = (
  articleId: Article['id'],
  params?: GetArticleAttachmentsParams,
  queryOptions?: Partial<UseQueryOptions<GetArticleAttachmentsResponse>>
) =>
  useQuery<GetArticleAttachmentsResponse>({
    queryKey: queryKeys.helpCenterArticleAttachments(articleId, params),
    queryFn: () => helpCenterApi.articleAttachments.getArticleAttachments(articleId, params),
    ...queryOptions,
  });
