import React from 'react';
import ShapeLogo from '../ShapeLogo/ShapeLogo';

export const InstallLogo = () => {
  return (
    <div className="flex w-9 h-9 m-auto items-center justify-center bg-white rounded-lg">
      <div className="w-6">
        <ShapeLogo />
      </div>
    </div>
  );
};
