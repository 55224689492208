import { Disclosure } from '@headlessui/react';
import React from 'react';

export type ExpansionPanelContentProps = {
  children: React.ReactNode;
};

export const ExpansionPanelContent: React.FC<ExpansionPanelContentProps> = ({ children }) => (
  <Disclosure.Panel>{children}</Disclosure.Panel>
);
