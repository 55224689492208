import * as React from 'react';
import type { SVGProps } from 'react';
const DiamondIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/diamond">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00001 4C5.64875 4 5.32324 4.1843 5.14252 4.4855L2.14252 9.4855C1.91703 9.86132 1.96253 10.3402 2.25477 10.6668L10.7548 20.1668C10.7648 20.178 10.7752 20.1891 10.7857 20.1999C10.944 20.3614 11.1329 20.4897 11.3414 20.5774C11.55 20.665 11.7738 20.7101 12 20.7101C12.2262 20.7101 12.4501 20.665 12.6586 20.5774C12.8671 20.4897 13.056 20.3614 13.2143 20.1999C13.2249 20.1891 13.2352 20.178 13.2453 20.1668L21.7453 10.6668C22.0375 10.3402 22.083 9.86132 21.8575 9.4855L18.8575 4.4855C18.6768 4.1843 18.3513 4 18 4H6.00001ZM9.45749 9.31454C9.74164 8.84096 9.58808 8.2267 9.1145 7.94256C8.64092 7.65841 8.02666 7.81197 7.74251 8.28555L7.14251 9.28555C6.91551 9.66388 6.96328 10.1463 7.26006 10.4727L9.26006 12.6727C9.63157 13.0814 10.264 13.1115 10.6727 12.74C11.0813 12.3685 11.1114 11.736 10.7399 11.3274L9.23985 9.67728L9.45749 9.31454Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DiamondIcon;
