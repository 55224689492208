import type React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { Link } from 'react-router-dom';
import type { ProjectSchema, TeamSchema } from '@shape-construction/api/model';
import { Alert } from '@shape-construction/arch-ui';
import { DiamondIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { now, parseDate } from '@shape-construction/utils/DateTime';
import { useTeamsSubscriptionPlan } from 'app/queries/teamsSubscriptionPlan/teamsSubscriptionPlan';

export interface TrialBarProps {
  projectId: ProjectSchema['id'];
  teamId: TeamSchema['id'];
}

export const TrialBar: React.FC<TrialBarProps> = ({ projectId, teamId }) => {
  const messages = useMessageGetter('trial.bar');

  const { data, isLoading } = useTeamsSubscriptionPlan(projectId!, teamId!);

  if (!projectId || !teamId) return null;
  if (isLoading || !data?.trialEndAt) return null;

  const displayUpgradeLink = data?.availableActions.edit;
  const daysLeft = parseDate(data.trialEndAt).diff(now(), 'days');

  return (
    <Alert
      color="success"
      rounded={false}
      justifyContent="center"
      customIcon={<DiamondIcon className="text-green-400" />}
    >
      <Alert.Message>
        {messages('badge', { daysLeft })}{' '}
        {displayUpgradeLink && (
          <Link
            to={`/projects/${projectId}/settings/teams/${teamId}/subscription`}
            className="underline font-bold"
          >
            {messages('link')}
          </Link>
        )}
        {!displayUpgradeLink && messages('contactUpgrade')}
      </Alert.Message>
    </Alert>
  );
};
