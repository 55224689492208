import React, { type PropsWithChildren } from 'react';
import { Portal, Menu, type MenuItemsProps } from '@headlessui/react';
import { useMediaQuery } from '@shape-construction/hooks';
import { breakpoints } from '../utils/breakpoints';

const DEFAULT_TAG = 'div' as const;
type OwnProps = PropsWithChildren<{}>;
export type DropdownItemsProps = MenuItemsProps<typeof DEFAULT_TAG> & OwnProps;

export const DropdownItems = React.forwardRef<HTMLDivElement, DropdownItemsProps>(
  ({ children, style, ...props }, ref) => {
    const isSmallScreen = useMediaQuery(breakpoints.down('md'));

    if (isSmallScreen) {
      return (
        <Menu.Items {...props} ref={ref} className="block md:hidden">
          <div
            role="none"
            className="overflow-none pointer-events-none fixed left-0 top-0 z-popover h-screen w-screen bg-gray-800 bg-opacity-75 backdrop-filter"
          />
          <div className="fixed left-0 bottom-0 z-popover w-full rounded-t-md bg-white shadow-lg">
            {children}
          </div>
        </Menu.Items>
      );
    }

    return (
      <Portal>
        <Menu.Items
          {...props}
          ref={ref}
          style={style}
          className="z-popover flex w-56 flex-col items-start rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {children}
        </Menu.Items>
      </Portal>
    );
  }
);
DropdownItems.displayName = 'DropdownItems';
