import * as React from 'react';
import type { SVGProps } from 'react';
const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/trash">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1.20001C8.67744 1.20001 7.19998 2.67747 7.19998 4.50001V5.03125C6.24564 5.12367 5.2992 5.24337 4.36147 5.38956C3.87035 5.46613 3.53428 5.92633 3.61085 6.41746C3.68741 6.90858 4.14762 7.24465 4.63874 7.16808C4.69787 7.15886 4.75703 7.14975 4.81624 7.14076L5.82603 19.7632C5.96323 21.4782 7.39503 22.8 9.11552 22.8H14.8842C16.6047 22.8 18.0365 21.4782 18.1737 19.7632L19.1835 7.14068C19.2428 7.1497 19.3022 7.15884 19.3615 7.16808C19.8526 7.24465 20.3128 6.90858 20.3894 6.41746C20.4659 5.92633 20.1299 5.46613 19.6387 5.38956C18.7009 5.24336 17.7544 5.12365 16.8 5.03122V4.50001C16.8 2.67747 15.3225 1.20001 13.5 1.20001H10.5ZM12.0001 4.80001C13.0073 4.80001 14.0076 4.83029 15 4.89V4.50001C15 3.67159 14.3284 3.00001 13.5 3.00001H10.5C9.67155 3.00001 8.99998 3.67159 8.99998 4.50001V4.89001C9.99246 4.8303 10.9928 4.80001 12.0001 4.80001ZM10.1455 9.27004C10.1289 8.85615 9.77997 8.53406 9.36609 8.55061C8.95221 8.56717 8.63011 8.91611 8.64667 9.32999L9.00668 18.33C9.02324 18.7439 9.37218 19.066 9.78606 19.0494C10.1999 19.0329 10.522 18.6839 10.5055 18.27L10.1455 9.27004ZM15.3535 9.32999C15.37 8.91611 15.0479 8.56717 14.6341 8.55061C14.2202 8.53406 13.8712 8.85615 13.8547 9.27004L13.4947 18.27C13.4781 18.6839 13.8002 19.0329 14.2141 19.0494C14.628 19.066 14.9769 18.7439 14.9935 18.33L15.3535 9.32999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default TrashIcon;
