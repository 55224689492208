import * as React from 'react';
import type { SVGProps } from 'react';
const PhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/photo">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.20001 6.29998C1.20001 4.80881 2.40884 3.59998 3.90001 3.59998H20.1C21.5912 3.59998 22.8 4.80881 22.8 6.29998V17.7C22.8 19.1911 21.5912 20.4 20.1 20.4H3.90001C2.40884 20.4 1.20001 19.1911 1.20001 17.7V6.29998ZM3.00001 13.2728V17.7C3.00001 18.197 3.40296 18.6 3.90001 18.6H20.1C20.5971 18.6 21 18.197 21 17.7V14.4728L18.3364 11.8092C17.9849 11.4577 17.4151 11.4577 17.0636 11.8092L14.7728 14.1L15.3364 14.6636C15.6879 15.015 15.6879 15.5849 15.3364 15.9364C14.9849 16.2878 14.4151 16.2878 14.0636 15.9364L7.83641 9.70916C7.48494 9.35769 6.91509 9.35769 6.56362 9.70916L3.00001 13.2728ZM14.4 8.39997C14.4 9.06272 13.8628 9.59997 13.2 9.59997C12.5373 9.59997 12 9.06272 12 8.39997C12 7.73723 12.5373 7.19998 13.2 7.19998C13.8628 7.19998 14.4 7.73723 14.4 8.39997Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PhotoIcon;
