import { useLiveQuery } from 'dexie-react-hooks';
import {
  PostApiProjectsProjectIdIssuesBodyIssue,
  ProjectSchema,
} from '@shape-construction/api/model';
import UtilsStats from 'app/components/Utils/UtilsStats';
import db from './db';

export const buildIssue = (
  issue: PostApiProjectsProjectIdIssuesBodyIssue,
  projectId: ProjectSchema['id']
) => ({
  ...issue,
  id: UtilsStats.uuidv4(),
  projectId,
});

export type Issue = PostApiProjectsProjectIdIssuesBodyIssue & {
  id: string;
  projectId: ProjectSchema['id'];
};

export const createIssue = async (issue: Issue) => {
  await db.issues.add(issue);
};

export const deleteIssue = async (id: Issue['id']) => {
  await db.issues.delete(id);
};

export const useIssues = () => useLiveQuery(() => db.issues.toArray(), []);

export const useLocalProjectIssues = (projectId: ProjectSchema['id']) =>
  useLiveQuery(() => db.issues.where({ projectId }).toArray(), [projectId]);
