import React, { useState } from 'react';
import { MessageProvider, useMessage } from '@messageformat/react';
import type { DocumentKindSchema, ProjectSchema } from '@shape-construction/api/model';
import { Button, Divider, IconButton, Menu, Portal } from '@shape-construction/arch-ui';
import { PlusIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { ChevronDownIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import galleryMessages from 'app/components/Gallery/messages.en.yaml';
import { useProjectDocumentsUploadWorkflow } from 'app/hooks/useProjectDocumentsUploadWorkflow';
import { useProject } from 'app/queries/projects/projects';
import type { DocumentBody, DocumentPreview } from '../Gallery/types';
import { UploadPreview } from '../Gallery/UploadPreview';
import { GallerySection } from './GallerySection';
import { IssueTrackerSection } from './IssueTrackerSection';
import { ShiftManagerSection } from './ShiftManagerSection';

export const QuickNewButtonTrigger = () => {
  const title = useMessage('quickNewButton.trigger.title');
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  return isLargeScreen ? (
    <Button
      color="primary"
      variant="contained"
      size="md"
      data-cy="quick-new-button"
      aria-label={title}
    >
      {title}
      <ChevronDownIcon className="w-5 h-5" />
    </Button>
  ) : (
    <IconButton
      data-cy="quick-new-button"
      aria-label={title}
      icon={PlusIcon}
      size="xl"
      color="primary"
      variant="contained"
    />
  );
};

export type QuickNewButtonProps = {
  projectId: ProjectSchema['id'];
};

export const QuickNewButton = ({ projectId }: QuickNewButtonProps) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const { data: project } = useProject(projectId);
  const { uploadDocument } = useProjectDocumentsUploadWorkflow(projectId);
  const [documentPreview, setDocumentPreview] = useState<DocumentPreview>(null);

  const canCreateIssue = Boolean(project?.availableActions?.createIssue);
  const canCreateShiftReport = Boolean(project?.availableActions?.createShiftReport);
  const canCreateShiftActivity = Boolean(project?.availableActions?.createWeeklyWorkPlan); // api is not returning the correct permission yet
  const canCreateWeeklyWorkPlan = Boolean(project?.availableActions?.createWeeklyWorkPlan);
  const canUploadDocument = Boolean(project?.availableActions?.uploadDocument);
  const showCreateIssueDivider = canCreateIssue && (canCreateShiftReport || canUploadDocument);
  const showCreateShiftReportDivider =
    (canCreateShiftReport || canCreateShiftActivity) && canUploadDocument;
  // include WWP permissions when enabling the section in menu (on the right-hand side of the evaluation), e.g.:
  // const showCreateIssueDivider = canCreateIssue && (canCreateShiftReport || canCreateWeeklyWorkPlan || canUploadDocument);
  // const showCreateShiftReportDivider = canCreateShiftReport && (canCreateWeeklyWorkPlan || canUploadDocument);
  // const showCreateWeeklyWorkPlanDivider = canCreateWeeklyWorkPlan && canUploadDocument;

  const createPermissionsCount = [
    canCreateIssue,
    canCreateShiftReport,
    canCreateShiftActivity,
    canCreateWeeklyWorkPlan,
    canUploadDocument,
  ].filter(Boolean).length;

  const onAddDocument = (file: File, type: DocumentKindSchema) => {
    const preview: DocumentPreview = {
      fileToUpload: file,
      type,
      preview: URL.createObjectURL(file),
    };
    setDocumentPreview(preview);
  };

  const onUpload = (file: File, body: DocumentBody) => {
    onCloseModal();
    return uploadDocument(file, body);
  };

  const onCloseModal = () => {
    setDocumentPreview(null);
  };

  if (!project || createPermissionsCount === 0) return null;

  return (
    <MessageProvider messages={galleryMessages}>
      <Menu.Root>
        <Menu.Trigger as="div" className="relative md:mr-5">
          <QuickNewButtonTrigger />
        </Menu.Trigger>
        <Portal>
          <Menu.Items
            className="md:w-96 whitespace-normal text-gray-700 flex-col justify-start items-start inline-flex"
            unmount={false}
          >
            <IssueTrackerSection projectId={projectId} />
            {showCreateIssueDivider && <Divider orientation="horizontal" />}
            <ShiftManagerSection projectId={projectId} />
            {showCreateShiftReportDivider && <Divider orientation="horizontal" />}
            {/* Section switched off until WWP is released */}
            {/* <WeeklyPlannerSection projectId={projectId} /> */}
            {/* {showCreateWeeklyWorkPlanDivider && <Divider orientation="horizontal" />} */}
            <GallerySection projectId={projectId} onUpload={onAddDocument} />
            {!isLargeScreen && <div className="h-4 w-full opacity-0" />}
          </Menu.Items>
        </Portal>
      </Menu.Root>

      {documentPreview && (
        <UploadPreview
          projectId={projectId}
          documentPreview={documentPreview}
          onUploadDocument={onUpload}
          onClosePreview={onCloseModal}
        />
      )}
    </MessageProvider>
  );
};
