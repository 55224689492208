import { getGetApiUsersMeQueryKey } from '@shape-construction/api/api';
import { clearToken, getToken } from './authorization';
import { chatClient } from './channels/get-stream/sdkClient';
import { clearLocalDatabase } from './localData/db';
import { removeIssuesFilters } from './pages/projects/[projectId]/issues/components/IssuesFilters/hooks/useIssuesFilters';
import queryClient from './queries/query.client.builder';
import { store } from './store';
import { resetState } from './store/actions';

export const hasAuthToken = () => Boolean(getToken());

export const endSession = async () => {
  clearToken();
  queryClient.cancelQueries();

  removeIssuesFilters();
  store.dispatch(resetState());

  chatClient?.disconnectUser();
  await clearLocalDatabase();

  await queryClient.resetQueries({ queryKey: getGetApiUsersMeQueryKey() });
  queryClient.clear();
};
