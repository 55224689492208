import * as React from 'react';
import type { SVGProps } from 'react';
const MppIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/mpp">
      <g id="Vector" filter="url(#filter0_dd_3816_38071)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="#059669"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="url(#paint0_radial_3816_38071)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2241 17.2222H10.3314C9.65746 17.2222 9.11108 17.7685 9.11108 18.4425V29.3351C9.11108 30.0091 9.65746 30.5555 10.3314 30.5555H21.2241C21.898 30.5555 22.4444 30.0091 22.4444 29.3351V18.4425C22.4444 17.7685 21.898 17.2222 21.2241 17.2222ZM18.3653 21.2766C18.1673 20.9259 17.8886 20.6542 17.5292 20.4614C17.1698 20.2687 16.754 20.1723 16.2817 20.1723H13.2448V27.7463H14.6721V24.8082H16.2817C16.7539 24.8082 17.1698 24.7127 17.5292 24.5216C17.8887 24.3306 18.1674 24.0606 18.3653 23.7116C18.5633 23.3624 18.6623 22.957 18.6622 22.4953C18.6622 22.0336 18.5632 21.6274 18.3653 21.2766ZM17.0734 22.9855C17.0005 23.128 16.8989 23.2382 16.7686 23.3163C16.6384 23.3944 16.4882 23.4335 16.3181 23.4335H14.6721V21.5475H16.3181C16.4918 21.5475 16.6436 21.5866 16.7737 21.6647C16.9037 21.7429 17.0044 21.854 17.0757 21.9981C17.1469 22.1423 17.1825 22.3081 17.1826 22.4955C17.1828 22.6797 17.1464 22.843 17.0734 22.9855ZM23.1832 21.0737V20.3505H29.2472V21.0737V21.7968H23.1832V21.0737ZM26.511 26.8451V26.1219H30.6722V26.8451V27.5683H26.511V26.8451ZM23.1832 23.2425V23.9523V23.9656V24.6755H28.1172V23.9656V23.9523V23.2425H23.1832Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38071"
        x={2.66669}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38071" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3816_38071"
          result="effect2_dropShadow_3816_38071"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3816_38071"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38071"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default MppIcon;
