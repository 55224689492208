import React from 'react';
import classNames from 'clsx';
import Badge from '../../../Badge';
import type { TabProps } from '../../components/Tab';
import { SHAPE, SIZE, THEME } from '../../../Badge/Badge.types';

export const ScrollableTab: React.FC<TabProps> = React.forwardRef(
  (props: TabProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      badge,
      children,
      onSelect,
      value,
      selected,
      // @ts-ignore internal use only. See React.cloneElement on Tabs component
      onChange,
      leadingIcon: LeadingIcon,
      ...buttonProps
    } = props;
    const handleClick = (event: any) => {
      if (onChange) {
        onChange(event, value);
      }

      if (onSelect) {
        onSelect(event);
      }
    };

    const tabClassnames = classNames(
      'border-b-2 flex font-medium px-1 py-4 text-sm whitespace-nowrap gap-x-1 items-center',
      {
        'border-indigo-500 text-indigo-600 cursor-default': selected,
        'border-transparent text-gray-500 cursor-pointer': !selected,
        'opacity-50 cursor-not-allowed': buttonProps.disabled,
        'hover:border-gray-300 hover:text-gray-700': !buttonProps.disabled && !selected,
      }
    );
    const content = (
      <>
        {LeadingIcon && <LeadingIcon className="h-4 w-4" data-testid="leading-icon" />}
        {children}
        {badge && (
          <Badge
            label={badge.toString()}
            shape={SHAPE.BASIC}
            theme={THEME.PINK}
            size={SIZE.SMALL}
          />
        )}
      </>
    );

    if (selected) {
      return (
        <div aria-selected className={tabClassnames} role="tab">
          {content}
        </div>
      );
    }

    return (
      <button
        ref={ref}
        aria-selected={false}
        className={tabClassnames}
        onClick={handleClick}
        role="tab"
        type="button"
        tabIndex={0}
        {...buttonProps}
      >
        {content}
      </button>
    );
  }
);

ScrollableTab.displayName = 'Tab';
