import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import classnames from 'clsx';
import { IconButton } from '../../../Button';

type ScrollableArrowProps = {
  width: number;
  position: number;
  parentRef: React.RefObject<HTMLDivElement>;
  isOverflowing: boolean;
};

export const ScrollableRightArrow = ({
  width,
  position,
  parentRef,
  isOverflowing,
}: ScrollableArrowProps) => {
  const calculateScroll = () =>
    parentRef.current
      ? Math.min(position + width, parentRef.current.scrollWidth - parentRef.current.clientWidth)
      : 0;

  const handleScroll = () => {
    if (!parentRef.current) return;

    parentRef.current.scrollTo({
      left: calculateScroll(),
      behavior: 'smooth',
    });
  };

  const isArrowActive = position < calculateScroll();
  if (!isArrowActive || !isOverflowing) return null;

  const style: string = 'absolute right-0 w-8 h-12 mt-0.5 mr-0.5 flex items-center justify-center';
  const gradient: string = 'bg-gradient-to-l from-white via-white';

  return (
    <div className={classnames(style, gradient)}>
      <IconButton
        color="secondary"
        variant="text"
        icon={ChevronRightIcon}
        onClick={handleScroll}
        size="xxs"
        aria-label="scroll right"
      />
    </div>
  );
};
