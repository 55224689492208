import type { Section, Locale, PageParams, PageResponse } from 'zendesk/api/help-center/types';
import { BaseApi } from 'zendesk/base-api';

export type GetSectionsParams = PageParams;
export type GetSectionsResponse = PageResponse & { sections: Section[] };
export type GetSectionResponse = { section: Section };

export class Sections extends BaseApi {
  getSections(locale: Locale, params?: GetSectionsParams) {
    return this.request<GetSectionsResponse>({
      url: `/${locale}/sections`,
      method: 'get',
      params,
    });
  }

  getSection(locale: Locale, sectionId: number) {
    return this.request<GetSectionResponse>({
      url: `/${locale}/sections/${sectionId}`,
      method: 'get',
    });
  }
}
