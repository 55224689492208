import { v4 } from 'uuid';

/**
 * The factory pattern is used to encompass multiple function expressions
 * and export them in a single module.
 * @module UtilsStats
 * @classdesc Utility statistical and numerical functions.
 */
const UtilsStats = () => {
  /**
   * Calculates the sum from the array of numbers.
   * @static
   * @function sum
   * @param {Array<number>} arr Array of numbers to sum.
   * @return {?number} The sum of all array elements
   */
  const sum = (arr: Array<number>): number => {
    // return null if is not array or if is an empty array
    if (!Array.isArray(arr) || arr.length === 0) {
      return 0;
    }
    return arr.reduce((acc, val) => acc + val, 0);
  };

  /**
   * Calculates the arithmetic mean from the array of numbers.
   * @static
   * @function mean
   * @param {Array<number>} arr Array of numbers to aggregate.
   * @return {?number} The arithmetic mean.
   * Null is returned when array was empty or contained incorrect value types.
   */
  const mean = (arr: Array<number>): number | null => {
    // return null if is not array or if is an empty array
    if (!Array.isArray(arr) || arr.length === 0) {
      return null;
    }
    return sum(arr) / arr.length;
  };

  /**
   * Calculates the median from the array of numbers.
   * @static
   * @function median
   * @param {Array<number>} arr Array of numbers to aggregate.
   * @return {?number} The median.
   * Null is returned when array was empty or contained incorrect value types.
   */
  const median = (arr: Array<number>) => {
    // return null if is not array or if is an empty array
    if (!Array.isArray(arr) || arr.length === 0) {
      return null;
    }
    const sorted = arr.slice(0).sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    if (sorted.length % 2 === 1) {
      // odd number of elements
      return sorted[mid];
    }
    // even number of elements
    return (sorted[mid - 1] + sorted[mid]) / 2;
  };

  /**
   * Generates [RFC4122]{@link https://tools.ietf.org/html/rfc4122} compliant UUID.
   * Example: <pre><code>50f12bf1-7eb3-4aef-a34c-7600b184f435</code></pre>
   * [Read more at Stack Overflow]{@link https://stackoverflow.com/a/2117523}
   * @static
   * @function uuidv4
   * @return {string} Pseudo-random UUID compliant with the RFC4122 standard.
   */
  const uuidv4 = () => v4();

  /**
   * Generates a random integer number between a min and a max value.
   * The default min value is 0, the default max value is 999_999_999
   * @static
   *
   * @param {number} [min=0] - Min number
   * @param {number} [max=999_999_999] - Max number
   *
   * @function randomNumericId
   * @return {number} Random number
   */
  function randomNumericId(min = 0, max = 999_999_999) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return { sum, mean, median, uuidv4, randomNumericId };
};

export default UtilsStats();
