import { Configuration } from 'zendesk/base-api';
import { ArticleAttachments } from './article-attachments';
import { ArticleLabels } from './article-labels';
import { Articles } from './articles';
import { Categories } from './categories';
import { Sections } from './sections';

export class HelpCenter {
  private serviceUrl = '/help_center';

  public articles: Articles;
  public articleLabels: ArticleLabels;
  public articleAttachments: ArticleAttachments;
  public categories: Categories;
  public sections: Sections;

  constructor(config: Configuration) {
    const helpCenterConfig = {
      ...config,
      baseURL: `${config.baseURL}${this.serviceUrl}`,
    };

    this.articles = new Articles(helpCenterConfig);
    this.articleLabels = new ArticleLabels(helpCenterConfig);
    this.articleAttachments = new ArticleAttachments(helpCenterConfig);
    this.categories = new Categories(helpCenterConfig);
    this.sections = new Sections(helpCenterConfig);
  }
}
