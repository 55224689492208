import type { IssuesFilterFormValues, IssuesFilters } from './types/types';

export const emptyFilters: IssuesFilters = {
  impact: [],
  filter_state: [],
  location: [],
  observer: [],
  responsible_team: [],
  responsible: [],
  date: null,
  group_by: null,
  group_properties: [],
  sort_by: null,
  sort_order: null,
};

export const issuesFiltersFormKeys: (keyof IssuesFilterFormValues)[] = [
  'date',
  'filter_state',
  'impact',
  'location',
  'observer',
  'responsible_team',
  'responsible',
];

export const ISSUES_FILTERS_KEY = 'issues_filters';
