/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Shape API
 * OpenAPI spec version: v1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AgreementSchema,
  AuthenticationErrorSchema,
  ChannelsTokenSchema,
  CommentSchema,
  ConfirmEmailErrorSchema,
  ConstructionRoleListSchema,
  CreateTeamMemberWithTokenErrorSchema,
  CustomFieldListSchema,
  CustomFieldSchema,
  DashboardIssuesStalenessesCountSchema,
  DirectUploadSchema,
  DirectUploadTypeSchema,
  DisciplineListSchema,
  DisciplineSchema,
  DocumentAssociatedReferencesSchema,
  DocumentListSchema,
  DocumentReferenceAndDocumentListSchema,
  DocumentReferenceAndDocumentSchema,
  DocumentSchema,
  ErrorSchema,
  FeatureFlagErrorSchema,
  FeatureFlagListSchema,
  FeedbackSchema,
  GetApiFeatureFlagsParams,
  GetApiNotificationsParams,
  GetApiProjectsProjectIdAccessRequestsParams,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams,
  GetApiProjectsProjectIdDisciplinesParams,
  GetApiProjectsProjectIdDocumentsParams,
  GetApiProjectsProjectIdEventsParams,
  GetApiProjectsProjectIdIssuesGroupCountParams,
  GetApiProjectsProjectIdIssuesIssueIdDocumentsParams,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams,
  GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams,
  GetApiProjectsProjectIdIssuesParams,
  GetApiProjectsProjectIdLocationsParams,
  GetApiProjectsProjectIdPeopleParams,
  GetApiProjectsProjectIdShiftActivitiesArchivedParams,
  GetApiProjectsProjectIdShiftActivitiesParams,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams,
  GetApiProjectsProjectIdShiftReportsArchivedParams,
  GetApiProjectsProjectIdShiftReportsDraftParams,
  GetApiProjectsProjectIdShiftReportsParams,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams,
  GetApiProjectsProjectIdWeeklyWorkPlansParams,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams,
  IssueAssignmentSchema,
  IssueCustomFieldListSchema,
  IssueFeedSchema,
  IssueGroupCountSchema,
  IssueImageListSchema,
  IssueImageSchema,
  IssueListSchema,
  IssueSchema,
  IssueStatusStatementListSchema,
  IssueStatusStatementSchema,
  IssueViewListSchema,
  IssueViewSchema,
  IssueVisitSchema,
  LocationListSchema,
  LocationSchema,
  LoginAttemptSchema,
  MetabaseDashboardSchema,
  NewOrExistingDocumentWithAttributesBodyParameterSchema,
  NotificationListSchema,
  NotificationsMarkedAsReadSchema,
  NotificationsOverviewSchema,
  OrgDomainCheckSchema,
  OrgListSchema,
  OrgSchema,
  PatchApiOnboardingBody,
  PatchApiOrgsOrgIdBody,
  PatchApiProductToursProductTourKeyBody,
  PatchApiProjectsProjectIdBody,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody,
  PatchApiProjectsProjectIdDisciplinesDisciplineIdBody,
  PatchApiProjectsProjectIdDocumentsDocumentIdBody,
  PatchApiProjectsProjectIdIssueViewsIssueViewIdBody,
  PatchApiProjectsProjectIdIssuesIssueIdBody,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody,
  PatchApiProjectsProjectIdLocationsLocationIdBody,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody,
  PatchApiProjectsProjectIdShiftReportsShiftReportIdBody,
  PatchApiProjectsProjectIdTeamsTeamIdBody,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody,
  PatchApiPushSubscriptionsPushSubscriptionIdBody,
  PatchApiUsersMeBody,
  PatchApiUsersPasswordBody,
  PostApiAgreementsAcceptEuaParams,
  PostApiAnalyticalEventsBody,
  PostApiAuthenticationBody,
  PostApiDirectUploadsTypeBody,
  PostApiFeedbacksBody,
  PostApiLoginBody,
  PostApiNotificationsNotificationIdMarkReadBody,
  PostApiOnboardingFinishBody,
  PostApiOrgsBody,
  PostApiOrgsCheckDomainBody,
  PostApiProjectsBody,
  PostApiProjectsProjectIdAccessRequestsBody,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody,
  PostApiProjectsProjectIdCustomFieldsBody,
  PostApiProjectsProjectIdDisciplinesBody,
  PostApiProjectsProjectIdDisciplinesSortBody,
  PostApiProjectsProjectIdDocumentsBody,
  PostApiProjectsProjectIdIssueViewsBody,
  PostApiProjectsProjectIdIssuesBody,
  PostApiProjectsProjectIdIssuesExportParams,
  PostApiProjectsProjectIdIssuesIssueIdArchiveBody,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody,
  PostApiProjectsProjectIdIssuesIssueIdCommentsBody,
  PostApiProjectsProjectIdIssuesIssueIdDocumentsBody,
  PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody,
  PostApiProjectsProjectIdIssuesIssueIdRejectBody,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody,
  PostApiProjectsProjectIdLocationsBody,
  PostApiProjectsProjectIdLocationsLocationIdSortBody,
  PostApiProjectsProjectIdShiftActivitiesBody,
  PostApiProjectsProjectIdShiftActivitiesExportBody,
  PostApiProjectsProjectIdShiftActivitiesImportsBody,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody,
  PostApiProjectsProjectIdShiftReportsBody,
  PostApiProjectsProjectIdShiftReportsExportBody,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExportParams,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublishParams,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionParams,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody,
  PostApiProjectsProjectIdTeamsBody,
  PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody,
  PostApiProjectsProjectIdTeamsTeamIdMembersBody,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody,
  PostApiProjectsProjectIdWeeklyWorkPlansBody,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody,
  PostApiPushSubscriptionsBody,
  PostApiTeamMembersBody,
  PostApiUsersBody,
  PostApiUsersConfirmationBody,
  PostApiUsersConfirmationInstructionsBody,
  PostApiUsersPasswordInstructionsBody,
  PrintingPreferencesBodyParameterSchema,
  ProjectAccessRequestListSchema,
  ProjectAccessRequestSchema,
  ProjectIssueEventListSchema,
  ProjectListSchema,
  ProjectSchema,
  PushSubscriptionSchema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody,
  ResetPasswordErrorSchema,
  ResourceKindSchema,
  ResourceListSchema,
  ResourceSchema,
  ShiftActivityListSchema,
  ShiftActivityProgressLogListSchema,
  ShiftActivityProgressLogSchema,
  ShiftActivitySchema,
  ShiftReportCommentListSchema,
  ShiftReportCommentSchema,
  ShiftReportCompletionListSchema,
  ShiftReportListSchema,
  ShiftReportQualityIndicatorsSchema,
  ShiftReportResetSectionErrorSchema,
  ShiftReportSchema,
  TeamChannelConfigurationSchema,
  TeamJoinTokenPublicSchema,
  TeamJoinTokenSchema,
  TeamListSchema,
  TeamMemberFromJoinTokenSchema,
  TeamMemberIssueDependencyListSchema,
  TeamMemberListSchema,
  TeamMemberSchema,
  TeamSchema,
  TeamSubscriptionBillingPortalSchema,
  TeamSubscriptionPlanSchema,
  TeamSubscriptionUpdateResultSchema,
  TimeZoneListSchema,
  TruncatedResourceListSchema,
  UserOnboardingSchema,
  UserProductTourSchema,
  UserSchema,
  WatchingListSchema,
  WatchingSchema,
  WeeklyWorkPlanActivityListSchema,
  WeeklyWorkPlanActivitySchema,
  WeeklyWorkPlanListSchema,
  WeeklyWorkPlanSchema
} from '../model'
import { customInstance } from '../client';
import type { ErrorType } from '../client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Retrieves the latest End User Agreement
 */
export const getApiAgreementsLatestEua = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<AgreementSchema>(
      {url: `/api/agreements/latest_eua`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiAgreementsLatestEuaQueryKey = () => {
    return [`/api/agreements/latest_eua`] as const;
    }

    
export const getGetApiAgreementsLatestEuaInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>>, TError = ErrorType<void>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAgreementsLatestEuaQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>> = ({ signal }) => getApiAgreementsLatestEua(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAgreementsLatestEuaInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>>
export type GetApiAgreementsLatestEuaInfiniteQueryError = ErrorType<void>


export function useGetApiAgreementsLatestEuaInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>>, TError = ErrorType<void>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiAgreementsLatestEua>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiAgreementsLatestEuaInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiAgreementsLatestEua>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiAgreementsLatestEuaInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the latest End User Agreement
 */

export function useGetApiAgreementsLatestEuaInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAgreementsLatestEuaInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiAgreementsLatestEuaQueryOptions = <TData = Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAgreementsLatestEuaQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>> = ({ signal }) => getApiAgreementsLatestEua(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAgreementsLatestEuaQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>>
export type GetApiAgreementsLatestEuaQueryError = ErrorType<void>


export function useGetApiAgreementsLatestEua<TData = Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError = ErrorType<void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiAgreementsLatestEua>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiAgreementsLatestEua<TData = Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiAgreementsLatestEua>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiAgreementsLatestEua<TData = Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the latest End User Agreement
 */

export function useGetApiAgreementsLatestEua<TData = Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAgreementsLatestEua>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAgreementsLatestEuaQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Accepts the End User Agreement for the current user
 */
export const postApiAgreementsAcceptEua = (
    params?: PostApiAgreementsAcceptEuaParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/agreements/accept_eua`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostApiAgreementsAcceptEuaMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAgreementsAcceptEua>>, TError,{params?: PostApiAgreementsAcceptEuaParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAgreementsAcceptEua>>, TError,{params?: PostApiAgreementsAcceptEuaParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAgreementsAcceptEua>>, {params?: PostApiAgreementsAcceptEuaParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAgreementsAcceptEua(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAgreementsAcceptEuaMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAgreementsAcceptEua>>>
    
    export type PostApiAgreementsAcceptEuaMutationError = ErrorType<void>

    /**
 * @summary Accepts the End User Agreement for the current user
 */
export const usePostApiAgreementsAcceptEua = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAgreementsAcceptEua>>, TError,{params?: PostApiAgreementsAcceptEuaParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAgreementsAcceptEua>>,
        TError,
        {params?: PostApiAgreementsAcceptEuaParams},
        TContext
      > => {

      const mutationOptions = getPostApiAgreementsAcceptEuaMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Tracks analytical events
 */
export const postApiAnalyticalEvents = (
    postApiAnalyticalEventsBody: PostApiAnalyticalEventsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/analytical/events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiAnalyticalEventsBody
    },
      options);
    }
  


export const getPostApiAnalyticalEventsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAnalyticalEvents>>, TError,{data: PostApiAnalyticalEventsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAnalyticalEvents>>, TError,{data: PostApiAnalyticalEventsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAnalyticalEvents>>, {data: PostApiAnalyticalEventsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiAnalyticalEvents(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAnalyticalEventsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAnalyticalEvents>>>
    export type PostApiAnalyticalEventsMutationBody = PostApiAnalyticalEventsBody
    export type PostApiAnalyticalEventsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema>

    /**
 * @summary Tracks analytical events
 */
export const usePostApiAnalyticalEvents = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAnalyticalEvents>>, TError,{data: PostApiAnalyticalEventsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAnalyticalEvents>>,
        TError,
        {data: PostApiAnalyticalEventsBody},
        TContext
      > => {

      const mutationOptions = getPostApiAnalyticalEventsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Authenticates a user
 */
export const postApiLogin = (
    postApiLoginBody: PostApiLoginBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiLoginBody
    },
      options);
    }
  


export const getPostApiLoginMutationOptions = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiLogin>>, TError,{data: PostApiLoginBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiLogin>>, TError,{data: PostApiLoginBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiLogin>>, {data: PostApiLoginBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiLogin(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postApiLogin>>>
    export type PostApiLoginMutationBody = PostApiLoginBody
    export type PostApiLoginMutationError = ErrorType<AuthenticationErrorSchema>

    /**
 * @summary Authenticates a user
 */
export const usePostApiLogin = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiLogin>>, TError,{data: PostApiLoginBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiLogin>>,
        TError,
        {data: PostApiLoginBody},
        TContext
      > => {

      const mutationOptions = getPostApiLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Logs out a user
 */
export const deleteApiLogout = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/logout`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiLogoutMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiLogout>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiLogout>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiLogout>>, void> = () => {
          

          return  deleteApiLogout(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiLogout>>>
    
    export type DeleteApiLogoutMutationError = ErrorType<unknown>

    /**
 * @summary Logs out a user
 */
export const useDeleteApiLogout = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiLogout>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiLogout>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteApiLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Attempts a login with a provider or checks if the account exists
 */
export const postApiAuthentication = (
    postApiAuthenticationBody: PostApiAuthenticationBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<LoginAttemptSchema>(
      {url: `/api/authentication`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiAuthenticationBody
    },
      options);
    }
  


export const getPostApiAuthenticationMutationOptions = <TError = ErrorType<ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthentication>>, TError,{data: PostApiAuthenticationBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthentication>>, TError,{data: PostApiAuthenticationBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthentication>>, {data: PostApiAuthenticationBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthentication(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthenticationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthentication>>>
    export type PostApiAuthenticationMutationBody = PostApiAuthenticationBody
    export type PostApiAuthenticationMutationError = ErrorType<ErrorSchema>

    /**
 * @summary Attempts a login with a provider or checks if the account exists
 */
export const usePostApiAuthentication = <TError = ErrorType<ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthentication>>, TError,{data: PostApiAuthenticationBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthentication>>,
        TError,
        {data: PostApiAuthenticationBody},
        TContext
      > => {

      const mutationOptions = getPostApiAuthenticationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Requests a new Channels token
 */
export const postApiChannelsToken = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ChannelsTokenSchema>(
      {url: `/api/channels/token`, method: 'POST'
    },
      options);
    }
  


export const getPostApiChannelsTokenMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiChannelsToken>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiChannelsToken>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiChannelsToken>>, void> = () => {
          

          return  postApiChannelsToken(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiChannelsTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiChannelsToken>>>
    
    export type PostApiChannelsTokenMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Requests a new Channels token
 */
export const usePostApiChannelsToken = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiChannelsToken>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiChannelsToken>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostApiChannelsTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Save message attachments to project gallery
 */
export const postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments = (
    projectId: string,
    messageId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/channels/messages/${messageId}/save_attachments`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments>>, TError,{projectId: string;messageId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments>>, TError,{projectId: string;messageId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments>>, {projectId: string;messageId: string}> = (props) => {
          const {projectId,messageId} = props ?? {};

          return  postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments(projectId,messageId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments>>>
    
    export type PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Save message attachments to project gallery
 */
export const usePostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments>>, TError,{projectId: string;messageId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments>>,
        TError,
        {projectId: string;messageId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates a comment
 */
export const postApiProjectsProjectIdIssuesIssueIdComments = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdCommentsBody: PostApiProjectsProjectIdIssuesIssueIdCommentsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<CommentSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/comments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdCommentsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdCommentsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdCommentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdCommentsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComments>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdCommentsBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdComments(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdCommentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComments>>>
    export type PostApiProjectsProjectIdIssuesIssueIdCommentsMutationBody = PostApiProjectsProjectIdIssuesIssueIdCommentsBody
    export type PostApiProjectsProjectIdIssuesIssueIdCommentsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a comment
 */
export const usePostApiProjectsProjectIdIssuesIssueIdComments = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdCommentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComments>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdCommentsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdCommentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a comment
 */
export const deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId = (
    projectId: string,
    issueId: string,
    commentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issues/${issueId}/comments/${commentId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId>>, TError,{projectId: string;issueId: string;commentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId>>, TError,{projectId: string;issueId: string;commentId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId>>, {projectId: string;issueId: string;commentId: string}> = (props) => {
          const {projectId,issueId,commentId} = props ?? {};

          return  deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId(projectId,issueId,commentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId>>>
    
    export type DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a comment
 */
export const useDeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId>>, TError,{projectId: string;issueId: string;commentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId>>,
        TError,
        {projectId: string;issueId: string;commentId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves construction roles
 */
export const getApiConstructionRoles = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ConstructionRoleListSchema>(
      {url: `/api/construction_roles`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiConstructionRolesQueryKey = () => {
    return [`/api/construction_roles`] as const;
    }

    
export const getGetApiConstructionRolesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiConstructionRoles>>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiConstructionRolesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiConstructionRoles>>> = ({ signal }) => getApiConstructionRoles(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiConstructionRolesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiConstructionRoles>>>
export type GetApiConstructionRolesInfiniteQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiConstructionRolesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiConstructionRoles>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiConstructionRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiConstructionRolesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiConstructionRoles>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiConstructionRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiConstructionRolesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiConstructionRoles>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves construction roles
 */

export function useGetApiConstructionRolesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiConstructionRoles>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiConstructionRolesInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiConstructionRolesQueryOptions = <TData = Awaited<ReturnType<typeof getApiConstructionRoles>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiConstructionRolesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiConstructionRoles>>> = ({ signal }) => getApiConstructionRoles(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiConstructionRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiConstructionRoles>>>
export type GetApiConstructionRolesQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiConstructionRoles<TData = Awaited<ReturnType<typeof getApiConstructionRoles>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiConstructionRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiConstructionRoles<TData = Awaited<ReturnType<typeof getApiConstructionRoles>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiConstructionRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiConstructionRoles<TData = Awaited<ReturnType<typeof getApiConstructionRoles>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves construction roles
 */

export function useGetApiConstructionRoles<TData = Awaited<ReturnType<typeof getApiConstructionRoles>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiConstructionRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiConstructionRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves project custom fields
 */
export const getApiProjectsProjectIdCustomFields = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<CustomFieldListSchema>(
      {url: `/api/projects/${projectId}/custom_fields`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdCustomFieldsQueryKey = (projectId: string,) => {
    return [`/api/projects/${projectId}/custom_fields`] as const;
    }

    
export const getGetApiProjectsProjectIdCustomFieldsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>> = ({ signal }) => getApiProjectsProjectIdCustomFields(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdCustomFieldsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>>
export type GetApiProjectsProjectIdCustomFieldsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdCustomFieldsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdCustomFieldsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdCustomFieldsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves project custom fields
 */

export function useGetApiProjectsProjectIdCustomFieldsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdCustomFieldsInfiniteQueryOptions(projectId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdCustomFieldsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>> = ({ signal }) => getApiProjectsProjectIdCustomFields(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdCustomFieldsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>>
export type GetApiProjectsProjectIdCustomFieldsQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdCustomFields<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdCustomFields<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdCustomFields<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves project custom fields
 */

export function useGetApiProjectsProjectIdCustomFields<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdCustomFields>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdCustomFieldsQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a custom field
 */
export const postApiProjectsProjectIdCustomFields = (
    projectId: string,
    postApiProjectsProjectIdCustomFieldsBody: PostApiProjectsProjectIdCustomFieldsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<CustomFieldSchema>(
      {url: `/api/projects/${projectId}/custom_fields`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdCustomFieldsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdCustomFieldsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdCustomFields>>, TError,{projectId: string;data: PostApiProjectsProjectIdCustomFieldsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdCustomFields>>, TError,{projectId: string;data: PostApiProjectsProjectIdCustomFieldsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdCustomFields>>, {projectId: string;data: PostApiProjectsProjectIdCustomFieldsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdCustomFields(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdCustomFieldsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdCustomFields>>>
    export type PostApiProjectsProjectIdCustomFieldsMutationBody = PostApiProjectsProjectIdCustomFieldsBody
    export type PostApiProjectsProjectIdCustomFieldsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a custom field
 */
export const usePostApiProjectsProjectIdCustomFields = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdCustomFields>>, TError,{projectId: string;data: PostApiProjectsProjectIdCustomFieldsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdCustomFields>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdCustomFieldsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdCustomFieldsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates a custom field
 */
export const patchApiProjectsProjectIdCustomFieldsCustomFieldId = (
    projectId: string,
    customFieldId: string,
    patchApiProjectsProjectIdCustomFieldsCustomFieldIdBody: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<CustomFieldSchema>(
      {url: `/api/projects/${projectId}/custom_fields/${customFieldId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdCustomFieldsCustomFieldIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdCustomFieldsCustomFieldId>>, TError,{projectId: string;customFieldId: string;data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdCustomFieldsCustomFieldId>>, TError,{projectId: string;customFieldId: string;data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdCustomFieldsCustomFieldId>>, {projectId: string;customFieldId: string;data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody}> = (props) => {
          const {projectId,customFieldId,data} = props ?? {};

          return  patchApiProjectsProjectIdCustomFieldsCustomFieldId(projectId,customFieldId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdCustomFieldsCustomFieldId>>>
    export type PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationBody = PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody
    export type PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a custom field
 */
export const usePatchApiProjectsProjectIdCustomFieldsCustomFieldId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdCustomFieldsCustomFieldId>>, TError,{projectId: string;customFieldId: string;data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdCustomFieldsCustomFieldId>>,
        TError,
        {projectId: string;customFieldId: string;data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a custom field
 */
export const deleteApiProjectsProjectIdCustomFieldsCustomFieldId = (
    projectId: string,
    customFieldId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/custom_fields/${customFieldId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdCustomFieldsCustomFieldId>>, TError,{projectId: string;customFieldId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdCustomFieldsCustomFieldId>>, TError,{projectId: string;customFieldId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdCustomFieldsCustomFieldId>>, {projectId: string;customFieldId: string}> = (props) => {
          const {projectId,customFieldId} = props ?? {};

          return  deleteApiProjectsProjectIdCustomFieldsCustomFieldId(projectId,customFieldId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdCustomFieldsCustomFieldId>>>
    
    export type DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a custom field
 */
export const useDeleteApiProjectsProjectIdCustomFieldsCustomFieldId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdCustomFieldsCustomFieldId>>, TError,{projectId: string;customFieldId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdCustomFieldsCustomFieldId>>,
        TError,
        {projectId: string;customFieldId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates issue custom fields
 */
export const patchApiProjectsProjectIdIssuesIssueIdCustomFields = (
    projectId: string,
    issueId: string,
    patchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem[],
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueCustomFieldListSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/custom_fields`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdCustomFields>>, TError,{projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem[]}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdCustomFields>>, TError,{projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdCustomFields>>, {projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem[]}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  patchApiProjectsProjectIdIssuesIssueIdCustomFields(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdCustomFields>>>
    export type PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationBody = PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem[]
    export type PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates issue custom fields
 */
export const usePatchApiProjectsProjectIdIssuesIssueIdCustomFields = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdCustomFields>>, TError,{projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem[]}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdCustomFields>>,
        TError,
        {projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsBodyItem[]},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates a direct upload
 */
export const postApiDirectUploadsType = (
    type: DirectUploadTypeSchema,
    postApiDirectUploadsTypeBody: PostApiDirectUploadsTypeBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DirectUploadSchema>(
      {url: `/api/direct_uploads/${type}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiDirectUploadsTypeBody
    },
      options);
    }
  


export const getPostApiDirectUploadsTypeMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiDirectUploadsType>>, TError,{type: DirectUploadTypeSchema;data: PostApiDirectUploadsTypeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiDirectUploadsType>>, TError,{type: DirectUploadTypeSchema;data: PostApiDirectUploadsTypeBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiDirectUploadsType>>, {type: DirectUploadTypeSchema;data: PostApiDirectUploadsTypeBody}> = (props) => {
          const {type,data} = props ?? {};

          return  postApiDirectUploadsType(type,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiDirectUploadsTypeMutationResult = NonNullable<Awaited<ReturnType<typeof postApiDirectUploadsType>>>
    export type PostApiDirectUploadsTypeMutationBody = PostApiDirectUploadsTypeBody
    export type PostApiDirectUploadsTypeMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates a direct upload
 */
export const usePostApiDirectUploadsType = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiDirectUploadsType>>, TError,{type: DirectUploadTypeSchema;data: PostApiDirectUploadsTypeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiDirectUploadsType>>,
        TError,
        {type: DirectUploadTypeSchema;data: PostApiDirectUploadsTypeBody},
        TContext
      > => {

      const mutationOptions = getPostApiDirectUploadsTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves disciplines
 */
export const getApiProjectsProjectIdDisciplines = (
    projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DisciplineListSchema>(
      {url: `/api/projects/${projectId}/disciplines`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdDisciplinesQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams,) => {
    return [`/api/projects/${projectId}/disciplines`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdDisciplinesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDisciplinesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>> = ({ signal }) => getApiProjectsProjectIdDisciplines(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDisciplinesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>>
export type GetApiProjectsProjectIdDisciplinesInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDisciplinesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdDisciplinesParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplinesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplinesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves disciplines
 */

export function useGetApiProjectsProjectIdDisciplinesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDisciplinesInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdDisciplinesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDisciplinesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>> = ({ signal }) => getApiProjectsProjectIdDisciplines(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDisciplinesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>>
export type GetApiProjectsProjectIdDisciplinesQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDisciplines<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdDisciplinesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplines<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplines<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves disciplines
 */

export function useGetApiProjectsProjectIdDisciplines<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDisciplinesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplines>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDisciplinesQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a discipline
 */
export const postApiProjectsProjectIdDisciplines = (
    projectId: string,
    postApiProjectsProjectIdDisciplinesBody: PostApiProjectsProjectIdDisciplinesBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DisciplineSchema>(
      {url: `/api/projects/${projectId}/disciplines`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdDisciplinesBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdDisciplinesMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplines>>, TError,{projectId: string;data: PostApiProjectsProjectIdDisciplinesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplines>>, TError,{projectId: string;data: PostApiProjectsProjectIdDisciplinesBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplines>>, {projectId: string;data: PostApiProjectsProjectIdDisciplinesBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdDisciplines(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdDisciplinesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplines>>>
    export type PostApiProjectsProjectIdDisciplinesMutationBody = PostApiProjectsProjectIdDisciplinesBody
    export type PostApiProjectsProjectIdDisciplinesMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a discipline
 */
export const usePostApiProjectsProjectIdDisciplines = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplines>>, TError,{projectId: string;data: PostApiProjectsProjectIdDisciplinesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplines>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdDisciplinesBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdDisciplinesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a discipline
 */
export const getApiProjectsProjectIdDisciplinesDisciplineId = (
    projectId: string,
    disciplineId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DisciplineSchema>(
      {url: `/api/projects/${projectId}/disciplines/${disciplineId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdDisciplinesDisciplineIdQueryKey = (projectId: string,
    disciplineId: string,) => {
    return [`/api/projects/${projectId}/disciplines/${disciplineId}`] as const;
    }

    
export const getGetApiProjectsProjectIdDisciplinesDisciplineIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    disciplineId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDisciplinesDisciplineIdQueryKey(projectId,disciplineId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>> = ({ signal }) => getApiProjectsProjectIdDisciplinesDisciplineId(projectId,disciplineId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && disciplineId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDisciplinesDisciplineIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>>
export type GetApiProjectsProjectIdDisciplinesDisciplineIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDisciplinesDisciplineIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplinesDisciplineIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplinesDisciplineIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a discipline
 */

export function useGetApiProjectsProjectIdDisciplinesDisciplineIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDisciplinesDisciplineIdInfiniteQueryOptions(projectId,disciplineId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdDisciplinesDisciplineIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    disciplineId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDisciplinesDisciplineIdQueryKey(projectId,disciplineId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>> = ({ signal }) => getApiProjectsProjectIdDisciplinesDisciplineId(projectId,disciplineId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && disciplineId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>>
export type GetApiProjectsProjectIdDisciplinesDisciplineIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDisciplinesDisciplineId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplinesDisciplineId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDisciplinesDisciplineId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a discipline
 */

export function useGetApiProjectsProjectIdDisciplinesDisciplineId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    disciplineId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDisciplinesDisciplineId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDisciplinesDisciplineIdQueryOptions(projectId,disciplineId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a discipline
 */
export const patchApiProjectsProjectIdDisciplinesDisciplineId = (
    projectId: string,
    disciplineId: string,
    patchApiProjectsProjectIdDisciplinesDisciplineIdBody: PatchApiProjectsProjectIdDisciplinesDisciplineIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DisciplineSchema>(
      {url: `/api/projects/${projectId}/disciplines/${disciplineId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdDisciplinesDisciplineIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdDisciplinesDisciplineIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdDisciplinesDisciplineId>>, TError,{projectId: string;disciplineId: string;data: PatchApiProjectsProjectIdDisciplinesDisciplineIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdDisciplinesDisciplineId>>, TError,{projectId: string;disciplineId: string;data: PatchApiProjectsProjectIdDisciplinesDisciplineIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdDisciplinesDisciplineId>>, {projectId: string;disciplineId: string;data: PatchApiProjectsProjectIdDisciplinesDisciplineIdBody}> = (props) => {
          const {projectId,disciplineId,data} = props ?? {};

          return  patchApiProjectsProjectIdDisciplinesDisciplineId(projectId,disciplineId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdDisciplinesDisciplineId>>>
    export type PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationBody = PatchApiProjectsProjectIdDisciplinesDisciplineIdBody
    export type PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a discipline
 */
export const usePatchApiProjectsProjectIdDisciplinesDisciplineId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdDisciplinesDisciplineId>>, TError,{projectId: string;disciplineId: string;data: PatchApiProjectsProjectIdDisciplinesDisciplineIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdDisciplinesDisciplineId>>,
        TError,
        {projectId: string;disciplineId: string;data: PatchApiProjectsProjectIdDisciplinesDisciplineIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdDisciplinesDisciplineIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a discipline
 */
export const deleteApiProjectsProjectIdDisciplinesDisciplineId = (
    projectId: string,
    disciplineId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/disciplines/${disciplineId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDisciplinesDisciplineId>>, TError,{projectId: string;disciplineId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDisciplinesDisciplineId>>, TError,{projectId: string;disciplineId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDisciplinesDisciplineId>>, {projectId: string;disciplineId: string}> = (props) => {
          const {projectId,disciplineId} = props ?? {};

          return  deleteApiProjectsProjectIdDisciplinesDisciplineId(projectId,disciplineId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDisciplinesDisciplineId>>>
    
    export type DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a discipline
 */
export const useDeleteApiProjectsProjectIdDisciplinesDisciplineId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDisciplinesDisciplineId>>, TError,{projectId: string;disciplineId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdDisciplinesDisciplineId>>,
        TError,
        {projectId: string;disciplineId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Sorts disciplines
 */
export const postApiProjectsProjectIdDisciplinesSort = (
    projectId: string,
    postApiProjectsProjectIdDisciplinesSortBody: PostApiProjectsProjectIdDisciplinesSortBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/disciplines/sort`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdDisciplinesSortBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdDisciplinesSortMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplinesSort>>, TError,{projectId: string;data: PostApiProjectsProjectIdDisciplinesSortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplinesSort>>, TError,{projectId: string;data: PostApiProjectsProjectIdDisciplinesSortBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplinesSort>>, {projectId: string;data: PostApiProjectsProjectIdDisciplinesSortBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdDisciplinesSort(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdDisciplinesSortMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplinesSort>>>
    export type PostApiProjectsProjectIdDisciplinesSortMutationBody = PostApiProjectsProjectIdDisciplinesSortBody
    export type PostApiProjectsProjectIdDisciplinesSortMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Sorts disciplines
 */
export const usePostApiProjectsProjectIdDisciplinesSort = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplinesSort>>, TError,{projectId: string;data: PostApiProjectsProjectIdDisciplinesSortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdDisciplinesSort>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdDisciplinesSortBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdDisciplinesSortMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves document references
 */
export const getApiProjectsProjectIdDocumentsDocumentIdReferences = (
    projectId: string,
    documentId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DocumentAssociatedReferencesSchema>(
      {url: `/api/projects/${projectId}/documents/${documentId}/references`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey = (projectId: string,
    documentId: string,) => {
    return [`/api/projects/${projectId}/documents/${documentId}/references`] as const;
    }

    
export const getGetApiProjectsProjectIdDocumentsDocumentIdReferencesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey(projectId,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>> = ({ signal }) => getApiProjectsProjectIdDocumentsDocumentIdReferences(projectId,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && documentId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDocumentsDocumentIdReferencesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>>
export type GetApiProjectsProjectIdDocumentsDocumentIdReferencesInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDocumentsDocumentIdReferencesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentIdReferencesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentIdReferencesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves document references
 */

export function useGetApiProjectsProjectIdDocumentsDocumentIdReferencesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDocumentsDocumentIdReferencesInfiniteQueryOptions(projectId,documentId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey(projectId,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>> = ({ signal }) => getApiProjectsProjectIdDocumentsDocumentIdReferences(projectId,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>>
export type GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDocumentsDocumentIdReferences<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentIdReferences<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentIdReferences<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves document references
 */

export function useGetApiProjectsProjectIdDocumentsDocumentIdReferences<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentIdReferences>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryOptions(projectId,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves documents
 */
export const getApiProjectsProjectIdDocuments = (
    projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DocumentListSchema>(
      {url: `/api/projects/${projectId}/documents`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdDocumentsQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams,) => {
    return [`/api/projects/${projectId}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdDocumentsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDocumentsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdDocuments(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDocumentsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>>
export type GetApiProjectsProjectIdDocumentsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdDocumentsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves documents
 */

export function useGetApiProjectsProjectIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDocumentsInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDocumentsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdDocuments(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>>
export type GetApiProjectsProjectIdDocumentsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdDocumentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves documents
 */

export function useGetApiProjectsProjectIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDocumentsQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a document
 */
export const postApiProjectsProjectIdDocuments = (
    projectId: string,
    postApiProjectsProjectIdDocumentsBody: PostApiProjectsProjectIdDocumentsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DocumentSchema>(
      {url: `/api/projects/${projectId}/documents`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdDocumentsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdDocumentsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDocuments>>, TError,{projectId: string;data: PostApiProjectsProjectIdDocumentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDocuments>>, TError,{projectId: string;data: PostApiProjectsProjectIdDocumentsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdDocuments>>, {projectId: string;data: PostApiProjectsProjectIdDocumentsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdDocuments(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdDocumentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdDocuments>>>
    export type PostApiProjectsProjectIdDocumentsMutationBody = PostApiProjectsProjectIdDocumentsBody
    export type PostApiProjectsProjectIdDocumentsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates a document
 */
export const usePostApiProjectsProjectIdDocuments = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDocuments>>, TError,{projectId: string;data: PostApiProjectsProjectIdDocumentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdDocuments>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdDocumentsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a document
 */
export const getApiProjectsProjectIdDocumentsDocumentId = (
    projectId: string,
    documentId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DocumentSchema>(
      {url: `/api/projects/${projectId}/documents/${documentId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdDocumentsDocumentIdQueryKey = (projectId: string,
    documentId: string,) => {
    return [`/api/projects/${projectId}/documents/${documentId}`] as const;
    }

    
export const getGetApiProjectsProjectIdDocumentsDocumentIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDocumentsDocumentIdQueryKey(projectId,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>> = ({ signal }) => getApiProjectsProjectIdDocumentsDocumentId(projectId,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && documentId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDocumentsDocumentIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>>
export type GetApiProjectsProjectIdDocumentsDocumentIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDocumentsDocumentIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a document
 */

export function useGetApiProjectsProjectIdDocumentsDocumentIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDocumentsDocumentIdInfiniteQueryOptions(projectId,documentId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdDocumentsDocumentIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDocumentsDocumentIdQueryKey(projectId,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>> = ({ signal }) => getApiProjectsProjectIdDocumentsDocumentId(projectId,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDocumentsDocumentIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>>
export type GetApiProjectsProjectIdDocumentsDocumentIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDocumentsDocumentId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDocumentsDocumentId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a document
 */

export function useGetApiProjectsProjectIdDocumentsDocumentId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDocumentsDocumentIdQueryOptions(projectId,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a document
 */
export const patchApiProjectsProjectIdDocumentsDocumentId = (
    projectId: string,
    documentId: string,
    patchApiProjectsProjectIdDocumentsDocumentIdBody: PatchApiProjectsProjectIdDocumentsDocumentIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DocumentSchema>(
      {url: `/api/projects/${projectId}/documents/${documentId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdDocumentsDocumentIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdDocumentsDocumentIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdDocumentsDocumentId>>, TError,{projectId: string;documentId: string;data: PatchApiProjectsProjectIdDocumentsDocumentIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdDocumentsDocumentId>>, TError,{projectId: string;documentId: string;data: PatchApiProjectsProjectIdDocumentsDocumentIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdDocumentsDocumentId>>, {projectId: string;documentId: string;data: PatchApiProjectsProjectIdDocumentsDocumentIdBody}> = (props) => {
          const {projectId,documentId,data} = props ?? {};

          return  patchApiProjectsProjectIdDocumentsDocumentId(projectId,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdDocumentsDocumentIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdDocumentsDocumentId>>>
    export type PatchApiProjectsProjectIdDocumentsDocumentIdMutationBody = PatchApiProjectsProjectIdDocumentsDocumentIdBody
    export type PatchApiProjectsProjectIdDocumentsDocumentIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a document
 */
export const usePatchApiProjectsProjectIdDocumentsDocumentId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdDocumentsDocumentId>>, TError,{projectId: string;documentId: string;data: PatchApiProjectsProjectIdDocumentsDocumentIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdDocumentsDocumentId>>,
        TError,
        {projectId: string;documentId: string;data: PatchApiProjectsProjectIdDocumentsDocumentIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdDocumentsDocumentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a document
 */
export const deleteApiProjectsProjectIdDocumentsDocumentId = (
    projectId: string,
    documentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/documents/${documentId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdDocumentsDocumentIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDocumentsDocumentId>>, TError,{projectId: string;documentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDocumentsDocumentId>>, TError,{projectId: string;documentId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDocumentsDocumentId>>, {projectId: string;documentId: string}> = (props) => {
          const {projectId,documentId} = props ?? {};

          return  deleteApiProjectsProjectIdDocumentsDocumentId(projectId,documentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdDocumentsDocumentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDocumentsDocumentId>>>
    
    export type DeleteApiProjectsProjectIdDocumentsDocumentIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a document
 */
export const useDeleteApiProjectsProjectIdDocumentsDocumentId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdDocumentsDocumentId>>, TError,{projectId: string;documentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdDocumentsDocumentId>>,
        TError,
        {projectId: string;documentId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdDocumentsDocumentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves events
 */
export const getApiProjectsProjectIdEvents = (
    projectId: string,
    params?: GetApiProjectsProjectIdEventsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProjectIssueEventListSchema>(
      {url: `/api/projects/${projectId}/events`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdEventsQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdEventsParams,) => {
    return [`/api/projects/${projectId}/events`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdEventsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdEventsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>> = ({ signal }) => getApiProjectsProjectIdEvents(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdEventsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>>
export type GetApiProjectsProjectIdEventsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdEventsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdEventsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdEventsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdEventsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves events
 */

export function useGetApiProjectsProjectIdEventsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdEventsInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdEventsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdEventsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>> = ({ signal }) => getApiProjectsProjectIdEvents(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>>
export type GetApiProjectsProjectIdEventsQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdEvents<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdEventsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdEvents<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdEvents<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves events
 */

export function useGetApiProjectsProjectIdEvents<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdEventsQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves the list of feature flags
 */
export const getApiFeatureFlags = (
    params?: GetApiFeatureFlagsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<FeatureFlagListSchema>(
      {url: `/api/feature_flags`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiFeatureFlagsQueryKey = (params?: GetApiFeatureFlagsParams,) => {
    return [`/api/feature_flags`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiFeatureFlagsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiFeatureFlags>>>, TError = ErrorType<FeatureFlagErrorSchema>>(params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiFeatureFlagsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiFeatureFlags>>> = ({ signal }) => getApiFeatureFlags(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiFeatureFlagsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFeatureFlags>>>
export type GetApiFeatureFlagsInfiniteQueryError = ErrorType<FeatureFlagErrorSchema>


export function useGetApiFeatureFlagsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiFeatureFlags>>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params: undefined |  GetApiFeatureFlagsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiFeatureFlags>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiFeatureFlagsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiFeatureFlags>>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiFeatureFlags>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiFeatureFlagsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiFeatureFlags>>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the list of feature flags
 */

export function useGetApiFeatureFlagsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiFeatureFlags>>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiFeatureFlagsInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiFeatureFlagsQueryOptions = <TData = Awaited<ReturnType<typeof getApiFeatureFlags>>, TError = ErrorType<FeatureFlagErrorSchema>>(params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiFeatureFlagsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiFeatureFlags>>> = ({ signal }) => getApiFeatureFlags(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiFeatureFlagsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFeatureFlags>>>
export type GetApiFeatureFlagsQueryError = ErrorType<FeatureFlagErrorSchema>


export function useGetApiFeatureFlags<TData = Awaited<ReturnType<typeof getApiFeatureFlags>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params: undefined |  GetApiFeatureFlagsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiFeatureFlags>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiFeatureFlags<TData = Awaited<ReturnType<typeof getApiFeatureFlags>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiFeatureFlags>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiFeatureFlags<TData = Awaited<ReturnType<typeof getApiFeatureFlags>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the list of feature flags
 */

export function useGetApiFeatureFlags<TData = Awaited<ReturnType<typeof getApiFeatureFlags>>, TError = ErrorType<FeatureFlagErrorSchema>>(
 params?: GetApiFeatureFlagsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiFeatureFlags>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiFeatureFlagsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a feedback
 */
export const postApiFeedbacks = (
    postApiFeedbacksBody: PostApiFeedbacksBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<FeedbackSchema>(
      {url: `/api/feedbacks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiFeedbacksBody
    },
      options);
    }
  


export const getPostApiFeedbacksMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiFeedbacks>>, TError,{data: PostApiFeedbacksBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiFeedbacks>>, TError,{data: PostApiFeedbacksBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiFeedbacks>>, {data: PostApiFeedbacksBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiFeedbacks(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiFeedbacksMutationResult = NonNullable<Awaited<ReturnType<typeof postApiFeedbacks>>>
    export type PostApiFeedbacksMutationBody = PostApiFeedbacksBody
    export type PostApiFeedbacksMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Creates a feedback
 */
export const usePostApiFeedbacks = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiFeedbacks>>, TError,{data: PostApiFeedbacksBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiFeedbacks>>,
        TError,
        {data: PostApiFeedbacksBody},
        TContext
      > => {

      const mutationOptions = getPostApiFeedbacksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates an issue assignment
 */
export const postApiProjectsProjectIdIssuesIssueIdAssignments = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdAssignmentsBody: PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueAssignmentSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/assignments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdAssignmentsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignments>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdAssignments(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignments>>>
    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationBody = PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody
    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates an issue assignment
 */
export const usePostApiProjectsProjectIdIssuesIssueIdAssignments = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignments>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Accepts issue assignment
 */
export const postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept = (
    projectId: string,
    issueId: string,
    issueAssignmentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueAssignmentSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/assignments/${issueAssignmentId}/accept`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept>>, TError,{projectId: string;issueId: string;issueAssignmentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept>>, TError,{projectId: string;issueId: string;issueAssignmentId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept>>, {projectId: string;issueId: string;issueAssignmentId: string}> = (props) => {
          const {projectId,issueId,issueAssignmentId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept(projectId,issueId,issueAssignmentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Accepts issue assignment
 */
export const usePostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept>>, TError,{projectId: string;issueId: string;issueAssignmentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept>>,
        TError,
        {projectId: string;issueId: string;issueAssignmentId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rejects issue assignment
 */
export const postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject = (
    projectId: string,
    issueId: string,
    issueAssignmentId: string,
    postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueAssignmentSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/assignments/${issueAssignmentId}/reject`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject>>, TError,{projectId: string;issueId: string;issueAssignmentId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject>>, TError,{projectId: string;issueId: string;issueAssignmentId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject>>, {projectId: string;issueId: string;issueAssignmentId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody}> = (props) => {
          const {projectId,issueId,issueAssignmentId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject(projectId,issueId,issueAssignmentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject>>>
    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationBody = PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody
    export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Rejects issue assignment
 */
export const usePostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject>>, TError,{projectId: string;issueId: string;issueAssignmentId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject>>,
        TError,
        {projectId: string;issueId: string;issueAssignmentId: string;data: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Removes a document from an issue
 */
export const deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId = (
    projectId: string,
    issueId: string,
    documentReferenceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issues/${issueId}/document_references/${documentReferenceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;issueId: string;documentReferenceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;issueId: string;documentReferenceId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId>>, {projectId: string;issueId: string;documentReferenceId: string}> = (props) => {
          const {projectId,issueId,documentReferenceId} = props ?? {};

          return  deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId(projectId,issueId,documentReferenceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId>>>
    
    export type DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Removes a document from an issue
 */
export const useDeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;issueId: string;documentReferenceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId>>,
        TError,
        {projectId: string;issueId: string;documentReferenceId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves issue documents
 */
export const getApiProjectsProjectIdIssuesIssueIdDocuments = (
    projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DocumentReferenceAndDocumentListSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/documents`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey = (projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams,) => {
    return [`/api/projects/${projectId}/issues/${issueId}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdDocumentsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdDocuments(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdDocumentsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>>
export type GetApiProjectsProjectIdIssuesIssueIdDocumentsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue documents
 */

export function useGetApiProjectsProjectIdIssuesIssueIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdDocumentsInfiniteQueryOptions(projectId,issueId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdDocuments(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>>
export type GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue documents
 */

export function useGetApiProjectsProjectIdIssuesIssueIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdDocumentsQueryOptions(projectId,issueId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Adds a document to an issue (from an upload or an existing document)
 */
export const postApiProjectsProjectIdIssuesIssueIdDocuments = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdDocumentsBody: PostApiProjectsProjectIdIssuesIssueIdDocumentsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DocumentReferenceAndDocumentSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/documents`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdDocumentsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdDocumentsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdDocuments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdDocumentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdDocuments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdDocumentsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdDocuments>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdDocumentsBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdDocuments(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdDocuments>>>
    export type PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationBody = PostApiProjectsProjectIdIssuesIssueIdDocumentsBody
    export type PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema>

    /**
 * @summary Adds a document to an issue (from an upload or an existing document)
 */
export const usePostApiProjectsProjectIdIssuesIssueIdDocuments = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdDocuments>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdDocumentsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdDocuments>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdDocumentsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves team only issue events
 */
export const getApiProjectsProjectIdIssuesIssueIdFeedTeam = (
    projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueFeedSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/feed/team`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey = (projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams,) => {
    return [`/api/projects/${projectId}/issues/${issueId}/feed/team`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdFeedTeam(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdFeedTeamInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>>
export type GetApiProjectsProjectIdIssuesIssueIdFeedTeamInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team only issue events
 */

export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfiniteQueryOptions(projectId,issueId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdFeedTeam(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>>
export type GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeam<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeam<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeam<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team only issue events
 */

export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeam<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedTeam>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryOptions(projectId,issueId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves public issue events
 */
export const getApiProjectsProjectIdIssuesIssueIdFeedPublic = (
    projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueFeedSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/feed/public`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey = (projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams,) => {
    return [`/api/projects/${projectId}/issues/${issueId}/feed/public`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdFeedPublic(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdFeedPublicInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>>
export type GetApiProjectsProjectIdIssuesIssueIdFeedPublicInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves public issue events
 */

export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfiniteQueryOptions(projectId,issueId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdFeedPublic(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>>
export type GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves public issue events
 */

export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdFeedPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryOptions(projectId,issueId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Deprecated: use /api/projects/{project_id}/issues/{issue_id}/documents
 * @deprecated
 * @summary Retrieves issue images and evidence images
 */
export const getApiProjectsProjectIdIssuesIssueIdIssueImages = (
    projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueImageListSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/issue_images`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey = (projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams,) => {
    return [`/api/projects/${projectId}/issues/${issueId}/issue_images`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdIssueImagesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdIssueImages(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdIssueImagesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>>
export type GetApiProjectsProjectIdIssuesIssueIdIssueImagesInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdIssueImagesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdIssueImagesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdIssueImagesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @deprecated
 * @summary Retrieves issue images and evidence images
 */

export function useGetApiProjectsProjectIdIssuesIssueIdIssueImagesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdIssueImagesInfiniteQueryOptions(projectId,issueId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey(projectId,issueId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdIssueImages(projectId,issueId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>>
export type GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdIssueImages<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdIssueImages<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdIssueImages<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @deprecated
 * @summary Retrieves issue images and evidence images
 */

export function useGetApiProjectsProjectIdIssuesIssueIdIssueImages<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string,
    params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions(projectId,issueId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Deprecated: use /api/projects/{project_id}/issues/{issue_id}/documents
 * @deprecated
 * @summary Uploads an image (from an upload or an existing document)
 */
export const postApiProjectsProjectIdIssuesIssueIdIssueImages = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdIssueImagesBody: PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueImageSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/issue_images`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdIssueImagesBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdIssueImages>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdIssueImages(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdIssueImages>>>
    export type PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationBody = PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody
    export type PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema>

    /**
 * @deprecated
 * @summary Uploads an image (from an upload or an existing document)
 */
export const usePostApiProjectsProjectIdIssuesIssueIdIssueImages = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdIssueImages>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdIssueImages>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdIssueImagesBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Deprecated: use /api/projects/{project_id}/issues/{issue_id}/document_references/{document_reference_id}
 * @deprecated
 * @summary Removes an image from an issue
 */
export const deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId = (
    projectId: string,
    issueId: string,
    issueImageId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issues/${issueId}/issue_images/${issueImageId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, TError,{projectId: string;issueId: string;issueImageId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, TError,{projectId: string;issueId: string;issueImageId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, {projectId: string;issueId: string;issueImageId: string}> = (props) => {
          const {projectId,issueId,issueImageId} = props ?? {};

          return  deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(projectId,issueId,issueImageId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>>
    
    export type DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @deprecated
 * @summary Removes an image from an issue
 */
export const useDeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, TError,{projectId: string;issueId: string;issueImageId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>,
        TError,
        {projectId: string;issueId: string;issueImageId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Deprecated: use /api/projects/{project_id}/documents/{document_id}
 * @deprecated
 * @summary Updates an image
 */
export const patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId = (
    projectId: string,
    issueId: string,
    issueImageId: string,
    patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueImageSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/issue_images/${issueImageId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, TError,{projectId: string;issueId: string;issueImageId: string;data: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, TError,{projectId: string;issueId: string;issueImageId: string;data: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, {projectId: string;issueId: string;issueImageId: string;data: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody}> = (props) => {
          const {projectId,issueId,issueImageId,data} = props ?? {};

          return  patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(projectId,issueId,issueImageId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>>
    export type PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationBody = PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody
    export type PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @deprecated
 * @summary Updates an image
 */
export const usePatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>, TError,{projectId: string;issueId: string;issueImageId: string;data: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId>>,
        TError,
        {projectId: string;issueId: string;issueImageId: string;data: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Approves an issue
 */
export const postApiProjectsProjectIdIssuesIssueIdApprove = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/approve`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdApproveMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdApprove>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdApprove>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdApprove>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdApprove(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdApproveMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdApprove>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdApproveMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Approves an issue
 */
export const usePostApiProjectsProjectIdIssuesIssueIdApprove = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdApprove>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdApprove>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdApproveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates an issue to completed
 */
export const postApiProjectsProjectIdIssuesIssueIdComplete = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/complete`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdCompleteMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComplete>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComplete>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComplete>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdComplete(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdCompleteMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComplete>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdCompleteMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Updates an issue to completed
 */
export const usePostApiProjectsProjectIdIssuesIssueIdComplete = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComplete>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdComplete>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdCompleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rejects an issue
 */
export const postApiProjectsProjectIdIssuesIssueIdReject = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdRejectBody: PostApiProjectsProjectIdIssuesIssueIdRejectBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/reject`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdRejectBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdRejectMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReject>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdRejectBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReject>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdRejectBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReject>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdRejectBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdReject(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdRejectMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReject>>>
    export type PostApiProjectsProjectIdIssuesIssueIdRejectMutationBody = PostApiProjectsProjectIdIssuesIssueIdRejectBody
    export type PostApiProjectsProjectIdIssuesIssueIdRejectMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Rejects an issue
 */
export const usePostApiProjectsProjectIdIssuesIssueIdReject = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReject>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdRejectBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReject>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdRejectBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdRejectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Reopens an resolved issue
 */
export const postApiProjectsProjectIdIssuesIssueIdReopen = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/reopen`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdReopenMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReopen>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReopen>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReopen>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdReopen(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdReopenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReopen>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdReopenMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Reopens an resolved issue
 */
export const usePostApiProjectsProjectIdIssuesIssueIdReopen = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReopen>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdReopen>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdReopenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates an issue to in_progress
 */
export const postApiProjectsProjectIdIssuesIssueIdStart = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/start`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdStartMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStart>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStart>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStart>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdStart(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdStartMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStart>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdStartMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Updates an issue to in_progress
 */
export const usePostApiProjectsProjectIdIssuesIssueIdStart = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStart>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStart>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdStartMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates an issue to assigned
 */
export const postApiProjectsProjectIdIssuesIssueIdStop = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/stop`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdStopMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStop>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStop>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStop>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdStop(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdStopMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStop>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdStopMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Updates an issue to assigned
 */
export const usePostApiProjectsProjectIdIssuesIssueIdStop = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStop>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStop>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdStopMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Submits a draft issue
 */
export const postApiProjectsProjectIdIssuesIssueIdSubmit = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/submit`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdSubmitMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdSubmit>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdSubmit>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdSubmit>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdSubmit(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdSubmitMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdSubmit>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdSubmitMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Submits a draft issue
 */
export const usePostApiProjectsProjectIdIssuesIssueIdSubmit = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdSubmit>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdSubmit>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdSubmitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates an issue status statement
 */
export const postApiProjectsProjectIdIssuesIssueIdStatusStatements = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdStatusStatementsBody: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueStatusStatementSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/status_statements`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdStatusStatementsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStatusStatements>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdStatusStatements(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStatusStatements>>>
    export type PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationBody = PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody
    export type PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates an issue status statement
 */
export const usePostApiProjectsProjectIdIssuesIssueIdStatusStatements = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdStatusStatements>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves issue status statements
 */
export const getApiProjectsProjectIdIssuesIssueIdStatusStatements = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueStatusStatementListSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/status_statements`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey = (projectId: string,
    issueId: string,) => {
    return [`/api/projects/${projectId}/issues/${issueId}/status_statements`] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdStatusStatements(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>>
export type GetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue status statements
 */

export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdStatusStatementsInfiniteQueryOptions(projectId,issueId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdStatusStatements(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>>
export type GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatements<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatements<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatements<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue status statements
 */

export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatements<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdStatusStatements>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryOptions(projectId,issueId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Deletes an issue status statement
 */
export const deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId = (
    projectId: string,
    issueId: string,
    statusStatementId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issues/${issueId}/status_statements/${statusStatementId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId>>, TError,{projectId: string;issueId: string;statusStatementId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId>>, TError,{projectId: string;issueId: string;statusStatementId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId>>, {projectId: string;issueId: string;statusStatementId: string}> = (props) => {
          const {projectId,issueId,statusStatementId} = props ?? {};

          return  deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId(projectId,issueId,statusStatementId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId>>>
    
    export type DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes an issue status statement
 */
export const useDeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId>>, TError,{projectId: string;issueId: string;statusStatementId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId>>,
        TError,
        {projectId: string;issueId: string;statusStatementId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves issue views
 */
export const getApiProjectsProjectIdIssueViews = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueViewListSchema>(
      {url: `/api/projects/${projectId}/issue_views`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssueViewsQueryKey = (projectId: string,) => {
    return [`/api/projects/${projectId}/issue_views`] as const;
    }

    
export const getGetApiProjectsProjectIdIssueViewsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssueViewsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>> = ({ signal }) => getApiProjectsProjectIdIssueViews(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssueViewsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>>
export type GetApiProjectsProjectIdIssueViewsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssueViewsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssueViewsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssueViewsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue views
 */

export function useGetApiProjectsProjectIdIssueViewsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssueViewsInfiniteQueryOptions(projectId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssueViewsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssueViewsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>> = ({ signal }) => getApiProjectsProjectIdIssueViews(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssueViewsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>>
export type GetApiProjectsProjectIdIssueViewsQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssueViews<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssueViews<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssueViews<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue views
 */

export function useGetApiProjectsProjectIdIssueViews<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssueViews>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssueViewsQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates issue view
 */
export const postApiProjectsProjectIdIssueViews = (
    projectId: string,
    postApiProjectsProjectIdIssueViewsBody: PostApiProjectsProjectIdIssueViewsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueViewSchema>(
      {url: `/api/projects/${projectId}/issue_views`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssueViewsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssueViewsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssueViews>>, TError,{projectId: string;data: PostApiProjectsProjectIdIssueViewsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssueViews>>, TError,{projectId: string;data: PostApiProjectsProjectIdIssueViewsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssueViews>>, {projectId: string;data: PostApiProjectsProjectIdIssueViewsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdIssueViews(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssueViewsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssueViews>>>
    export type PostApiProjectsProjectIdIssueViewsMutationBody = PostApiProjectsProjectIdIssueViewsBody
    export type PostApiProjectsProjectIdIssueViewsMutationError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>

    /**
 * @summary Creates issue view
 */
export const usePostApiProjectsProjectIdIssueViews = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssueViews>>, TError,{projectId: string;data: PostApiProjectsProjectIdIssueViewsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssueViews>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdIssueViewsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssueViewsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates issue view
 */
export const patchApiProjectsProjectIdIssueViewsIssueViewId = (
    projectId: string,
    issueViewId: string,
    patchApiProjectsProjectIdIssueViewsIssueViewIdBody: PatchApiProjectsProjectIdIssueViewsIssueViewIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueViewSchema>(
      {url: `/api/projects/${projectId}/issue_views/${issueViewId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdIssueViewsIssueViewIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdIssueViewsIssueViewIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssueViewsIssueViewId>>, TError,{projectId: string;issueViewId: string;data: PatchApiProjectsProjectIdIssueViewsIssueViewIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssueViewsIssueViewId>>, TError,{projectId: string;issueViewId: string;data: PatchApiProjectsProjectIdIssueViewsIssueViewIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssueViewsIssueViewId>>, {projectId: string;issueViewId: string;data: PatchApiProjectsProjectIdIssueViewsIssueViewIdBody}> = (props) => {
          const {projectId,issueViewId,data} = props ?? {};

          return  patchApiProjectsProjectIdIssueViewsIssueViewId(projectId,issueViewId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssueViewsIssueViewId>>>
    export type PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationBody = PatchApiProjectsProjectIdIssueViewsIssueViewIdBody
    export type PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>

    /**
 * @summary Updates issue view
 */
export const usePatchApiProjectsProjectIdIssueViewsIssueViewId = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssueViewsIssueViewId>>, TError,{projectId: string;issueViewId: string;data: PatchApiProjectsProjectIdIssueViewsIssueViewIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdIssueViewsIssueViewId>>,
        TError,
        {projectId: string;issueViewId: string;data: PatchApiProjectsProjectIdIssueViewsIssueViewIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdIssueViewsIssueViewIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes an issue view
 */
export const deleteApiProjectsProjectIdIssueViewsIssueViewId = (
    projectId: string,
    issueViewId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issue_views/${issueViewId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssueViewsIssueViewId>>, TError,{projectId: string;issueViewId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssueViewsIssueViewId>>, TError,{projectId: string;issueViewId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssueViewsIssueViewId>>, {projectId: string;issueViewId: string}> = (props) => {
          const {projectId,issueViewId} = props ?? {};

          return  deleteApiProjectsProjectIdIssueViewsIssueViewId(projectId,issueViewId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssueViewsIssueViewId>>>
    
    export type DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes an issue view
 */
export const useDeleteApiProjectsProjectIdIssueViewsIssueViewId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssueViewsIssueViewId>>, TError,{projectId: string;issueViewId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssueViewsIssueViewId>>,
        TError,
        {projectId: string;issueViewId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieve issue visit status
 */
export const getApiProjectsProjectIdIssuesIssueIdVisit = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueVisitSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/visit`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdVisitQueryKey = (projectId: string,
    issueId: string,) => {
    return [`/api/projects/${projectId}/issues/${issueId}/visit`] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdVisitInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdVisitQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdVisit(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdVisitInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>>
export type GetApiProjectsProjectIdIssuesIssueIdVisitInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdVisitInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdVisitInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdVisitInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieve issue visit status
 */

export function useGetApiProjectsProjectIdIssuesIssueIdVisitInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdVisitInfiniteQueryOptions(projectId,issueId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdVisitQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdVisitQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdVisit(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdVisitQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>>
export type GetApiProjectsProjectIdIssuesIssueIdVisitQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdVisit<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdVisit<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdVisit<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieve issue visit status
 */

export function useGetApiProjectsProjectIdIssuesIssueIdVisit<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdVisit>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdVisitQueryOptions(projectId,issueId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Saves issue visit
 */
export const postApiProjectsProjectIdIssuesIssueIdVisit = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueVisitSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/visit`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdVisitMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdVisit>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdVisit>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdVisit>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdVisit(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdVisitMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdVisit>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdVisitMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Saves issue visit
 */
export const usePostApiProjectsProjectIdIssuesIssueIdVisit = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdVisit>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdVisit>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdVisitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates an issue
 */
export const postApiProjectsProjectIdIssues = (
    projectId: string,
    postApiProjectsProjectIdIssuesBody: PostApiProjectsProjectIdIssuesBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssues>>, TError,{projectId: string;data: PostApiProjectsProjectIdIssuesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssues>>, TError,{projectId: string;data: PostApiProjectsProjectIdIssuesBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssues>>, {projectId: string;data: PostApiProjectsProjectIdIssuesBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdIssues(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssues>>>
    export type PostApiProjectsProjectIdIssuesMutationBody = PostApiProjectsProjectIdIssuesBody
    export type PostApiProjectsProjectIdIssuesMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates an issue
 */
export const usePostApiProjectsProjectIdIssues = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssues>>, TError,{projectId: string;data: PostApiProjectsProjectIdIssuesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssues>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdIssuesBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves issues
 */
export const getApiProjectsProjectIdIssues = (
    projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueListSchema>(
      {url: `/api/projects/${projectId}/issues`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams,) => {
    return [`/api/projects/${projectId}/issues`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>> = ({ signal }) => getApiProjectsProjectIdIssues(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>>
export type GetApiProjectsProjectIdIssuesInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues
 */

export function useGetApiProjectsProjectIdIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>> = ({ signal }) => getApiProjectsProjectIdIssues(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>>
export type GetApiProjectsProjectIdIssuesQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues
 */

export function useGetApiProjectsProjectIdIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves issues group count
 */
export const getApiProjectsProjectIdIssuesGroupCount = (
    projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueGroupCountSchema>(
      {url: `/api/projects/${projectId}/issues/group_count`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesGroupCountQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams,) => {
    return [`/api/projects/${projectId}/issues/group_count`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesGroupCountInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesGroupCountQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>> = ({ signal }) => getApiProjectsProjectIdIssuesGroupCount(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesGroupCountInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>>
export type GetApiProjectsProjectIdIssuesGroupCountInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesGroupCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesGroupCountParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesGroupCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesGroupCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues group count
 */

export function useGetApiProjectsProjectIdIssuesGroupCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesGroupCountInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesGroupCountQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesGroupCountQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>> = ({ signal }) => getApiProjectsProjectIdIssuesGroupCount(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesGroupCountQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>>
export type GetApiProjectsProjectIdIssuesGroupCountQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesGroupCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdIssuesGroupCountParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesGroupCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesGroupCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues group count
 */

export function useGetApiProjectsProjectIdIssuesGroupCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdIssuesGroupCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesGroupCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesGroupCountQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Requests issue export
 */
export const postApiProjectsProjectIdIssuesExport = (
    projectId: string,
    params: PostApiProjectsProjectIdIssuesExportParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issues/export`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesExportMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesExport>>, TError,{projectId: string;params: PostApiProjectsProjectIdIssuesExportParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesExport>>, TError,{projectId: string;params: PostApiProjectsProjectIdIssuesExportParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesExport>>, {projectId: string;params: PostApiProjectsProjectIdIssuesExportParams}> = (props) => {
          const {projectId,params} = props ?? {};

          return  postApiProjectsProjectIdIssuesExport(projectId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesExportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesExport>>>
    
    export type PostApiProjectsProjectIdIssuesExportMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Requests issue export
 */
export const usePostApiProjectsProjectIdIssuesExport = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesExport>>, TError,{projectId: string;params: PostApiProjectsProjectIdIssuesExportParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesExport>>,
        TError,
        {projectId: string;params: PostApiProjectsProjectIdIssuesExportParams},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves an issue
 */
export const getApiProjectsProjectIdIssuesIssueId = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdQueryKey = (projectId: string,
    issueId: string,) => {
    return [`/api/projects/${projectId}/issues/${issueId}`] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueId(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>>
export type GetApiProjectsProjectIdIssuesIssueIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves an issue
 */

export function useGetApiProjectsProjectIdIssuesIssueIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdInfiniteQueryOptions(projectId,issueId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueId(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>>
export type GetApiProjectsProjectIdIssuesIssueIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves an issue
 */

export function useGetApiProjectsProjectIdIssuesIssueId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdQueryOptions(projectId,issueId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates an issue
 */
export const patchApiProjectsProjectIdIssuesIssueId = (
    projectId: string,
    issueId: string,
    patchApiProjectsProjectIdIssuesIssueIdBody: PatchApiProjectsProjectIdIssuesIssueIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdIssuesIssueIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdIssuesIssueIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueId>>, TError,{projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueId>>, TError,{projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueId>>, {projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  patchApiProjectsProjectIdIssuesIssueId(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdIssuesIssueIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueId>>>
    export type PatchApiProjectsProjectIdIssuesIssueIdMutationBody = PatchApiProjectsProjectIdIssuesIssueIdBody
    export type PatchApiProjectsProjectIdIssuesIssueIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates an issue
 */
export const usePatchApiProjectsProjectIdIssuesIssueId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueId>>, TError,{projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdIssuesIssueId>>,
        TError,
        {projectId: string;issueId: string;data: PatchApiProjectsProjectIdIssuesIssueIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdIssuesIssueIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes an issue
 */
export const deleteApiProjectsProjectIdIssuesIssueId = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issues/${issueId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdIssuesIssueIdMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueId>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueId>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueId>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  deleteApiProjectsProjectIdIssuesIssueId(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdIssuesIssueIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueId>>>
    
    export type DeleteApiProjectsProjectIdIssuesIssueIdMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Deletes an issue
 */
export const useDeleteApiProjectsProjectIdIssuesIssueId = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueId>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueId>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdIssuesIssueIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Archives an issue
 */
export const postApiProjectsProjectIdIssuesIssueIdArchive = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdArchiveBody: PostApiProjectsProjectIdIssuesIssueIdArchiveBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/archive`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdArchiveBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdArchiveMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdArchive>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdArchiveBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdArchive>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdArchiveBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdArchive>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdArchiveBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdArchive(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdArchive>>>
    export type PostApiProjectsProjectIdIssuesIssueIdArchiveMutationBody = PostApiProjectsProjectIdIssuesIssueIdArchiveBody
    export type PostApiProjectsProjectIdIssuesIssueIdArchiveMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Archives an issue
 */
export const usePostApiProjectsProjectIdIssuesIssueIdArchive = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdArchive>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdArchiveBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdArchive>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdArchiveBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdArchiveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Requests issue export
 */
export const postApiProjectsProjectIdIssuesIssueIdExport = (
    projectId: string,
    issueId: string,
    printingPreferencesBodyParameterSchema: PrintingPreferencesBodyParameterSchema,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/issues/${issueId}/export`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: printingPreferencesBodyParameterSchema
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdExportMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdExport>>, TError,{projectId: string;issueId: string;data: PrintingPreferencesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdExport>>, TError,{projectId: string;issueId: string;data: PrintingPreferencesBodyParameterSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdExport>>, {projectId: string;issueId: string;data: PrintingPreferencesBodyParameterSchema}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdExport(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdExportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdExport>>>
    export type PostApiProjectsProjectIdIssuesIssueIdExportMutationBody = PrintingPreferencesBodyParameterSchema
    export type PostApiProjectsProjectIdIssuesIssueIdExportMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Requests issue export
 */
export const usePostApiProjectsProjectIdIssuesIssueIdExport = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdExport>>, TError,{projectId: string;issueId: string;data: PrintingPreferencesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdExport>>,
        TError,
        {projectId: string;issueId: string;data: PrintingPreferencesBodyParameterSchema},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Restores an archived issue
 */
export const postApiProjectsProjectIdIssuesIssueIdRestore = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/restore`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdRestoreMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdRestore>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdRestore>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdRestore>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdRestore(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdRestoreMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdRestore>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdRestoreMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Restores an archived issue
 */
export const usePostApiProjectsProjectIdIssuesIssueIdRestore = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdRestore>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdRestore>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdRestoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates impact
 */
export const postApiProjectsProjectIdIssuesIssueIdUpdateImpact = (
    projectId: string,
    issueId: string,
    postApiProjectsProjectIdIssuesIssueIdUpdateImpactBody: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IssueSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/update_impact`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdIssuesIssueIdUpdateImpactBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdUpdateImpact>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdUpdateImpact>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdUpdateImpact>>, {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody}> = (props) => {
          const {projectId,issueId,data} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdUpdateImpact(projectId,issueId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdUpdateImpact>>>
    export type PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationBody = PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody
    export type PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates impact
 */
export const usePostApiProjectsProjectIdIssuesIssueIdUpdateImpact = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdUpdateImpact>>, TError,{projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdUpdateImpact>>,
        TError,
        {projectId: string;issueId: string;data: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves issues staleness count
 */
export const getApiProjectsProjectIdDashboardsIssuesStalenessCount = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DashboardIssuesStalenessesCountSchema>(
      {url: `/api/projects/${projectId}/dashboards/issues_staleness/count`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey = (projectId: string,) => {
    return [`/api/projects/${projectId}/dashboards/issues_staleness/count`] as const;
    }

    
export const getGetApiProjectsProjectIdDashboardsIssuesStalenessCountInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>> = ({ signal }) => getApiProjectsProjectIdDashboardsIssuesStalenessCount(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDashboardsIssuesStalenessCountInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>>
export type GetApiProjectsProjectIdDashboardsIssuesStalenessCountInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues staleness count
 */

export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCountInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDashboardsIssuesStalenessCountInfiniteQueryOptions(projectId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>> = ({ signal }) => getApiProjectsProjectIdDashboardsIssuesStalenessCount(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>>
export type GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues staleness count
 */

export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCount<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves issues list
 */
export const getApiProjectsProjectIdDashboardsIssuesStalenessIssues = (
    projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IssueListSchema>(
      {url: `/api/projects/${projectId}/dashboards/issues_staleness/issues`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams,) => {
    return [`/api/projects/${projectId}/dashboards/issues_staleness/issues`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>> = ({ signal }) => getApiProjectsProjectIdDashboardsIssuesStalenessIssues(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>>
export type GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues list
 */

export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>> = ({ signal }) => getApiProjectsProjectIdDashboardsIssuesStalenessIssues(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>>
export type GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issues list
 */

export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssues<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssues>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves locations
 */
export const getApiProjectsProjectIdLocations = (
    projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<LocationListSchema>(
      {url: `/api/projects/${projectId}/locations`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdLocationsQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams,) => {
    return [`/api/projects/${projectId}/locations`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdLocationsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdLocationsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>> = ({ signal }) => getApiProjectsProjectIdLocations(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdLocationsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>>
export type GetApiProjectsProjectIdLocationsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdLocationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdLocationsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves locations
 */

export function useGetApiProjectsProjectIdLocationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdLocationsInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdLocationsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdLocationsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>> = ({ signal }) => getApiProjectsProjectIdLocations(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdLocationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>>
export type GetApiProjectsProjectIdLocationsQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdLocations<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdLocationsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocations<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocations<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves locations
 */

export function useGetApiProjectsProjectIdLocations<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocations>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdLocationsQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a location
 */
export const postApiProjectsProjectIdLocations = (
    projectId: string,
    postApiProjectsProjectIdLocationsBody: PostApiProjectsProjectIdLocationsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<LocationSchema>(
      {url: `/api/projects/${projectId}/locations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdLocationsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdLocationsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdLocations>>, TError,{projectId: string;data: PostApiProjectsProjectIdLocationsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdLocations>>, TError,{projectId: string;data: PostApiProjectsProjectIdLocationsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdLocations>>, {projectId: string;data: PostApiProjectsProjectIdLocationsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdLocations(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdLocationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdLocations>>>
    export type PostApiProjectsProjectIdLocationsMutationBody = PostApiProjectsProjectIdLocationsBody
    export type PostApiProjectsProjectIdLocationsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a location
 */
export const usePostApiProjectsProjectIdLocations = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdLocations>>, TError,{projectId: string;data: PostApiProjectsProjectIdLocationsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdLocations>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdLocationsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdLocationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates a location
 */
export const patchApiProjectsProjectIdLocationsLocationId = (
    projectId: string,
    locationId: string,
    patchApiProjectsProjectIdLocationsLocationIdBody: PatchApiProjectsProjectIdLocationsLocationIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<LocationSchema>(
      {url: `/api/projects/${projectId}/locations/${locationId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdLocationsLocationIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdLocationsLocationIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdLocationsLocationId>>, TError,{projectId: string;locationId: string;data: PatchApiProjectsProjectIdLocationsLocationIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdLocationsLocationId>>, TError,{projectId: string;locationId: string;data: PatchApiProjectsProjectIdLocationsLocationIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdLocationsLocationId>>, {projectId: string;locationId: string;data: PatchApiProjectsProjectIdLocationsLocationIdBody}> = (props) => {
          const {projectId,locationId,data} = props ?? {};

          return  patchApiProjectsProjectIdLocationsLocationId(projectId,locationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdLocationsLocationIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdLocationsLocationId>>>
    export type PatchApiProjectsProjectIdLocationsLocationIdMutationBody = PatchApiProjectsProjectIdLocationsLocationIdBody
    export type PatchApiProjectsProjectIdLocationsLocationIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a location
 */
export const usePatchApiProjectsProjectIdLocationsLocationId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdLocationsLocationId>>, TError,{projectId: string;locationId: string;data: PatchApiProjectsProjectIdLocationsLocationIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdLocationsLocationId>>,
        TError,
        {projectId: string;locationId: string;data: PatchApiProjectsProjectIdLocationsLocationIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdLocationsLocationIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a location
 */
export const getApiProjectsProjectIdLocationsLocationId = (
    projectId: string,
    locationId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<LocationSchema>(
      {url: `/api/projects/${projectId}/locations/${locationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdLocationsLocationIdQueryKey = (projectId: string,
    locationId: string,) => {
    return [`/api/projects/${projectId}/locations/${locationId}`] as const;
    }

    
export const getGetApiProjectsProjectIdLocationsLocationIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    locationId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdLocationsLocationIdQueryKey(projectId,locationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>> = ({ signal }) => getApiProjectsProjectIdLocationsLocationId(projectId,locationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && locationId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdLocationsLocationIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>>
export type GetApiProjectsProjectIdLocationsLocationIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdLocationsLocationIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocationsLocationIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocationsLocationIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a location
 */

export function useGetApiProjectsProjectIdLocationsLocationIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdLocationsLocationIdInfiniteQueryOptions(projectId,locationId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdLocationsLocationIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdLocationsLocationIdQueryKey(projectId,locationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>> = ({ signal }) => getApiProjectsProjectIdLocationsLocationId(projectId,locationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && locationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdLocationsLocationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>>
export type GetApiProjectsProjectIdLocationsLocationIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdLocationsLocationId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocationsLocationId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdLocationsLocationId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a location
 */

export function useGetApiProjectsProjectIdLocationsLocationId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdLocationsLocationId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdLocationsLocationIdQueryOptions(projectId,locationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Deletes a location
 */
export const deleteApiProjectsProjectIdLocationsLocationId = (
    projectId: string,
    locationId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/locations/${locationId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdLocationsLocationIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdLocationsLocationId>>, TError,{projectId: string;locationId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdLocationsLocationId>>, TError,{projectId: string;locationId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdLocationsLocationId>>, {projectId: string;locationId: string}> = (props) => {
          const {projectId,locationId} = props ?? {};

          return  deleteApiProjectsProjectIdLocationsLocationId(projectId,locationId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdLocationsLocationIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdLocationsLocationId>>>
    
    export type DeleteApiProjectsProjectIdLocationsLocationIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a location
 */
export const useDeleteApiProjectsProjectIdLocationsLocationId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdLocationsLocationId>>, TError,{projectId: string;locationId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdLocationsLocationId>>,
        TError,
        {projectId: string;locationId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdLocationsLocationIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Sorts locations
 */
export const postApiProjectsProjectIdLocationsLocationIdSort = (
    projectId: string,
    locationId: string,
    postApiProjectsProjectIdLocationsLocationIdSortBody: PostApiProjectsProjectIdLocationsLocationIdSortBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/locations/${locationId}/sort`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdLocationsLocationIdSortBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdLocationsLocationIdSortMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdLocationsLocationIdSort>>, TError,{projectId: string;locationId: string;data: PostApiProjectsProjectIdLocationsLocationIdSortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdLocationsLocationIdSort>>, TError,{projectId: string;locationId: string;data: PostApiProjectsProjectIdLocationsLocationIdSortBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdLocationsLocationIdSort>>, {projectId: string;locationId: string;data: PostApiProjectsProjectIdLocationsLocationIdSortBody}> = (props) => {
          const {projectId,locationId,data} = props ?? {};

          return  postApiProjectsProjectIdLocationsLocationIdSort(projectId,locationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdLocationsLocationIdSortMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdLocationsLocationIdSort>>>
    export type PostApiProjectsProjectIdLocationsLocationIdSortMutationBody = PostApiProjectsProjectIdLocationsLocationIdSortBody
    export type PostApiProjectsProjectIdLocationsLocationIdSortMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Sorts locations
 */
export const usePostApiProjectsProjectIdLocationsLocationIdSort = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdLocationsLocationIdSort>>, TError,{projectId: string;locationId: string;data: PostApiProjectsProjectIdLocationsLocationIdSortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdLocationsLocationIdSort>>,
        TError,
        {projectId: string;locationId: string;data: PostApiProjectsProjectIdLocationsLocationIdSortBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdLocationsLocationIdSortMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves notifications
 */
export const getApiNotifications = (
    params?: GetApiNotificationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NotificationListSchema>(
      {url: `/api/notifications`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiNotificationsQueryKey = (params?: GetApiNotificationsParams,) => {
    return [`/api/notifications`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiNotificationsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiNotifications>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(params?: GetApiNotificationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiNotificationsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotifications>>> = ({ signal }) => getApiNotifications(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiNotificationsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotifications>>>
export type GetApiNotificationsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema>


export function useGetApiNotificationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotifications>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params: undefined |  GetApiNotificationsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotifications>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotificationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotifications>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params?: GetApiNotificationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotifications>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotificationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotifications>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params?: GetApiNotificationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves notifications
 */

export function useGetApiNotificationsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotifications>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params?: GetApiNotificationsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiNotificationsInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiNotificationsQueryOptions = <TData = Awaited<ReturnType<typeof getApiNotifications>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(params?: GetApiNotificationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiNotificationsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotifications>>> = ({ signal }) => getApiNotifications(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotifications>>>
export type GetApiNotificationsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema>


export function useGetApiNotifications<TData = Awaited<ReturnType<typeof getApiNotifications>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params: undefined |  GetApiNotificationsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotifications>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotifications<TData = Awaited<ReturnType<typeof getApiNotifications>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params?: GetApiNotificationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotifications>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotifications<TData = Awaited<ReturnType<typeof getApiNotifications>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params?: GetApiNotificationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves notifications
 */

export function useGetApiNotifications<TData = Awaited<ReturnType<typeof getApiNotifications>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>>(
 params?: GetApiNotificationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiNotificationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves notifications overview
 */
export const getApiNotificationsOverview = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NotificationsOverviewSchema>(
      {url: `/api/notifications/overview`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiNotificationsOverviewQueryKey = () => {
    return [`/api/notifications/overview`] as const;
    }

    
export const getGetApiNotificationsOverviewInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiNotificationsOverview>>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiNotificationsOverviewQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotificationsOverview>>> = ({ signal }) => getApiNotificationsOverview(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiNotificationsOverviewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotificationsOverview>>>
export type GetApiNotificationsOverviewInfiniteQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiNotificationsOverviewInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotificationsOverview>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotificationsOverview>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotificationsOverviewInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotificationsOverview>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotificationsOverview>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotificationsOverviewInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotificationsOverview>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves notifications overview
 */

export function useGetApiNotificationsOverviewInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiNotificationsOverview>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiNotificationsOverviewInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiNotificationsOverviewQueryOptions = <TData = Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiNotificationsOverviewQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotificationsOverview>>> = ({ signal }) => getApiNotificationsOverview(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiNotificationsOverviewQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotificationsOverview>>>
export type GetApiNotificationsOverviewQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiNotificationsOverview<TData = Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotificationsOverview>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotificationsOverview<TData = Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiNotificationsOverview>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiNotificationsOverview<TData = Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves notifications overview
 */

export function useGetApiNotificationsOverview<TData = Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsOverview>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiNotificationsOverviewQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Marks notification as read
 */
export const postApiNotificationsNotificationIdMarkRead = (
    notificationId: string,
    postApiNotificationsNotificationIdMarkReadBody: PostApiNotificationsNotificationIdMarkReadBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<NotificationsMarkedAsReadSchema>(
      {url: `/api/notifications/${notificationId}/mark_read`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiNotificationsNotificationIdMarkReadBody
    },
      options);
    }
  


export const getPostApiNotificationsNotificationIdMarkReadMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNotificationsNotificationIdMarkRead>>, TError,{notificationId: string;data: PostApiNotificationsNotificationIdMarkReadBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiNotificationsNotificationIdMarkRead>>, TError,{notificationId: string;data: PostApiNotificationsNotificationIdMarkReadBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiNotificationsNotificationIdMarkRead>>, {notificationId: string;data: PostApiNotificationsNotificationIdMarkReadBody}> = (props) => {
          const {notificationId,data} = props ?? {};

          return  postApiNotificationsNotificationIdMarkRead(notificationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiNotificationsNotificationIdMarkReadMutationResult = NonNullable<Awaited<ReturnType<typeof postApiNotificationsNotificationIdMarkRead>>>
    export type PostApiNotificationsNotificationIdMarkReadMutationBody = PostApiNotificationsNotificationIdMarkReadBody
    export type PostApiNotificationsNotificationIdMarkReadMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Marks notification as read
 */
export const usePostApiNotificationsNotificationIdMarkRead = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNotificationsNotificationIdMarkRead>>, TError,{notificationId: string;data: PostApiNotificationsNotificationIdMarkReadBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiNotificationsNotificationIdMarkRead>>,
        TError,
        {notificationId: string;data: PostApiNotificationsNotificationIdMarkReadBody},
        TContext
      > => {

      const mutationOptions = getPostApiNotificationsNotificationIdMarkReadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Marks all notifications as read
 */
export const postApiNotificationsMarkAllRead = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/notifications/mark_all_read`, method: 'POST'
    },
      options);
    }
  


export const getPostApiNotificationsMarkAllReadMutationOptions = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNotificationsMarkAllRead>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiNotificationsMarkAllRead>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiNotificationsMarkAllRead>>, void> = () => {
          

          return  postApiNotificationsMarkAllRead(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiNotificationsMarkAllReadMutationResult = NonNullable<Awaited<ReturnType<typeof postApiNotificationsMarkAllRead>>>
    
    export type PostApiNotificationsMarkAllReadMutationError = ErrorType<AuthenticationErrorSchema>

    /**
 * @summary Marks all notifications as read
 */
export const usePostApiNotificationsMarkAllRead = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNotificationsMarkAllRead>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiNotificationsMarkAllRead>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostApiNotificationsMarkAllReadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieve the current user onboarding data
 */
export const getApiOnboarding = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserOnboardingSchema>(
      {url: `/api/onboarding`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiOnboardingQueryKey = () => {
    return [`/api/onboarding`] as const;
    }

    
export const getGetApiOnboardingInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiOnboarding>>>, TError = ErrorType<AuthenticationErrorSchema | void>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiOnboardingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiOnboarding>>> = ({ signal }) => getApiOnboarding(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiOnboardingInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOnboarding>>>
export type GetApiOnboardingInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiOnboardingInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOnboarding>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOnboarding>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOnboardingInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOnboarding>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOnboarding>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOnboardingInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOnboarding>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieve the current user onboarding data
 */

export function useGetApiOnboardingInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOnboarding>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiOnboardingInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiOnboardingQueryOptions = <TData = Awaited<ReturnType<typeof getApiOnboarding>>, TError = ErrorType<AuthenticationErrorSchema | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiOnboardingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiOnboarding>>> = ({ signal }) => getApiOnboarding(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiOnboardingQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOnboarding>>>
export type GetApiOnboardingQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiOnboarding<TData = Awaited<ReturnType<typeof getApiOnboarding>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOnboarding>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOnboarding<TData = Awaited<ReturnType<typeof getApiOnboarding>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOnboarding>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOnboarding<TData = Awaited<ReturnType<typeof getApiOnboarding>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieve the current user onboarding data
 */

export function useGetApiOnboarding<TData = Awaited<ReturnType<typeof getApiOnboarding>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOnboarding>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiOnboardingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates the current user onboarding
 */
export const patchApiOnboarding = (
    patchApiOnboardingBody: PatchApiOnboardingBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserOnboardingSchema>(
      {url: `/api/onboarding`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiOnboardingBody
    },
      options);
    }
  


export const getPatchApiOnboardingMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiOnboarding>>, TError,{data: PatchApiOnboardingBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiOnboarding>>, TError,{data: PatchApiOnboardingBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiOnboarding>>, {data: PatchApiOnboardingBody}> = (props) => {
          const {data} = props ?? {};

          return  patchApiOnboarding(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiOnboardingMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiOnboarding>>>
    export type PatchApiOnboardingMutationBody = PatchApiOnboardingBody
    export type PatchApiOnboardingMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Updates the current user onboarding
 */
export const usePatchApiOnboarding = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiOnboarding>>, TError,{data: PatchApiOnboardingBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiOnboarding>>,
        TError,
        {data: PatchApiOnboardingBody},
        TContext
      > => {

      const mutationOptions = getPatchApiOnboardingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Finishes onboarding
 */
export const postApiOnboardingFinish = (
    postApiOnboardingFinishBody: PostApiOnboardingFinishBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserOnboardingSchema>(
      {url: `/api/onboarding/finish`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiOnboardingFinishBody
    },
      options);
    }
  


export const getPostApiOnboardingFinishMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOnboardingFinish>>, TError,{data: PostApiOnboardingFinishBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiOnboardingFinish>>, TError,{data: PostApiOnboardingFinishBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiOnboardingFinish>>, {data: PostApiOnboardingFinishBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiOnboardingFinish(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiOnboardingFinishMutationResult = NonNullable<Awaited<ReturnType<typeof postApiOnboardingFinish>>>
    export type PostApiOnboardingFinishMutationBody = PostApiOnboardingFinishBody
    export type PostApiOnboardingFinishMutationError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>

    /**
 * @summary Finishes onboarding
 */
export const usePostApiOnboardingFinish = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOnboardingFinish>>, TError,{data: PostApiOnboardingFinishBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiOnboardingFinish>>,
        TError,
        {data: PostApiOnboardingFinishBody},
        TContext
      > => {

      const mutationOptions = getPostApiOnboardingFinishMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates an org
 */
export const postApiOrgs = (
    postApiOrgsBody: PostApiOrgsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<OrgSchema>(
      {url: `/api/orgs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiOrgsBody
    },
      options);
    }
  


export const getPostApiOrgsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOrgs>>, TError,{data: PostApiOrgsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiOrgs>>, TError,{data: PostApiOrgsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiOrgs>>, {data: PostApiOrgsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiOrgs(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiOrgsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiOrgs>>>
    export type PostApiOrgsMutationBody = PostApiOrgsBody
    export type PostApiOrgsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Creates an org
 */
export const usePostApiOrgs = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOrgs>>, TError,{data: PostApiOrgsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiOrgs>>,
        TError,
        {data: PostApiOrgsBody},
        TContext
      > => {

      const mutationOptions = getPostApiOrgsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves orgs
 */
export const getApiOrgs = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<OrgListSchema>(
      {url: `/api/orgs`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiOrgsQueryKey = () => {
    return [`/api/orgs`] as const;
    }

    
export const getGetApiOrgsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgs>>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiOrgsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiOrgs>>> = ({ signal }) => getApiOrgs(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiOrgsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrgs>>>
export type GetApiOrgsInfiniteQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiOrgsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgs>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgs>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgs>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves orgs
 */

export function useGetApiOrgsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgs>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiOrgsInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiOrgsQueryOptions = <TData = Awaited<ReturnType<typeof getApiOrgs>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiOrgsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiOrgs>>> = ({ signal }) => getApiOrgs(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiOrgsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrgs>>>
export type GetApiOrgsQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiOrgs<TData = Awaited<ReturnType<typeof getApiOrgs>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgs<TData = Awaited<ReturnType<typeof getApiOrgs>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgs<TData = Awaited<ReturnType<typeof getApiOrgs>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves orgs
 */

export function useGetApiOrgs<TData = Awaited<ReturnType<typeof getApiOrgs>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiOrgsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates an org
 */
export const patchApiOrgsOrgId = (
    orgId: string,
    patchApiOrgsOrgIdBody: PatchApiOrgsOrgIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<OrgSchema>(
      {url: `/api/orgs/${orgId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiOrgsOrgIdBody
    },
      options);
    }
  


export const getPatchApiOrgsOrgIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiOrgsOrgId>>, TError,{orgId: string;data: PatchApiOrgsOrgIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiOrgsOrgId>>, TError,{orgId: string;data: PatchApiOrgsOrgIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiOrgsOrgId>>, {orgId: string;data: PatchApiOrgsOrgIdBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  patchApiOrgsOrgId(orgId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiOrgsOrgIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiOrgsOrgId>>>
    export type PatchApiOrgsOrgIdMutationBody = PatchApiOrgsOrgIdBody
    export type PatchApiOrgsOrgIdMutationError = ErrorType<AuthenticationErrorSchema>

    /**
 * @summary Updates an org
 */
export const usePatchApiOrgsOrgId = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiOrgsOrgId>>, TError,{orgId: string;data: PatchApiOrgsOrgIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiOrgsOrgId>>,
        TError,
        {orgId: string;data: PatchApiOrgsOrgIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiOrgsOrgIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves an org
 */
export const getApiOrgsOrgId = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<OrgSchema>(
      {url: `/api/orgs/${orgId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiOrgsOrgIdQueryKey = (orgId: string,) => {
    return [`/api/orgs/${orgId}`] as const;
    }

    
export const getGetApiOrgsOrgIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgsOrgId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(orgId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiOrgsOrgIdQueryKey(orgId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiOrgsOrgId>>> = ({ signal }) => getApiOrgsOrgId(orgId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiOrgsOrgIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrgsOrgId>>>
export type GetApiOrgsOrgIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiOrgsOrgIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgsOrgId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgsOrgId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgsOrgIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgsOrgId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgsOrgId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgsOrgIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgsOrgId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves an org
 */

export function useGetApiOrgsOrgIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiOrgsOrgId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiOrgsOrgIdInfiniteQueryOptions(orgId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiOrgsOrgIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiOrgsOrgIdQueryKey(orgId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiOrgsOrgId>>> = ({ signal }) => getApiOrgsOrgId(orgId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiOrgsOrgIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrgsOrgId>>>
export type GetApiOrgsOrgIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiOrgsOrgId<TData = Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgsOrgId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgsOrgId<TData = Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiOrgsOrgId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiOrgsOrgId<TData = Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves an org
 */

export function useGetApiOrgsOrgId<TData = Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiOrgsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiOrgsOrgIdQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Checks org domain
 */
export const postApiOrgsCheckDomain = (
    postApiOrgsCheckDomainBody: PostApiOrgsCheckDomainBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<OrgDomainCheckSchema | void>(
      {url: `/api/orgs/check_domain`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiOrgsCheckDomainBody
    },
      options);
    }
  


export const getPostApiOrgsCheckDomainMutationOptions = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOrgsCheckDomain>>, TError,{data: PostApiOrgsCheckDomainBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiOrgsCheckDomain>>, TError,{data: PostApiOrgsCheckDomainBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiOrgsCheckDomain>>, {data: PostApiOrgsCheckDomainBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiOrgsCheckDomain(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiOrgsCheckDomainMutationResult = NonNullable<Awaited<ReturnType<typeof postApiOrgsCheckDomain>>>
    export type PostApiOrgsCheckDomainMutationBody = PostApiOrgsCheckDomainBody
    export type PostApiOrgsCheckDomainMutationError = ErrorType<AuthenticationErrorSchema>

    /**
 * @summary Checks org domain
 */
export const usePostApiOrgsCheckDomain = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOrgsCheckDomain>>, TError,{data: PostApiOrgsCheckDomainBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiOrgsCheckDomain>>,
        TError,
        {data: PostApiOrgsCheckDomainBody},
        TContext
      > => {

      const mutationOptions = getPostApiOrgsCheckDomainMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Resends verification email
 */
export const postApiOrgsOrgIdResendVerificationEmail = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/orgs/${orgId}/resend_verification_email`, method: 'POST'
    },
      options);
    }
  


export const getPostApiOrgsOrgIdResendVerificationEmailMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOrgsOrgIdResendVerificationEmail>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiOrgsOrgIdResendVerificationEmail>>, TError,{orgId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiOrgsOrgIdResendVerificationEmail>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  postApiOrgsOrgIdResendVerificationEmail(orgId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiOrgsOrgIdResendVerificationEmailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiOrgsOrgIdResendVerificationEmail>>>
    
    export type PostApiOrgsOrgIdResendVerificationEmailMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Resends verification email
 */
export const usePostApiOrgsOrgIdResendVerificationEmail = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiOrgsOrgIdResendVerificationEmail>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiOrgsOrgIdResendVerificationEmail>>,
        TError,
        {orgId: string},
        TContext
      > => {

      const mutationOptions = getPostApiOrgsOrgIdResendVerificationEmailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves the product tour status for the user
 */
export const getApiProductToursProductTourKey = (
    productTourKey: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserProductTourSchema>(
      {url: `/api/product_tours/${productTourKey}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProductToursProductTourKeyQueryKey = (productTourKey: string,) => {
    return [`/api/product_tours/${productTourKey}`] as const;
    }

    
export const getGetApiProductToursProductTourKeyInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(productTourKey: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProductToursProductTourKeyQueryKey(productTourKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>> = ({ signal }) => getApiProductToursProductTourKey(productTourKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productTourKey), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProductToursProductTourKeyInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>>
export type GetApiProductToursProductTourKeyInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProductToursProductTourKeyInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProductToursProductTourKey>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProductToursProductTourKeyInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProductToursProductTourKey>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProductToursProductTourKeyInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the product tour status for the user
 */

export function useGetApiProductToursProductTourKeyInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProductToursProductTourKeyInfiniteQueryOptions(productTourKey,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProductToursProductTourKeyQueryOptions = <TData = Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError = ErrorType<AuthenticationErrorSchema | void>>(productTourKey: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProductToursProductTourKeyQueryKey(productTourKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>> = ({ signal }) => getApiProductToursProductTourKey(productTourKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productTourKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProductToursProductTourKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>>
export type GetApiProductToursProductTourKeyQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProductToursProductTourKey<TData = Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProductToursProductTourKey>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProductToursProductTourKey<TData = Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProductToursProductTourKey>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProductToursProductTourKey<TData = Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the product tour status for the user
 */

export function useGetApiProductToursProductTourKey<TData = Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 productTourKey: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProductToursProductTourKey>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProductToursProductTourKeyQueryOptions(productTourKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates the product tour status for the user
 */
export const patchApiProductToursProductTourKey = (
    productTourKey: string,
    patchApiProductToursProductTourKeyBody: PatchApiProductToursProductTourKeyBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserProductTourSchema>(
      {url: `/api/product_tours/${productTourKey}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProductToursProductTourKeyBody
    },
      options);
    }
  


export const getPatchApiProductToursProductTourKeyMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProductToursProductTourKey>>, TError,{productTourKey: string;data: PatchApiProductToursProductTourKeyBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProductToursProductTourKey>>, TError,{productTourKey: string;data: PatchApiProductToursProductTourKeyBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProductToursProductTourKey>>, {productTourKey: string;data: PatchApiProductToursProductTourKeyBody}> = (props) => {
          const {productTourKey,data} = props ?? {};

          return  patchApiProductToursProductTourKey(productTourKey,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProductToursProductTourKeyMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProductToursProductTourKey>>>
    export type PatchApiProductToursProductTourKeyMutationBody = PatchApiProductToursProductTourKeyBody
    export type PatchApiProductToursProductTourKeyMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Updates the product tour status for the user
 */
export const usePatchApiProductToursProductTourKey = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProductToursProductTourKey>>, TError,{productTourKey: string;data: PatchApiProductToursProductTourKeyBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProductToursProductTourKey>>,
        TError,
        {productTourKey: string;data: PatchApiProductToursProductTourKeyBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProductToursProductTourKeyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves project pending access requests
 */
export const getApiProjectsProjectIdAccessRequests = (
    projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProjectAccessRequestListSchema>(
      {url: `/api/projects/${projectId}/access_requests`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdAccessRequestsQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams,) => {
    return [`/api/projects/${projectId}/access_requests`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdAccessRequestsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdAccessRequestsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>> = ({ signal }) => getApiProjectsProjectIdAccessRequests(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdAccessRequestsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>>
export type GetApiProjectsProjectIdAccessRequestsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdAccessRequestsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdAccessRequestsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequestsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequestsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves project pending access requests
 */

export function useGetApiProjectsProjectIdAccessRequestsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdAccessRequestsInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdAccessRequestsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdAccessRequestsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>> = ({ signal }) => getApiProjectsProjectIdAccessRequests(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdAccessRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>>
export type GetApiProjectsProjectIdAccessRequestsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdAccessRequests<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdAccessRequestsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequests<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequests<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves project pending access requests
 */

export function useGetApiProjectsProjectIdAccessRequests<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdAccessRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequests>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdAccessRequestsQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a project access request
 */
export const postApiProjectsProjectIdAccessRequests = (
    projectId: string,
    postApiProjectsProjectIdAccessRequestsBody: PostApiProjectsProjectIdAccessRequestsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ProjectAccessRequestSchema>(
      {url: `/api/projects/${projectId}/access_requests`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdAccessRequestsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdAccessRequestsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequests>>, TError,{projectId: string;data: PostApiProjectsProjectIdAccessRequestsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequests>>, TError,{projectId: string;data: PostApiProjectsProjectIdAccessRequestsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequests>>, {projectId: string;data: PostApiProjectsProjectIdAccessRequestsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdAccessRequests(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdAccessRequestsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequests>>>
    export type PostApiProjectsProjectIdAccessRequestsMutationBody = PostApiProjectsProjectIdAccessRequestsBody
    export type PostApiProjectsProjectIdAccessRequestsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates a project access request
 */
export const usePostApiProjectsProjectIdAccessRequests = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequests>>, TError,{projectId: string;data: PostApiProjectsProjectIdAccessRequestsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequests>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdAccessRequestsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdAccessRequestsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a project access request
 */
export const getApiProjectsProjectIdAccessRequestsProjectAccessRequestId = (
    projectId: string,
    projectAccessRequestId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProjectAccessRequestSchema>(
      {url: `/api/projects/${projectId}/access_requests/${projectAccessRequestId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey = (projectId: string,
    projectAccessRequestId: string,) => {
    return [`/api/projects/${projectId}/access_requests/${projectAccessRequestId}`] as const;
    }

    
export const getGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey(projectId,projectAccessRequestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>> = ({ signal }) => getApiProjectsProjectIdAccessRequestsProjectAccessRequestId(projectId,projectAccessRequestId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && projectAccessRequestId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>>
export type GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a project access request
 */

export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdInfiniteQueryOptions(projectId,projectAccessRequestId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey(projectId,projectAccessRequestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>> = ({ signal }) => getApiProjectsProjectIdAccessRequestsProjectAccessRequestId(projectId,projectAccessRequestId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && projectAccessRequestId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>>
export type GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a project access request
 */

export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    projectAccessRequestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryOptions(projectId,projectAccessRequestId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Accepts a project access request
 */
export const postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept = (
    projectId: string,
    projectAccessRequestId: string,
    postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ProjectAccessRequestSchema>(
      {url: `/api/projects/${projectId}/access_requests/${projectAccessRequestId}/accept`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept>>, TError,{projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept>>, TError,{projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept>>, {projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody}> = (props) => {
          const {projectId,projectAccessRequestId,data} = props ?? {};

          return  postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept(projectId,projectAccessRequestId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept>>>
    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationBody = PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody
    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Accepts a project access request
 */
export const usePostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept>>, TError,{projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept>>,
        TError,
        {projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rejects a project access request
 */
export const postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject = (
    projectId: string,
    projectAccessRequestId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ProjectAccessRequestSchema>(
      {url: `/api/projects/${projectId}/access_requests/${projectAccessRequestId}/reject`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject>>, TError,{projectId: string;projectAccessRequestId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject>>, TError,{projectId: string;projectAccessRequestId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject>>, {projectId: string;projectAccessRequestId: string}> = (props) => {
          const {projectId,projectAccessRequestId} = props ?? {};

          return  postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject(projectId,projectAccessRequestId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject>>>
    
    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Rejects a project access request
 */
export const usePostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject>>, TError,{projectId: string;projectAccessRequestId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject>>,
        TError,
        {projectId: string;projectAccessRequestId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Redirects a project access request to another team
 */
export const postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect = (
    projectId: string,
    projectAccessRequestId: string,
    postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ProjectAccessRequestSchema>(
      {url: `/api/projects/${projectId}/access_requests/${projectAccessRequestId}/redirect`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect>>, TError,{projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect>>, TError,{projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect>>, {projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody}> = (props) => {
          const {projectId,projectAccessRequestId,data} = props ?? {};

          return  postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect(projectId,projectAccessRequestId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect>>>
    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationBody = PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody
    export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Redirects a project access request to another team
 */
export const usePostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect>>, TError,{projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect>>,
        TError,
        {projectId: string;projectAccessRequestId: string;data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Excludes archived team members by default, unless status is given
 * @summary Retrieves project people
 */
export const getApiProjectsProjectIdPeople = (
    projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamMemberListSchema>(
      {url: `/api/projects/${projectId}/people`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdPeopleQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams,) => {
    return [`/api/projects/${projectId}/people`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdPeopleInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdPeopleQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>> = ({ signal }) => getApiProjectsProjectIdPeople(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdPeopleInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>>
export type GetApiProjectsProjectIdPeopleInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdPeopleParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves project people
 */

export function useGetApiProjectsProjectIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdPeopleInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdPeopleQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdPeopleQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>> = ({ signal }) => getApiProjectsProjectIdPeople(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdPeopleQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>>
export type GetApiProjectsProjectIdPeopleQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdPeopleParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves project people
 */

export function useGetApiProjectsProjectIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdPeopleQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves a team member
 */
export const getApiProjectsProjectIdPeopleTeamMemberId = (
    projectId: string,
    teamMemberId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamMemberSchema>(
      {url: `/api/projects/${projectId}/people/${teamMemberId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdPeopleTeamMemberIdQueryKey = (projectId: string,
    teamMemberId: number,) => {
    return [`/api/projects/${projectId}/people/${teamMemberId}`] as const;
    }

    
export const getGetApiProjectsProjectIdPeopleTeamMemberIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdPeopleTeamMemberIdQueryKey(projectId,teamMemberId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>> = ({ signal }) => getApiProjectsProjectIdPeopleTeamMemberId(projectId,teamMemberId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamMemberId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdPeopleTeamMemberIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>>
export type GetApiProjectsProjectIdPeopleTeamMemberIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdPeopleTeamMemberIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeopleTeamMemberIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeopleTeamMemberIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a team member
 */

export function useGetApiProjectsProjectIdPeopleTeamMemberIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdPeopleTeamMemberIdInfiniteQueryOptions(projectId,teamMemberId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdPeopleTeamMemberIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdPeopleTeamMemberIdQueryKey(projectId,teamMemberId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>> = ({ signal }) => getApiProjectsProjectIdPeopleTeamMemberId(projectId,teamMemberId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamMemberId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdPeopleTeamMemberIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>>
export type GetApiProjectsProjectIdPeopleTeamMemberIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdPeopleTeamMemberId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeopleTeamMemberId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdPeopleTeamMemberId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a team member
 */

export function useGetApiProjectsProjectIdPeopleTeamMemberId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdPeopleTeamMemberId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdPeopleTeamMemberIdQueryOptions(projectId,teamMemberId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a project
 */
export const postApiProjects = (
    postApiProjectsBody: PostApiProjectsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ProjectSchema>(
      {url: `/api/projects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsBody
    },
      options);
    }
  


export const getPostApiProjectsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjects>>, TError,{data: PostApiProjectsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjects>>, TError,{data: PostApiProjectsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjects>>, {data: PostApiProjectsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiProjects(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjects>>>
    export type PostApiProjectsMutationBody = PostApiProjectsBody
    export type PostApiProjectsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Creates a project
 */
export const usePostApiProjects = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjects>>, TError,{data: PostApiProjectsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjects>>,
        TError,
        {data: PostApiProjectsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves projects
 */
export const getApiProjects = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProjectListSchema>(
      {url: `/api/projects`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsQueryKey = () => {
    return [`/api/projects`] as const;
    }

    
export const getGetApiProjectsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjects>>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjects>>> = ({ signal }) => getApiProjects(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjects>>>
export type GetApiProjectsInfiniteQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiProjectsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjects>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjects>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjects>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjects>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjects>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves projects
 */

export function useGetApiProjectsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjects>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjects>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjects>>> = ({ signal }) => getApiProjects(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjects>>>
export type GetApiProjectsQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiProjects<TData = Awaited<ReturnType<typeof getApiProjects>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjects>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjects<TData = Awaited<ReturnType<typeof getApiProjects>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjects>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjects<TData = Awaited<ReturnType<typeof getApiProjects>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves projects
 */

export function useGetApiProjects<TData = Awaited<ReturnType<typeof getApiProjects>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjects>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves a project
 */
export const getApiProjectsProjectId = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProjectSchema>(
      {url: `/api/projects/${projectId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdQueryKey = (projectId: string,) => {
    return [`/api/projects/${projectId}`] as const;
    }

    
export const getGetApiProjectsProjectIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectId>>> = ({ signal }) => getApiProjectsProjectId(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectId>>>
export type GetApiProjectsProjectIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a project
 */

export function useGetApiProjectsProjectIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdInfiniteQueryOptions(projectId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectId>>> = ({ signal }) => getApiProjectsProjectId(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectId>>>
export type GetApiProjectsProjectIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectId<TData = Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectId<TData = Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectId<TData = Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a project
 */

export function useGetApiProjectsProjectId<TData = Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a project
 */
export const patchApiProjectsProjectId = (
    projectId: string,
    patchApiProjectsProjectIdBody: PatchApiProjectsProjectIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ProjectSchema>(
      {url: `/api/projects/${projectId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectId>>, TError,{projectId: string;data: PatchApiProjectsProjectIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectId>>, TError,{projectId: string;data: PatchApiProjectsProjectIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectId>>, {projectId: string;data: PatchApiProjectsProjectIdBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  patchApiProjectsProjectId(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectId>>>
    export type PatchApiProjectsProjectIdMutationBody = PatchApiProjectsProjectIdBody
    export type PatchApiProjectsProjectIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a project
 */
export const usePatchApiProjectsProjectId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectId>>, TError,{projectId: string;data: PatchApiProjectsProjectIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectId>>,
        TError,
        {projectId: string;data: PatchApiProjectsProjectIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Archives a project
 */
export const postApiProjectsProjectIdArchive = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/archive`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdArchiveMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdArchive>>, TError,{projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdArchive>>, TError,{projectId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdArchive>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  postApiProjectsProjectIdArchive(projectId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdArchive>>>
    
    export type PostApiProjectsProjectIdArchiveMutationError = ErrorType<void>

    /**
 * @summary Archives a project
 */
export const usePostApiProjectsProjectIdArchive = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdArchive>>, TError,{projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdArchive>>,
        TError,
        {projectId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdArchiveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Set the default project
 */
export const postApiProjectsProjectIdDefault = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/default`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdDefaultMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDefault>>, TError,{projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDefault>>, TError,{projectId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdDefault>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  postApiProjectsProjectIdDefault(projectId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdDefault>>>
    
    export type PostApiProjectsProjectIdDefaultMutationError = ErrorType<void>

    /**
 * @summary Set the default project
 */
export const usePostApiProjectsProjectIdDefault = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdDefault>>, TError,{projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdDefault>>,
        TError,
        {projectId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdDefaultMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates a push subscription
 */
export const postApiPushSubscriptions = (
    postApiPushSubscriptionsBody: PostApiPushSubscriptionsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PushSubscriptionSchema>(
      {url: `/api/push_subscriptions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiPushSubscriptionsBody
    },
      options);
    }
  


export const getPostApiPushSubscriptionsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiPushSubscriptions>>, TError,{data: PostApiPushSubscriptionsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiPushSubscriptions>>, TError,{data: PostApiPushSubscriptionsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiPushSubscriptions>>, {data: PostApiPushSubscriptionsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiPushSubscriptions(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiPushSubscriptionsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiPushSubscriptions>>>
    export type PostApiPushSubscriptionsMutationBody = PostApiPushSubscriptionsBody
    export type PostApiPushSubscriptionsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Creates a push subscription
 */
export const usePostApiPushSubscriptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiPushSubscriptions>>, TError,{data: PostApiPushSubscriptionsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiPushSubscriptions>>,
        TError,
        {data: PostApiPushSubscriptionsBody},
        TContext
      > => {

      const mutationOptions = getPostApiPushSubscriptionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates a push subscription
 */
export const patchApiPushSubscriptionsPushSubscriptionId = (
    pushSubscriptionId: string,
    patchApiPushSubscriptionsPushSubscriptionIdBody: PatchApiPushSubscriptionsPushSubscriptionIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PushSubscriptionSchema>(
      {url: `/api/push_subscriptions/${pushSubscriptionId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiPushSubscriptionsPushSubscriptionIdBody
    },
      options);
    }
  


export const getPatchApiPushSubscriptionsPushSubscriptionIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiPushSubscriptionsPushSubscriptionId>>, TError,{pushSubscriptionId: string;data: PatchApiPushSubscriptionsPushSubscriptionIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiPushSubscriptionsPushSubscriptionId>>, TError,{pushSubscriptionId: string;data: PatchApiPushSubscriptionsPushSubscriptionIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiPushSubscriptionsPushSubscriptionId>>, {pushSubscriptionId: string;data: PatchApiPushSubscriptionsPushSubscriptionIdBody}> = (props) => {
          const {pushSubscriptionId,data} = props ?? {};

          return  patchApiPushSubscriptionsPushSubscriptionId(pushSubscriptionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiPushSubscriptionsPushSubscriptionIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiPushSubscriptionsPushSubscriptionId>>>
    export type PatchApiPushSubscriptionsPushSubscriptionIdMutationBody = PatchApiPushSubscriptionsPushSubscriptionIdBody
    export type PatchApiPushSubscriptionsPushSubscriptionIdMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Updates a push subscription
 */
export const usePatchApiPushSubscriptionsPushSubscriptionId = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiPushSubscriptionsPushSubscriptionId>>, TError,{pushSubscriptionId: string;data: PatchApiPushSubscriptionsPushSubscriptionIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiPushSubscriptionsPushSubscriptionId>>,
        TError,
        {pushSubscriptionId: string;data: PatchApiPushSubscriptionsPushSubscriptionIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiPushSubscriptionsPushSubscriptionIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a push subscription
 */
export const deleteApiPushSubscriptionsPushSubscriptionId = (
    pushSubscriptionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/push_subscriptions/${pushSubscriptionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiPushSubscriptionsPushSubscriptionIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiPushSubscriptionsPushSubscriptionId>>, TError,{pushSubscriptionId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiPushSubscriptionsPushSubscriptionId>>, TError,{pushSubscriptionId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiPushSubscriptionsPushSubscriptionId>>, {pushSubscriptionId: string}> = (props) => {
          const {pushSubscriptionId} = props ?? {};

          return  deleteApiPushSubscriptionsPushSubscriptionId(pushSubscriptionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiPushSubscriptionsPushSubscriptionIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiPushSubscriptionsPushSubscriptionId>>>
    
    export type DeleteApiPushSubscriptionsPushSubscriptionIdMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Deletes a push subscription
 */
export const useDeleteApiPushSubscriptionsPushSubscriptionId = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiPushSubscriptionsPushSubscriptionId>>, TError,{pushSubscriptionId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiPushSubscriptionsPushSubscriptionId>>,
        TError,
        {pushSubscriptionId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiPushSubscriptionsPushSubscriptionIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Requests a test message
 */
export const postApiPushSubscriptionsPing = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/push_subscriptions/ping`, method: 'POST'
    },
      options);
    }
  


export const getPostApiPushSubscriptionsPingMutationOptions = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiPushSubscriptionsPing>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiPushSubscriptionsPing>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiPushSubscriptionsPing>>, void> = () => {
          

          return  postApiPushSubscriptionsPing(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiPushSubscriptionsPingMutationResult = NonNullable<Awaited<ReturnType<typeof postApiPushSubscriptionsPing>>>
    
    export type PostApiPushSubscriptionsPingMutationError = ErrorType<AuthenticationErrorSchema>

    /**
 * @summary Requests a test message
 */
export const usePostApiPushSubscriptionsPing = <TError = ErrorType<AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiPushSubscriptionsPing>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiPushSubscriptionsPing>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostApiPushSubscriptionsPingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Refreshes a session
 */
export const postApiLoginRefresh = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/login/refresh`, method: 'POST'
    },
      options);
    }
  


export const getPostApiLoginRefreshMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiLoginRefresh>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiLoginRefresh>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiLoginRefresh>>, void> = () => {
          

          return  postApiLoginRefresh(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiLoginRefreshMutationResult = NonNullable<Awaited<ReturnType<typeof postApiLoginRefresh>>>
    
    export type PostApiLoginRefreshMutationError = ErrorType<void>

    /**
 * @summary Refreshes a session
 */
export const usePostApiLoginRefresh = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiLoginRefresh>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiLoginRefresh>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostApiLoginRefreshMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves shift activities
 */
export const getApiProjectsProjectIdShiftActivities = (
    projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftActivityListSchema>(
      {url: `/api/projects/${projectId}/shift_activities`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftActivitiesQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams,) => {
    return [`/api/projects/${projectId}/shift_activities`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftActivitiesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>> = ({ signal }) => getApiProjectsProjectIdShiftActivities(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>>
export type GetApiProjectsProjectIdShiftActivitiesInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift activities
 */

export function useGetApiProjectsProjectIdShiftActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>> = ({ signal }) => getApiProjectsProjectIdShiftActivities(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>>
export type GetApiProjectsProjectIdShiftActivitiesQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift activities
 */

export function useGetApiProjectsProjectIdShiftActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a shift activity
 */
export const postApiProjectsProjectIdShiftActivities = (
    projectId: string,
    postApiProjectsProjectIdShiftActivitiesBody: PostApiProjectsProjectIdShiftActivitiesBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftActivitySchema>(
      {url: `/api/projects/${projectId}/shift_activities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftActivitiesBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftActivitiesMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivities>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivities>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivities>>, {projectId: string;data: PostApiProjectsProjectIdShiftActivitiesBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftActivities(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftActivitiesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivities>>>
    export type PostApiProjectsProjectIdShiftActivitiesMutationBody = PostApiProjectsProjectIdShiftActivitiesBody
    export type PostApiProjectsProjectIdShiftActivitiesMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a shift activity
 */
export const usePostApiProjectsProjectIdShiftActivities = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivities>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivities>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdShiftActivitiesBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftActivitiesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a shift activity
 */
export const getApiProjectsProjectIdShiftActivitiesShiftActivityId = (
    projectId: string,
    shiftActivityId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftActivitySchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey = (projectId: string,
    shiftActivityId: string,) => {
    return [`/api/projects/${projectId}/shift_activities/${shiftActivityId}`] as const;
    }

    
export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey(projectId,shiftActivityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityId(projectId,shiftActivityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift activity
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdInfiniteQueryOptions(projectId,shiftActivityId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey(projectId,shiftActivityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityId(projectId,shiftActivityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift activity
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryOptions(projectId,shiftActivityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a shift activity
 */
export const patchApiProjectsProjectIdShiftActivitiesShiftActivityId = (
    projectId: string,
    shiftActivityId: string,
    patchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftActivitySchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError,{projectId: string;shiftActivityId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError,{projectId: string;shiftActivityId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityId>>, {projectId: string;shiftActivityId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody}> = (props) => {
          const {projectId,shiftActivityId,data} = props ?? {};

          return  patchApiProjectsProjectIdShiftActivitiesShiftActivityId(projectId,shiftActivityId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityId>>>
    export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationBody = PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody
    export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a shift activity
 */
export const usePatchApiProjectsProjectIdShiftActivitiesShiftActivityId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityId>>, TError,{projectId: string;shiftActivityId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityId>>,
        TError,
        {projectId: string;shiftActivityId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves archived shift activities
 */
export const getApiProjectsProjectIdShiftActivitiesArchived = (
    projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftActivityListSchema>(
      {url: `/api/projects/${projectId}/shift_activities/archived`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftActivitiesArchivedQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams,) => {
    return [`/api/projects/${projectId}/shift_activities/archived`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftActivitiesArchivedInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesArchivedQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesArchived(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesArchivedInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>>
export type GetApiProjectsProjectIdShiftActivitiesArchivedInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesArchivedParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves archived shift activities
 */

export function useGetApiProjectsProjectIdShiftActivitiesArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesArchivedInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftActivitiesArchivedQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesArchivedQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesArchived(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesArchivedQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>>
export type GetApiProjectsProjectIdShiftActivitiesArchivedQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesArchivedParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves archived shift activities
 */

export function useGetApiProjectsProjectIdShiftActivitiesArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesArchivedQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Archives a shift activity
 */
export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive = (
    projectId: string,
    shiftActivityId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftActivitySchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/archive`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive>>, TError,{projectId: string;shiftActivityId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive>>, TError,{projectId: string;shiftActivityId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive>>, {projectId: string;shiftActivityId: string}> = (props) => {
          const {projectId,shiftActivityId} = props ?? {};

          return  postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive(projectId,shiftActivityId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive>>>
    
    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Archives a shift activity
 */
export const usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive>>, TError,{projectId: string;shiftActivityId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive>>,
        TError,
        {projectId: string;shiftActivityId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Restores a shift activity
 */
export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore = (
    projectId: string,
    shiftActivityId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftActivitySchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/restore`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore>>, TError,{projectId: string;shiftActivityId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore>>, TError,{projectId: string;shiftActivityId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore>>, {projectId: string;shiftActivityId: string}> = (props) => {
          const {projectId,shiftActivityId} = props ?? {};

          return  postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore(projectId,shiftActivityId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore>>>
    
    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Restores a shift activity
 */
export const usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore>>, TError,{projectId: string;shiftActivityId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore>>,
        TError,
        {projectId: string;shiftActivityId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Exports a list of shift activities
 */
export const postApiProjectsProjectIdShiftActivitiesExport = (
    projectId: string,
    postApiProjectsProjectIdShiftActivitiesExportBody: PostApiProjectsProjectIdShiftActivitiesExportBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_activities/export`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftActivitiesExportBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftActivitiesExportMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesExport>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesExportBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesExport>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesExportBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesExport>>, {projectId: string;data: PostApiProjectsProjectIdShiftActivitiesExportBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftActivitiesExport(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftActivitiesExportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesExport>>>
    export type PostApiProjectsProjectIdShiftActivitiesExportMutationBody = PostApiProjectsProjectIdShiftActivitiesExportBody
    export type PostApiProjectsProjectIdShiftActivitiesExportMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Exports a list of shift activities
 */
export const usePostApiProjectsProjectIdShiftActivitiesExport = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesExport>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesExportBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesExport>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdShiftActivitiesExportBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftActivitiesExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates a shift activity import
 */
export const postApiProjectsProjectIdShiftActivitiesImports = (
    projectId: string,
    postApiProjectsProjectIdShiftActivitiesImportsBody: PostApiProjectsProjectIdShiftActivitiesImportsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      const formData = new FormData();
if(postApiProjectsProjectIdShiftActivitiesImportsBody.xlsx !== undefined) {
 formData.append('xlsx', postApiProjectsProjectIdShiftActivitiesImportsBody.xlsx)
 }

      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_activities/imports`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftActivitiesImportsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesImports>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesImportsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesImports>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesImportsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesImports>>, {projectId: string;data: PostApiProjectsProjectIdShiftActivitiesImportsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftActivitiesImports(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftActivitiesImportsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesImports>>>
    export type PostApiProjectsProjectIdShiftActivitiesImportsMutationBody = PostApiProjectsProjectIdShiftActivitiesImportsBody
    export type PostApiProjectsProjectIdShiftActivitiesImportsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a shift activity import
 */
export const usePostApiProjectsProjectIdShiftActivitiesImports = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesImports>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftActivitiesImportsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesImports>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdShiftActivitiesImportsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftActivitiesImportsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Removes a document from a shift activity progress log
 */
export const deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId = (
    projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    documentReferenceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs/${progressLogId}/document_references/${documentReferenceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;documentReferenceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;documentReferenceId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId>>, {projectId: string;shiftActivityId: string;progressLogId: string;documentReferenceId: string}> = (props) => {
          const {projectId,shiftActivityId,progressLogId,documentReferenceId} = props ?? {};

          return  deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId(projectId,shiftActivityId,progressLogId,documentReferenceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId>>>
    
    export type DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Removes a document from a shift activity progress log
 */
export const useDeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;documentReferenceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId>>,
        TError,
        {projectId: string;shiftActivityId: string;progressLogId: string;documentReferenceId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves shift activity progress log documents
 */
export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments = (
    projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DocumentReferenceAndDocumentListSchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs/${progressLogId}/documents`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey = (projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams,) => {
    return [`/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs/${progressLogId}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey(projectId,shiftActivityId,progressLogId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(projectId,shiftActivityId,progressLogId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId && progressLogId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift activity progress log documents
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsInfiniteQueryOptions(projectId,shiftActivityId,progressLogId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey(projectId,shiftActivityId,progressLogId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(projectId,shiftActivityId,progressLogId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId && progressLogId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift activity progress log documents
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryOptions(projectId,shiftActivityId,progressLogId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Adds a document to a shift activity progress log (from an upload or an existing document)
 */
export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments = (
    projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    newOrExistingDocumentWithAttributesBodyParameterSchema: NewOrExistingDocumentWithAttributesBodyParameterSchema,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DocumentReferenceAndDocumentSchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs/${progressLogId}/documents`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newOrExistingDocumentWithAttributesBodyParameterSchema
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, {projectId: string;shiftActivityId: string;progressLogId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}> = (props) => {
          const {projectId,shiftActivityId,progressLogId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(projectId,shiftActivityId,progressLogId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>>
    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationBody = NewOrExistingDocumentWithAttributesBodyParameterSchema
    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Adds a document to a shift activity progress log (from an upload or an existing document)
 */
export const usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments>>,
        TError,
        {projectId: string;shiftActivityId: string;progressLogId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a shift activity progress logs
 */
export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs = (
    projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftActivityProgressLogListSchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey = (projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams,) => {
    return [`/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey(projectId,shiftActivityId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(projectId,shiftActivityId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift activity progress logs
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsInfiniteQueryOptions(projectId,shiftActivityId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey(projectId,shiftActivityId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(projectId,shiftActivityId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params: undefined |  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift activity progress logs
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryOptions(projectId,shiftActivityId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a shift activity progress log
 */
export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs = (
    projectId: string,
    shiftActivityId: string,
    postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftActivityProgressLogSchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError,{projectId: string;shiftActivityId: string;data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError,{projectId: string;shiftActivityId: string;data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, {projectId: string;shiftActivityId: string;data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody}> = (props) => {
          const {projectId,shiftActivityId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(projectId,shiftActivityId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>>
    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationBody = PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody
    export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a shift activity progress log
 */
export const usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>, TError,{projectId: string;shiftActivityId: string;data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs>>,
        TError,
        {projectId: string;shiftActivityId: string;data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a shift activity progress log
 */
export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId = (
    projectId: string,
    shiftActivityId: string,
    progressLogId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftActivityProgressLogSchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs/${progressLogId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey = (projectId: string,
    shiftActivityId: string,
    progressLogId: string,) => {
    return [`/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs/${progressLogId}`] as const;
    }

    
export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey(projectId,shiftActivityId,progressLogId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(projectId,shiftActivityId,progressLogId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId && progressLogId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift activity progress log
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdInfiniteQueryOptions(projectId,shiftActivityId,progressLogId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey(projectId,shiftActivityId,progressLogId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>> = ({ signal }) => getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(projectId,shiftActivityId,progressLogId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftActivityId && progressLogId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>
export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift activity progress log
 */

export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftActivityId: string,
    progressLogId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryOptions(projectId,shiftActivityId,progressLogId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a shift activity progress log
 */
export const patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId = (
    projectId: string,
    shiftActivityId: string,
    progressLogId: string,
    patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftActivityProgressLogSchema>(
      {url: `/api/projects/${projectId}/shift_activities/${shiftActivityId}/progress_logs/${progressLogId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, {projectId: string;shiftActivityId: string;progressLogId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody}> = (props) => {
          const {projectId,shiftActivityId,progressLogId,data} = props ?? {};

          return  patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(projectId,shiftActivityId,progressLogId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>>
    export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationBody = PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody
    export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a shift activity progress log
 */
export const usePatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>, TError,{projectId: string;shiftActivityId: string;progressLogId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId>>,
        TError,
        {projectId: string;shiftActivityId: string;progressLogId: string;data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves shift report collaborators comments
 */
export const getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators = (
    projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportCommentListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/comments/collaborators`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey = (projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}/comments/collaborators`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report collaborators comments
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsInfiniteQueryOptions(projectId,shiftReportId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report collaborators comments
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryOptions(projectId,shiftReportId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a shift report collaborators comment
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators = (
    projectId: string,
    shiftReportId: string,
    postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftReportCommentSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/comments/collaborators`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, {projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody}> = (props) => {
          const {projectId,shiftReportId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(projectId,shiftReportId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>>
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationBody = PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates a shift report collaborators comment
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators>>,
        TError,
        {projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves shift report public comments
 */
export const getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic = (
    projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportCommentListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/comments/public`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey = (projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}/comments/public`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report public comments
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicInfiniteQueryOptions(projectId,shiftReportId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report public comments
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryOptions(projectId,shiftReportId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a shift report public comment
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic = (
    projectId: string,
    shiftReportId: string,
    postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftReportCommentSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/comments/public`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, {projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody}> = (props) => {
          const {projectId,shiftReportId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(projectId,shiftReportId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>>
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationBody = PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates a shift report public comment
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic>>,
        TError,
        {projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a shift report comment
 */
export const deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId = (
    projectId: string,
    shiftReportId: string,
    commentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/comments/${commentId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId>>, TError,{projectId: string;shiftReportId: string;commentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId>>, TError,{projectId: string;shiftReportId: string;commentId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId>>, {projectId: string;shiftReportId: string;commentId: string}> = (props) => {
          const {projectId,shiftReportId,commentId} = props ?? {};

          return  deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId(projectId,shiftReportId,commentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId>>>
    
    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a shift report comment
 */
export const useDeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId>>, TError,{projectId: string;shiftReportId: string;commentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId>>,
        TError,
        {projectId: string;shiftReportId: string;commentId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Removes a document from a shift report
 */
export const deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId = (
    projectId: string,
    shiftReportId: string,
    documentReferenceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/document_references/${documentReferenceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;shiftReportId: string;documentReferenceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;shiftReportId: string;documentReferenceId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId>>, {projectId: string;shiftReportId: string;documentReferenceId: string}> = (props) => {
          const {projectId,shiftReportId,documentReferenceId} = props ?? {};

          return  deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId(projectId,shiftReportId,documentReferenceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId>>>
    
    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Removes a document from a shift report
 */
export const useDeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId>>, TError,{projectId: string;shiftReportId: string;documentReferenceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId>>,
        TError,
        {projectId: string;shiftReportId: string;documentReferenceId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves shift report documents
 */
export const getApiProjectsProjectIdShiftReportsShiftReportIdDocuments = (
    projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DocumentReferenceAndDocumentListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/documents`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey = (projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdDocuments(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report documents
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsInfiniteQueryOptions(projectId,shiftReportId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdDocuments(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report documents
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryOptions(projectId,shiftReportId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Adds a document to a shift report (from an upload or an existing document)
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdDocuments = (
    projectId: string,
    shiftReportId: string,
    newOrExistingDocumentWithAttributesBodyParameterSchema: NewOrExistingDocumentWithAttributesBodyParameterSchema,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DocumentReferenceAndDocumentSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/documents`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newOrExistingDocumentWithAttributesBodyParameterSchema
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError,{projectId: string;shiftReportId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError,{projectId: string;shiftReportId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, {projectId: string;shiftReportId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}> = (props) => {
          const {projectId,shiftReportId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdDocuments(projectId,shiftReportId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>>
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationBody = NewOrExistingDocumentWithAttributesBodyParameterSchema
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema>

    /**
 * @summary Adds a document to a shift report (from an upload or an existing document)
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdDocuments = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>, TError,{projectId: string;shiftReportId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdDocuments>>,
        TError,
        {projectId: string;shiftReportId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Exports a list of shift reports
 */
export const postApiProjectsProjectIdShiftReportsExport = (
    projectId: string,
    postApiProjectsProjectIdShiftReportsExportBody: PostApiProjectsProjectIdShiftReportsExportBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/export`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftReportsExportBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsExportMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsExport>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftReportsExportBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsExport>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftReportsExportBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsExport>>, {projectId: string;data: PostApiProjectsProjectIdShiftReportsExportBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsExport(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsExportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsExport>>>
    export type PostApiProjectsProjectIdShiftReportsExportMutationBody = PostApiProjectsProjectIdShiftReportsExportBody
    export type PostApiProjectsProjectIdShiftReportsExportMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Exports a list of shift reports
 */
export const usePostApiProjectsProjectIdShiftReportsExport = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsExport>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftReportsExportBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsExport>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdShiftReportsExportBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Exports a shift report
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdExport = (
    projectId: string,
    shiftReportId: string,
    params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/export`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationOptions = <TError = ErrorType<void | AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdExport>>, TError,{projectId: string;shiftReportId: string;params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdExport>>, TError,{projectId: string;shiftReportId: string;params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdExport>>, {projectId: string;shiftReportId: string;params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportParams}> = (props) => {
          const {projectId,shiftReportId,params} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdExport(projectId,shiftReportId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdExport>>>
    
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationError = ErrorType<void | AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Exports a shift report
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdExport = <TError = ErrorType<void | AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdExport>>, TError,{projectId: string;shiftReportId: string;params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdExport>>,
        TError,
        {projectId: string;shiftReportId: string;params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportParams},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Imports data from an XLSX file to a draft shift report
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdImport = (
    projectId: string,
    shiftReportId: string,
    postApiProjectsProjectIdShiftReportsShiftReportIdImportBody: PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      const formData = new FormData();
if(postApiProjectsProjectIdShiftReportsShiftReportIdImportBody.sections !== undefined) {
 postApiProjectsProjectIdShiftReportsShiftReportIdImportBody.sections.forEach(value => formData.append('sections', value));
 }
if(postApiProjectsProjectIdShiftReportsShiftReportIdImportBody.xlsx !== undefined) {
 formData.append('xlsx', postApiProjectsProjectIdShiftReportsShiftReportIdImportBody.xlsx)
 }

      return customInstance<ShiftReportSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/import`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationOptions = <TError = ErrorType<void | AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdImport>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdImport>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdImport>>, {projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody}> = (props) => {
          const {projectId,shiftReportId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdImport(projectId,shiftReportId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdImport>>>
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationBody = PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationError = ErrorType<void | AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Imports data from an XLSX file to a draft shift report
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdImport = <TError = ErrorType<void | AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdImport>>, TError,{projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdImport>>,
        TError,
        {projectId: string;shiftReportId: string;data: PostApiProjectsProjectIdShiftReportsShiftReportIdImportBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves team members with access to shift report
 */
export const getApiProjectsProjectIdShiftReportsShiftReportIdPeople = (
    projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamMemberListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/people`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey = (projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}/people`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdPeople(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team members with access to shift report
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleInfiniteQueryOptions(projectId,shiftReportId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey(projectId,shiftReportId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdPeople(projectId,shiftReportId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team members with access to shift report
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeople>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryOptions(projectId,shiftReportId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves shift report resource documents
 */
export const getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments = (
    projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DocumentReferenceAndDocumentListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/${resourceType}/${resourceId}/documents`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey = (projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}/${resourceType}/${resourceId}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey(projectId,shiftReportId,resourceType,resourceId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(projectId,shiftReportId,resourceType,resourceId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId && resourceType && resourceId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report resource documents
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsInfiniteQueryOptions(projectId,shiftReportId,resourceType,resourceId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey(projectId,shiftReportId,resourceType,resourceId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(projectId,shiftReportId,resourceType,resourceId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId && resourceType && resourceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves shift report resource documents
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryOptions(projectId,shiftReportId,resourceType,resourceId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Adds a document to a shift report resource (from an upload or an existing document)
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments = (
    projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    newOrExistingDocumentWithAttributesBodyParameterSchema: NewOrExistingDocumentWithAttributesBodyParameterSchema,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<DocumentReferenceAndDocumentSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/${resourceType}/${resourceId}/documents`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newOrExistingDocumentWithAttributesBodyParameterSchema
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError,{projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError,{projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, {projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}> = (props) => {
          const {projectId,shiftReportId,resourceType,resourceId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(projectId,shiftReportId,resourceType,resourceId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>>
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationBody = NewOrExistingDocumentWithAttributesBodyParameterSchema
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Adds a document to a shift report resource (from an upload or an existing document)
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>, TError,{projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments>>,
        TError,
        {projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;data: NewOrExistingDocumentWithAttributesBodyParameterSchema},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Removes a document from a shift report resource
 */
export const deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId = (
    projectId: string,
    shiftReportId: string,
    resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments',
    resourceId: string,
    documentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/${resourceType}/${resourceId}/documents/${documentId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId>>, TError,{projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;documentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId>>, TError,{projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;documentId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId>>, {projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;documentId: string}> = (props) => {
          const {projectId,shiftReportId,resourceType,resourceId,documentId} = props ?? {};

          return  deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId(projectId,shiftReportId,resourceType,resourceId,documentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId>>>
    
    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Removes a document from a shift report resource
 */
export const useDeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId>>, TError,{projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;documentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId>>,
        TError,
        {projectId: string;shiftReportId: string;resourceType: 'activities' | 'contract_forces' | 'down_times' | 'equipments' | 'materials' | 'safety_health_environments';resourceId: string;documentId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves resources
 */
export const getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind = (
    projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TruncatedResourceListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/resources/${kind}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey = (projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}/resources/${kind}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey(projectId,shiftReportId,kind,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(projectId,shiftReportId,kind,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId && kind), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves resources
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindInfiniteQueryOptions(projectId,shiftReportId,kind,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey(projectId,shiftReportId,kind,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(projectId,shiftReportId,kind,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId && kind), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params: undefined |  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves resources
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryOptions(projectId,shiftReportId,kind,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create resource
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind = (
    projectId: string,
    shiftReportId: string,
    kind: ResourceKindSchema,
    postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ResourceSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/resources/${kind}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError,{projectId: string;shiftReportId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError,{projectId: string;shiftReportId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, {projectId: string;shiftReportId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody}> = (props) => {
          const {projectId,shiftReportId,kind,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(projectId,shiftReportId,kind,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>>
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationBody = PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>

    /**
 * @summary Create resource
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>, TError,{projectId: string;shiftReportId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind>>,
        TError,
        {projectId: string;shiftReportId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves published shift reports
 */
export const getApiProjectsProjectIdShiftReportsCompletions = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportCompletionListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/completions`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsCompletionsQueryKey = (projectId: string,) => {
    return [`/api/projects/${projectId}/shift_reports/completions`] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsCompletionsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsCompletionsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsCompletions(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsCompletionsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>>
export type GetApiProjectsProjectIdShiftReportsCompletionsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsCompletionsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsCompletionsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsCompletionsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves published shift reports
 */

export function useGetApiProjectsProjectIdShiftReportsCompletionsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsCompletionsInfiniteQueryOptions(projectId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsCompletionsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsCompletionsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsCompletions(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsCompletionsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>>
export type GetApiProjectsProjectIdShiftReportsCompletionsQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsCompletions<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsCompletions<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsCompletions<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves published shift reports
 */

export function useGetApiProjectsProjectIdShiftReportsCompletions<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsCompletions>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsCompletionsQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves all quality indicators for a shift report
 */
export const getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators = (
    projectId: string,
    shiftReportId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportQualityIndicatorsSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/quality_indicators`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey = (projectId: string,
    shiftReportId: string,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}/quality_indicators`] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey(projectId,shiftReportId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators(projectId,shiftReportId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves all quality indicators for a shift report
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsInfiniteQueryOptions(projectId,shiftReportId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey(projectId,shiftReportId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators(projectId,shiftReportId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves all quality indicators for a shift report
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryOptions(projectId,shiftReportId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves published shift reports
 */
export const getApiProjectsProjectIdShiftReports = (
    projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportListSchema>(
      {url: `/api/projects/${projectId}/shift_reports`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams,) => {
    return [`/api/projects/${projectId}/shift_reports`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>> = ({ signal }) => getApiProjectsProjectIdShiftReports(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>>
export type GetApiProjectsProjectIdShiftReportsInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves published shift reports
 */

export function useGetApiProjectsProjectIdShiftReportsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>> = ({ signal }) => getApiProjectsProjectIdShiftReports(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>>
export type GetApiProjectsProjectIdShiftReportsQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReports<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReports<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReports<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves published shift reports
 */

export function useGetApiProjectsProjectIdShiftReports<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReports>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a shift report
 */
export const postApiProjectsProjectIdShiftReports = (
    projectId: string,
    postApiProjectsProjectIdShiftReportsBody: PostApiProjectsProjectIdShiftReportsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftReportSchema>(
      {url: `/api/projects/${projectId}/shift_reports`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdShiftReportsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReports>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftReportsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReports>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftReportsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReports>>, {projectId: string;data: PostApiProjectsProjectIdShiftReportsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdShiftReports(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReports>>>
    export type PostApiProjectsProjectIdShiftReportsMutationBody = PostApiProjectsProjectIdShiftReportsBody
    export type PostApiProjectsProjectIdShiftReportsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a shift report
 */
export const usePostApiProjectsProjectIdShiftReports = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReports>>, TError,{projectId: string;data: PostApiProjectsProjectIdShiftReportsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReports>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdShiftReportsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves archived shift reports
 */
export const getApiProjectsProjectIdShiftReportsArchived = (
    projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/archived`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsArchivedQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams,) => {
    return [`/api/projects/${projectId}/shift_reports/archived`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsArchivedInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsArchived(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsArchivedInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>>
export type GetApiProjectsProjectIdShiftReportsArchivedInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsArchivedParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves archived shift reports
 */

export function useGetApiProjectsProjectIdShiftReportsArchivedInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsArchivedInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsArchivedQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsArchived(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsArchivedQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>>
export type GetApiProjectsProjectIdShiftReportsArchivedQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsArchivedParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves archived shift reports
 */

export function useGetApiProjectsProjectIdShiftReportsArchived<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsArchivedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsArchived>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsArchivedQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves draft shift reports where the user is an owner or a collaborator
 */
export const getApiProjectsProjectIdShiftReportsDraft = (
    projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportListSchema>(
      {url: `/api/projects/${projectId}/shift_reports/draft`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsDraftQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams,) => {
    return [`/api/projects/${projectId}/shift_reports/draft`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsDraftInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsDraftQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsDraft(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsDraftInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>>
export type GetApiProjectsProjectIdShiftReportsDraftInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsDraftInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsDraftParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsDraftInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsDraftInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves draft shift reports where the user is an owner or a collaborator
 */

export function useGetApiProjectsProjectIdShiftReportsDraftInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsDraftInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsDraftQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsDraftQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsDraft(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsDraftQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>>
export type GetApiProjectsProjectIdShiftReportsDraftQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsDraft<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdShiftReportsDraftParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsDraft<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsDraft<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves draft shift reports where the user is an owner or a collaborator
 */

export function useGetApiProjectsProjectIdShiftReportsDraft<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdShiftReportsDraftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsDraft>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsDraftQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves a shift report
 */
export const getApiProjectsProjectIdShiftReportsShiftReportId = (
    projectId: string,
    shiftReportId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftReportSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey = (projectId: string,
    shiftReportId: string,) => {
    return [`/api/projects/${projectId}/shift_reports/${shiftReportId}`] as const;
    }

    
export const getGetApiProjectsProjectIdShiftReportsShiftReportIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId,shiftReportId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportId(projectId,shiftReportId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift report
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdInfiniteQueryOptions(projectId,shiftReportId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId,shiftReportId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>> = ({ signal }) => getApiProjectsProjectIdShiftReportsShiftReportId(projectId,shiftReportId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && shiftReportId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>>
export type GetApiProjectsProjectIdShiftReportsShiftReportIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdShiftReportsShiftReportId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdShiftReportsShiftReportId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a shift report
 */

export function useGetApiProjectsProjectIdShiftReportsShiftReportId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    shiftReportId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions(projectId,shiftReportId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a draft shift report
 */
export const patchApiProjectsProjectIdShiftReportsShiftReportId = (
    projectId: string,
    shiftReportId: string,
    patchApiProjectsProjectIdShiftReportsShiftReportIdBody: PatchApiProjectsProjectIdShiftReportsShiftReportIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftReportSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdShiftReportsShiftReportIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdShiftReportsShiftReportIdMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftReportsShiftReportId>>, TError,{projectId: string;shiftReportId: string;data: PatchApiProjectsProjectIdShiftReportsShiftReportIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftReportsShiftReportId>>, TError,{projectId: string;shiftReportId: string;data: PatchApiProjectsProjectIdShiftReportsShiftReportIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftReportsShiftReportId>>, {projectId: string;shiftReportId: string;data: PatchApiProjectsProjectIdShiftReportsShiftReportIdBody}> = (props) => {
          const {projectId,shiftReportId,data} = props ?? {};

          return  patchApiProjectsProjectIdShiftReportsShiftReportId(projectId,shiftReportId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftReportsShiftReportId>>>
    export type PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationBody = PatchApiProjectsProjectIdShiftReportsShiftReportIdBody
    export type PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Updates a draft shift report
 */
export const usePatchApiProjectsProjectIdShiftReportsShiftReportId = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftReportsShiftReportId>>, TError,{projectId: string;shiftReportId: string;data: PatchApiProjectsProjectIdShiftReportsShiftReportIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdShiftReportsShiftReportId>>,
        TError,
        {projectId: string;shiftReportId: string;data: PatchApiProjectsProjectIdShiftReportsShiftReportIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdShiftReportsShiftReportIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a draft shift report
 */
export const deleteApiProjectsProjectIdShiftReportsShiftReportId = (
    projectId: string,
    shiftReportId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportId>>, TError,{projectId: string;shiftReportId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportId>>, TError,{projectId: string;shiftReportId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportId>>, {projectId: string;shiftReportId: string}> = (props) => {
          const {projectId,shiftReportId} = props ?? {};

          return  deleteApiProjectsProjectIdShiftReportsShiftReportId(projectId,shiftReportId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportId>>>
    
    export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Deletes a draft shift report
 */
export const useDeleteApiProjectsProjectIdShiftReportsShiftReportId = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportId>>, TError,{projectId: string;shiftReportId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdShiftReportsShiftReportId>>,
        TError,
        {projectId: string;shiftReportId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Archives a shift report
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdArchive = (
    projectId: string,
    shiftReportId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/archive`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdArchive>>, TError,{projectId: string;shiftReportId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdArchive>>, TError,{projectId: string;shiftReportId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdArchive>>, {projectId: string;shiftReportId: string}> = (props) => {
          const {projectId,shiftReportId} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdArchive(projectId,shiftReportId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdArchive>>>
    
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Archives a shift report
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdArchive = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdArchive>>, TError,{projectId: string;shiftReportId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdArchive>>,
        TError,
        {projectId: string;shiftReportId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Publishes a draft shift report
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdPublish = (
    projectId: string,
    shiftReportId: string,
    params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftReportSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/publish`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdPublish>>, TError,{projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdPublish>>, TError,{projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdPublish>>, {projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishParams}> = (props) => {
          const {projectId,shiftReportId,params} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdPublish(projectId,shiftReportId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdPublish>>>
    
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Publishes a draft shift report
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdPublish = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdPublish>>, TError,{projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdPublish>>,
        TError,
        {projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishParams},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes all elements from a shift report section
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdResetSection = (
    projectId: string,
    shiftReportId: string,
    params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ShiftReportSchema>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/reset_section`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationOptions = <TError = ErrorType<ShiftReportResetSectionErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResetSection>>, TError,{projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResetSection>>, TError,{projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResetSection>>, {projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionParams}> = (props) => {
          const {projectId,shiftReportId,params} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdResetSection(projectId,shiftReportId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResetSection>>>
    
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationError = ErrorType<ShiftReportResetSectionErrorSchema | AuthenticationErrorSchema>

    /**
 * @summary Deletes all elements from a shift report section
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdResetSection = <TError = ErrorType<ShiftReportResetSectionErrorSchema | AuthenticationErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResetSection>>, TError,{projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdResetSection>>,
        TError,
        {projectId: string;shiftReportId: string;params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionParams},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Restores a shift report
 */
export const postApiProjectsProjectIdShiftReportsShiftReportIdRestore = (
    projectId: string,
    shiftReportId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/shift_reports/${shiftReportId}/restore`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdRestore>>, TError,{projectId: string;shiftReportId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdRestore>>, TError,{projectId: string;shiftReportId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdRestore>>, {projectId: string;shiftReportId: string}> = (props) => {
          const {projectId,shiftReportId} = props ?? {};

          return  postApiProjectsProjectIdShiftReportsShiftReportIdRestore(projectId,shiftReportId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdRestore>>>
    
    export type PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Restores a shift report
 */
export const usePostApiProjectsProjectIdShiftReportsShiftReportIdRestore = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdRestore>>, TError,{projectId: string;shiftReportId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdShiftReportsShiftReportIdRestore>>,
        TError,
        {projectId: string;shiftReportId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves the team channel configuration
 */
export const getApiProjectsProjectIdTeamsTeamIdChannelConfiguration = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamChannelConfigurationSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/channel_configuration`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey = (projectId: string,
    teamId: string,) => {
    return [`/api/projects/${projectId}/teams/${teamId}/channel_configuration`] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdChannelConfiguration(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>
export type GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the team channel configuration
 */

export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationInfiniteQueryOptions(projectId,teamId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdChannelConfiguration(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>
export type GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfiguration<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfiguration<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfiguration<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves the team channel configuration
 */

export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfiguration<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryOptions(projectId,teamId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates the team channel configuration
 */
export const patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration = (
    projectId: string,
    teamId: string,
    patchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamChannelConfigurationSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/channel_configuration`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError,{projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError,{projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, {projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody}> = (props) => {
          const {projectId,teamId,data} = props ?? {};

          return  patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration(projectId,teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>>
    export type PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationBody = PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody
    export type PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates the team channel configuration
 */
export const usePatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>, TError,{projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration>>,
        TError,
        {projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a team join token
 */
export const getApiProjectsProjectIdTeamsTeamIdJoinToken = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamJoinTokenSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/join_token`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey = (projectId: string,
    teamId: string,) => {
    return [`/api/projects/${projectId}/teams/${teamId}/join_token`] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsTeamIdJoinTokenInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdJoinToken(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdJoinTokenInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>>
export type GetApiProjectsProjectIdTeamsTeamIdJoinTokenInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdJoinTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdJoinTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdJoinTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a team join token
 */

export function useGetApiProjectsProjectIdTeamsTeamIdJoinTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdJoinTokenInfiniteQueryOptions(projectId,teamId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdJoinToken(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>>
export type GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdJoinToken<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdJoinToken<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdJoinToken<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a team join token
 */

export function useGetApiProjectsProjectIdTeamsTeamIdJoinToken<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryOptions(projectId,teamId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a new team join token (and expires existing join token)
 */
export const postApiProjectsProjectIdTeamsTeamIdJoinToken = (
    projectId: string,
    teamId: string,
    postApiProjectsProjectIdTeamsTeamIdJoinTokenBody: PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamJoinTokenSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/join_token`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdTeamsTeamIdJoinTokenBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdJoinToken>>, {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody}> = (props) => {
          const {projectId,teamId,data} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdJoinToken(projectId,teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdJoinToken>>>
    export type PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationBody = PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody
    export type PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a new team join token (and expires existing join token)
 */
export const usePostApiProjectsProjectIdTeamsTeamIdJoinToken = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdJoinToken>>,
        TError,
        {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Expires a team join token
 */
export const deleteApiProjectsProjectIdTeamsTeamIdJoinToken = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamJoinTokenSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/join_token`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError,{projectId: string;teamId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdJoinToken>>, {projectId: string;teamId: string}> = (props) => {
          const {projectId,teamId} = props ?? {};

          return  deleteApiProjectsProjectIdTeamsTeamIdJoinToken(projectId,teamId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdJoinToken>>>
    
    export type DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Expires a team join token
 */
export const useDeleteApiProjectsProjectIdTeamsTeamIdJoinToken = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdJoinToken>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdJoinToken>>,
        TError,
        {projectId: string;teamId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves public team join token
 */
export const getApiTeamJoinTokensToken = (
    token: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamJoinTokenPublicSchema>(
      {url: `/api/team_join_tokens/${token}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiTeamJoinTokensTokenQueryKey = (token: string,) => {
    return [`/api/team_join_tokens/${token}`] as const;
    }

    
export const getGetApiTeamJoinTokensTokenInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>>, TError = ErrorType<void>>(token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiTeamJoinTokensTokenQueryKey(token);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>> = ({ signal }) => getApiTeamJoinTokensToken(token, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiTeamJoinTokensTokenInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>>
export type GetApiTeamJoinTokensTokenInfiniteQueryError = ErrorType<void>


export function useGetApiTeamJoinTokensTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>>, TError = ErrorType<void>>(
 token: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTeamJoinTokensTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>>, TError = ErrorType<void>>(
 token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTeamJoinTokensTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>>, TError = ErrorType<void>>(
 token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves public team join token
 */

export function useGetApiTeamJoinTokensTokenInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>>, TError = ErrorType<void>>(
 token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiTeamJoinTokensTokenInfiniteQueryOptions(token,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiTeamJoinTokensTokenQueryOptions = <TData = Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError = ErrorType<void>>(token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiTeamJoinTokensTokenQueryKey(token);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>> = ({ signal }) => getApiTeamJoinTokensToken(token, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiTeamJoinTokensTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>>
export type GetApiTeamJoinTokensTokenQueryError = ErrorType<void>


export function useGetApiTeamJoinTokensToken<TData = Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError = ErrorType<void>>(
 token: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTeamJoinTokensToken<TData = Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError = ErrorType<void>>(
 token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTeamJoinTokensToken<TData = Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError = ErrorType<void>>(
 token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves public team join token
 */

export function useGetApiTeamJoinTokensToken<TData = Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError = ErrorType<void>>(
 token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTeamJoinTokensToken>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiTeamJoinTokensTokenQueryOptions(token,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Adds a user to a team using a team join token
 */
export const postApiTeamMembers = (
    postApiTeamMembersBody: PostApiTeamMembersBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamMemberFromJoinTokenSchema>(
      {url: `/api/team_members`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiTeamMembersBody
    },
      options);
    }
  


export const getPostApiTeamMembersMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | CreateTeamMemberWithTokenErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTeamMembers>>, TError,{data: PostApiTeamMembersBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiTeamMembers>>, TError,{data: PostApiTeamMembersBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTeamMembers>>, {data: PostApiTeamMembersBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiTeamMembers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiTeamMembersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTeamMembers>>>
    export type PostApiTeamMembersMutationBody = PostApiTeamMembersBody
    export type PostApiTeamMembersMutationError = ErrorType<AuthenticationErrorSchema | CreateTeamMemberWithTokenErrorSchema>

    /**
 * @summary Adds a user to a team using a team join token
 */
export const usePostApiTeamMembers = <TError = ErrorType<AuthenticationErrorSchema | CreateTeamMemberWithTokenErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTeamMembers>>, TError,{data: PostApiTeamMembersBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiTeamMembers>>,
        TError,
        {data: PostApiTeamMembersBody},
        TContext
      > => {

      const mutationOptions = getPostApiTeamMembersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Invites a user to the team
 */
export const postApiProjectsProjectIdTeamsTeamIdMembers = (
    projectId: string,
    teamId: string,
    postApiProjectsProjectIdTeamsTeamIdMembersBody: PostApiProjectsProjectIdTeamsTeamIdMembersBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamMemberSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/members`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdTeamsTeamIdMembersBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdMembersMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembers>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdMembersBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembers>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdMembersBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembers>>, {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdMembersBody}> = (props) => {
          const {projectId,teamId,data} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdMembers(projectId,teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdMembersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembers>>>
    export type PostApiProjectsProjectIdTeamsTeamIdMembersMutationBody = PostApiProjectsProjectIdTeamsTeamIdMembersBody
    export type PostApiProjectsProjectIdTeamsTeamIdMembersMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Invites a user to the team
 */
export const usePostApiProjectsProjectIdTeamsTeamIdMembers = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembers>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdMembersBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembers>>,
        TError,
        {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdMembersBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdMembersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates a team member
 */
export const patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId = (
    projectId: string,
    teamId: string,
    teamMemberId: number,
    patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamMemberSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/members/${teamMemberId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, TError,{projectId: string;teamId: string;teamMemberId: number;data: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, TError,{projectId: string;teamId: string;teamMemberId: number;data: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, {projectId: string;teamId: string;teamMemberId: number;data: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody}> = (props) => {
          const {projectId,teamId,teamMemberId,data} = props ?? {};

          return  patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(projectId,teamId,teamMemberId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>>
    export type PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationBody = PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody
    export type PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a team member
 */
export const usePatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, TError,{projectId: string;teamId: string;teamMemberId: number;data: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>,
        TError,
        {projectId: string;teamId: string;teamMemberId: number;data: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes an invited team member
 */
export const deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId = (
    projectId: string,
    teamId: string,
    teamMemberId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/teams/${teamId}/members/${teamMemberId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, TError,{projectId: string;teamId: string;teamMemberId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, TError,{projectId: string;teamId: string;teamMemberId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, {projectId: string;teamId: string;teamMemberId: number}> = (props) => {
          const {projectId,teamId,teamMemberId} = props ?? {};

          return  deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(projectId,teamId,teamMemberId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>>
    
    export type DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes an invited team member
 */
export const useDeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>, TError,{projectId: string;teamId: string;teamMemberId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId>>,
        TError,
        {projectId: string;teamId: string;teamMemberId: number},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Archives a team member
 */
export const postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive = (
    projectId: string,
    teamId: string,
    teamMemberId: number,
    postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamMemberSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/members/${teamMemberId}/archive`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive>>, TError,{projectId: string;teamId: string;teamMemberId: number;data: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive>>, TError,{projectId: string;teamId: string;teamMemberId: number;data: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive>>, {projectId: string;teamId: string;teamMemberId: number;data: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody}> = (props) => {
          const {projectId,teamId,teamMemberId,data} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive(projectId,teamId,teamMemberId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive>>>
    export type PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationBody = PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody
    export type PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Archives a team member
 */
export const usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive>>, TError,{projectId: string;teamId: string;teamMemberId: number;data: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive>>,
        TError,
        {projectId: string;teamId: string;teamMemberId: number;data: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves team member issue dependencies
 */
export const getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies = (
    projectId: string,
    teamId: string,
    teamMemberId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamMemberIssueDependencyListSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/members/${teamMemberId}/issue_dependencies`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey = (projectId: string,
    teamId: string,
    teamMemberId: number,) => {
    return [`/api/projects/${projectId}/teams/${teamId}/members/${teamMemberId}/issue_dependencies`] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey(projectId,teamId,teamMemberId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies(projectId,teamId,teamMemberId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId && teamMemberId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>>
export type GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team member issue dependencies
 */

export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesInfiniteQueryOptions(projectId,teamId,teamMemberId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey(projectId,teamId,teamMemberId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies(projectId,teamId,teamMemberId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId && teamMemberId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>>
export type GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team member issue dependencies
 */

export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    teamMemberId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryOptions(projectId,teamId,teamMemberId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Resends invite email to an invited user
 */
export const postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail = (
    projectId: string,
    teamId: string,
    teamMemberId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/teams/${teamId}/members/${teamMemberId}/resend_invite_email`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail>>, TError,{projectId: string;teamId: string;teamMemberId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail>>, TError,{projectId: string;teamId: string;teamMemberId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail>>, {projectId: string;teamId: string;teamMemberId: number}> = (props) => {
          const {projectId,teamId,teamMemberId} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail(projectId,teamId,teamMemberId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail>>>
    
    export type PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Resends invite email to an invited user
 */
export const usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail>>, TError,{projectId: string;teamId: string;teamMemberId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail>>,
        TError,
        {projectId: string;teamId: string;teamMemberId: number},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieve metabase dashboard token
 */
export const getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<MetabaseDashboardSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/metabase_dashboard`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey = (projectId: string,
    teamId: string,) => {
    return [`/api/projects/${projectId}/teams/${teamId}/metabase_dashboard`] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>>
export type GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieve metabase dashboard token
 */

export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardInfiniteQueryOptions(projectId,teamId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>>
export type GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieve metabase dashboard token
 */

export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryOptions(projectId,teamId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Enables a resource
 */
export const postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable = (
    projectId: string,
    teamId: string,
    resourceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ResourceSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/resources/${resourceId}/enable`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable>>, TError,{projectId: string;teamId: string;resourceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable>>, TError,{projectId: string;teamId: string;resourceId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable>>, {projectId: string;teamId: string;resourceId: string}> = (props) => {
          const {projectId,teamId,resourceId} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable(projectId,teamId,resourceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable>>>
    
    export type PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Enables a resource
 */
export const usePostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable>>, TError,{projectId: string;teamId: string;resourceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable>>,
        TError,
        {projectId: string;teamId: string;resourceId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Disables a resource
 */
export const postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable = (
    projectId: string,
    teamId: string,
    resourceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ResourceSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/resources/${resourceId}/disable`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable>>, TError,{projectId: string;teamId: string;resourceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable>>, TError,{projectId: string;teamId: string;resourceId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable>>, {projectId: string;teamId: string;resourceId: string}> = (props) => {
          const {projectId,teamId,resourceId} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable(projectId,teamId,resourceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable>>>
    
    export type PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Disables a resource
 */
export const usePostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable>>, TError,{projectId: string;teamId: string;resourceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable>>,
        TError,
        {projectId: string;teamId: string;resourceId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves resources
 */
export const getApiProjectsProjectIdTeamsTeamIdResourcesKind = (
    projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ResourceListSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/resources/${kind}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey = (projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams,) => {
    return [`/api/projects/${projectId}/teams/${teamId}/resources/${kind}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsTeamIdResourcesKindInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey(projectId,teamId,kind,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdResourcesKind(projectId,teamId,kind,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId && kind), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdResourcesKindInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>>
export type GetApiProjectsProjectIdTeamsTeamIdResourcesKindInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params: undefined |  GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves resources
 */

export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKindInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdResourcesKindInfiniteQueryOptions(projectId,teamId,kind,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey(projectId,teamId,kind,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdResourcesKind(projectId,teamId,kind,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId && kind), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>>
export type GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params: undefined |  GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves resources
 */

export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKind<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryOptions(projectId,teamId,kind,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create resource
 */
export const postApiProjectsProjectIdTeamsTeamIdResourcesKind = (
    projectId: string,
    teamId: string,
    kind: ResourceKindSchema,
    postApiProjectsProjectIdTeamsTeamIdResourcesKindBody: PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ResourceSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/resources/${kind}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdTeamsTeamIdResourcesKindBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError,{projectId: string;teamId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError,{projectId: string;teamId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesKind>>, {projectId: string;teamId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody}> = (props) => {
          const {projectId,teamId,kind,data} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdResourcesKind(projectId,teamId,kind,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesKind>>>
    export type PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationBody = PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody
    export type PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>

    /**
 * @summary Create resource
 */
export const usePostApiProjectsProjectIdTeamsTeamIdResourcesKind = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesKind>>, TError,{projectId: string;teamId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResourcesKind>>,
        TError,
        {projectId: string;teamId: string;kind: ResourceKindSchema;data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves team current subscription plan
 */
export const getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamSubscriptionPlanSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/subscription_plan`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey = (projectId: string,
    teamId: string,) => {
    return [`/api/projects/${projectId}/teams/${teamId}/subscription_plan`] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>>
export type GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team current subscription plan
 */

export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanInfiniteQueryOptions(projectId,teamId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>>
export type GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves team current subscription plan
 */

export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryOptions(projectId,teamId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Start a team subscription update
 */
export const postApiProjectsProjectIdTeamsTeamIdSubscription = (
    projectId: string,
    teamId: string,
    postApiProjectsProjectIdTeamsTeamIdSubscriptionBody: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamSubscriptionUpdateResultSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/subscription`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdTeamsTeamIdSubscriptionBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscription>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscription>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscription>>, {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody}> = (props) => {
          const {projectId,teamId,data} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdSubscription(projectId,teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscription>>>
    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationBody = PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody
    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Start a team subscription update
 */
export const usePostApiProjectsProjectIdTeamsTeamIdSubscription = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscription>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscription>>,
        TError,
        {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Confirm a Stripe Billing subscription
 */
export const postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm = (
    projectId: string,
    teamId: string,
    postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamSubscriptionPlanSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/subscription/confirm`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm>>, {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody}> = (props) => {
          const {projectId,teamId,data} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm(projectId,teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm>>>
    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationBody = PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody
    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Confirm a Stripe Billing subscription
 */
export const usePostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm>>, TError,{projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm>>,
        TError,
        {projectId: string;teamId: string;data: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves an URL for the billing portal
 */
export const postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamSubscriptionBillingPortalSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}/subscription/billing_portal`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal>>, TError,{projectId: string;teamId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal>>, {projectId: string;teamId: string}> = (props) => {
          const {projectId,teamId} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal(projectId,teamId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal>>>
    
    export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Retrieves an URL for the billing portal
 */
export const usePostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal>>,
        TError,
        {projectId: string;teamId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves teams
 */
export const getApiProjectsProjectIdTeams = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamListSchema>(
      {url: `/api/projects/${projectId}/teams`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsQueryKey = (projectId: string,) => {
    return [`/api/projects/${projectId}/teams`] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>> = ({ signal }) => getApiProjectsProjectIdTeams(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>>
export type GetApiProjectsProjectIdTeamsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves teams
 */

export function useGetApiProjectsProjectIdTeamsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsInfiniteQueryOptions(projectId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>> = ({ signal }) => getApiProjectsProjectIdTeams(projectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>>
export type GetApiProjectsProjectIdTeamsQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeams<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeams<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeams<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves teams
 */

export function useGetApiProjectsProjectIdTeams<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeams>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a team
 */
export const postApiProjectsProjectIdTeams = (
    projectId: string,
    postApiProjectsProjectIdTeamsBody: PostApiProjectsProjectIdTeamsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamSchema>(
      {url: `/api/projects/${projectId}/teams`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdTeamsBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeams>>, TError,{projectId: string;data: PostApiProjectsProjectIdTeamsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeams>>, TError,{projectId: string;data: PostApiProjectsProjectIdTeamsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeams>>, {projectId: string;data: PostApiProjectsProjectIdTeamsBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdTeams(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeams>>>
    export type PostApiProjectsProjectIdTeamsMutationBody = PostApiProjectsProjectIdTeamsBody
    export type PostApiProjectsProjectIdTeamsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Creates a team
 */
export const usePostApiProjectsProjectIdTeams = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeams>>, TError,{projectId: string;data: PostApiProjectsProjectIdTeamsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeams>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdTeamsBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a team
 */
export const getApiProjectsProjectIdTeamsTeamId = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdTeamsTeamIdQueryKey = (projectId: string,
    teamId: string,) => {
    return [`/api/projects/${projectId}/teams/${teamId}`] as const;
    }

    
export const getGetApiProjectsProjectIdTeamsTeamIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamId(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>>
export type GetApiProjectsProjectIdTeamsTeamIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a team
 */

export function useGetApiProjectsProjectIdTeamsTeamIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdInfiniteQueryOptions(projectId,teamId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdTeamsTeamIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdTeamsTeamIdQueryKey(projectId,teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>> = ({ signal }) => getApiProjectsProjectIdTeamsTeamId(projectId,teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && teamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdTeamsTeamIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>>
export type GetApiProjectsProjectIdTeamsTeamIdQueryError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>


export function useGetApiProjectsProjectIdTeamsTeamId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdTeamsTeamId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a team
 */

export function useGetApiProjectsProjectIdTeamsTeamId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>>(
 projectId: string,
    teamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdTeamsTeamId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdTeamsTeamIdQueryOptions(projectId,teamId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a team
 */
export const patchApiProjectsProjectIdTeamsTeamId = (
    projectId: string,
    teamId: string,
    patchApiProjectsProjectIdTeamsTeamIdBody: PatchApiProjectsProjectIdTeamsTeamIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamSchema>(
      {url: `/api/projects/${projectId}/teams/${teamId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdTeamsTeamIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdTeamsTeamIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamId>>, TError,{projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamId>>, TError,{projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamId>>, {projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdBody}> = (props) => {
          const {projectId,teamId,data} = props ?? {};

          return  patchApiProjectsProjectIdTeamsTeamId(projectId,teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdTeamsTeamIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamId>>>
    export type PatchApiProjectsProjectIdTeamsTeamIdMutationBody = PatchApiProjectsProjectIdTeamsTeamIdBody
    export type PatchApiProjectsProjectIdTeamsTeamIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a team
 */
export const usePatchApiProjectsProjectIdTeamsTeamId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamId>>, TError,{projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdTeamsTeamId>>,
        TError,
        {projectId: string;teamId: string;data: PatchApiProjectsProjectIdTeamsTeamIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdTeamsTeamIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a team
 */
export const deleteApiProjectsProjectIdTeamsTeamId = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/teams/${teamId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdTeamsTeamIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamId>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamId>>, TError,{projectId: string;teamId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamId>>, {projectId: string;teamId: string}> = (props) => {
          const {projectId,teamId} = props ?? {};

          return  deleteApiProjectsProjectIdTeamsTeamId(projectId,teamId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdTeamsTeamIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamId>>>
    
    export type DeleteApiProjectsProjectIdTeamsTeamIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a team
 */
export const useDeleteApiProjectsProjectIdTeamsTeamId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamId>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdTeamsTeamId>>,
        TError,
        {projectId: string;teamId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdTeamsTeamIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Resends invite email to all invited users on the team
 */
export const postApiProjectsProjectIdTeamsTeamIdResendMembersInvites = (
    projectId: string,
    teamId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/teams/${teamId}/resend_members_invites`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResendMembersInvites>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResendMembersInvites>>, TError,{projectId: string;teamId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResendMembersInvites>>, {projectId: string;teamId: string}> = (props) => {
          const {projectId,teamId} = props ?? {};

          return  postApiProjectsProjectIdTeamsTeamIdResendMembersInvites(projectId,teamId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResendMembersInvites>>>
    
    export type PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Resends invite email to all invited users on the team
 */
export const usePostApiProjectsProjectIdTeamsTeamIdResendMembersInvites = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResendMembersInvites>>, TError,{projectId: string;teamId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdTeamsTeamIdResendMembersInvites>>,
        TError,
        {projectId: string;teamId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves time zones
 */
export const getApiTimeZones = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TimeZoneListSchema>(
      {url: `/api/time_zones`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiTimeZonesQueryKey = () => {
    return [`/api/time_zones`] as const;
    }

    
export const getGetApiTimeZonesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiTimeZones>>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiTimeZonesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTimeZones>>> = ({ signal }) => getApiTimeZones(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiTimeZonesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTimeZones>>>
export type GetApiTimeZonesInfiniteQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiTimeZonesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTimeZones>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTimeZones>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTimeZonesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTimeZones>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTimeZones>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTimeZonesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTimeZones>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves time zones
 */

export function useGetApiTimeZonesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiTimeZones>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiTimeZonesInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiTimeZonesQueryOptions = <TData = Awaited<ReturnType<typeof getApiTimeZones>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiTimeZonesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTimeZones>>> = ({ signal }) => getApiTimeZones(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiTimeZonesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTimeZones>>>
export type GetApiTimeZonesQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiTimeZones<TData = Awaited<ReturnType<typeof getApiTimeZones>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTimeZones>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTimeZones<TData = Awaited<ReturnType<typeof getApiTimeZones>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiTimeZones>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiTimeZones<TData = Awaited<ReturnType<typeof getApiTimeZones>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves time zones
 */

export function useGetApiTimeZones<TData = Awaited<ReturnType<typeof getApiTimeZones>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTimeZones>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiTimeZonesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Sends a confirmation email
 */
export const postApiUsersConfirmationInstructions = (
    postApiUsersConfirmationInstructionsBody: PostApiUsersConfirmationInstructionsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/users/confirmation/instructions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiUsersConfirmationInstructionsBody
    },
      options);
    }
  


export const getPostApiUsersConfirmationInstructionsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersConfirmationInstructions>>, TError,{data: PostApiUsersConfirmationInstructionsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUsersConfirmationInstructions>>, TError,{data: PostApiUsersConfirmationInstructionsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUsersConfirmationInstructions>>, {data: PostApiUsersConfirmationInstructionsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiUsersConfirmationInstructions(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUsersConfirmationInstructionsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUsersConfirmationInstructions>>>
    export type PostApiUsersConfirmationInstructionsMutationBody = PostApiUsersConfirmationInstructionsBody
    export type PostApiUsersConfirmationInstructionsMutationError = ErrorType<unknown>

    /**
 * @summary Sends a confirmation email
 */
export const usePostApiUsersConfirmationInstructions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersConfirmationInstructions>>, TError,{data: PostApiUsersConfirmationInstructionsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUsersConfirmationInstructions>>,
        TError,
        {data: PostApiUsersConfirmationInstructionsBody},
        TContext
      > => {

      const mutationOptions = getPostApiUsersConfirmationInstructionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Confirms a user
 */
export const postApiUsersConfirmation = (
    postApiUsersConfirmationBody: PostApiUsersConfirmationBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users/confirmation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiUsersConfirmationBody
    },
      options);
    }
  


export const getPostApiUsersConfirmationMutationOptions = <TError = ErrorType<ConfirmEmailErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersConfirmation>>, TError,{data: PostApiUsersConfirmationBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUsersConfirmation>>, TError,{data: PostApiUsersConfirmationBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUsersConfirmation>>, {data: PostApiUsersConfirmationBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiUsersConfirmation(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUsersConfirmationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUsersConfirmation>>>
    export type PostApiUsersConfirmationMutationBody = PostApiUsersConfirmationBody
    export type PostApiUsersConfirmationMutationError = ErrorType<ConfirmEmailErrorSchema>

    /**
 * @summary Confirms a user
 */
export const usePostApiUsersConfirmation = <TError = ErrorType<ConfirmEmailErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersConfirmation>>, TError,{data: PostApiUsersConfirmationBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUsersConfirmation>>,
        TError,
        {data: PostApiUsersConfirmationBody},
        TContext
      > => {

      const mutationOptions = getPostApiUsersConfirmationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates a user
 */
export const postApiUsers = (
    postApiUsersBody: PostApiUsersBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiUsersBody
    },
      options);
    }
  


export const getPostApiUsersMutationOptions = <TError = ErrorType<ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsers>>, TError,{data: PostApiUsersBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUsers>>, TError,{data: PostApiUsersBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUsers>>, {data: PostApiUsersBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiUsers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUsers>>>
    export type PostApiUsersMutationBody = PostApiUsersBody
    export type PostApiUsersMutationError = ErrorType<ErrorSchema>

    /**
 * @summary Creates a user
 */
export const usePostApiUsers = <TError = ErrorType<ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsers>>, TError,{data: PostApiUsersBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUsers>>,
        TError,
        {data: PostApiUsersBody},
        TContext
      > => {

      const mutationOptions = getPostApiUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves current user
 */
export const getApiUsersMe = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users/me`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiUsersMeQueryKey = () => {
    return [`/api/users/me`] as const;
    }

    
export const getGetApiUsersMeInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiUsersMe>>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUsersMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersMe>>> = ({ signal }) => getApiUsersMe(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiUsersMeInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersMe>>>
export type GetApiUsersMeInfiniteQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiUsersMeInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiUsersMe>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersMe>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiUsersMeInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiUsersMe>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersMe>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiUsersMeInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiUsersMe>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves current user
 */

export function useGetApiUsersMeInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiUsersMe>>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiUsersMeInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiUsersMeQueryOptions = <TData = Awaited<ReturnType<typeof getApiUsersMe>>, TError = ErrorType<AuthenticationErrorSchema>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUsersMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersMe>>> = ({ signal }) => getApiUsersMe(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiUsersMeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersMe>>>
export type GetApiUsersMeQueryError = ErrorType<AuthenticationErrorSchema>


export function useGetApiUsersMe<TData = Awaited<ReturnType<typeof getApiUsersMe>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersMe>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiUsersMe<TData = Awaited<ReturnType<typeof getApiUsersMe>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersMe>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiUsersMe<TData = Awaited<ReturnType<typeof getApiUsersMe>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves current user
 */

export function useGetApiUsersMe<TData = Awaited<ReturnType<typeof getApiUsersMe>>, TError = ErrorType<AuthenticationErrorSchema>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiUsersMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates current user
 */
export const patchApiUsersMe = (
    patchApiUsersMeBody: PatchApiUsersMeBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users/me`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiUsersMeBody
    },
      options);
    }
  


export const getPatchApiUsersMeMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiUsersMe>>, TError,{data: PatchApiUsersMeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiUsersMe>>, TError,{data: PatchApiUsersMeBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiUsersMe>>, {data: PatchApiUsersMeBody}> = (props) => {
          const {data} = props ?? {};

          return  patchApiUsersMe(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiUsersMeMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiUsersMe>>>
    export type PatchApiUsersMeMutationBody = PatchApiUsersMeBody
    export type PatchApiUsersMeMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema>

    /**
 * @summary Updates current user
 */
export const usePatchApiUsersMe = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiUsersMe>>, TError,{data: PatchApiUsersMeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiUsersMe>>,
        TError,
        {data: PatchApiUsersMeBody},
        TContext
      > => {

      const mutationOptions = getPatchApiUsersMeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Sends password reset instructions
 */
export const postApiUsersPasswordInstructions = (
    postApiUsersPasswordInstructionsBody: PostApiUsersPasswordInstructionsBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/users/password/instructions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiUsersPasswordInstructionsBody
    },
      options);
    }
  


export const getPostApiUsersPasswordInstructionsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersPasswordInstructions>>, TError,{data: PostApiUsersPasswordInstructionsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUsersPasswordInstructions>>, TError,{data: PostApiUsersPasswordInstructionsBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUsersPasswordInstructions>>, {data: PostApiUsersPasswordInstructionsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiUsersPasswordInstructions(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUsersPasswordInstructionsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUsersPasswordInstructions>>>
    export type PostApiUsersPasswordInstructionsMutationBody = PostApiUsersPasswordInstructionsBody
    export type PostApiUsersPasswordInstructionsMutationError = ErrorType<unknown>

    /**
 * @summary Sends password reset instructions
 */
export const usePostApiUsersPasswordInstructions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersPasswordInstructions>>, TError,{data: PostApiUsersPasswordInstructionsBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUsersPasswordInstructions>>,
        TError,
        {data: PostApiUsersPasswordInstructionsBody},
        TContext
      > => {

      const mutationOptions = getPostApiUsersPasswordInstructionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Updates password with reset token
 */
export const patchApiUsersPassword = (
    patchApiUsersPasswordBody: PatchApiUsersPasswordBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/users/password`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiUsersPasswordBody
    },
      options);
    }
  


export const getPatchApiUsersPasswordMutationOptions = <TError = ErrorType<ResetPasswordErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiUsersPassword>>, TError,{data: PatchApiUsersPasswordBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiUsersPassword>>, TError,{data: PatchApiUsersPasswordBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiUsersPassword>>, {data: PatchApiUsersPasswordBody}> = (props) => {
          const {data} = props ?? {};

          return  patchApiUsersPassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiUsersPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiUsersPassword>>>
    export type PatchApiUsersPasswordMutationBody = PatchApiUsersPasswordBody
    export type PatchApiUsersPasswordMutationError = ErrorType<ResetPasswordErrorSchema>

    /**
 * @summary Updates password with reset token
 */
export const usePatchApiUsersPassword = <TError = ErrorType<ResetPasswordErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiUsersPassword>>, TError,{data: PatchApiUsersPasswordBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiUsersPassword>>,
        TError,
        {data: PatchApiUsersPasswordBody},
        TContext
      > => {

      const mutationOptions = getPatchApiUsersPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves issue watchings
 */
export const getApiProjectsProjectIdIssuesIssueIdWatchings = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<WatchingListSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/watchings`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey = (projectId: string,
    issueId: string,) => {
    return [`/api/projects/${projectId}/issues/${issueId}/watchings`] as const;
    }

    
export const getGetApiProjectsProjectIdIssuesIssueIdWatchingsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdWatchings(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdWatchingsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>>
export type GetApiProjectsProjectIdIssuesIssueIdWatchingsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdWatchingsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdWatchingsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdWatchingsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue watchings
 */

export function useGetApiProjectsProjectIdIssuesIssueIdWatchingsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdWatchingsInfiniteQueryOptions(projectId,issueId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdIssuesIssueIdWatchingsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey(projectId,issueId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>> = ({ signal }) => getApiProjectsProjectIdIssuesIssueIdWatchings(projectId,issueId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && issueId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>>
export type GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdIssuesIssueIdWatchings<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdWatchings<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdIssuesIssueIdWatchings<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves issue watchings
 */

export function useGetApiProjectsProjectIdIssuesIssueIdWatchings<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    issueId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdIssuesIssueIdWatchings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdIssuesIssueIdWatchingsQueryOptions(projectId,issueId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Watches an issue
 */
export const postApiProjectsProjectIdIssuesIssueIdWatchings = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WatchingSchema>(
      {url: `/api/projects/${projectId}/issues/${issueId}/watchings`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdIssuesIssueIdWatchingsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdWatchings>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdWatchings>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdWatchings>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  postApiProjectsProjectIdIssuesIssueIdWatchings(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdWatchings>>>
    
    export type PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Watches an issue
 */
export const usePostApiProjectsProjectIdIssuesIssueIdWatchings = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdWatchings>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdIssuesIssueIdWatchings>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdIssuesIssueIdWatchingsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Unwatches an issue
 */
export const deleteApiProjectsProjectIdIssuesIssueIdWatchings = (
    projectId: string,
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WatchingSchema | void>(
      {url: `/api/projects/${projectId}/issues/${issueId}/watchings`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdWatchings>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdWatchings>>, TError,{projectId: string;issueId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdWatchings>>, {projectId: string;issueId: string}> = (props) => {
          const {projectId,issueId} = props ?? {};

          return  deleteApiProjectsProjectIdIssuesIssueIdWatchings(projectId,issueId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdWatchings>>>
    
    export type DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Unwatches an issue
 */
export const useDeleteApiProjectsProjectIdIssuesIssueIdWatchings = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdWatchings>>, TError,{projectId: string;issueId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdIssuesIssueIdWatchings>>,
        TError,
        {projectId: string;issueId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a weekly work plan activities
 */
export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities = (
    projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<WeeklyWorkPlanActivityListSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey = (projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams,) => {
    return [`/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey(projectId,weeklyWorkPlanId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(projectId,weeklyWorkPlanId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params: undefined |  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan activities
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesInfiniteQueryOptions(projectId,weeklyWorkPlanId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey(projectId,weeklyWorkPlanId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(projectId,weeklyWorkPlanId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params: undefined |  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan activities
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryOptions(projectId,weeklyWorkPlanId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a weekly work plan activity
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities = (
    projectId: string,
    weeklyWorkPlanId: string,
    postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WeeklyWorkPlanActivitySchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, {projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody}> = (props) => {
          const {projectId,weeklyWorkPlanId,data} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(projectId,weeklyWorkPlanId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>>
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationBody = PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates a weekly work plan activity
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a weekly work plan activity
 */
export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId = (
    projectId: string,
    weeklyWorkPlanId: string,
    activityId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<WeeklyWorkPlanActivitySchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities/${activityId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey = (projectId: string,
    weeklyWorkPlanId: string,
    activityId: string,) => {
    return [`/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities/${activityId}`] as const;
    }

    
export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey(projectId,weeklyWorkPlanId,activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(projectId,weeklyWorkPlanId,activityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId && activityId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan activity
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdInfiniteQueryOptions(projectId,weeklyWorkPlanId,activityId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey(projectId,weeklyWorkPlanId,activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(projectId,weeklyWorkPlanId,activityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId && activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan activity
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryOptions(projectId,weeklyWorkPlanId,activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a weekly work plan activity
 */
export const patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId = (
    projectId: string,
    weeklyWorkPlanId: string,
    activityId: string,
    patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WeeklyWorkPlanActivitySchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities/${activityId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody
    },
      options);
    }
  


export const getPatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;data: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;data: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, {projectId: string;weeklyWorkPlanId: string;activityId: string;data: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody}> = (props) => {
          const {projectId,weeklyWorkPlanId,activityId,data} = props ?? {};

          return  patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(projectId,weeklyWorkPlanId,activityId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>
    export type PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationBody = PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody
    export type PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Updates a weekly work plan activity
 */
export const usePatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;data: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;activityId: string;data: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a weekly work plan activity
 */
export const deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId = (
    projectId: string,
    weeklyWorkPlanId: string,
    activityId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities/${activityId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, {projectId: string;weeklyWorkPlanId: string;activityId: string}> = (props) => {
          const {projectId,weeklyWorkPlanId,activityId} = props ?? {};

          return  deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(projectId,weeklyWorkPlanId,activityId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>>
    
    export type DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>

    /**
 * @summary Deletes a weekly work plan activity
 */
export const useDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId = <TError = ErrorType<AuthenticationErrorSchema | ErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;activityId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Sorts weekly work plan activities
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort = (
    projectId: string,
    weeklyWorkPlanId: string,
    postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities/sort`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort>>, {projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody}> = (props) => {
          const {projectId,weeklyWorkPlanId,data} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort(projectId,weeklyWorkPlanId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort>>>
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationBody = PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Sorts weekly work plan activities
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates a weekly work plan activity scheduled day
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays = (
    projectId: string,
    weeklyWorkPlanId: string,
    activityId: string,
    postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities/${activityId}/scheduled_days`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays>>, {projectId: string;weeklyWorkPlanId: string;activityId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody}> = (props) => {
          const {projectId,weeklyWorkPlanId,activityId,data} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays(projectId,weeklyWorkPlanId,activityId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays>>>
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationBody = PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Creates a weekly work plan activity scheduled day
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;activityId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deletes a weekly work plan activity scheduled day
 */
export const deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate = (
    projectId: string,
    weeklyWorkPlanId: string,
    activityId: string,
    date: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/activities/${activityId}/scheduled_days/${date}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;date: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;date: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate>>, {projectId: string;weeklyWorkPlanId: string;activityId: string;date: string}> = (props) => {
          const {projectId,weeklyWorkPlanId,activityId,date} = props ?? {};

          return  deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate(projectId,weeklyWorkPlanId,activityId,date,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate>>>
    
    export type DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Deletes a weekly work plan activity scheduled day
 */
export const useDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate>>, TError,{projectId: string;weeklyWorkPlanId: string;activityId: string;date: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;activityId: string;date: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a weekly work plan progress logs
 */
export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs = (
    projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ShiftActivityProgressLogListSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/progress_logs`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey = (projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams,) => {
    return [`/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/progress_logs`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey(projectId,weeklyWorkPlanId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs(projectId,weeklyWorkPlanId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params: undefined |  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan progress logs
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsInfiniteQueryOptions(projectId,weeklyWorkPlanId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey(projectId,weeklyWorkPlanId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs(projectId,weeklyWorkPlanId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params: undefined |  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan progress logs
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryOptions(projectId,weeklyWorkPlanId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves all team weekly work plans
 */
export const getApiProjectsProjectIdWeeklyWorkPlans = (
    projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<WeeklyWorkPlanListSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdWeeklyWorkPlansQueryKey = (projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams,) => {
    return [`/api/projects/${projectId}/weekly_work_plans`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiProjectsProjectIdWeeklyWorkPlansInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlans(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansInfiniteQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdWeeklyWorkPlansParams, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves all team weekly work plans
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansInfiniteQueryOptions(projectId,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdWeeklyWorkPlansQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlans(projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansQueryError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlans<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params: undefined |  GetApiProjectsProjectIdWeeklyWorkPlansParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlans<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlans<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves all team weekly work plans
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlans<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>>(
 projectId: string,
    params?: GetApiProjectsProjectIdWeeklyWorkPlansParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlans>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Creates a weekly work plan
 */
export const postApiProjectsProjectIdWeeklyWorkPlans = (
    projectId: string,
    postApiProjectsProjectIdWeeklyWorkPlansBody: PostApiProjectsProjectIdWeeklyWorkPlansBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WeeklyWorkPlanSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdWeeklyWorkPlansBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlans>>, TError,{projectId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlans>>, TError,{projectId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlans>>, {projectId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlans(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlans>>>
    export type PostApiProjectsProjectIdWeeklyWorkPlansMutationBody = PostApiProjectsProjectIdWeeklyWorkPlansBody
    export type PostApiProjectsProjectIdWeeklyWorkPlansMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Creates a weekly work plan
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlans = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlans>>, TError,{projectId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlans>>,
        TError,
        {projectId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retrieves a weekly work plan
 */
export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId = (
    projectId: string,
    weeklyWorkPlanId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<WeeklyWorkPlanSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey = (projectId: string,
    weeklyWorkPlanId: string,) => {
    return [`/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}`] as const;
    }

    
export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey(projectId,weeklyWorkPlanId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(projectId,weeklyWorkPlanId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfiniteQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options: { query:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfinite<TData = InfiniteData<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdInfiniteQueryOptions(projectId,weeklyWorkPlanId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey(projectId,weeklyWorkPlanId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>> = ({ signal }) => getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(projectId,weeklyWorkPlanId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && weeklyWorkPlanId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>
export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryError = ErrorType<AuthenticationErrorSchema | void>


export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves a weekly work plan
 */

export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId<TData = Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError = ErrorType<AuthenticationErrorSchema | void>>(
 projectId: string,
    weeklyWorkPlanId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryOptions(projectId,weeklyWorkPlanId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Updates a weekly work plan
 */
export const putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId = (
    projectId: string,
    weeklyWorkPlanId: string,
    putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WeeklyWorkPlanSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody
    },
      options);
    }
  


export const getPutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, {projectId: string;weeklyWorkPlanId: string;data: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody}> = (props) => {
          const {projectId,weeklyWorkPlanId,data} = props ?? {};

          return  putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(projectId,weeklyWorkPlanId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>>
    export type PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationBody = PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody
    export type PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Updates a weekly work plan
 */
export const usePutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;data: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdBody},
        TContext
      > => {

      const mutationOptions = getPutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Closes a weekly work plan
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose = (
    projectId: string,
    weeklyWorkPlanId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WeeklyWorkPlanSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/close`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose>>, {projectId: string;weeklyWorkPlanId: string}> = (props) => {
          const {projectId,weeklyWorkPlanId} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose(projectId,weeklyWorkPlanId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose>>>
    
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>

    /**
 * @summary Closes a weekly work plan
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Duplicates the weekly work plan
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate = (
    projectId: string,
    weeklyWorkPlanId: string,
    postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WeeklyWorkPlanSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/duplicate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationOptions = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate>>, {projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody}> = (props) => {
          const {projectId,weeklyWorkPlanId,data} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate(projectId,weeklyWorkPlanId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate>>>
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationBody = PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>

    /**
 * @summary Duplicates the weekly work plan
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate = <TError = ErrorType<ErrorSchema | AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate>>, TError,{projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string;data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateBody},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Requests a weekly work plan export
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport = (
    projectId: string,
    weeklyWorkPlanId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/export`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport>>, {projectId: string;weeklyWorkPlanId: string}> = (props) => {
          const {projectId,weeklyWorkPlanId} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport(projectId,weeklyWorkPlanId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport>>>
    
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Requests a weekly work plan export
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Requests a weekly work plan lookback export
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport = (
    projectId: string,
    weeklyWorkPlanId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/lookback/export`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport>>, {projectId: string;weeklyWorkPlanId: string}> = (props) => {
          const {projectId,weeklyWorkPlanId} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport(projectId,weeklyWorkPlanId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport>>>
    
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationError = ErrorType<AuthenticationErrorSchema | void>

    /**
 * @summary Requests a weekly work plan lookback export
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport = <TError = ErrorType<AuthenticationErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Publishes a weekly work plan
 */
export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish = (
    projectId: string,
    weeklyWorkPlanId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WeeklyWorkPlanSchema>(
      {url: `/api/projects/${projectId}/weekly_work_plans/${weeklyWorkPlanId}/publish`, method: 'POST'
    },
      options);
    }
  


export const getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationOptions = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish>>, {projectId: string;weeklyWorkPlanId: string}> = (props) => {
          const {projectId,weeklyWorkPlanId} = props ?? {};

          return  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish(projectId,weeklyWorkPlanId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish>>>
    
    export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>

    /**
 * @summary Publishes a weekly work plan
 */
export const usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish = <TError = ErrorType<AuthenticationErrorSchema | void | ErrorSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish>>, TError,{projectId: string;weeklyWorkPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish>>,
        TError,
        {projectId: string;weeklyWorkPlanId: string},
        TContext
      > => {

      const mutationOptions = getPostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
