import * as React from 'react';
import type { SVGProps } from 'react';
const Bars3Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/bars-3">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40002 5.70005C2.40002 5.20299 2.80297 4.80005 3.30002 4.80005H20.7C21.1971 4.80005 21.6 5.20299 21.6 5.70005C21.6 6.19711 21.1971 6.60005 20.7 6.60005H3.30002C2.80297 6.60005 2.40002 6.19711 2.40002 5.70005ZM2.40002 12.0001C2.40002 11.503 2.80297 11.1001 3.30002 11.1001H20.7C21.1971 11.1001 21.6 11.503 21.6 12.0001C21.6 12.4971 21.1971 12.9001 20.7 12.9001H3.30002C2.80297 12.9001 2.40002 12.4971 2.40002 12.0001ZM2.40002 18.3001C2.40002 17.803 2.80297 17.4001 3.30002 17.4001H20.7C21.1971 17.4001 21.6 17.803 21.6 18.3001C21.6 18.7971 21.1971 19.2001 20.7 19.2001H3.30002C2.80297 19.2001 2.40002 18.7971 2.40002 18.3001Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default Bars3Icon;
