import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { Popover } from '@shape-construction/arch-ui';
import { PopoverContentProps } from '@shape-construction/arch-ui/src/Popover/PopoverContent/Content';
import { useProductTipsPopover } from './hooks/useProductTipsPopover';

export type ProductTipsGeneralPopoverProps = PopoverContentProps;

export const ProductTipsGeneralPopover: React.FC<ProductTipsGeneralPopoverProps> = ({
  ...popoverProps
}) => {
  const productTourMessages = useMessageGetter('productTour');
  const { closeProductTipsPopover } = useProductTipsPopover();

  return (
    <Popover.Root open>
      <Popover.Trigger className="absolute w-8 h-8" />

      <Popover.Content
        color="discovery"
        onClose={closeProductTipsPopover}
        sideOffset={6}
        {...popoverProps}
      >
        <Popover.Content.Heading>
          {productTourMessages('generalPopover.title')}
        </Popover.Content.Heading>
        <Popover.Content.Body>
          {productTourMessages('generalPopover.description')}
        </Popover.Content.Body>
      </Popover.Content>
    </Popover.Root>
  );
};
