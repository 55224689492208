class ShapeAnalyticsWrapper {
  private static instance: ShapeAnalyticsWrapper;

  public isInitialized: boolean;
  private gtag!: Gtag.Gtag;

  private constructor() {
    this.isInitialized = false;
  }

  public static getInstance(): ShapeAnalyticsWrapper {
    if (!ShapeAnalyticsWrapper.instance) {
      ShapeAnalyticsWrapper.instance = new ShapeAnalyticsWrapper();
    }

    return ShapeAnalyticsWrapper.instance;
  }

  public initialize(analyticsId: string) {
    if (!this.isInitialized) {
      this.setupDefaultConsentScript();
      this.setupGtmScript(analyticsId);
      this.gtag = window.gtag;
      this.isInitialized = true;
    }
  }

  public consent({ analyticsStorage }: { analyticsStorage?: boolean }) {
    if (this.isInitialized) {
      // from https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic
      this.gtag('consent', 'update', {
        analytics_storage: analyticsStorage ? 'granted' : 'denied',
      });
    }
  }

  private setupDefaultConsentScript() {
    const script = document.createElement('script');

    // from https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() { dataLayer.push(arguments); }
      gtag('consent', 'default', {
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 500,
      });
      dataLayer.push({'gtm.start': new Date().getTime(), 'event': 'gtm.js'});
    `;

    document.head.appendChild(script);
  }

  private setupGtmScript(analyticsId: string) {
    // from https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${analyticsId}`;
    document.head.appendChild(gtmScript);
  }
}

export const ShapeAnalytics = ShapeAnalyticsWrapper.getInstance();
