import { useCallback } from 'react';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useMatch } from 'react-router-dom';
import {
  useDeleteApiProjectsProjectIdTeamsTeamId,
  usePatchApiProjectsProjectIdTeamsTeamId,
  usePostApiProjectsProjectIdTeams,
  useGetApiProjectsProjectIdTeamsTeamId,
  useGetApiTeamJoinTokensToken,
  getGetApiProjectsProjectIdTeamsQueryKey,
  getGetApiProjectsProjectIdTeamsQueryOptions,
  getGetApiProjectsProjectIdTeamsTeamIdQueryOptions,
} from '@shape-construction/api/api';
import type {
  IssueGroupCountEntitySchemaIdentifier,
  ProjectSchema,
} from '@shape-construction/api/model';
import { useProject } from './projects';

// queries
export const useCurrentTeamId = () => {
  // Using useMatch here instead of useParam because this hook should be usable
  // outside the context of Router
  const match = useMatch('/projects/:projectId/*');
  const { data: project } = useProject(match?.params?.projectId!);
  return project?.currentTeamId;
};

export const getProjectTeamsQueryOptions = getGetApiProjectsProjectIdTeamsQueryOptions;

/**
 * @deprecated Use getProjectTeamQueryOptions instead
 */
export const useProjectTeam = useGetApiProjectsProjectIdTeamsTeamId;
export const getProjectTeamQueryOptions = getGetApiProjectsProjectIdTeamsTeamIdQueryOptions;

export const useTeamGetter = (projectId: ProjectSchema['id']) => {
  const { data } = useSuspenseQuery(getProjectTeamsQueryOptions(projectId));

  return useCallback(
    (teamId: IssueGroupCountEntitySchemaIdentifier) => data?.find(({ id }) => id === teamId),
    [data]
  );
};

export const useTeamJoinToken = useGetApiTeamJoinTokensToken;

// mutations
export const useCreateTeam = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdTeams({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdTeamsTeamId({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdTeamsTeamId({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};
