import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-right">
      <path
        id="Icon"
        d="M16.2864 12.6364C16.6379 12.2849 16.6379 11.7151 16.2864 11.3636L9.7864 4.86358C9.43492 4.51211 8.86508 4.51211 8.5136 4.86358C8.16213 5.21505 8.16213 5.7849 8.5136 6.13637L14.3772 12L8.5136 17.8636C8.16213 18.2151 8.16213 18.7849 8.5136 19.1364C8.86508 19.4878 9.43492 19.4878 9.7864 19.1364L16.2864 12.6364Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChevronRightIcon;
