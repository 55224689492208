export const BUILDER_ISSUE = 'BUILDER_ISSUE';

// Toast messages
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

// Layout Actions
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';

// Notifications
export const NOTIFICATIONS_PERMISSIONS_ACCEPT = 'NOTIFICATIONS_PERMISSIONS_ACCEPT';
export const NOTIFICATIONS_PERMISSIONS_REJECT = 'NOTIFICATIONS_PERMISSIONS_REJECT';
export const USER_CONSENT_ACCEPT = 'USER_CONSENT_ACCEPT';
export const USER_CONSENT_CLOSE = 'USER_CONSENT_CLOSE';
export const USER_CONSENT_DENY = 'USER_CONSENT_DENY';

export const RESET_STATE = 'RESET_STATE';
