import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-left">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4 12C20.4 12.497 19.997 12.9 19.5 12.9H6.73447L11.7238 17.6512C12.0821 17.9958 12.0932 18.5655 11.7487 18.9238C11.4042 19.2821 10.8345 19.2933 10.4762 18.9487L3.87618 12.6487C3.69971 12.4791 3.59998 12.2448 3.59998 12C3.59998 11.7552 3.69971 11.5209 3.87618 11.3512L10.4762 5.05124C10.8345 4.70673 11.4042 4.7179 11.7487 5.07619C12.0932 5.43449 12.0821 6.00422 11.7238 6.34874L6.73447 11.1L19.5 11.1C19.997 11.1 20.4 11.5029 20.4 12Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ArrowLeftIcon;
