import React from 'react';
import { ModalBaseHeader, ModalBaseHeaderProps } from '../../ModalBase/components/ModalBaseHeader';

export type DrawerHeaderProps = ModalBaseHeaderProps;

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({ children, onClose }) => {
  return (
    <ModalBaseHeader onClose={onClose} bottomBorder className="min-h-fit px-6">
      {children}
    </ModalBaseHeader>
  );
};
