import React, { useEffect, useRef } from 'react';
import { useDebounceCallback } from '@shape-construction/hooks';
import { Combobox, ComboboxInputProps } from '@headlessui/react';
import classNames from 'clsx';
import { twMerge } from 'tailwind-merge';
import { ExclamationCircleIcon, TrashIcon, XCircleIcon } from '../../Icons/solid';
import { MagnifyingGlassIcon } from '../../Icons/outline';
import { PrimitiveProps } from '../../utils/render';
import * as Tooltip from '../../Tooltip/index';

export type Variant = 'bordered' | 'plain';

export type OwnProps = {
  /**
   * Text to render when there's no value
   */
  placeholder?: string;
  /**
   * Callback called when user changes the search value. It is debounced for 250ms
   */
  selectedValue?: unknown;
  onChange: (value: string) => void;
  onClear?: () => void;
  onDelete?: () => void;
  variant?: Variant;
  withSearchIcon?: boolean;
  autoFocus?: boolean;
  error?: string;
};

export type SearchFieldProps<TTag> = PrimitiveProps<
  Omit<ComboboxInputProps<'input', TTag>, 'onChange'>
> &
  OwnProps;

const DeleteButton = ({ onClick }: { onClick?: () => void }) =>
  onClick ? (
    <button type="button" title="Delete" aria-label="Delete" onClick={onClick}>
      <TrashIcon className="w-5 h-5 text-icon-neutral-subtle" />
    </button>
  ) : null;

const ClearButton = ({ onClick }: { onClick?: () => void }) =>
  onClick ? (
    <button type="button" title="Clear" aria-label="Clear" onClick={onClick}>
      <XCircleIcon className="w-5 h-5 text-icon-neutral-subtle" />
    </button>
  ) : null;

export function SearchField<TTag>({
  placeholder = 'Search',
  onChange,
  onClear,
  onDelete,
  selectedValue,
  variant = 'bordered',
  withSearchIcon = true,
  className,
  autoFocus = false,
  error,
  ...props
}: SearchFieldProps<TTag>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedOnChange = useDebounceCallback(onChange, 250);

  useEffect(() => {
    // Making sure the input gets focused/blured when autoFocus changes state and component is already mounted
    if (autoFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [autoFocus]);

  return (
    <div className="flex items-center gap-2">
      <div
        className={classNames(
          'relative w-full cursor-default overflow-hidden rounded-lg text-left sm:text-sm',
          'border ring-gray-300 border-gray-300 rounded-lg',
          'hover:ring-2',
          'focus-within:ring-2 focus-within:ring-indigo-500',
          {
            'bg-white': variant !== 'plain',
            'border-0': variant === 'plain',
            'border-red-700': !!error,
          }
        )}
      >
        {withSearchIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-3 flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
          </div>
        )}
        <Combobox.Input
          ref={inputRef}
          className={twMerge(
            classNames(
              'w-full border-none py-2  text-sm leading-5 text-gray-900 placeholder-gray-500',
              'focus:ring-0 focus:placeholder-gray-400',
              { 'pl-9': withSearchIcon },
              { 'pr-9': error || (selectedValue && (onClear || onDelete)) },
              { 'pr-16': selectedValue && onClear && onDelete },
              { 'text-icon-danger-bold hover:text-gray-900': error && variant === 'plain' }
            ),
            className
          )}
          onChange={(event) => debouncedOnChange(event.target.value)}
          placeholder={placeholder}
          autoComplete="off"
          autoFocus={autoFocus}
          {...props}
        />
        {!!selectedValue && (
          <div className="absolute inset-y-0 right-2 flex items-center gap-x-1">
            <DeleteButton onClick={onDelete} />
            <ClearButton onClick={onClear} />
          </div>
        )}
        {error && (
          <div className="absolute inset-y-0 right-2 flex items-center">
            <Tooltip.Root delayDuration={0}>
              <Tooltip.Trigger
                aria-label="search-field-error-icon-trigger"
                onClick={(e) => e.preventDefault()}
              >
                <ExclamationCircleIcon className="w-5 h-5 text-icon-danger-bold" />
              </Tooltip.Trigger>
              <Tooltip.Content side="bottom" className="z-10">
                {error}
              </Tooltip.Content>
            </Tooltip.Root>
          </div>
        )}
      </div>
    </div>
  );
}
SearchField.displayName = 'Search.Field';
