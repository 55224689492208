import React from 'react';
import { XMarkIcon } from '../../Icons/solid';
import { IconButton } from '../../Button';

export type ModalBaseCloseButtonProps = {
  className?: string;
  onClose: () => void;
};

export const ModalBaseCloseButton: React.FC<ModalBaseCloseButtonProps> = ({ onClose }) => {
  return (
    <IconButton icon={XMarkIcon} onClick={onClose} size="sm" color="secondary" variant="text" />
  );
};
