import React from 'react';
import InputPassword from '../InputPassword';
import InputText from '../InputText';
import InputEmail from '../InputEmail';
import InputCheckbox from '../InputCheckbox';
import Button from '../Button';
import { AuthPagesHeader } from '../AuthPagesHeader/AuthPagesHeader';
import { InputExtraPropsType } from '../types/InputBase';
import { CreateAccountBottomSection } from './components/CreateAccountBottomSection';

type Components = {
  BottomSection: typeof CreateAccountBottomSection;
};

export type ExtraPropsType = {
  email: InputExtraPropsType;
  password?: InputExtraPropsType;
  firstName: InputExtraPropsType;
  lastName: InputExtraPropsType;
  acceptedEua: InputExtraPropsType;
};

export interface CreateAccountProps {
  /**
   * Renders the content of the checkbox
   */
  euaLabel?: React.ReactNode;
  /**
   * Link component for the log in page
   */
  notYouLink: React.ReactNode;
  /**
   * Logo to be displayed on the top section
   */
  logo?: React.ReactNode;
  /**
   * function to be called on the submit of form
   */
  submitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  /**
   * Renders the feedback/response after the submission of form
   */
  submitFeedback?: React.ReactNode;
  /**
   * Disables submit button while submitting the form
   */
  disableSubmitButton: boolean;
  /**
   * Stores the error messages for password input
   */
  passwordError?: string;
  /**
   * Stores the error messages for firstName input
   */
  firstNameError?: string;
  /**
   * Stores the error messages for lastName input
   */
  lastNameError?: string;
  /**
   * Stores the error messages for acceptedEua input
   */
  acceptedEuaError?: string;
  /**
   * Extra props to register an input and apply validation rules to React Hook Form
   */
  extraProps: ExtraPropsType;
  /**
   * Element to be rendered at the end of the component
   */
  children?: React.ReactNode;
  /**
   * Title of the page
   */
  title?: string;
  /**
   * If user is on a provider signup flow
   */
  isProviderSignup?: boolean;
}

export const CreateAccount: React.FC<CreateAccountProps> & Components = ({
  euaLabel,
  notYouLink,
  logo,
  submitForm,
  submitFeedback,
  disableSubmitButton,
  passwordError,
  firstNameError,
  lastNameError,
  acceptedEuaError,
  extraProps,
  children,
  title,
  isProviderSignup = false,
}) => {
  return (
    <div className="flex flex-col justify-center">
      <AuthPagesHeader title={title ? title : 'Create an account'} logo={logo} />

      {submitFeedback && <div className="mt-8">{submitFeedback}</div>}

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8">
          <form className="space-y-6" onSubmit={submitForm} noValidate>
            <InputEmail
              {...extraProps.email}
              name="email"
              label="Email"
              cornerAdornment={notYouLink}
              disabled
            />
            <InputText
              {...extraProps.firstName}
              name="firstName"
              label="First name"
              error={firstNameError}
              required
            />
            <InputText
              {...extraProps.lastName}
              name="lastName"
              label="Last name"
              error={lastNameError}
              required
            />
            {!isProviderSignup && (
              <InputPassword
                {...extraProps.password}
                name="password"
                label="Password"
                error={passwordError}
                required
                showVisibilityToggle
              />
            )}
            <InputCheckbox
              {...extraProps.acceptedEua}
              name="acceptedEua"
              label={euaLabel}
              error={acceptedEuaError}
              required
            />

            <Button
              color="primary"
              variant="contained"
              size="md"
              type="submit"
              disabled={disableSubmitButton}
              fullWidth
            >
              Create account
            </Button>
          </form>
        </div>
        {children}
      </div>
    </div>
  );
};

CreateAccount.BottomSection = CreateAccountBottomSection;
