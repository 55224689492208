/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Shape API
 * OpenAPI spec version: v1
 */

export type TeamMemberRoleSchema = (typeof TeamMemberRoleSchema)[keyof typeof TeamMemberRoleSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamMemberRoleSchema = {
  viewer: 'viewer',
  contributor: 'contributor',
  admin: 'admin',
  owner: 'owner',
} as const;
