import * as React from 'react';
import type { SVGProps } from 'react';
const ShapeProjectGalleryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="outline/shape-project-gallery">
      <path
        id="Icon"
        d="M5 20.25H4.75C3.64543 20.25 2.75 19.3546 2.75 18.25V6C2.75 5.30964 3.30964 4.75 4 4.75H8C8.55228 4.75 9 5.19772 9 5.75V6.25C9 6.52614 9.22386 6.75 9.5 6.75H16.75C17.3023 6.75 17.75 7.19772 17.75 7.75V9M18.0147 19.6584L21.2124 12.5105C21.5824 11.6835 20.9773 10.75 20.0714 10.75H7.64814C7.25384 10.75 6.89634 10.9817 6.73532 11.3416L3.53757 18.4895C3.16764 19.3165 3.77271 20.25 4.6786 20.25H17.1019C17.4962 20.25 17.8537 20.0183 18.0147 19.6584Z"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
export default ShapeProjectGalleryIcon;
