import { useCallback, useMemo, useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import isEqual from 'lodash.isequal';
import {
  IssueViewGroupBySchema,
  ProjectSchema,
  IssueViewSchemaSortBy,
  IssueViewSchemaSortOrder,
} from '@shape-construction/api/model';
import { useStableCallback } from '@shape-construction/hooks';
import { parseDate } from '@shape-construction/utils/DateTime';
import {
  PredefinedOptionKey,
  datePickerPredefinedOptionsMap,
} from 'app/components/Filters/DateSelect/DateSelectOptions';
import { useProjectIssueViews } from 'app/queries/issues/views/views';
import { TabOption } from '../../IssueList/issue-tab-options';
import {
  CurrentSorting,
  SortingOption,
  defaultTabSorting,
} from '../../IssueList/IssueSortingOptions';
import { tabIds } from '../../types';
import { ISSUES_FILTERS_KEY, emptyFilters } from '../constants';
import type { FiltersStorageData, IssuesFilterFormValues, IssuesFilters } from '../types/types';
import { mapSavedViews } from './saved-views-helpers';
import { defaultTabGrouping, IssueGroupKey } from './useGroupingOptions';

export const localFiltersAtom = atom(emptyFilters);

const storageFiltersAtom = atomWithStorage<FiltersStorageData>(ISSUES_FILTERS_KEY, {});

const updateStorageFiltersAtom = atom(
  null,
  (
    get,
    set,
    update: { projectId: ProjectSchema['id']; tabId: TabOption; filters: Partial<IssuesFilters> }
  ) => {
    const currentStorageFiltersData = get(storageFiltersAtom);

    set(storageFiltersAtom, {
      ...currentStorageFiltersData,
      [update.projectId]: {
        ...currentStorageFiltersData[update.projectId],
        [update.tabId]: update.filters,
      },
    });
  }
);

export const removeIssuesFilters = () => {
  localStorage.removeItem(ISSUES_FILTERS_KEY);
};

export const toGroupItems = (groupBy?: IssueViewGroupBySchema): null | IssueGroupKey[] => {
  if (!groupBy) return null;
  return (Array.isArray(groupBy) ? groupBy : [groupBy]) as IssueGroupKey[];
};

const dateFiltersFromIssues = (dateObj: IssuesFilters['date']) => {
  if (!dateObj) return undefined;

  const relativeDate = dateObj?.relative_date as PredefinedOptionKey;
  if (!relativeDate) {
    return {
      date: undefined,
      endDate: undefined,
    };
  }

  const range =
    relativeDate === 'custom'
      ? datePickerPredefinedOptionsMap.custom.value(dateObj?.date, dateObj?.end_date)
      : datePickerPredefinedOptionsMap[relativeDate].value();

  return {
    date: range.from!,
    end_date: range.to!,
  };
};

export const useInitialValues = (projectId: ProjectSchema['id'], tabId: TabOption) => {
  const { data: viewTabs } = useProjectIssueViews(projectId);

  const emptyView: IssuesFilters = useMemo(
    () => ({
      ...emptyFilters,
      group_by: defaultTabGrouping[tabId] as IssuesFilters['group_by'],
      ...((defaultTabSorting[tabId] ?? defaultTabSorting.all) as Pick<
        IssuesFilters,
        'sort_order' | 'sort_by'
      >),
    }),
    [tabId]
  );

  const defaultFilters = useMemo(() => {
    // setting the filters for each view tab after retrieval.
    const viewTabFilters: Record<string, IssuesFilters> = mapSavedViews(viewTabs);
    // setting the empty filters for the predefined tabs.
    const preDefinedTabs: Record<string, IssuesFilters> = Object.values(tabIds).reduce(
      (acc, predefinedTabId) => ({
        ...acc,
        [predefinedTabId]: {
          ...emptyFilters,
          group_by: defaultTabGrouping[predefinedTabId],
          ...defaultTabSorting[predefinedTabId],
        },
      }),
      {}
    );

    return {
      ...preDefinedTabs,
      ...viewTabFilters,
    };
  }, [viewTabs]);

  const [currentStorageFilters] = useAtom(storageFiltersAtom);
  const currentStorageFiltersByTab = useMemo(
    () => currentStorageFilters[projectId] || {},
    [currentStorageFilters, projectId]
  );

  const initialValues: IssuesFilterFormValues = useMemo(
    () => ({
      impact: currentStorageFiltersByTab[tabId]?.impact || defaultFilters[tabId]?.impact,
      filter_state:
        currentStorageFiltersByTab[tabId]?.filter_state || defaultFilters[tabId]?.filter_state,
      location: currentStorageFiltersByTab[tabId]?.location || defaultFilters[tabId]?.location,
      observer: currentStorageFiltersByTab[tabId]?.observer || defaultFilters[tabId]?.observer,
      responsible_team:
        currentStorageFiltersByTab[tabId]?.responsible_team ||
        defaultFilters[tabId]?.responsible_team ||
        [],
      responsible:
        currentStorageFiltersByTab[tabId]?.responsible || defaultFilters[tabId]?.responsible,
      date: currentStorageFiltersByTab[tabId]?.date || defaultFilters[tabId]?.date,
      group_by: currentStorageFiltersByTab[tabId]?.group_by || defaultFilters[tabId]?.group_by,
      group_properties:
        currentStorageFiltersByTab[tabId]?.group_properties ||
        defaultFilters[tabId]?.group_properties,
      sort_by: currentStorageFiltersByTab[tabId]?.sort_by || defaultFilters[tabId]?.sort_by,
      sort_order:
        currentStorageFiltersByTab[tabId]?.sort_order || defaultFilters[tabId]?.sort_order,
    }),
    [tabId, currentStorageFiltersByTab, defaultFilters]
  );

  return {
    defaultFilters,
    emptyView,
    initialValues,
  };
};

export const useIssuesFilters = (projectId: ProjectSchema['id'], tabId: TabOption) => {
  const { defaultFilters, initialValues, emptyView } = useInitialValues(projectId, tabId);

  const [localFilters, setLocalFilters] = useAtom(localFiltersAtom);

  useEffect(() => {
    setLocalFilters(initialValues);
  }, [initialValues, setLocalFilters]);

  const clearLocalFilters = () => {
    setLocalFilters({ ...emptyView });
  };

  const [, updateStorageFiltersData] = useAtom(updateStorageFiltersAtom);
  const updateStorageFilters = useCallback(() => {
    updateStorageFiltersData({ projectId, tabId, filters: localFilters });
  }, [projectId, tabId, localFilters, updateStorageFiltersData]);

  const resetStorageFilters = useCallback(() => {
    updateStorageFiltersData({ projectId, tabId, filters: initialValues });
  }, [initialValues, projectId, tabId, updateStorageFiltersData]);

  const resetDefaultFilters = () => {
    setLocalFilters(defaultFilters[tabId]);
  };

  const queryParamsFromLocalFilters = useMemo(() => {
    if (!localFilters) return {};

    const {
      date,
      group_by: _groupBy,
      group_properties: _groupProperties,
      sort_by: _sortBy,
      sort_order: _sortOrder,
      ...otherParams
    } = localFilters;
    const newDate = dateFiltersFromIssues(date);
    return {
      ...otherParams,
      ...(newDate
        ? {
            published_date_end: parseDate(newDate.end_date as Date).format('YYYY-MM-DD'),
            published_date_start: parseDate(newDate.date as Date).format('YYYY-MM-DD'),
          }
        : {}),
    };
  }, [localFilters]);

  const setGroupingItems = useStableCallback((groups: IssueGroupKey[]) =>
    setLocalFilters({ ...localFilters, group_by: groups as IssueViewGroupBySchema })
  );

  const currentGrouping = useMemo(
    () => ({
      groupingItems: toGroupItems(localFilters.group_by) ?? [],
      groupingProperties: localFilters.group_properties,
      setGroupingItems,
    }),
    [localFilters, setGroupingItems]
  );

  const setSortBy = useStableCallback((sort: SortingOption['sort_by']) =>
    setLocalFilters({ ...localFilters, sort_by: sort as IssueViewSchemaSortBy })
  );
  const setSortOrder = useStableCallback((order: IssueViewSchemaSortOrder) =>
    setLocalFilters({ ...localFilters, sort_order: order })
  );

  const currentSorting: CurrentSorting = useMemo(
    () => ({
      currentSortingOptions: {
        sort_by: localFilters?.sort_by ?? defaultFilters[tabId]?.sort_by,
        sort_order: localFilters?.sort_order ?? defaultFilters[tabId]?.sort_order,
      } as SortingOption,
      setSortBy,
      setSortOrder,
      reset: (_: any) =>
        setLocalFilters({
          ...localFilters,
          sort_by: defaultFilters[tabId]?.sort_by,
          sort_order: defaultFilters[tabId]?.sort_order,
        }),
    }),
    [tabId, localFilters, defaultFilters, setSortBy, setSortOrder, setLocalFilters]
  );

  const isEqualToInitialValues = useMemo(() => {
    return isEqual(localFilters, initialValues);
  }, [localFilters, initialValues]);

  return {
    localFilters,
    isEqualToInitialValues,
    queryParamsFromLocalFilters,
    currentGrouping,
    currentSorting,
    updateLocalFilters: setLocalFilters,
    clearLocalFilters,
    updateStorageFilters,
    resetStorageFilters,
    resetDefaultFilters,
  };
};
