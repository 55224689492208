import React, { FC, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import classNames from 'clsx';
import { ChevronRightIcon } from '../Icons/solid';
import ButtonPanel from '../ButtonPanel';
import Badge from '../Badge';
import { SHAPE, SIZE, THEME } from '../Badge/Badge.types';
import { SearchField } from '../SearchField';
import { MagnifyingGlassIcon } from '../Icons/outline';

export interface TreeSelectorProps {
  /**
   * Name attribute for the element to be used together with onSelect to set the selected node id.
   */
  name?: string;
  /**
   * Array of nodes from which the tree will be built
   */
  nodes: Node[];
  /**
   * Default selected node
   */
  defaultSelectedNodeId?: string | null;
  /**
   * Tree root node - will be default selected if defaultSelectedNodeId is not provided
   */
  rootNodeId: string;
  /**
   * Callback function that calls with the selected node id as the argument
   */
  onSelect?: (name: string, nodeId: string) => void;
  /**
   * The text that will appear in the search box
   */
  searchFieldPlaceholder?: string;
  /**
   * optional boolean prop that determines whether or not the search field is hidden
   */
  hideSearchField?: boolean;
}

export interface Node {
  id: string;
  parentId: string | null;
  name: string;
  shortCode: string;
  hasChildren?: boolean;
}

const defaultNode = {
  id: '',
  parentId: null,
  name: '',
  shortCode: '',
};

const findNode = (nodeId: string, nodes: Node[]) => {
  const node = nodes.find((searchedNode) => searchedNode.id === nodeId);
  if (!node) throw new Error(`Could not find node with id ${nodeId}`);
  return node;
};

const getNodeChildren = (passedNode: Node, nodes: Node[]) =>
  nodes.filter(
    (node: Node) => node.parentId === (passedNode.hasChildren ? passedNode.id : passedNode.parentId)
  );

const getNodeParents = (nodeId: string, nodes: Node[], list: Node[] = []): Node[] => {
  const node = findNode(nodeId, nodes);
  const newList = list ? [node, ...list] : [node];
  return node.parentId ? getNodeParents(node.parentId, nodes, newList) : newList;
};

export const TreeSelector: FC<TreeSelectorProps> = ({
  defaultSelectedNodeId,
  rootNodeId,
  nodes,
  onSelect,
  name = '',
  searchFieldPlaceholder,
  hideSearchField,
}) => {
  const [currentNode, setCurrentNode] = useState<Node>(defaultNode);
  const [currentNodeOptions, setCurrentNodeOptions] = useState<Node[]>([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState<Node[]>([]);
  const [search, setSearch] = useState<string | null>(null);

  const clearSearch = () => {
    setSearch('');
  };

  const searchNodes = useMemo(() => {
    if (!search) return null;

    return nodes
      .filter((node) => {
        const nodeName = node.name.toLowerCase();
        const nodeShortCode = node.shortCode?.toLowerCase();
        const searchWord = search.toLowerCase();

        return nodeName.includes(searchWord) || nodeShortCode?.includes(searchWord);
      })
      .map((node) => ({ ...node, hasChildren: false }));
  }, [nodes, search]);

  const onSearch = (searchWord: string) => {
    if (!searchWord) clearSearch();
    setSearch(searchWord);
  };

  const getNodeParentsPath = (node: Node) => {
    const nodeParents = getNodeParents(node.id, nodes)
      .filter((nodeParent) => nodeParent.id !== node.id)
      .map((nodeParent) => nodeParent.name);

    // remove the first level
    nodeParents.shift();

    if (nodeParents.length > 0) return `${nodeParents.join(' > ')} > `;
    return '';
  };

  const setNodeParents = useCallback(
    (nodeId: string): void => {
      const nodeParents = getNodeParents(nodeId, nodes);
      setBreadcrumbItems(nodeParents.map((node) => node));
    },
    [setBreadcrumbItems, nodes]
  );

  const selectNode = useCallback(
    (nodeId: string): void => {
      setCurrentNode(findNode(nodeId, nodes));
      setNodeParents(nodeId);
    },
    [setCurrentNode, setNodeParents, nodes]
  );

  const resetSelection = useCallback((): void => {
    clearSearch();

    selectNode(rootNodeId);
    if (onSelect) onSelect(name, rootNodeId);
  }, [rootNodeId, selectNode, onSelect, name]);

  useEffect(() => {
    selectNode(defaultSelectedNodeId || rootNodeId);
  }, [defaultSelectedNodeId, rootNodeId, selectNode]);

  useEffect(() => {
    setCurrentNodeOptions(searchNodes || getNodeChildren(currentNode, nodes));
  }, [currentNode, nodes, searchNodes]);

  const handleClickOption = (nodeId: string): void => {
    selectNode(nodeId);
    if (onSelect) onSelect(name, nodeId);
  };

  return (
    <div className="flex flex-1 flex-col">
      {!hideSearchField && (
        <div className="px-4 py-2">
          <SearchField
            onChange={(event) => onSearch(event.target.value)}
            placeholder={searchFieldPlaceholder || 'Search'}
            value={search || ''}
          />
        </div>
      )}
      <div className="flex items-baseline border-b border-gray-200 bg-gray-50 px-4 pt-4 pb-2">
        <div className="flex flex-1 flex-wrap">
          {React.Children.map(
            breadcrumbItems.map((item, index) => (
              <div className="mb-2" key={item.id}>
                {index !== 0 && (
                  <ChevronRightIcon className="inline h-5 w-5 text-gray-500" aria-hidden="true" />
                )}
                <Badge
                  key={item.id}
                  label={item.name}
                  onRemove={() => {
                    if (item.parentId) {
                      clearSearch();
                      handleClickOption(item.parentId);
                    }
                  }}
                  shape={SHAPE.BASIC}
                  size={SIZE.LARGE}
                  theme={item.id === currentNode.id ? THEME.BLUE : THEME.GRAY}
                  withRemoveButton={index !== 0}
                />
              </div>
            )),
            (item) => item
          )}
        </div>
        <div
          role="button"
          onClick={resetSelection}
          className="pl-3 text-sm font-medium text-indigo-500 hover:text-indigo-400"
          tabIndex={0}
        >
          Clear all
        </div>
      </div>

      <div className="flex flex-1 flex-col space-y-2 overflow-y-auto p-4">
        {currentNodeOptions.length > 0 &&
          currentNodeOptions.map((node) => (
            <ButtonPanel key={node.id} onClick={() => handleClickOption(node.id)}>
              <div
                className={classNames('overflow-hidden rounded-md border border-gray-300', {
                  'bg-indigo-500 text-white hover:bg-indigo-400': node.id === currentNode.id,
                  'bg-white hover:bg-gray-50': node.id !== currentNode.id,
                })}
              >
                <div className="flex items-center p-4">
                  <div className="flex flex-col flex-1">
                    {!searchNodes && <div>{node.name}</div>}
                    {searchNodes && (
                      <div className="inline">
                        <span className="opacity-50">{getNodeParentsPath(node)}</span>
                        <span className="inline-flex font-semibold">{node.name}</span>
                      </div>
                    )}
                  </div>
                  {node.hasChildren && (
                    <div className="ml-5 shrink-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  )}
                </div>
              </div>
            </ButtonPanel>
          ))}
        {currentNodeOptions.length === 0 && (
          <div className="flex h-full w-full flex-col items-center justify-center gap-y-2">
            <MagnifyingGlassIcon className="h-12 w-12 text-gray-400" />
            <p className="text-sm font-medium text-gray-900">No matching search results</p>
            <p className="text-sm text-gray-500">Please check spelling or try different keywords</p>
          </div>
        )}
      </div>
    </div>
  );
};
