import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Tree as DndTree,
  TreeProps as DndTreeProps,
  getDescendants,
} from '@minoru/react-dnd-treeview';
import type {
  NodeModel,
  RenderParams as CoreRenderParams,
  TreeMethods as CoreTreeMethods,
} from '@minoru/react-dnd-treeview/dist/types';
import { TreeItem, TreeItemProps } from './components/TreeItem';

export type TreeData<T> = NodeModel<T>;
export type { NodeModel };

export type RenderParams<T> = CoreRenderParams & {
  checked: boolean;
  onCheck: (node: NodeModel<T>, checked: boolean) => void;
};

export type TreeMethods = CoreTreeMethods & {
  checkAll: () => void;
  uncheckAll: () => void;
};

export type TreeProps<T> = Omit<DndTreeProps<T>, 'render' | 'tree' | 'onDrop'> & {
  data: NodeModel<T>[];
  enableDrag?: boolean;
  itemRef?: React.Ref<HTMLInputElement>;
  checkedOptions?: NodeModel<T>['id'][];
  onDrop?: DndTreeProps<T>['onDrop'];
  onCheck?: (checkedIds: NodeModel<T>['id'][]) => void;
  render?: (
    node: NodeModel<T>,
    params: TreeItemProps<T>['params']
  ) => React.ReactElement | undefined;
};

export const TreeInner = <T,>(
  {
    data,
    enableDrag = true,
    canDrag,
    itemRef,
    initialOpen,
    checkedOptions,
    render,
    onCheck,
    onDrop = () => {},
    ...treeProps
  }: TreeProps<T>,
  ref?: React.ForwardedRef<TreeMethods>
) => {
  const [internalCheckedOptions, setInternalCheckedOptions] = useState<NodeModel<T>['id'][]>([]);
  const treeRef = useRef<CoreTreeMethods>(null);
  const defaultInitialOpen = data.map(({ id }) => id);

  const checkLocation = (node: NodeModel<T>, checked: boolean) => {
    const descendents = getDescendants(data, node.id).map(({ id }) => id as string);
    const list = [node.id as string, ...descendents];

    setInternalCheckedOptions((nodes) => {
      let newCheckedList: NodeModel<T>['id'][];

      if (checked) newCheckedList = [...new Set([...nodes, ...list])];
      else newCheckedList = nodes.filter((id) => !list.includes(id as string));

      onCheck?.(newCheckedList);

      return newCheckedList;
    });
  };

  const uncheckAll = () => {
    const options: NodeModel<T>['id'][] = [];
    setInternalCheckedOptions(options);
    onCheck?.(options);
  };

  const checkAll = () => {
    const options: NodeModel<T>['id'][] = data.map(({ id }) => id) as string[];
    setInternalCheckedOptions(options);
    onCheck?.(options);
  };

  const getOptionParams = (
    node: NodeModel<T>,
    coreParams: CoreRenderParams
  ): TreeItemProps<T>['params'] => ({
    ...coreParams,
    draggable: Boolean(enableDrag),
    checked: internalCheckedOptions.includes(node.id as string),
    onCheck: checkLocation,
  });

  useImperativeHandle(ref, () => ({
    ...treeRef.current!,
    checkAll,
    uncheckAll,
  }));

  useEffect(() => {
    if (checkedOptions) setInternalCheckedOptions(checkedOptions);
  }, [checkedOptions]);

  return (
    <DndProvider backend={HTML5Backend}>
      <DndTree
        ref={treeRef}
        classes={{
          draggingSource: 'opacity-20',
          dropTarget: 'bg-blue-100',
        }}
        canDrop={(_, { dragSource, dropTargetId }) => {
          if (dragSource?.parent === dropTargetId) return true;
        }}
        onDrop={onDrop}
        sort={false}
        insertDroppableFirst={false}
        initialOpen={initialOpen ?? defaultInitialOpen}
        tree={data}
        canDrag={(params) => {
          if (!enableDrag) return false;
          return canDrag?.(params) || true;
        }}
        render={(node, coreParams) => {
          const params = getOptionParams(node, coreParams);
          return render?.(node, params) ?? <TreeItem ref={itemRef} node={node} params={params} />;
        }}
        {...treeProps}
      />
    </DndProvider>
  );
};

export const Tree = forwardRef(TreeInner) as <T>(
  props: TreeProps<T> & { ref?: React.ForwardedRef<TreeMethods> }
) => ReturnType<typeof TreeInner>;
